import { apiClient } from './ApiClient';

function getAllIntegrations() {
    return apiClient().get('/admin/integration/');
}

function getIntegrationById(id) {
    return apiClient().get('/admin/integration/' + id);
}

function changeIntegrationStatus(data) {
    return apiClient().put('/admin/integration/', data);
}

function sendConfigIntegration(data) {
    return apiClient().put('/admin/integration', data);
}

export default {
    getAllIntegrations,
    getIntegrationById,
    changeIntegrationStatus,
    sendConfigIntegration
}
