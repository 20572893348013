import { apiClient } from './ApiClient'

function getAllItems() {
    return apiClient().get('/admin/item/')
}

function getItemById(id) {
    return apiClient().get('/admin/item/' + id)
}

function editItem(data) {
    return apiClient().patch('/admin/item/', data)
}

function createItemPrototype(data) {
    return apiClient().post('admin/item/product', data)
}

function duplicateItemById(id) {
    return apiClient().post('admin/item/duplicate/' + id)
}

function deleteItemById(id) {
    return apiClient().delete('admin/item/' + id)
}

export default {
    getAllItems,
    getItemById,
    editItem,
    createItemPrototype,
    duplicateItemById,
    deleteItemById
}
