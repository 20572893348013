import { GET_ALL_NOTIFICATIONS, UPDATE_NOTIFICATIONS } from '../actions/types'

const defaultState = {}

export default (state = defaultState, action) => {
    const { type, payload, error = {} } = action

    switch (type) {
        case GET_ALL_NOTIFICATIONS:
            return { ...state, ...payload }
        case UPDATE_NOTIFICATIONS:
            return []
        default:
            return state
    }
}