import { apiClient } from './ApiClient'

function getAllRegions() {
    return apiClient().get('/admin/region/')
}

function addRegion(data) {
    return apiClient().post('/admin/region/', data)
}

function getRegionById(id) {
    return apiClient().get('/admin/region/' + id)
}

function editRegion(data) {
    return apiClient().patch('/admin/region/', data)
}

function deleteRegionById(id) {
    return apiClient().delete('/admin/region/' + id)
}

function getAllShipper() {
    return apiClient().get('/admin/region/shipper')
}

export default {
    getAllRegions,
    addRegion,
    getRegionById,
    editRegion,
    deleteRegionById,
    getAllShipper
}
