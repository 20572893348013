import { applyMiddleware, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import AsyncStorage from '@react-native-async-storage/async-storage'
import thunk from 'redux-thunk'
import reducers from '../reducers'

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['auth', 'orders'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const middleware = [thunk]

const store = createStore(persistedReducer, applyMiddleware(...middleware))

export const persistor = persistStore(store, null)

export default store
