import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteRegionById, editRegion, getAllRegions } from '../actions/regions';
import countries from '../utils/countries.json';
import { NotificationManager } from 'react-notifications';

const Delivery = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const componentRef = useRef();

    const [regions, setRegions] = useState([]);
    const [regionsItem, setRegionsItem] = useState([]);

    const [idRegion, setIdRegion] = useState(null);
    const [dropdown, setDropdown] = useState(false);
    const [showModalConfirmDisable, setShowModalConfirmDisable] = useState(false);
    const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
    const [idShippingMethod, setIdShippingMethod] = useState();

    // SMALL SEARCH
    const [searchInput, setSearchInput] = useState();

    // LOADING SPINNER
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    // DROPDOWN FILTER
    const [dropdownFilter, setDropdownFilter] = useState(false);
    const [statusFilterCheckbox, setStatusFilterCheckbox] = useState(false);
    const [activeFilter, setActiveFilter] = useState(false);

    const [filter, setFilter] = useState({});
    const [filterData, setFilterData] = useState([]);

    // CHECKBOX CHECKED
    const [checkedRow, setCheckedRow] = useState([]);


    useEffect(() => {
        setLoadingSpinner(true);

        dispatch(getAllRegions()).then((res) => {
            setLoadingSpinner(false);
            setRegions(res);
            setRegionsItem(res);


            let filteredShipping = filterShipping(res, searchInput, filterData);

            setRegions(filteredShipping);
        })
    }, [searchInput, filterData]);

    const filterShipping = (shippings, searchInput, filterData) => {
        return shippings.filter(shipping => {
            if (searchInput && (
                shipping.name.toLowerCase().indexOf(searchInput?.toLowerCase()) === -1
            )) {
                return false;
            }
            if (filterData) {
                if (filterData.disabled !== undefined && filterData.disabled !== shipping.disabled) {
                    return false;
                }
            }
            return true;
        });
    }

    const handleDropdownForeach = (row) => {
        setDropdown(row);
    }

    const handleShowModalConfirmDisable = (id) => {
        setDropdown(false);
        setShowModalConfirmDisable(true);
        setIdShippingMethod(id);

        if (showModalConfirmDisable) {
            setShowModalConfirmDisable(false);
        }
    }

    const handleShowModalConfirmDelete = (id) => {
        setDropdown(false);
        setIdRegion(id);
        setShowModalConfirmDelete(true);

        if (showModalConfirmDelete) {
            setShowModalConfirmDelete(false);
        }
    }

    const handleCloseModalConfirmDisable = () => {
        setShowModalConfirmDisable(false);
    }

    const handleCloseModalConfirmDelete = () => {
        setShowModalConfirmDelete(false);
    }

    const handleDeleteShipping = (id) => {
        dispatch(deleteRegionById(id)).then((res) => {
            setRegions(res);
            setShowModalConfirmDelete(false);
            NotificationManager.success('Votre zone d’expédition a bien été supprimé !', 'Succès')
        })
    }

    const handleDisabledShippingMethod = () => {
        const data = {
            id: idShippingMethod,
            disabled: true,
        }

        setDropdown(false)
        setShowModalConfirmDisable(false);

        editRegion(data).then(() => {
            NotificationManager.success("La méthode d'expédition a été désactiver avec succès !", 'Succès')

            dispatch(getAllRegions())
                .then((res) => {
                    setRegions(res);
                })
        })
    }

    const handleActivatedShippingMethod = (id) => {
        const data = {
            id: id,
            disabled: false,
        }

        setDropdown(false)
        setShowModalConfirmDisable(false);

        editRegion(data)
            .then(() => {
                NotificationManager.success("La méthode d'expédition a été activer avec succès !", 'Succès')

                dispatch(getAllRegions())
                    .then((res) => {
                        setRegions(res);
                    })
            })
            .catch((err) => {
                NotificationManager.error(err?.response?.data?.error, 'Erreur')
            })
    }

    const handleSmallSearch = (query) => {
        setSearchInput(query);
        var arrItemSearch = []
        const arrSearch = [];

        arrSearch.push(regionsItem)

        for (var index = 0; index < arrSearch.length; index++) {
            if (!!arrSearch[index]) {
                arrItemSearch.push(Object.values(arrSearch[index]));
            }
        }

        const results = arrItemSearch[0].filter((item) => {
            return item?.name.toString().toLowerCase().indexOf(query.toLowerCase()) > -1
        })

        setRegions(results)
    }

    const handleClearFilter = () => {
        setStatusFilterCheckbox(false);
        setActiveFilter(false);
        setDropdownFilter(false);
        setFilter({});
        setFilterData();
        setSearchInput();

        dispatch(getAllRegions()).then((res) => {
            setLoadingSpinner(false);
            setRegions(res);
            setRegionsItem(res);
        })
    }

    const handleApplyFilter = () => {
        if (Object.keys(filter).length !== 0) {
            setActiveFilter(true);
            setDropdownFilter(false);

            setFilterData(filter)
        } else {
            NotificationManager.error('Merci de bien vouloir sélectionner des filtres avant d\'appliquer !', 'Erreur')
        }
    }

    const handleStatusCheckbox = () => {
        setStatusFilterCheckbox(true);

        if (statusFilterCheckbox)
            setStatusFilterCheckbox(false);
    }

    const handleFilterDropdown = () => {
        setDropdownFilter(true);

        if (dropdownFilter)
            setDropdownFilter(false);
    }

    const handleCheckRow = (id) => {
        if (checkedRow?.includes(id)) {
            const newCheckedRow = checkedRow.filter((checkedId) => checkedId !== id)
            setCheckedRow(newCheckedRow)
        } else {
            setCheckedRow([...checkedRow, id])
        }
    }

    const handleCheckAllRow = () => {
        if (checkedRow.length === Object.keys(regions).length) {
            setCheckedRow([])
        } else {
            const allRegionsIds = Object.keys(regions).reduce((acc, key) => {
                const region = regions[key]
                if (region) {
                    acc.push(region._id)
                }
                return acc
            }, []);

            setCheckedRow(allRegionsIds)
        }
    }

    return (
        <div className='container-margin'>
            <div onClick={() => { setDropdown(false); setDropdownFilter(false) }} className={`${dropdown !== false && "mydropdown"} ${dropdownFilter !== false && "mydropdown"}`}></div>

            {showModalConfirmDisable &&
                <>
                    <div>
                        <div onClick={() => handleCloseModalConfirmDisable()} className="myModal"></div>
                        <div className="center-absolutely bg-[white] rounded w-4/12 mt-10">

                            <div className="flex row justify-between">
                                <div className="px-6 mt-4">
                                    <h1 className="text-xl font-semibold mb-1">Désactiver la zone d'expédition</h1>
                                    <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mb-4">Attention en désactivant cette zone d'expédition, nous désactiverons toutes les données liées à cette zone d'expédition.</p>
                                </div>

                                <div className="px-6 mt-4">
                                    <span data-state="closed" className="cursor-pointer" onClick={() => handleCloseModalConfirmDisable()}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex text-grey-50">
                                            <path d="M15 5L5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M5 5L15 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-indigo-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => handleCloseModalConfirmDisable()}
                                >
                                    Annuler
                                </button>
                                <button
                                    className="bg-red-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => handleDisabledShippingMethod()}
                                >

                                    Désactiver
                                </button>
                            </div>


                        </div>
                    </div>
                </>
            }

            {showModalConfirmDelete &&
                <>
                    <div>
                        <div onClick={() => handleCloseModalConfirmDelete()} className="myModal"></div>
                        <div className="center-absolutely bg-[white] rounded w-4/12 mt-10">

                            <div className="flex row justify-between">
                                <div className="px-6 mt-4">
                                    <h1 className="text-xl font-semibold mb-1">Supprimer la zone d'expédition</h1>
                                    <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mb-4">Attention en supprimant cette zone d'expédition, nous supprimerons toutes les données liées à cette zone d'expédition.</p>
                                </div>

                                <div className="px-6 mt-4">
                                    <span data-state="closed" className="cursor-pointer" onClick={() => handleCloseModalConfirmDelete()}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex text-grey-50">
                                            <path d="M15 5L5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M5 5L15 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-indigo-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => handleCloseModalConfirmDelete()}
                                >
                                    Annuler
                                </button>
                                <button
                                    className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => handleDeleteShipping(idRegion)}
                                >
                                    Supprimer
                                </button>
                            </div>


                        </div>
                    </div>
                </>
            }

            {/* BREADCRUMBS */}
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                    <a href="/" className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-900">
                        <svg width="15px" height="15px" viewBox="0 0 50 50" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.8889 0H8.33333C6.04167 0 3.95833 0.936111 2.44722 2.44722C0.936111 3.95833 0 6.04167 0 8.33333V13.8889C0 16.1806 0.936111 18.2639 2.44722 19.775C3.95833 21.2861 6.04167 22.2222 8.33333 22.2222H13.8889C16.1806 22.2222 18.2639 21.2861 19.775 19.775C21.2861 18.2639 22.2222 16.1806 22.2222 13.8889V8.33333C22.2222 6.04167 21.2861 3.95833 19.775 2.44722C18.2639 0.936111 16.1806 0 13.8889 0Z" />
                            <path d="M41.6667 0H36.1112C33.8195 0 31.7362 0.936111 30.2251 2.44722C28.7139 3.95833 27.7778 6.04167 27.7778 8.33333V13.8889C27.7778 16.1806 28.7139 18.2639 30.2251 19.775C31.7362 21.2861 33.8195 22.2222 36.1112 22.2222H41.6667C43.9584 22.2222 46.0417 21.2861 47.5528 19.775C49.0639 18.2639 50.0001 16.1806 50.0001 13.8889V8.33333C50.0001 6.04167 49.0639 3.95833 47.5528 2.44722C46.0417 0.936111 43.9584 0 41.6667 0Z" />
                            <path d="M13.8889 27.7778H8.33333C6.04167 27.7778 3.95833 28.7139 2.44722 30.225C0.936111 31.7361 0 33.8194 0 36.1111V41.6667C0 43.9583 0.936111 46.0417 2.44722 47.5528C3.95833 49.0639 6.04167 50 8.33333 50H13.8889C16.1806 50 18.2639 49.0639 19.775 47.5528C21.2861 46.0417 22.2222 43.9583 22.2222 41.6667V36.1111C22.2222 33.8194 21.2861 31.7361 19.775 30.225C18.2639 28.7139 16.1806 27.7778 13.8889 27.7778Z" />
                            <path d="M41.6667 27.7778H36.1112C33.8195 27.7778 31.7362 28.7139 30.2251 30.225C28.7139 31.7361 27.7778 33.8194 27.7778 36.1111V41.6667C27.7778 43.9583 28.7139 46.0417 30.2251 47.5528C31.7362 49.0639 33.8195 50 36.1112 50H41.6667C43.9584 50 46.0417 49.0639 47.5528 47.5528C49.0639 46.0417 50.0001 43.9583 50.0001 41.6667V36.1111C50.0001 33.8194 49.0639 31.7361 47.5528 30.225C46.0417 28.7139 43.9584 27.7778 41.6667 27.7778Z" />
                            <clipPath id="clip0_308_250">
                                <rect width="15px" height="15px" />
                            </clipPath>
                        </svg>
                        <span className="ml-2">Tableau de bord</span>
                    </a>
                </li>
                <li aria-current="page">
                    <div className="flex items-center">
                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                        <span className="ml-1 text-sm font-medium text-gray-900 md:ml-2z">Expédition</span>
                    </div>
                </li>
            </ol>
            {/* BREADCRUMBS */}

            <div className="flex items-center justify-between w-full mt-10">
                <div className="w-full lg:w-3/3">
                    <div className="py-0">
                        <div className="mx-auto bg-white shadow rounded">

                            <div className="flex row">
                                <h1 className="text-xl font-semibold mt-1 ml-2 px-6 pt-6 w-full whitespace-nowrap">Zones d’expédition</h1>
                                <div className="flex flex-col lg:flex-row p-4 justify-between items-start lg:items-stretch w-full">
                                    <div className="w-full lg:w-1/3 flex flex-col lg:flex-row items-start lg:items-center"></div>
                                    <div className="w-full lg:w-2/3 flex flex-col lg:flex-row items-start lg:items-center justify-end">
                                        <div className="lg:ml-6 flex items-center">
                                            {/* <button className="bg-gray-200 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-gray-300 rounded text-indigo-700 p-4 h-8 flex items-center text-sm font-bold">Exporter</button> */}
                                            <button onClick={() => navigate('/add/delivery')} className="text-white ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 font-bold focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex text-[14px] items-center justify-center">
                                                Ajouter une zone d'expédition
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* SEARCH BAR */}
                            <div className="flex row">

                                <div className="flex ml-8 mb-4 w-full md:w-1/6">
                                    <div
                                        className="flex-grow md:flex-none inter-small-regular mt-1 transition-color transition-width duration-150 ease-in-out flex text-grey-50 flex items-center mb-1 pl-1 py-1.5 rounded border border-grey-0 w-full focus-within:bg-grey-5">
                                        <span className="px-2.5 py-0.5">
                                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M20.4696 21.5303C20.7625 21.8232 21.2373 21.8232 21.5302 21.5303C21.8231 21.2374 21.8231 20.7626 21.5302 20.4697L20.4696 21.5303ZM17.1802 16.1197C16.8873 15.8268 16.4125 15.8268 16.1196 16.1197C15.8267 16.4126 15.8267 16.8874 16.1196 17.1803L17.1802 16.1197ZM18.25 11C18.25 15.0041 15.0041 18.25 11 18.25V19.75C15.8325 19.75 19.75 15.8325 19.75 11H18.25ZM11 18.25C6.99594 18.25 3.75 15.0041 3.75 11H2.25C2.25 15.8325 6.16751 19.75 11 19.75V18.25ZM3.75 11C3.75 6.99594 6.99594 3.75 11 3.75V2.25C6.16751 2.25 2.25 6.16751 2.25 11H3.75ZM11 3.75C15.0041 3.75 18.25 6.99594 18.25 11H19.75C19.75 6.16751 15.8325 2.25 11 2.25V3.75ZM21.5302 20.4697L17.1802 16.1197L16.1196 17.1803L20.4696 21.5303L21.5302 20.4697Z"
                                                    fill="currentColor">
                                                </path>
                                            </svg>
                                        </span>
                                        <input type="text"
                                            className="border-transparent focus:border-transparent  focus:ring-0 mini-search w-full focus:bg-grey-5 focus:text-grey-90 caret-violet-60 w-full"
                                            placeholder="Rechercher..." size="6" />
                                    </div>
                                </div>

                                <div ref={componentRef}>
                                    <button className="flex border border-grey-0 p-[.6rem] rounded mr-8 mt-1 ml-2" type="button" onClick={handleFilterDropdown} aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-33" data-state="closed">

                                        <svg width={16} height={16}
                                            viewBox="0 0 64 64" >
                                            <path d="M41,64l-18-7.7V37.5L0.5,0h63.1L41,37.6V64z M27,53.7L37,58V36.4L56.5,4H7.5L27,36.4V53.7z" fill="currentColor" />
                                        </svg>

                                        {activeFilter &&
                                            <div className={`ml-1 w-2 h-2 rounded-full bg-indigo-500`} />
                                        }
                                    </button>

                                    <div className={`origin-top-right absolute left-[30.4rem] top-[19.5rem] w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${dropdownFilter ? "block" : "hidden"}`} style={{ zIndex: 999 }} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                                        <div className="" role="none">
                                            <div className='flex row justify-center mr-4 mt-4'>
                                                <button onClick={handleClearFilter} className="w-full text-indigo-700 ml-4 cursor-pointer focus:outline-none border border-transparent bg-gray-200 transition duration-150 ease-in-out p-4 h-8 rounded flex items-center justify-center">
                                                    Effacer
                                                </button>
                                                <button onClick={handleApplyFilter} className="w-full text-white ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                                                    Appliquer
                                                </button>
                                            </div>

                                            <div className="w-full border-[.5px] border-gray-200 mt-4" />

                                            <div onClick={handleStatusCheckbox} className="flex items-center px-6 hover:bg-gray-100 p-3">
                                                <input id="checked-checkbox" type="checkbox" checked={statusFilterCheckbox} onChange={handleStatusCheckbox} value={statusFilterCheckbox} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                <label className="text-gray-700 ml-2">Statut</label>
                                            </div>

                                            {statusFilterCheckbox &&
                                                <div className="mx-2 my-1">
                                                    <div className="flex items-center px-6 hover:bg-gray-100 p-3" onClick={() => setFilter({ ...filter, disabled: false })}>
                                                        <input id="checked-checkbox" name="radio_btn_status" type="radio" checked={filter.disabled === false && true} onChange={() => { }} value="false" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                        <label className="text-gray-700 ml-2">Actif</label>
                                                    </div>
                                                    <div className="flex items-center px-6 hover:bg-gray-100 p-3" onClick={() => setFilter({ ...filter, disabled: true })}>
                                                        <input id="checked-checkbox" type="radio" name="radio_btn_status" checked={filter.disabled === true && true} onChange={() => { }} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                        <label className="text-gray-700 ml-2">Inactif</label>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>


                            {regions.length === 0 &&

                                <>
                                    {loadingSpinner
                                        ? <div className="flex justify-center items-center content-center">
                                            <svg className="w-11 h-11 animate-spin mt-14 mb-14" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#000000" />
                                                <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z" fill="#000000" />
                                            </svg>
                                        </div>
                                        : <div>
                                            <div className="w-full">
                                                <div className="flex justify-center ml-5 mb-4">
                                                    <img src="../../no_data.png" width={100} />
                                                </div>
                                                <div className="flex justify-center">
                                                    {searchInput
                                                        ? <span className="text-gray-500 mb-10">Aucune zone d’expédition ne corresponds à votre recherche "<span className="font-semibold italic">{searchInput}</span>".</span>
                                                        : <span className="text-gray-500 mb-10">Aucune zone d’expédition.</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }

                            <div className={`w-full overflow-x-scroll xl:overflow-x-hidden ${regions.length === 0 ? 'hidden' : 'block'}`}>
                                <table className="min-w-full bg-white">
                                    <thead>
                                        <tr className="w-full h-16 border-gray-300 border-b py-8">
                                            <th className="pl-8 text-gray-600 font-normal pr-6 text-left text-sm tracking-normal leading-4">
                                                <input type="checkbox" onChange={() => handleCheckAllRow()} checked={checkedRow.length === Object.keys(regions).length} className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                                            </th>
                                            <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Nom</th>
                                            <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Pays</th>
                                            <td className="text-gray-600 pr-8 text-left text-sm tracking-normal leading-4 font-semibold">Expéditeur</td>
                                            <td className="text-gray-600 pr-8 text-left text-sm tracking-normal leading-4 font-semibold">Statut</td>
                                            <td className="text-gray-600 pr-8 text-left text-sm tracking-normal leading-4 font-semibold">Actions</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(regions).map((key) => (
                                            <tr key={key} className="lg:h-20 border-gray-300 border-b hover:bg-gray-100">
                                                <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                    <input type="checkbox" onChange={() => handleCheckRow(regions[key]?._id)} checked={checkedRow?.includes(regions[key]?._id)} className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                                                </td>
                                                <td className="pr-6 whitespace-no-wrap">
                                                    {regions[key]?.name}
                                                </td>

                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                    {Object.keys(regions).map((idx) => (
                                                        regions[idx]?.country.map((it, id) => (
                                                            <div key={id} className="mt-4 mb-4">
                                                                {key === idx &&
                                                                    <span className="bg-gray-100 p-[.35rem] rounded">{countries.filter((data) => { return data.code == it })[0]?.name}</span>
                                                                }
                                                            </div>

                                                        ))
                                                    ))}
                                                </td>
                                                <td className="pr-8">
                                                    {Object.keys(regions[key]?.delivery).map((idx) => (
                                                        <div className='flex flex-col lg:flex-row' key={idx}>
                                                            <span key={idx} className="bg-gray-100 p-[.35rem] rounded mt-1 mb-1">{regions[key]?.delivery[idx]?.shipperName}</span>
                                                        </div>
                                                    ))}
                                                </td>

                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                    <div className="flex items-center">
                                                        <div className={`w-2 h-2 rounded-full ${regions[key]?.disabled === false && "bg-green-600"} ${regions[key]?.disabled === true && "bg-red-600"}`} />
                                                        <span className="text-sm pr-2 whitespace-no-wrap text-gray-800 tracking-normal leading-4 ml-1">{regions[key]?.disabled === true ? 'Inactif' : "Actif"}</span>
                                                    </div>
                                                </td>

                                                <td className="pr-8">
                                                    <div className={`dropdown-content mt-8 absolute right-18 -ml-12 shadow-md z-[999] w-32 ${dropdown === regions[key]._id ? "block" : "hidden"}`}>
                                                        <ul className="bg-white shadow rounded py-1">
                                                            <li onClick={() => navigate("/edit/delivery/" + regions[key]?._id)} className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-edit"></i> Éditer</li>
                                                            <li onClick={() => !regions[key]?.disabled ? handleShowModalConfirmDisable(regions[key]?._id) : handleActivatedShippingMethod(regions[key]?._id)} className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className={`fa fa-power-off`}></i> {regions[key]?.disabled ? "Activer" : "Désactiver"}</li>
                                                            <li onClick={() => handleShowModalConfirmDelete(regions[key]?._id)} className="cursor-pointer text-red-500 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-trash"></i> Supprimer</li>
                                                        </ul>
                                                    </div>

                                                    <button onClick={() => handleDropdownForeach(regions[key]._id)} className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <circle cx={12} cy={12} r={1} />
                                                            <circle cx={12} cy={19} r={1} />
                                                            <circle cx={12} cy={5} r={1} />
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Delivery;
