import { Link } from "react-router-dom";

const Settings = () => {
    return (
        <div>
            <h1 className="text-2xl font-semibold b-4">Paramètres</h1>
            <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mb-8">Gérer les paramètres de votre boutique</p>

            <div className="grid overflow-hidden lg:grid-cols-2 gap-4 grid-cols-2 place-items-center">
                <Link to="/settings/merchant" className="flex w-full">
                    <div className="bg-white flex flex-col p-6 opacity-[.85] hover:opacity-[1] cursor-pointer w-full h-full">
                        <div className="flex justify-center items-center h-16">
                            <div className="w-10 h-10 rounded-full bg-indigo-500" />
                        </div>

                        <div className="flex justify-center items-center h-full">
                            <h1 className="text-xl font-semibold text-center">Informations boutique</h1>
                        </div>
                    </div>
                </Link>

                <Link to="/settings/shipper" className="flex w-full">
                    <div className="bg-white flex flex-col p-6 opacity-[.85] hover:opacity-[1] cursor-pointer w-full h-full">
                        <div className="flex justify-center items-center h-16">
                            <div className="w-10 h-10 rounded-full bg-indigo-500" />
                        </div>

                        <div className="flex justify-center items-center h-full">
                            <h1 className="text-xl font-semibold text-center">Expéditeurs</h1>
                        </div>
                    </div>
                </Link>

                {/* <Link to="/settings/subscription" className="flex w-full">
                    <div className="bg-white flex flex-col p-6 opacity-[.85] hover:opacity-[1] cursor-pointer w-full h-full">
                        <div className="flex justify-center items-center h-16">
                            <div className="w-10 h-10 rounded-full bg-indigo-500" />
                        </div>

                        <div className="flex justify-center items-center h-full">
                            <h1 className="text-xl font-semibold text-center">Abonnements</h1>
                        </div>
                    </div>
                </Link> */}

                {/* <Link to="/settings/shipper" className="flex w-full h-full">
                    <div className="bg-white flex flex-col p-6 opacity-[.85] hover:opacity-[1] cursor-pointer w-full items-center h-full">
                        <div className="flex justify-center items-center h-16">
                            <div className="w-10 h-10 rounded-full bg-indigo-500" />
                        </div>

                        <div className="flex justify-center items-center h-full">
                            <h1 className="text-xl font-semibold text-center">Expéditeurs</h1>
                        </div>
                    </div>
                </Link> */}

                <Link to="/settings/delivery" className="flex w-full h-full">
                    <div className="bg-white flex flex-col p-6 opacity-[.85] hover:opacity-[1] cursor-pointer w-full items-center h-full">
                        <div className="flex justify-center items-center h-16">
                            <div className="w-10 h-10 rounded-full bg-indigo-500" />
                        </div>

                        <div className="flex justify-center items-center h-full">
                            <h1 className="text-xl font-semibold text-center">Livraisons</h1>
                        </div>
                    </div>
                </Link>


                {/* <Link to="/settings/billing" className="flex w-full h-full">
                    <div className="bg-white flex flex-col p-6 opacity-[.85] hover:opacity-[1] cursor-pointer w-full items-center h-full">
                        <div className="flex justify-center items-center h-16">
                            <div className="w-10 h-10 rounded-full bg-indigo-500" />
                        </div>

                        <div className="flex justify-center items-center h-full">
                            <h1 className="text-xl font-semibold text-center">Facturation</h1>
                        </div>
                    </div>
                </Link> */}

                <Link to="/settings/taxes" className="flex w-full h-full">
                    <div className="bg-white flex flex-col p-6 opacity-[.85] hover:opacity-[1] cursor-pointer w-full items-center h-full">
                        <div className="flex justify-center items-center h-16">
                            <div className="w-10 h-10 rounded-full bg-indigo-500" />
                        </div>

                        <div className="flex justify-center items-center h-full">
                            <h1 className="text-xl font-semibold text-center">Taxes</h1>
                        </div>
                    </div>
                </Link>

                <Link to="/settings/languages" className="flex w-full h-full">
                    <div className="bg-white flex flex-col p-6 opacity-[.85] hover:opacity-[1] cursor-pointer w-full items-center h-full">
                        <div className="flex justify-center items-center h-16">
                            <div className="w-10 h-10 rounded-full bg-indigo-500" />
                        </div>

                        <div className="flex justify-center items-center h-full">
                            <h1 className="text-xl font-semibold text-center">Langues</h1>
                        </div>
                    </div>
                </Link>

                {/* <Link to="/settings/teams" className="flex w-full h-full">
                    <div className="bg-white flex flex-col p-6 opacity-[.85] hover:opacity-[1] cursor-pointer w-full items-center h-full">
                        <div className="flex justify-center items-center h-16">
                            <div className="w-10 h-10 rounded-full bg-indigo-500" />
                        </div>

                        <div className="flex justify-center items-center h-full">
                            <h1 className="text-xl font-semibold text-center">Équipes</h1>
                        </div>
                    </div>
                </Link> */}

            </div>

        </div>
    );
};
export default Settings;
