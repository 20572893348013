import { GET_ALL_COLLECTIONS } from "./types";
import apiAuth from "../api/collections";

// GET ALL COLLECTION
export const getAllCollections = () =>
    async (dispatch) => {
        return apiAuth
            .getAllCollections()
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_COLLECTIONS, payload: result.data })
                    return result?.data
                }
            })
    };

// DELETE COLLECTION BY ID
export const deleteCollectionById = (id) =>
    async (dispatch) => {
        return apiAuth
            .deleteCollectionById(id)
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_COLLECTIONS, payload: result.data })
                    return result?.data
                }
            })
    };

// GET COLLECTION BY ID
export const getCollectionById = async (id) => {
    const result = await apiAuth
        .getCollectionById(id);
    if (result) {
        return result?.data;
    }
};


// EDIT COLLECTION
export const editCollection = async (data) => {
    const result = await apiAuth
        .editCollection(data);
    if (result) {
        return result?.data;
    }
};

// ADD COLLECTION
export const addCollection = async (data) => {
    const result = await apiAuth
        .addCollection(data);
    if (result) {
        return result?.data;
    }
};