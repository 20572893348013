import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGIN_BACKEND_SUCCESS, LOGOUT } from "./types";
import apiAuth from "../api/auth";

// LOGIN USER
export const login = (data) =>
    async (dispatch) => {
        return apiAuth
            .login(data)
            .then(async (result) => {

                if (result.data) {
                    localStorage.setItem('token_milango_back_office', result.data.token);

                    await dispatch({
                        type: LOGIN_SUCCESS,
                        payload: {
                            user: result.data
                        },
                    });
                }
            })
    };

// REGISTER USER 
export const register = (data) =>
    async (dispatch) => {
        return apiAuth
            .register(data)
            .then(async (result) => {
                if (result.data) {
                    localStorage.setItem('token_milango_back_office', result.data.token);

                    await dispatch({
                        type: REGISTER_SUCCESS,
                        payload: {
                            user: result.data
                        },
                    });
                }
            })
    };

// ACCESS TO BACK OFFICE
export const loginToBackOffice = (storeId) =>
    async (dispatch) => {
        return apiAuth
            .loginToBackOffice(storeId)
            .then(async (result) => {

                localStorage.setItem('back_end_token', result.data.token);
                localStorage.setItem('back_end_url', result.data.url);

                if (result.data) {
                    await dispatch({
                        type: LOGIN_BACKEND_SUCCESS,
                        payload: {
                            backend: {...result.data, storeId}
                        },
                    });

                    return result?.data
                }
            })
    };

// LOGOUT USER
export const logout = (token) => (dispatch) => {
    return apiAuth
        .logout(token)
        .then(() => {
            localStorage.removeItem('back_end_token');
            localStorage.removeItem('back_end_url');
            localStorage.removeItem('token_milango_back_office');

            dispatch({
                type: LOGOUT,
                payload: {},
            });
        })
};

// FORGOT PASSWD 
export const forgotPass = (email) =>
    async (dispatch) => {
        return apiAuth
            .forgotPass(email)
            .then(async (result) => {
                if (result.data) {

                }
            })
    };

// GET USER LOGGED
export const getUserLogged = async () => {
    const result = await apiAuth
        .getUserLogged();
    if (result) {
        return result?.data;
    }
};

// EDIT USER LOGGED
export const editUserLogged = (data) =>
    async (dispatch) => {
        return apiAuth
            .editUserLogged(data)
            .then(async (result) => {

                if (result.data) {

                    await dispatch({
                        type: LOGIN_SUCCESS,
                        payload: {
                            user: result.data
                        },
                    });

                    return result?.data;

                }
            })
    };

// CHANGE PASSWORD
export const changePassword = async (data) => {
    const result = await apiAuth.changePassword(data);
    if (result) {
        return result?.data;
    }
};