import { combineReducers } from "redux";
import auth from "./auth";
import stores from "./stores";
import items from "./items";
import users from "./users";
import categories from "./categories";
import discounts from "./discounts";
import brands from "./brands";
import languages from "./languages";
import orders from "./orders";
import notifications from "./notifications";

export default combineReducers({
    auth,
    stores,
    items,
    users,
    categories,
    discounts,
    brands,
    languages,
    orders,
    notifications
});