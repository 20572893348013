import React from 'react'

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    const nextPage = () => {
        if (currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    
    const prevPage = () => {
        if (currentPage !== 1) setCurrentPage(currentPage - 1)
    }

    return (
        <div className='flex justify-end'>
            <ul className='flex row pagination justify-content-center'>
                <li className="page-item">
                    <a className="flex row page-link mr-4"
                        onClick={prevPage}
                        href='#'>
                        <span className='text-[#6d7175] font-semibold flex row opacity-[.45] hover:opacity-100'>
                            <div className="text-gray-500">
                                <svg width="18" height="18" viewBox="0 0 16 16" fill="none" className='mt-[.22rem] mr-[.35rem] text-[#6d7175] font-semibold' xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.667 8H3.33366" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M8 3.33331L3.33333 7.99998L8 12.6666" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </div>
                            Précédent
                        </span>
                    </a>
                </li>
                {pageNumbers.map(pgNumber => (
                    <li onClick={() => setCurrentPage(pgNumber)} key={pgNumber}
                        className={`page-item px-2 font-semibold cursor-pointer rounded ${pgNumber !== 1 && 'ml-2'} ${currentPage == pgNumber ? 'active bg-indigo-700 text-white' : 'bg-gray-200'} `} >

                        <a className='page-link' href='#'>
                            {pgNumber}
                        </a>
                    </li>
                ))}
                <li className="page-item ml-4">
                    <a className="page-link flex row"
                        onClick={nextPage}
                        href='#'>
                        <span className='text-[#6d7175] font-semibold flex row opacity-[.45] hover:opacity-100'>

                            Suivant
                            <div className="text-gray-500">
                                <svg width="18" height="18" viewBox="0 0 16 16" fill="none" className='mt-[.22rem] ml-[.35rem]' xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.33301 8H12.6663" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M8 3.33331L12.6667 7.99998L8 12.6666" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </div>
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default Pagination