import { GET_ALL_INTEGRATIONS } from "./types";
import apiAuth from "../api/integrations";

// GET ALL INTEGRATIONS
export const getAllIntegrations = () =>
    async (dispatch) => {
        return apiAuth
            .getAllIntegrations()
            .then(async (result) => {
                if (result?.data) {
                    await dispatch({ type: GET_ALL_INTEGRATIONS, payload: result.data })
                    return result.data
                }
            })
    };


// GET INTEGRATION BY ID
export const getIntegrationById = async (id) => {
    const result = await apiAuth
        .getIntegrationById(id);
    if (result) {
        return result?.data;
    }
};

// CHANGE INTEGRATION STATUS
export const changeIntegrationStatus = (data) =>
    async (dispatch) => {
        return apiAuth
            .changeIntegrationStatus(data)
            .then(async (result) => {
                if (result?.data) {
                    await dispatch({ type: GET_ALL_INTEGRATIONS, payload: result.data })
                    return result.data
                }
            })
    };

// SEND CONFIGURATION (API KEYS) FOR INTEGRATION
export const sendConfigIntegration = async (data) => {
    const result = await apiAuth
        .sendConfigIntegration(data);
    if (result) {
        return result?.data;
    }
};
