import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { deleteDiscountById, editDiscount, getAllDiscounts } from "../actions/discounts";
import { useDispatch } from "react-redux";
import moment from 'moment'
import "../datepicker.css";
import { NotificationManager } from 'react-notifications';
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr-CH";
import ReactPaginate from "react-paginate";

const Discounts = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const componentRef = useRef();

    const [dropdownManage, setDropdownManage] = useState(false);
    const [allDiscounts, setAllDiscounts] = useState([]);
    const [allDiscountsItem, setAllDiscountsItem] = useState([]);
    const [idDiscount, setIdDiscount] = useState();
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    // SHOW MODAL FORM
    const [showModalConfirmDisable, setShowModalConfirmDisable] = useState(false);
    const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);

    // SMALL SEARCH
    const [searchInput, setSearchInput] = useState();

    // DROPDOWN FILTER
    const [dropdownFilter, setDropdownFilter] = useState(false);
    const [statusFilterCheckbox, setStatusFilterCheckbox] = useState(false);
    const [typeFilterCheckbox, setTypeFilterCheckbox] = useState(false);
    const [dateFilterCheckbox, setDateFilterCheckbox] = useState(false);

    const [activeFilter, setActiveFilter] = useState(false);
    const [filter, setFilter] = useState({});
    const [filterData, setFilterData] = useState();

    // CHECKBOX CHECKED
    const [checkedRow, setCheckedRow] = useState([]);

    // PAGINATION
    const [itemPerPagePaginate, setItemPerPagePaginate] = useState(10);
    let itemsPerPage = itemPerPagePaginate;
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentRecords = allDiscounts.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(Object.keys(allDiscounts)?.length / itemsPerPage);

    useEffect(() => {
        setLoadingSpinner(true);

        dispatch(getAllDiscounts()).then((res) => {
            setLoadingSpinner(false);
            setAllDiscounts(res)
            setAllDiscountsItem(res);

            let filteredDiscounts = filterDiscounts(res, searchInput, filterData);

            setAllDiscounts(filteredDiscounts);
        });

    }, [searchInput, filterData]);

    const filterDiscounts = (discounts, searchInput, filterData) => {
        return discounts.filter(discount => {
            if (searchInput && (
                discount.discountCode.toLowerCase().indexOf(searchInput.toLowerCase()) === -1 &&
                discount._id.toLowerCase().indexOf(searchInput.toLowerCase()) === -1
            )) {
                return false;
            }
            if (filterData) {
                if (filterData.disabled !== undefined && filterData.disabled !== discount.disabled) {
                    return false;
                }
                if (filterData.type && discount.type !== filterData.type) {
                    return false;
                }
                if (filterData.startDate && new Date(discount.startDate) < new Date(filterData.startDate)) {
                    return false;
                }
                if (filterData.endDate && new Date(discount.endDate) > new Date(filterData.endDate)) {
                    return false;
                }
            }
            return true;
        });
    }

    const handleSmallSearch = (query) => {
        setSearchInput(query);
        var arrItemSearch = []
        const arrSearch = [];

        arrSearch.push(allDiscountsItem)

        for (var index = 0; index < arrSearch.length; index++) {
            if (!!arrSearch[index]) {
                arrItemSearch.push(Object.values(arrSearch[index]));
            }
        }

        const results = arrItemSearch[0].filter((item) => {
            return (item?.discountCode.toString().toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                item?._id.toString().toLowerCase().indexOf(query.toLowerCase()) > -1);
        })

        setAllDiscounts(results)
    }

    const handleDeleteDiscount = (id) => {
        dispatch(deleteDiscountById(id)).then((res) => {
            setAllDiscounts(res);
            setDropdownManage(false);
            setShowModalConfirmDelete(false);
        })
    }

    const handleDropdownForeach = (row) => {
        setDropdownManage(row);
    }

    const handleShowModalConfirmDisable = (id) => {
        setDropdownManage(null);
        setIdDiscount(id);
        setShowModalConfirmDisable(true);

        if (showModalConfirmDisable) {
            setShowModalConfirmDisable(false);
        }
    }

    const handleShowModalConfirmDelete = (id) => {
        setDropdownManage(null);
        setIdDiscount(id);
        setShowModalConfirmDelete(true);

        if (showModalConfirmDelete) {
            setShowModalConfirmDelete(false);
        }
    }

    const handleDisableDiscount = () => {
        const data = {
            id: idDiscount,
            disabled: true
        }

        setDropdownManage(false)

        editDiscount(data).then(() => {
            dispatch(getAllDiscounts())
                .then((res) => {
                    setShowModalConfirmDisable(false);
                    setAllDiscounts(res);

                    NotificationManager.success('Votre réduction a bien été désactiver !', 'Succès');
                })
                .catch((err) => {
                    NotificationManager.error(err.response.data.error, 'Erreur')
                })
        })
    }

    const handleActivateDiscount = (id) => {
        const data = {
            id: id,
            disabled: false
        }

        setDropdownManage(false)

        editDiscount(data).then(() => {
            dispatch(getAllDiscounts())
                .then((res) => {
                    setShowModalConfirmDisable(false);
                    setAllDiscounts(res);

                    NotificationManager.success('Votre réduction a bien été activer !', 'Succès');
                })
                .catch((err) => {
                    NotificationManager.error(err.response.data.error, 'Erreur')
                })
        })
    }

    const handleCloseModalConfirmDisable = () => {
        setShowModalConfirmDisable(false);
        setDropdownManage(false)
    }

    const handleCloseModalConfirmDelete = () => {
        setShowModalConfirmDelete(false);
        setDropdownManage(false)
    }

    const handleClearFilter = () => {
        setStatusFilterCheckbox(false);
        setActiveFilter(false);
        setDropdownFilter(false);
        setFilter({});
        setFilterData();
        setSearchInput();

        dispatch(getAllDiscounts()).then((res) => {
            setLoadingSpinner(false);
            setAllDiscounts(res)
            setAllDiscountsItem(res);
        })
    }

    const handleFilterDropdown = () => {
        setDropdownFilter(true);

        if (dropdownFilter)
            setDropdownFilter(false);
    }

    const handleStatusCheckbox = () => {
        setStatusFilterCheckbox(true);

        if (statusFilterCheckbox)
            setStatusFilterCheckbox(false);
    }

    const handleTypeCheckbox = () => {
        setTypeFilterCheckbox(true);

        if (typeFilterCheckbox)
            setTypeFilterCheckbox(false);
    }

    const handleDateCheckbox = () => {
        setDateFilterCheckbox(true);

        if (dateFilterCheckbox)
            setDateFilterCheckbox(false);
    }

    const handleApplyFilter = () => {
        if (Object.keys(filter).length !== 0) {
            setActiveFilter(true);
            setDropdownFilter(false);

            setFilterData(filter)
        } else {
            NotificationManager.error('Merci de bien vouloir sélectionner des filtres avant d\'appliquer !', 'Erreur')
        }
    }

    const handleCheckRow = (id) => {
        if (checkedRow?.includes(id)) {
            const newCheckedRow = checkedRow.filter((checkedId) => checkedId !== id)
            setCheckedRow(newCheckedRow)
        } else {
            setCheckedRow([...checkedRow, id])
        }
    }

    const handleCheckAllRow = () => {
        if (checkedRow.length === Object.keys(allDiscounts).length) {
            // Si toutes les cases sont déjà cochées, on décoche toutes les cases
            setCheckedRow([])
        } else {
            // Sinon, on coche toutes les cases
            const allDiscountIds = Object.keys(allDiscounts).reduce((acc, key) => {
                const discount = allDiscounts[key]
                if (discount) {
                    acc.push(discount._id)
                }
                return acc
            }, [])
            setCheckedRow(allDiscountIds)
        }
    }

    const handlePagePaginateClick = (event) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const newOffset = (event.selected * itemsPerPage) % allDiscounts?.length;

        setItemOffset(newOffset);
    };

    return (
        <div className="container-margin">
            <div onClick={() => { setDropdownManage(false); setDropdownFilter(false) }} className={`${dropdownManage !== false && "mydropdown"} ${dropdownFilter !== false && "mydropdown"}`}></div>

            {showModalConfirmDisable &&
                <div>
                    <div onClick={() => handleCloseModalConfirmDisable()} className="myModal"></div>
                    <div className="center-absolutely bg-[white] rounded w-4/12 mt-10">

                        <div className="flex row justify-between">
                            <div className="px-6 mt-4">
                                <h1 className="text-xl font-semibold mb-1">Désactiver la réduction</h1>
                                <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mb-4">Attention en désactivant cette réduction, nous désactiverons toutes les données liées à cette réduction.</p>
                            </div>

                            <div className="px-6 mt-4">
                                <span data-state="closed" className="cursor-pointer" onClick={() => handleCloseModalConfirmDisable()}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex text-grey-50">
                                        <path d="M15 5L5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M5 5L15 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="text-indigo-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => handleCloseModalConfirmDisable()}
                            >
                                Annuler
                            </button>
                            <button
                                className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => handleDisableDiscount()}
                            >

                                Désactiver
                            </button>
                        </div>


                    </div>
                </div>
            }

            {showModalConfirmDelete &&
                <div>
                    <div onClick={() => handleCloseModalConfirmDelete()} className="myModal"></div>
                    <div className="center-absolutely bg-[white] rounded w-4/12 mt-10">

                        <div className="flex row justify-between">
                            <div className="px-6 mt-4">
                                <h1 className="text-xl font-semibold mb-1">Supprimer la réduction</h1>
                                <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mb-4">Attention en supprimant cette réduction, nous supprimerons toutes les données liées à cette réduction.</p>
                            </div>

                            <div className="px-6 mt-4">
                                <span data-state="closed" className="cursor-pointer" onClick={() => handleCloseModalConfirmDelete()}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex text-grey-50">
                                        <path d="M15 5L5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M5 5L15 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="text-indigo-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => handleCloseModalConfirmDelete()}
                            >
                                Annuler
                            </button>
                            <button
                                className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => handleDeleteDiscount(idDiscount)}
                            >
                                Supprimer
                            </button>
                        </div>


                    </div>
                </div>
            }

            <div className="flex items-center justify-between w-full">
                <div className="w-full lg:w-3/3">
                    <div className="py-0">
                        <div className="mx-auto bg-white shadow rounded">
                            <div className="flex row">
                                <h1 className="text-xl font-semibold mt-1 ml-2 px-6 pt-6">Réductions</h1>

                                <div className="flex flex-col lg:flex-row p-4 lg:px-8 justify-between items-start lg:items-stretch w-full">
                                    <div className="w-full lg:w-1/3 flex flex-col lg:flex-row items-start lg:items-center"></div>
                                    <div className="w-full lg:w-2/3 flex flex-col lg:flex-row items-start lg:items-center justify-end">
                                        <div className="lg:ml-6 flex items-center">
                                            {/* <button className="bg-gray-200 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-gray-300 rounded text-indigo-700 p-4 h-8 flex items-center text-sm">Exporter</button> */}
                                            <button onClick={() => navigate('/add/discount')} className="text-white text-[14px] lg:text-[15px] mt-2 mr-4 lg:mt-0 lg:mr-0 lg:ml-4 cursor-pointer whitespace-nowrap focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                                                Ajouter une réduction
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* SEARCH BAR */}
                            <div className="flex row">
                                <div className="flex ml-8 mb-4 w-full md:w-1/6">
                                    <div
                                        className="flex-grow md:flex-none inter-small-regular mt-1 transition-color transition-width duration-150 ease-in-out flex text-grey-50 flex items-center mb-1 pl-1 py-1.5 rounded border border-grey-0 w-full focus-within:bg-grey-5">
                                        <span className="px-2.5 py-0.5">
                                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M20.4696 21.5303C20.7625 21.8232 21.2373 21.8232 21.5302 21.5303C21.8231 21.2374 21.8231 20.7626 21.5302 20.4697L20.4696 21.5303ZM17.1802 16.1197C16.8873 15.8268 16.4125 15.8268 16.1196 16.1197C15.8267 16.4126 15.8267 16.8874 16.1196 17.1803L17.1802 16.1197ZM18.25 11C18.25 15.0041 15.0041 18.25 11 18.25V19.75C15.8325 19.75 19.75 15.8325 19.75 11H18.25ZM11 18.25C6.99594 18.25 3.75 15.0041 3.75 11H2.25C2.25 15.8325 6.16751 19.75 11 19.75V18.25ZM3.75 11C3.75 6.99594 6.99594 3.75 11 3.75V2.25C6.16751 2.25 2.25 6.16751 2.25 11H3.75ZM11 3.75C15.0041 3.75 18.25 6.99594 18.25 11H19.75C19.75 6.16751 15.8325 2.25 11 2.25V3.75ZM21.5302 20.4697L17.1802 16.1197L16.1196 17.1803L20.4696 21.5303L21.5302 20.4697Z"
                                                    fill="currentColor">
                                                </path>
                                            </svg>
                                        </span>
                                        <input type="text" onChange={(e) => handleSmallSearch(e.target.value)}
                                            className="border-transparent focus:border-transparent  focus:ring-0 mini-search w-full focus:bg-grey-5 focus:text-grey-90 caret-violet-60 w-full"
                                            placeholder="Rechercher..." size="6" />
                                    </div>
                                </div>

                                <div ref={componentRef}>
                                    <button className="flex border border-grey-0 p-[.6rem] rounded mt-[.25rem] ml-2 mr-8" type="button" onClick={handleFilterDropdown} aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-33" data-state="closed">

                                        <svg width={16} height={16}
                                            viewBox="0 0 64 64" >
                                            <path d="M41,64l-18-7.7V37.5L0.5,0h63.1L41,37.6V64z M27,53.7L37,58V36.4L56.5,4H7.5L27,36.4V53.7z" fill="currentColor" />
                                        </svg>

                                        {activeFilter &&
                                            <div className={`ml-[.1rem] w-2 h-2 rounded-full bg-indigo-500`} />
                                        }
                                    </button>

                                    <div className={`origin-top-right absolute left-[29.8rem] top-[15.5rem] w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${dropdownFilter ? "block" : "hidden"}`} style={{ zIndex: 999 }} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                                        <div className="" role="none">
                                            <div className='flex row justify-center mr-4 mt-4'>
                                                <button onClick={handleClearFilter} className="w-full text-indigo-700 ml-4 cursor-pointer focus:outline-none border border-transparent bg-gray-200 transition duration-150 ease-in-out p-4 h-8 rounded flex items-center justify-center">
                                                    Effacer
                                                </button>
                                                <button onClick={handleApplyFilter} className="w-full text-white ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                                                    Appliquer
                                                </button>
                                            </div>

                                            <div className="w-full border-[.5px] border-gray-200 mt-4" />

                                            <div onClick={handleStatusCheckbox} className="flex items-center px-6 hover:bg-gray-100 p-3">
                                                <input id="checked-checkbox" type="checkbox" checked={statusFilterCheckbox} onChange={handleStatusCheckbox} value={statusFilterCheckbox} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                <label className="text-gray-700 ml-2">Statut</label>
                                            </div>

                                            {statusFilterCheckbox &&
                                                <div className="mx-2 my-1">
                                                    <div className="flex items-center px-6 hover:bg-gray-100 p-3" onClick={() => setFilter({ ...filter, disabled: false })}>
                                                        <input id="checked-checkbox" name="radio_btn_status" type="radio" checked={filter.disabled === false && true} onChange={() => { }} value="false" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                        <label className="text-gray-700 ml-2">Actif</label>
                                                    </div>
                                                    <div className="flex items-center px-6 hover:bg-gray-100 p-3" onClick={() => setFilter({ ...filter, disabled: true })}>
                                                        <input id="checked-checkbox" type="radio" name="radio_btn_status" checked={filter.disabled === true && true} onChange={() => { }} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                        <label className="text-gray-700 ml-2">Inactif</label>
                                                    </div>
                                                </div>
                                            }

                                            <div className="w-full border-[.5px] border-gray-200" />

                                            <div onClick={handleTypeCheckbox} className="flex items-center px-6 hover:bg-gray-100 p-3">
                                                <input id="checked-checkbox" type="checkbox" checked={typeFilterCheckbox} onChange={handleTypeCheckbox} value={typeFilterCheckbox} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                <label className="text-gray-700 ml-2">Type</label>
                                            </div>

                                            {typeFilterCheckbox &&
                                                <div className="mx-2 my-1">
                                                    <div className="flex items-center px-6 hover:bg-gray-100 p-3" onClick={() => setFilter({ ...filter, type: 'percentage' })}>
                                                        <input id="checked-checkbox" name="radio_btn_type" type="radio" checked={filter.type === 'percentage' && true} onChange={() => { }} value="false" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                        <label className="text-gray-700 ml-2">Pourcentage</label>
                                                    </div>
                                                    <div className="flex items-center px-6 hover:bg-gray-100 p-3" onClick={() => setFilter({ ...filter, type: 'flat' })}>
                                                        <input id="checked-checkbox" type="radio" name="radio_btn_type" checked={filter.type === 'flat' && true} onChange={() => { }} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                        <label className="text-gray-700 ml-2">Flat</label>
                                                    </div>
                                                </div>
                                            }

                                            <div className="w-full border-[.5px] border-gray-200" />

                                            <div onClick={handleDateCheckbox} className="flex items-center px-6 hover:bg-gray-100 p-3">
                                                <input id="checked-checkbox" type="checkbox" checked={dateFilterCheckbox} onChange={handleDateCheckbox} value={dateFilterCheckbox} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                <label className="text-gray-700 ml-2">Date</label>
                                            </div>

                                            {dateFilterCheckbox &&

                                                <div className="ml-8 mr-2 my-1">
                                                    <DatePicker
                                                        selected={filter?.startDate || ''}
                                                        locale={fr}
                                                        onChange={(date) => { setFilter({ ...filter, startDate: Number(moment(date).format("x")), }) }}
                                                        className="flex w-full"
                                                        placeholderText="Début de promotion"
                                                        dateFormat="dd/MM/yyyy"
                                                    />
                                                    <div className="mt-2">
                                                        <DatePicker
                                                            selected={filter?.endDate || ''}
                                                            locale={fr}
                                                            onChange={(date) => { setFilter({ ...filter, endDate: Number(moment(date).format("x")), }) }}
                                                            className="flex w-full"
                                                            placeholderText="Fin de promotion"
                                                            dateFormat="dd/MM/yyyy"
                                                        />
                                                    </div>

                                                </div>
                                            }


                                        </div>
                                    </div>
                                </div>

                            </div>

                            {allDiscounts.length === 0 &&

                                <>
                                    {loadingSpinner
                                        ? <div className="flex justify-center items-center content-center">
                                            <svg className="w-11 h-11 animate-spin mt-14 mb-14" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#000000" />
                                                <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z" fill="#000000" />
                                            </svg>
                                        </div>
                                        : <div>
                                            <div className="w-full">
                                                <div className="flex justify-center ml-5 mb-4">
                                                    <img src="../../no_data.png" width={100} />
                                                </div>
                                                <div className="flex justify-center">
                                                    {searchInput
                                                        ? <span className="text-gray-500 mb-10">Aucune réduction ne corresponds à votre recherche "<span className="font-semibold italic">{searchInput}</span>".</span>
                                                        : <span className="text-gray-500 mb-10">Aucune réduction.</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }

                            <div className={`w-full overflow-x-scroll xl:overflow-x-hidden ${allDiscounts.length === 0 ? 'hidden' : 'block'}`}>
                                <table className="min-w-full bg-white">
                                    <thead>
                                        <tr className="w-full h-16 border-gray-300 border-b py-8">
                                            <th className="pl-8 text-gray-600 font-normal pr-6 text-left text-sm tracking-normal leading-4">
                                                <input type="checkbox" onChange={() => handleCheckAllRow()} checked={checkedRow.length === Object.keys(allDiscounts).length} className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                                            </th>
                                            <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Code</th>
                                            <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Montant</th>
                                            <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Type</th>
                                            <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Utilisations</th>
                                            <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Début de promotion</th>
                                            <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Fin de promotion</th>
                                            <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Statut</th>
                                            <td className="text-gray-600 pr-8 text-left text-sm tracking-normal leading-4 font-semibold">Actions</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(currentRecords).map((i) => (
                                            <tr key={currentRecords[i]._id} className="lg:h-20 border-gray-300 border-b hover:bg-gray-100 text-[14px]">
                                                <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                    <input type="checkbox" onChange={() => handleCheckRow(currentRecords[i]?._id)} checked={checkedRow?.includes(currentRecords[i]?._id)} className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                                                </td>

                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                    <div className="flex justify-between">
                                                        <span className="responsive-thead hidden font-semibold">Code</span>
                                                        <span className="bg-gray-200 p-2 rounded text-[15px">{currentRecords[i].discountCode}</span>
                                                    </div>
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                    <div className="flex justify-between">
                                                        <span className="responsive-thead hidden font-semibold">Montant</span>
                                                        {currentRecords[i].amount}
                                                    </div>
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                    <div className="flex justify-between">
                                                        <span className="responsive-thead hidden font-semibold">Type</span>
                                                        {currentRecords[i].type}
                                                    </div>
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                    <div className="flex justify-between">
                                                        <span className="responsive-thead hidden font-semibold">Utilisations</span>
                                                        {currentRecords[i].usageCount || 0}
                                                    </div>
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                    <div className="flex justify-between">
                                                        <span className="responsive-thead hidden font-semibold">Date de début</span>
                                                        {moment(currentRecords[i].startDate).format("LL")}  {' à '} {moment(currentRecords[i]?.startDate).format("LT")}
                                                    </div>
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                    <div className="flex justify-between">
                                                        <span className="responsive-thead hidden font-semibold">Date de fin</span>
                                                        {moment(currentRecords[i].endDate).format("LL")}  {' à '} {moment(currentRecords[i]?.endDate).format("LT")}
                                                    </div>
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                    <div className="flex justify-between">
                                                        <span className="responsive-thead hidden font-semibold">Statut</span>
                                                        <div className="flex items-center">
                                                            <div className={`w-2 h-2 rounded-full ${currentRecords[i]?.disabled === false && "bg-green-600"} ${currentRecords[i]?.disabled === true && "bg-red-600"}`} />
                                                            <span className="text-sm pr-2 whitespace-no-wrap text-gray-800 tracking-normal leading-4 ml-1">{currentRecords[i]?.disabled === true ? 'Inactif' : "Actif"}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                    <div className="flex justify-between md:justify-start h-full">
                                                        <div className="flex items-center">
                                                            <span className="responsive-thead hidden font-semibold">Actions</span>
                                                        </div>
                                                        <div className={`dropdown-content mt-8 absolute right-18 -ml-12 shadow-md z-[999] w-32 ${dropdownManage === currentRecords[i]._id ? "block" : "hidden"}`}>
                                                            <ul className="bg-white shadow rounded py-1">
                                                                <li onClick={() => navigate('/edit/discount/' + currentRecords[i]._id)} className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-edit"></i> Éditer</li>
                                                                <li onClick={() => currentRecords[i]?.disabled !== true ? handleShowModalConfirmDisable(currentRecords[i]?._id) : handleActivateDiscount(currentRecords[i]?._id)} className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-power-off"></i> {currentRecords[i]?.disabled === true ? "Activer" : "Désactiver"}</li>
                                                                <li onClick={() => handleShowModalConfirmDelete(currentRecords[i]?._id)} className="cursor-pointer text-red-500 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-trash"></i> Supprimer</li>
                                                            </ul>
                                                        </div>

                                                        <button onClick={() => handleDropdownForeach(currentRecords[i]._id)} className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                                <circle cx={12} cy={12} r={1} />
                                                                <circle cx={12} cy={19} r={1} />
                                                                <circle cx={12} cy={5} r={1} />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>

                            <div className={`flex justify-between px-4 py-4 mt-4 ${allDiscounts.length !== 0 ? "block" : "hidden"} `}>
                                <div className="">
                                    <span>Afficher:</span>

                                    <select name="pets" id="pet-select" value={itemPerPagePaginate ? itemPerPagePaginate : 10} onChange={(e) => setItemPerPagePaginate(e?.target?.value)} className="border-[1px] ml-1 rounded p-1">
                                        <option value={1}>1</option>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                    </select>
                                </div>

                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePagePaginateClick}
                                    pageRangeDisplayed={3}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    containerClassName={'containerPaginate'}
                                    previousClassName="previousBtnPaginate"
                                    nextClassName="nextBtnPaginate"
                                    activeClassName="activeBtnPaginate"
                                    pageClassName="pageBtnPaginate"
                                />

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Discounts;
