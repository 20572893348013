import { apiStripe } from './ApiStripe'

function addCard(data) {
    return apiStripe().post('/user/card/', data)
}

function getAllCards() {
    return apiStripe().get('/user/card/')
}

function deleteCardById(id) {
    return apiStripe().delete('/user/card/' + id)
}

function getCustomer() {
    return apiStripe().get('/user/customer/')
}

function setDefaultCard(data) {
    return apiStripe().post('/user/card/default', data)
}

function getActualSubscription(storeId) {
    return apiStripe().get('/store/subscription/' + storeId)
}

function getAllPlans() {
    return apiStripe().get('/product/')
}

function getPlanById(id) {
    return apiStripe().get('/product/' + id)
}

function changeSubscription(data) {
    return apiStripe().put('/store/subscription', data)
}

export default {
    addCard,
    getAllCards,
    deleteCardById,
    getActualSubscription,
    getAllPlans,
    getPlanById,
    changeSubscription,
    getCustomer,
    setDefaultCard
}
