import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { addLanguage, editLanguage, getLanguageById } from "../actions/languages";
import { NotificationManager } from 'react-notifications';
import languages from '../utils/countriesCode.json';
import Select from 'react-select';
import { usePrompt } from '../utils/prompt';

const AddLanguage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [language, setLanguage] = useState({});
    const id = location?.pathname?.split("/")[3];
    const [allCodeLanguages, setAllCodeLanguages] = useState([])
    const status = [{ name: "Actif" }, { name: "Inactif" }];
    const [idLanguageCreated, setIdLanguageCreated] = useState();

    const [dataObj, setDataObj] = useState({});
    const [dataChanged, setDataChanged] = useState(false);

    const [errorInput, setErrorInput] = useState([]);

    const handleAddLanguage = () => {
        addLanguage(language)
            .then((res) => {
                setIdLanguageCreated(res?.data?._id);
                setDataChanged(false);
                setLanguage(res?.data);
                setDataObj(res?.data);
                NotificationManager.success("Votre langue a bien été ajouter !", 'Succès')
            })
            .catch((err) => {
                Object.keys(err?.response?.data?.error).map((key) => {
                    NotificationManager.error(err?.response?.data?.error[key], 'Erreur');
                    setErrorInput((state) => [...state, key]);
                })
            })
    }

    const handleEditLanguage = () => {
        editLanguage({ ...language, id: id })
            .then(() => {
                NotificationManager.success("Votre langue à bien été modifier !", 'Succès')
            })
            .catch((err) => {
                NotificationManager.error(err.response.data.error, 'Erreur')
            })
    }

    useEffect(() => {
        const codeLanguages = Object.entries(languages).map(([key, value]) => {
            return {
                label: value,
                value: key
            }
        })

        setAllCodeLanguages(codeLanguages);

        if (id) {
            getLanguageById(id)
                .then((res) => {
                    setLanguage(res);
                    setDataObj(res);
                })
                .catch((err) => {
                    NotificationManager.error(err.response.data.error, 'Erreur')
                })
        }
    }, []);

    useEffect(() => {
        compareObjects()
    }, [language]);

    const compareObjects = () => {

        const languageKeys = Object.keys(language);

        const isDifferent = languageKeys.some((key) => {
            const languageValue = language[key];
            const dataObjValue = dataObj[key];

            if (typeof languageValue !== "object" && typeof dataObjValue !== "object") {
                return languageValue !== dataObjValue;
            }

            return JSON.stringify(languageValue) !== JSON.stringify(dataObjValue);
        });

        setDataChanged(isDifferent);
    };

    const handleChangeISOCode = (item) => {
        setLanguage({ ...language, isoCode: item?.value })
    }

    const handleResetData = () => {
        setLanguage(dataObj);
        setDataChanged(false);
        setErrorInput([]);
    }

    useEffect(() => {
        if (idLanguageCreated) {
            navigate('/edit/language/' + idLanguageCreated)
        }
    }, [idLanguageCreated])

    return (
        usePrompt(dataChanged),

        <>
            <div className={`fixed bg-[#080404] top-0 py-[.7rem] px-5 w-full left-0 z-[999] m-0 text-center test ${dataChanged ? "transition ease-in-out delay-250 opacity-1 duration-500" : "opacity-0 delay-250 duration-500"}`}>
                <div className="flex justify-between text-[white]">
                    <div className="cursor-pointer" onClick={() => navigate('/')}>
                        <svg fill="currentColor" width="38" height="38" viewBox="0 0 174 174" xmlns="http://www.w3.org/2000/svg">
                            <path fill="white" fillRule="evenodd" clipRule="evenodd" d="M0 9.23318C0 4.13387 4.13275 0 9.23075 0H164.769C169.867 0 174 4.13387 174 9.23318V41.7256C168.389 42.1805 163.181 43.5167 158.377 45.7341C152.334 48.4078 147.394 52.1858 143.559 57.0681C140.421 51.9533 136.12 48.1172 130.658 45.5598C125.312 42.8861 119.152 41.5493 112.178 41.5493C105.786 41.5493 99.9745 42.6537 94.7445 44.8623C89.5144 47.0711 85.1559 50.2678 81.6691 54.4526V42.9386H60.9231V135.708H82.7151V88.4543C82.7151 79.3872 84.8653 72.4706 89.1657 67.7045C93.5822 62.9385 99.5097 60.5555 106.948 60.5555C113.689 60.5555 118.861 62.6479 122.464 66.8327C126.067 70.9013 127.868 77.0041 127.868 85.1414V135.708H149.661V88.4543C149.661 79.5034 151.869 72.6449 156.285 67.8789C160.702 62.9966 166.571 60.5555 173.893 60.5555L174 60.5557V164.767C174 169.866 169.867 174 164.769 174H9.23075C4.13275 174 0 169.866 0 164.767V9.23318ZM112.321 69.9598C106.222 69.9598 101.199 72.2675 97.4993 76.8928L97.4895 76.9051L97.4798 76.9175C93.89 81.527 92.2757 87.9726 92.2757 95.8953V135.709H97.1218V95.8953C97.1218 88.5482 98.631 83.3425 101.29 79.9206C104.013 76.523 107.607 74.8191 112.321 74.8191V69.9598Z"></path>
                        </svg>
                    </div>

                    <div className="mt-2 font-bold text-md">
                        Modifications non enregistrées
                    </div>

                    <div className="flex flex-row">
                        <div onClick={() => handleResetData()} className='border-[1px] border-gray-200 hover:bg-[#eb2f06] hover:border-[#eb2f06] rounded  hover:opacity-[1] cursor-pointer flex flex-row px-4 py-2 mr-2'>
                            <i className="fa fa-times text-gray-200 text-[27px] relative top-[.33rem]" />
                            <span className="text-gray-200 ml-1 mt-[.1rem] font-bold">Annuler</span>
                        </div>
                        <div onClick={() => id ? handleEditLanguage() : handleAddLanguage()} className='bg-indigo-700 hover:bg-indigo-500 rounded  hover:opacity-[1] cursor-pointer flex flex-row px-4 py-2'>
                            <i className="fa fa-save text-[white] text-[25px]" />
                            <span className="text-[white] ml-2 mt-[.1rem] font-bold">Enregistrer</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-margin">
                <div className="flex row">
                    <button onClick={() => navigate('/settings/languages')} className="text-white cursor-pointer border-[1.2px] border-[#babfc3] bg-transparent transition duration-150 ease-in-out w-10 h-10 rounded flex items-center justify-center ">
                        <svg viewBox="0 0 20 20" height={25} width={25} fill='#5c5f62' focusable="false" aria-hidden="true"><path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path></svg>
                    </button>

                    {id
                        ? <h1 className="text-xl font-semibold mt-1 ml-4">Éditer une langue</h1>
                        : <h1 className="text-xl font-semibold mt-1 ml-4">Ajouter une langue</h1>
                    }

                </div>

                <div className="grid grid-cols-3 gap-4 mt-6">
                    <div className="col-span-2">
                        <div className="flex justify-between w-full">
                            <div className="w-full rounded bg-white shadow p-6">

                                {/* TITLE INPUT */}
                                <div className="relative w-full">
                                    <label className="text-gray-700">
                                        Nom
                                    </label>
                                    <input type="text" name="nameCat" value={language?.name || ''} onChange={(e) => setLanguage({ ...language, name: e.target.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="Français" />
                                </div>

                                <div className="relative w-full mt-4">
                                    <label className="text-gray-700">
                                        ISO code <span className="text-[red]">*</span>
                                    </label>

                                    <div className={`mt-2 ${!language?.isoCode && errorInput.includes("isoCode") && 'border-[1px] border-[red] rounded'}`}>
                                        <Select
                                            isMulti={false}
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            styles={{
                                                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                                menu: (provided) => ({ ...provided, zIndex: 9999 })
                                            }}
                                            value={allCodeLanguages.find((item) => item?.value === language?.isoCode)}
                                            onChange={(item) => handleChangeISOCode(item)}
                                            options={allCodeLanguages}
                                            placeholder={"FR"}
                                        />
                                    </div>
                                </div>

                                {/* <div className="relative w-full mt-4">
                                    <label className="text-gray-700">
                                        Code langue
                                    </label>
                                    <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="fr-FR" />

                                    <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-2">Balise de langue IETF (par exemple, en-US, pt-BR).</p>

                                </div> */}

                                <div className="relative w-full mt-4">
                                    <label className="text-gray-700">
                                        Format date
                                    </label>
                                    <input type="text" onChange={(e) => setLanguage({ ...language, dateFormat: e.target.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="DD-MM-YYYY" />

                                    <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-2">Format de date court (par exemple, D-M-Y).</p>

                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between w-full mt-4">
                            <div className="w-full rounded bg-white shadow p-6">
                                <h1 className="font-semibold mb-4">Drapeau du pays</h1>

                                <div className="w-full rounded border-dashed hover:border-indigo-300 border-2 border-gray-300 p-10 text-center">
                                    <label className="btn btn-default bg-indigo-700 cursor-pointer text-[white] px-5 py-2 rounded">
                                        Ajouter une image
                                        <input accept="image/*" type="file" style={{ display: 'none' }} required />
                                    </label>
                                    <p className="mt-2">PNG & JPG ACCEPTÉ</p>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between w-full">
                            <div className="flex-col w-full items-start lg:items-center rounded bg-white shadow">
                                <div className="p-6">
                                    <h1 className="font-semibold mb-4">Statut</h1>

                                    <Select
                                        isMulti={false}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        styles={{
                                            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                            menu: (provided) => ({ ...provided, zIndex: 9999 })
                                        }}
                                        value={language?.disabled ? { name: "Inactif" } : { name: "Actif" }}
                                        onChange={(item) => item?.name === "Actif" ? setLanguage({ ...language, disabled: false }) : setLanguage({ ...language, disabled: true })}
                                        options={status}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.name}
                                        placeholder={"Actif"}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddLanguage;

