import { GET_ALL_ITEMS } from "./types";
import apiAuth from "../api/items";

// GET ALL ITEMS
export const getAllItems = () =>
    async (dispatch) => {
        return apiAuth
            .getAllItems()
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_ITEMS, payload: result.data });

                    return result?.data
                }
            })
    };

// GET ITEM BY ID
export const getItemById = (id) =>
    async () => {
        return apiAuth
            .getItemById(id)
            .then(async (result) => {
                if (result) {
                    return result?.data
                }
            })

            .catch(async (err) => {
                console.log(err)
            });
    }

// EDIT ITEM BY ID
export const editItem = (data) =>
    async () => {
        return apiAuth
            .editItem(data)
            .then(async (result) => {
                if (result) {
                    return result
                }
            })
    }

// CREATE ITEM
export const createItemPrototype = (data) =>
    async () => {
        return apiAuth
            .createItemPrototype(data)
            .then(async (result) => {
                if (result) {
                    return result
                }
            })
    }

// DUPLICATE ITEM BY ID
export const duplicateItemById = async (id) => {
    return apiAuth
        .duplicateItemById(id)
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
}

// DELETE ITEM BY ID
export const deleteItemById = (id) =>
    async (dispatch) => {
        return apiAuth
            .deleteItemById(id)
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_ITEMS, payload: result.data })
                    return result?.data
                }
            })
    };