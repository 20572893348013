import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { getAllCategories } from "../actions/categories";
import { createItemPrototype, duplicateItemById, editItem, getItemById } from "../actions/items";
import Switch from "react-switch";
import CreatableSelect from 'react-select/creatable';
import { getAllBrands } from "../actions/brands";
import { getAllCollections } from "../actions/collections";
import { NotificationManager } from 'react-notifications';
import { getAllTaxes } from "../actions/tax";
import { usePrompt } from '../utils/prompt';
import { IMG_LINK } from "../config/key";

const AddProduct = () => {
    let location = useLocation();
    let divRef = useRef({});

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const status = [{ name: "Actif" }, { name: "Inactif" }];
    const id = location?.pathname?.split("/")[3];
    const [product, setProduct] = useState([]);
    const [allBrands, setAllBrands] = useState([]);
    const [allCollections, setAllCollections] = useState([]);
    const [allTaxes, setAllTaxes] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [propertie, setPropertie] = useState([]);
    const [propertieValue, setPropertieValue] = useState([]);
    const [titleSpec, setTitleSpec] = useState();
    const [valueSpec, setValueSpec] = useState();
    const [declinaison, setDeclinaison] = useState([]);
    const [priceProps, setPriceProps] = useState();
    const [quantityProps, setQuantityProps] = useState();
    const [rebatedPriceProps, setRebatedPriceProps] = useState();
    const [skuProps, setSkuProps] = useState();
    const [imageDeclinaison, setImageDeclinaison] = useState([]);
    const [imageThumbnail, setImageThumbnail] = useState([]);
    const [chooseCollections, setChooseCollections] = useState();
    const [weightProps, setWeightProps] = useState('');
    const [netWeightProps, setNetWeightProps] = useState('');
    const [volumeProps, setVolumeProps] = useState('');
    const [dataChanged, setDataChanged] = useState(false);
    const [idProductCreated, setIdProductCreated] = useState()
    const [dataObj, setDataObj] = useState({});
    const [editDeclinaison, setEditDeclinaison] = useState();
    const [isOpen, setIsOpen] = useState(true);

    // WEIGHT FOR VARIANT AND SIMPLE PRODUCT
    const [dropdownUnitWeight, setDropdownUnitWeight] = useState(false);
    const [unitWeight, setUnitWeight] = useState('Gramme');
    const [dropdownUnitWeightVariant, setDropdownUnitWeightVariant] = useState(false);
    const [unitWeightVariant, setUnitWeightVariant] = useState('Gramme');

    // NET WEIGHT FOR VARIANT AND SIMPLE PRODUCT
    const [dropdownUnitWeightNet, setDropdownUnitWeightNet] = useState(false);
    const [unitWeightNet, setUnitWeightNet] = useState('Gramme');
    const [dropdownUnitWeightNetVariant, setDropdownUnitWeightNetVariant] = useState(false);
    const [unitWeightNetVariant, setUnitWeightNetVariant] = useState('Gramme');

    // VOLUME FOR VARIANT AND SIMPLE PRODUCT
    const [dropdownUnitVolume, setDropdownUnitVolume] = useState(false);
    const [unitVolume, setUnitVolume] = useState('Litre');
    const [dropdownUnitVolumeVariant, setDropdownUnitVolumeVariant] = useState(false);
    const [unitVolumeVariant, setUnitVolumeVariant] = useState('Litre');

    const [modalVariantShow, setModalVariantShow] = useState(false);
    const [showThumbnailVariant, setShowThumbnailVariant] = useState(false)
    const [tempDeclinaisonValue, setTempDeclinaisonValue] = useState({});
    const [declinaisonsToShow, setDeclinaisonsToShow] = useState(5); // Initialisation avec 5 déclinaisons à afficher

    useEffect(() => {
        if (idProductCreated) {
            navigate('/edit/product/' + idProductCreated);
            setIdProductCreated()
        }
    }, [idProductCreated])

    useEffect(() => {
        if (!modalVariantShow)
            document.body.style.overflow = 'auto'; // Rétablir le scroll
    }, [modalVariantShow])

    useEffect(() => {
        compareObjects()
    }, [product])

    useEffect(() => {
        dispatch(getAllTaxes()).then((res) => {
            setAllTaxes(res)
        })

        dispatch(getAllBrands()).then((res) => {
            setAllBrands(res)
        })

        dispatch(getAllCategories()).then((res) => {
            setAllCategories(res)
        })

        if (product?.simpleItem === undefined) {
            setProduct({ ...product, simpleItem: true, properties: [], images: [] });
            setDataObj({ ...product, simpleItem: true, properties: [], images: [] });
        }

        dispatch(getAllCollections()).then((res) => {
            setAllCollections(res);
        })

        if (location?.state?.product) {
            duplicateItemById(location?.state?.product)
                .then((response) => {
                    setProduct(response);
                    setDataObj(response);
                    setDataChanged([]);
                    navigate("/edit/product/" + response?._id);

                    setDeclinaison(response?.Variants);

                    dispatch(getAllCollections()).then((res) => {
                        setAllCollections(res);

                        const data = response?.collections?.map((id) => res.find((el) => el._id == id));

                        let tempArr = [];

                        for (let index = 0; index < data?.length; index++) {
                            tempArr.push({
                                ...data[index],
                                value: data[index]?.name,
                                label: data[index]?.name,
                                key: data[index]?._id
                            })
                        }

                        setChooseCollections(tempArr)
                    })

                    NotificationManager.success("Votre produit a été dupliquer avec succès !", 'Succès')
                })
                .catch((err) => {
                    NotificationManager.error(err.response.data.error, 'Erreur')
                })
        }

        if (id !== undefined) {
            dispatch(getItemById(id)).then(async (response) => {
                setDataObj(response)

                setProduct(response);



                dispatch(getAllCollections())

                    .then((res) => {
                        setAllCollections(res);

                        const data = response?.collections?.map((id) => res.find((el) => el._id == id));

                        let tempArr = [];

                        for (let index = 0; index < data?.length; index++) {
                            tempArr.push({
                                ...data[index],
                                value: data[index]?.name,
                                label: data[index]?.name,
                                key: data[index]?._id
                            })
                        }

                        setChooseCollections(tempArr);
                    })

                setDeclinaison(response?.Variants);

                const imgType = ['image1Id', 'image2Id', 'image3Id', 'image4Id', 'image5Id'];

                // DOWNLOAD OLD IMAGE AND CONVERT TO B64
                let tempArr = [];

                for (let i = 0; i < imgType.length; i++) {
                    if (response?.Variants[0].images[imgType[i]] !== undefined) {
                        const data = IMG_LINK + response?.Variants[0].images[imgType[i]];
                        const img = await getBase64FromUrl(data);

                        tempArr.push(img)
                    }
                }

                let thumbnailImg = null;

                // DOWNLOAD THUMBNAIL IMAGE AND CONVERT TO B64
                if (response?.Variants[0].images["thumbnailId"] !== undefined) {
                    const data = IMG_LINK + response?.Variants[0].images["thumbnailId"];
                    const img = await getBase64FromUrl(data);
                    thumbnailImg = img;
                }

                setProduct({ ...response, images: tempArr, thumbnail: thumbnailImg })
                setDataObj({ ...response, images: tempArr, thumbnail: thumbnailImg })
            })
        }


    }, [idProductCreated])

    useEffect(() => {
        if (showThumbnailVariant === false) {
            setImageThumbnail([])
        }
    }, [showThumbnailVariant])

    const compareObjects = () => {
        if (!idProductCreated) {
            const productKeys = Object.keys(product);

            const isDifferent = productKeys.some((key) => {
                const productValue = product[key];
                const dataObjValue = dataObj[key];

                if (typeof productValue !== "object" && typeof dataObjValue !== "object") {
                    return productValue !== dataObjValue;
                }

                return JSON.stringify(productValue) !== JSON.stringify(dataObjValue);
            });

            setDataChanged(isDifferent);
        }
    };

    const handleChangeSwitch = () => {
        setProduct({ ...product, simpleItem: !product?.simpleItem });

        if (product?.simpleItem) {
            setDeclinaison([]);
        }
    };

    const getBase64FromUrl = async (url) => {
        const data = await fetch(url + "?a=" + Date.now().toString(), {
            method: "GET",
            headers: {
                "Origin": "*"
            },
        })

        const blob = await data.blob();

        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const lastIndex = url.lastIndexOf('.');

                if (url.slice(lastIndex + 1) === 'png') {
                    resolve(reader.result.replace('application/octet-stream', 'image/png'));
                }
                if (url.slice(lastIndex + 1) === 'webp') {
                    resolve(reader.result.replace('application/octet-stream', 'image/webp'));
                }
                if (url.slice(lastIndex + 1) === 'jpg') {
                    resolve(reader.result.replace('application/octet-stream', 'image/jpg'));
                }
                if (url.slice(lastIndex + 1) === 'jpeg') {
                    resolve(reader.result.replace('application/octet-stream', 'image/jpeg'));
                }
            }
        });
    }

    const handleChooseCategories = (id) => {
        const categories = product.categories || [];
        if (categories.includes(id)) {
            setProduct({ ...product, categories: categories.filter((categoryId) => categoryId !== id) });
        } else {
            setProduct({ ...product, categories: [...categories, id] });
        }
    }

    const renderCategories = item => Object.keys(item).map((it, index) => {
        const hasSubCat = Array.isArray(item[it]?.subCat) && item[it]?.subCat.length > 0;

        const toggleOpen = () => {
            setIsOpen(!isOpen); // Inverse l'état de visibilité lors du clic sur le texte
        };

        return (
            <div key={index}>

                <ul>
                    <div className="flex row">
                        {hasSubCat &&
                            <svg onClick={() => toggleOpen()} className={`w-[22px] relative top-[.1rem] arrow-icon cursor-pointer`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        }

                        <input className="mt-1" type="checkbox" name={item[it]._id} onChange={() => handleChooseCategories(item[it]?._id)} checked={product?.categories && product?.categories?.includes(item[it]._id)} />
                        <label className="ml-1">{item[it].name}</label>
                    </div>

                    {hasSubCat && isOpen && (
                        <div className="ml-9">
                            {renderCategories(Object.assign({}, item[it]?.subCat))}
                        </div>
                    )}
                </ul>
            </div>
        )
    })

    const handleSetPropertieValue = (item) => {
        setPropertieValue(item);
    }

    const handleCreateProperties = () => {
        let currLabel = propertie;
        let currVal = propertieValue;
        let results = { ...product?.properties };

        if (currLabel.length !== 0 && currVal !== false) {
            currVal.forEach(function (item, idx) {
                if (Object.keys(results).includes(currLabel)) {
                    if (!results[currLabel].includes(currVal[idx].value))
                        results[currLabel].push(currVal[idx].value)
                } else {
                    results[currLabel] = [];
                    results[currLabel].push(currVal[idx].value);
                }
            })

            setProduct({ ...product, properties: { ...product.properties, ...results } })

            setPropertie([]);
            setPropertieValue(false);
        } else {
            NotificationManager.error("Merci de bien vouloir remplir tous les champs afin d'ajouter vos attributs !", 'Erreur')
        }
    }

    const handleRemovePropertie = (key) => {
        let tempObj = { ...product?.properties };

        delete tempObj[key];

        setProduct({ ...product, properties: tempObj })
    }

    const options = (prop, propertyName) => {
        let temp = [];

        prop.map(function (item) {
            temp.push({ value: item, label: item, property: propertyName })
        })

        return temp
    }

    const handleSetDeclinaison = (item) => {
        if (item !== null) {
            setTempDeclinaisonValue(prevState => {
                const newObj = { ...prevState, [item.property]: item.value };
                return newObj;
            });
        }
    }

    const handleRemoveDeclinaison = (idx) => {
        setDeclinaison((prevDeclinaison) => {
            const arr = [...prevDeclinaison];
            arr.splice(idx, 1);
            setDataChanged(true)
            return arr;
        });


    };

    const uploadDeclinaisonPictures = (data) => {
        if (imageDeclinaison.length < 5) {
            var reader = new FileReader();

            reader.readAsDataURL(data);

            reader.onload = function () {
                setImageDeclinaison([...imageDeclinaison, reader.result]);
            };

            const inputElement = document.getElementById('uploadPictureVariant');
            if (inputElement) {
                inputElement.value = null;
            }

        } else {
            NotificationManager.error("Merci de bien vouloir sélectionner 5 images au maximum !", 'Erreur');
        }
    };

    const handleDeleteDeclinaisonImage = (index) => {
        // Créez une copie de l'array imageThumbnail à l'aide de la méthode slice()
        const newImgArray = imageDeclinaison.slice();

        // Supprimez l'élément à l'index spécifié de la copie de l'array
        newImgArray.splice(index, 1);

        // Mettez à jour l'état setImageThumbnail avec la nouvelle copie de l'array modifiée
        setImageDeclinaison(newImgArray);
    };

    const handleCreateDeclinaison = (key) => {

        const data = {
            price: parseFloat(priceProps),
            rebatedPrice: parseFloat(rebatedPriceProps),
            quantity: parseFloat(quantityProps),
            weight: parseFloat(convertToGrams(weightProps, unitWeightVariant)),
            netWeight: parseFloat(convertToGrams(netWeightProps, unitWeightNetVariant)),
            volume: parseFloat(convertToLiters(volumeProps, unitVolumeVariant)),
            sku: skuProps,
            properties: tempDeclinaisonValue,
            images: {},
        }
        for (let index = 0; index < imageDeclinaison.length; index++) {
            const element = imageDeclinaison[index];
            data.images['image' + (Number(index) + 1)] = element;
        }

        if (imageThumbnail?.length === 0) {
            data.images['thumbnail'] = data.images['image1']; // Utilisez la première image
        }

        if (imageThumbnail?.length !== 0) {
            data.images['thumbnail'] = imageThumbnail; // Utilisez la première image
        }

        if (!editDeclinaison) {
            if (Object.keys(tempDeclinaisonValue).length === Object?.keys(product?.properties)?.length && data?.price && data?.weight && data?.quantity) {
                setDeclinaison(current => [...current, data]);
                setDataChanged(true)
                setModalVariantShow(false);
                document.body.style.overflow = 'auto'; // Rétablir le scroll
                setPriceProps()
                setRebatedPriceProps()
                setQuantityProps()
                setSkuProps();
                setWeightProps()
                setNetWeightProps()
                setVolumeProps();
                setImageDeclinaison([])
                setImageThumbnail([])
            } else { NotificationManager.error("Merci de bien vouloir remplir les champs obligatoires !", '') }
        } else {
            const updatedDeclinaisons = [...declinaison];
            const updatedData = {
                ...data,
                properties: tempDeclinaisonValue
            };
            updatedDeclinaisons.splice(key, 1, updatedData);
            setDeclinaison(updatedDeclinaisons);


            setPriceProps()
            setModalVariantShow(false);
            document.body.style.overflow = 'auto'; // Rétablir le scroll
            setRebatedPriceProps()
            setQuantityProps()
            setWeightProps()
            setSkuProps();
            setNetWeightProps()
            setVolumeProps();
            setImageDeclinaison([])
            setEditDeclinaison();
            setTempDeclinaisonValue();

            setDataChanged(true);
            setTempDeclinaisonValue({});
            setImageThumbnail([]);
            setShowThumbnailVariant(false);
            const updatedVariants = [...product.Variants];
            updatedVariants.splice(key, 1, data);
        }


        for (let i = 0; i < Object.keys(product.properties).length; i++) {
            divRef.current[i].clearValue();
        }

    }

    const handleResetData = () => {
        setProduct(dataObj);
        setDeclinaison(dataObj?.Variants);
    }

    const convertToGrams = (weight, unit) => {
        switch (unit) {
            case 'Milligramme':
                return weight * 0.001;
            case 'Gramme':
                return weight;
            case 'Kilogramme':
                return weight * 1000;
            default:
                return weight; // Si l'unité est déjà en grammes, retourne la valeur d'origine
        }
    }

    const convertToLiters = (volume, unit) => {
        switch (unit) {
            case 'Millilitre':
                return volume * 0.001;
            case 'Centilitre':
                return volume * 0.01;
            case 'Litre':
                return volume;
            default:
                return volume; // Si l'unité est déjà en litres, retourne la valeur d'origine
        }
    };

    const handleCreateProduct = () => {

        if (product?.simpleItem) {

            let imgObj = {}

            for (let index = 0; index < 5; index++) {
                if (product?.images[index]) {
                    imgObj["image" + (index + 1)] = product?.images[index]
                } else {
                    imgObj["image" + (index + 1)] = "delete"
                }
            }

            if (product?.thumbnail) {
                imgObj["thumbnail"] = product?.thumbnail;
            }

            let tempCollection = [];

            if (chooseCollections) {
                for (let index = 0; index < chooseCollections.length; index++) {
                    tempCollection.push(chooseCollections[index]?._id)
                }
            }

            const data = {
                _id: id,
                productId: product?._id,
                name: product?.name,
                longDescription: product?.longDescription,
                shortDescription: product?.shortDescription,
                categories: product?.categories,
                metaTitle: product?.metaTitle,
                metaDescription: product?.metaDescription,
                collections: product?.collections,
                properties: {},
                slug: product?.slug,
                simpleItem: true,
                brand: product?.Brand?._id,
                price: product?.price ? Number(product?.price) : product?.Variants && product?.Variants[0]?.price,
                quantity: product?.quantity ? Number(product?.quantity) : product?.Variants && product?.Variants[0]?.quantity,
                rebatedPrice: product?.rebatedPrice ? Number(product?.rebatedPrice) : product?.Variants && product?.Variants[0]?.rebatedPrice,
                sku: product?.sku,
                weight: product?.weight ? Number(convertToGrams(product?.weight, unitWeight)) : product?.Variants && product?.Variants[0]?.weight,
                netWeight: product?.netWeight ? Number(convertToGrams(product?.netWeight, unitWeightNet)) : product?.Variants && product?.Variants[0]?.netWeight,
                volume: product?.volume ? Number(convertToLiters(product?.volume, unitVolume)) : product?.Variants && product?.Variants[0]?.volume,

                images: imgObj,
                taxe: product?.taxe,
                specifications: product?.specifications,
                disabled: product?.disabled
            }

            dispatch(createItemPrototype(data))
                .then((res) => {
                    setIdProductCreated(res?.data?._id);
                    setDataChanged(false);

                    setProduct(res?.data)
                    setDataChanged(false);
                    NotificationManager.success("Votre produit a été créer avec succès !", 'Succès');
                })
                .catch((err) => {
                    Object.keys(err?.response?.data?.error).map((key) => {
                        NotificationManager.error(err?.response?.data?.error[key], 'Erreur');
                    })
                })

        } else {

            let tempCollection = [];

            if (chooseCollections !== undefined) {
                for (let index = 0; index < chooseCollections.length; index++) {
                    tempCollection.push(chooseCollections[index]?._id)
                }
            }

            const data = {
                _id: id,
                productId: product?._id,
                name: product?.name,
                longDescription: product?.longDescription,
                shortDescription: product?.shortDescription,
                categories: product?.categories,
                metaTitle: product?.metaTitle,
                metaDescription: product?.metaDescription,
                collections: product?.collections,
                brand: product?.Brand?._id,
                properties: product?.properties,
                productItem: declinaison,
                slug: product?.slug,
                simpleItem: false,
                taxe: product?.taxe,
                specifications: product?.specifications,
                disabled: product?.disabled
            }

            dispatch(createItemPrototype(data))
                .then((res) => {
                    setIdProductCreated(res?.data?._id);
                    setDataChanged(false);

                    setProduct(res?.data);
                    NotificationManager.success("Votre produit a été modifier avec succès !", 'Succès');
                })
                .catch((err) => {
                    NotificationManager.error(err.response.data.error, 'Erreur')
                })
        }

    }

    const handleEdit = () => {
        if (product?.simpleItem) {
            let imgObj = {}

            for (let index = 0; index < 5; index++) {

                if (product?.images && product?.images[index]) {
                    imgObj["image" + (index + 1)] = product?.images[index]
                } else {
                    imgObj["image" + (index + 1)] = "delete"
                }
            }

            if (product?.thumbnail) {
                imgObj["thumbnail"] = product?.thumbnail;
            }

            let tempCollection = [];

            for (let index = 0; index < chooseCollections?.length; index++) {
                tempCollection?.push(chooseCollections[index]?._id)
            }

            const data = {
                _id: id,
                productId: product?._id,
                name: product?.name,
                longDescription: product?.longDescription,
                shortDescription: product?.shortDescription,
                categories: product?.categories,
                metaTitle: product?.metaTitle,
                metaDescription: product?.metaDescription,
                properties: {},
                sku: product?.sku,
                collections: product?.collections,
                simpleItem: true,
                price: product?.price ? Number(product?.price) : product?.Variants && product?.Variants[0]?.price,
                quantity: product?.quantity !== 0 ? Number(product?.quantity) : product?.Variants && product?.Variants[0]?.quantity !== 0 ? Number(product?.Variants[0]?.quantity) : undefined,
                rebatedPrice: product?.rebatedPrice !== 0 ? Number(product?.rebatedPrice) : product?.Variants && product?.Variants[0]?.rebatedPrice !== 0 ? Number(product?.Variants[0]?.rebatedPrice) : -1,
                slug: product?.slug,
                weight: product?.weight ? Number(product?.weight) : product?.Variants && product?.Variants[0]?.weight,
                netWeight: product?.netWeight ? Number(product?.netWeight) : product?.Variants && product?.Variants[0]?.netWeight,
                volume: product?.volume ? Number(product?.volume) : product?.Variants && product?.Variants[0]?.volume,
                images: imgObj,
                disabled: product?.disabled,
                taxe: product?.taxe,
                brand: product?.Brand?._id,
                specifications: product?.specifications,
                disabled: product?.disabled
            }

            dispatch(editItem(data))
                .then((res) => {
                    setDataObj(res?.data);
                    setDataChanged(false);
                    NotificationManager.success("Votre produit a été modifier avec succès !", 'Succès');
                })
                .catch(() => {
                    NotificationManager.error("Une erreur est survenue lors de la modification de votre produit !", 'Erreur');
                })
        } else {

            let tempCollection = [];

            for (let index = 0; index < chooseCollections.length; index++) {
                tempCollection.push(chooseCollections[index]?._id)
            }

            const data = {
                _id: id,
                productId: product?._id,
                name: product?.name,
                longDescription: product?.longDescription,
                shortDescription: product?.shortDescription,
                categories: product?.categories,
                metaTitle: product?.metaTitle,
                collections: product?.collections,
                metaDescription: product?.metaDescription,
                properties: product?.properties,
                disabled: product?.disabled,
                productItem: declinaison,
                simpleItem: false,
                slug: product?.slug,
                taxe: product?.taxe,
                brand: product?.Brand?._id,
                specifications: product?.specifications,
                disabled: product?.disabled
            }

            dispatch(editItem(data))
                .then((res) => {
                    setDataObj(res?.data);
                    setDataChanged(false);
                    NotificationManager.success("Le produit a été modifié avec succès !", 'Succès')
                })
                .catch((err) => {
                    NotificationManager.error(err.response.data.error, 'Erreur')
                })
        }

    }

    const imageToB64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleDeleteImageSimpleItem = (key) => {
        let arrTemp = [...product?.images];

        arrTemp.splice(key, 1);

        setProduct({ ...product, images: arrTemp })
    }

    const handleUploadImageSimpleItem = async (file) => {
        const imageBase64 = await imageToB64(file);

        setProduct({ ...product, images: [...product?.images, imageBase64] })
    }

    const handleSelectBrandForProduct = (item) => {
        setProduct({ ...product, Brand: item })
    }

    const handleAddSpecification = () => {
        let spec = { ...product?.specification, [titleSpec]: valueSpec }

        setTitleSpec('')
        setValueSpec('')

        if (titleSpec && valueSpec) {
            setProduct({ ...product, specifications: { ...product?.specifications, ...spec } })
            NotificationManager.success("Votre spécification a été ajouter avec succès !", 'Succès')
        } else {
            NotificationManager.error("Merci de bien vouloir remplir tout les champs afin d'ajouter une spécification !", 'Erreur')
        }

    }

    const handleDeleteSpecification = (key) => {
        let tempObj = { ...product?.specifications };

        delete tempObj[key];

        setProduct({ ...product, specifications: tempObj })
    }

    const handleCheckedDefaultVariant = (key) => {

        Object.keys(declinaison).map((idx) => {
            if (key === idx) {
                declinaison[idx]["isDefaultVariant"] = true
            } else {
                declinaison[idx]["isDefaultVariant"] = false
            }

            setDataChanged(true);
        })
    }

    const handleSelectCollectionsForProduct = (item) => {
        setChooseCollections(item)

        let arr = [];

        Object.keys(item).map((key) => {
            arr.push(item[key]?._id)
        })

        setProduct({ ...product, collections: arr })
    }

    const handleSelectTaxe = (tax) => {
        setProduct({ ...product, taxe: tax?._id })
    }

    const handleThumbnailUpload = (data) => {
        var reader = new FileReader();

        reader.readAsDataURL(data);

        reader.onload = function () {
            setProduct({ ...product, thumbnail: reader.result })
        };
    }

    const handleThumbnailUploadVariant = (data) => {
        var reader = new FileReader();

        reader.readAsDataURL(data);

        reader.onload = function () {
            setImageThumbnail(reader.result)
        };
    }

    const handleEditDeclinaison = async (declinaison, key) => {
        document.body.style.overflow = 'hidden'; // Bloquer le scroll
        setEditDeclinaison(key);
        setModalVariantShow(true);
        setTempDeclinaisonValue(declinaison?.properties)

        setSkuProps(declinaison?.sku)


        setWeightProps(declinaison?.weight);
        setNetWeightProps(declinaison?.netWeight);
        setVolumeProps(declinaison?.volume);
        setPriceProps(declinaison?.price);
        setQuantityProps(declinaison?.quantity);
        setRebatedPriceProps(declinaison?.rebatedPrice);

        if (declinaison?.images?.thumbnail) {
            setImageThumbnail(declinaison?.images?.thumbnail)
        }


        const imgType = ['image1Id', 'image2Id', 'image3Id', 'image4Id', 'image5Id'];

        const images = imgType.map((imgKey) => {
            if (declinaison?.images && declinaison.images[imgKey]) {
                let imageUrl = declinaison.images[imgKey];
                if (!imageUrl.startsWith(IMG_LINK)) {
                    imageUrl = IMG_LINK + imageUrl;
                }
                return imageUrl;
            }
            return null;
        });

        if (declinaison?.images?.thumbnailId)
            setShowThumbnailVariant(true);

        if (declinaison?.images?.thumbnail)
            setShowThumbnailVariant(true);


        // Remove null values (for non-existing images) from the images array
        const filteredImages = images.filter((img) => img !== null);

        // Convertir les URL des images en base64
        const base64Images = await Promise.all(filteredImages.map((imageUrl) => getBase64FromUrl(imageUrl)));

        // Update the image state with the filtered images
        if (base64Images.length !== 0) {
            setImageDeclinaison(base64Images);
        } else {
            const arr = []
            Object.keys(declinaison?.images).forEach((key) => {
                if (key !== "thumbnail") {
                    arr.push(declinaison?.images[key])
                }
            })
            setImageDeclinaison(arr);

        }

        // Si thumbnailId existe, définissez l'image miniature en base64
        if (declinaison?.images && declinaison.images.thumbnailId) {
            const thumbnail = await getBase64FromUrl(IMG_LINK + declinaison.images.thumbnailId);
            setImageThumbnail(thumbnail);
        }
    };

    const handleSetUnitWeight = (unit) => {
        setUnitWeight(unit);
        setDropdownUnitWeight(false);
    }

    const handleSetUnitWeightVariant = (unit) => {
        setUnitWeightVariant(unit);
        setDropdownUnitWeightVariant(false);
    }

    const handleSetUnitWeightNet = (unit) => {
        setUnitWeightNet(unit);
        setDropdownUnitWeightNet(false);
    }

    const handleSetUnitWeightNetVariant = (unit) => {
        setUnitWeightNetVariant(unit);
        setDropdownUnitWeightNetVariant(false);
    }

    const handleSetUnitVolume = (unit) => {
        setUnitVolume(unit);
        setDropdownUnitVolume(false);
    }

    const handleSetUnitVolumeVariant = (unit) => {
        setUnitVolumeVariant(unit);
        setDropdownUnitVolumeVariant(false);
    }

    const handleCancelModalVariant = () => {
        setModalVariantShow(false);
        setEditDeclinaison()
        setPriceProps()
        setTempDeclinaisonValue({});
        setRebatedPriceProps()
        setQuantityProps()
        setSkuProps();
        setWeightProps()
        setNetWeightProps()
        setVolumeProps();
        setImageThumbnail();
        setImageDeclinaison([])
        setImageThumbnail([]);
        setShowThumbnailVariant(false);
    }

    return (
        usePrompt(dataChanged),

        <div>
            <div onClick={() => { dropdownUnitWeight && setDropdownUnitWeight(false); dropdownUnitWeightVariant && setDropdownUnitWeightVariant(false); dropdownUnitWeightNet && setDropdownUnitWeightNet(false); dropdownUnitWeightNetVariant && setDropdownUnitWeightNetVariant(false); dropdownUnitVolume && setDropdownUnitVolume(false); dropdownUnitVolumeVariant && setDropdownUnitVolumeVariant(false) }} >
                <div className={`fixed bg-[#080404] top-0 py-[.7rem] px-5 w-full left-0 z-[999] m-0 text-center test ${dataChanged ? "transition ease-in-out delay-250 opacity-1 duration-500 z-[999]" : "opacity-0 delay-250 duration-500 z-[0]"}`}>
                    <div className="flex justify-between text-[white]">
                        <div className="cursor-pointer" onClick={() => navigate('/')}>
                            <svg fill="currentColor" width="38" height="38" viewBox="0 0 174 174" xmlns="http://www.w3.org/2000/svg">
                                <path fill="white" fillRule="evenodd" clipRule="evenodd" d="M0 9.23318C0 4.13387 4.13275 0 9.23075 0H164.769C169.867 0 174 4.13387 174 9.23318V41.7256C168.389 42.1805 163.181 43.5167 158.377 45.7341C152.334 48.4078 147.394 52.1858 143.559 57.0681C140.421 51.9533 136.12 48.1172 130.658 45.5598C125.312 42.8861 119.152 41.5493 112.178 41.5493C105.786 41.5493 99.9745 42.6537 94.7445 44.8623C89.5144 47.0711 85.1559 50.2678 81.6691 54.4526V42.9386H60.9231V135.708H82.7151V88.4543C82.7151 79.3872 84.8653 72.4706 89.1657 67.7045C93.5822 62.9385 99.5097 60.5555 106.948 60.5555C113.689 60.5555 118.861 62.6479 122.464 66.8327C126.067 70.9013 127.868 77.0041 127.868 85.1414V135.708H149.661V88.4543C149.661 79.5034 151.869 72.6449 156.285 67.8789C160.702 62.9966 166.571 60.5555 173.893 60.5555L174 60.5557V164.767C174 169.866 169.867 174 164.769 174H9.23075C4.13275 174 0 169.866 0 164.767V9.23318ZM112.321 69.9598C106.222 69.9598 101.199 72.2675 97.4993 76.8928L97.4895 76.9051L97.4798 76.9175C93.89 81.527 92.2757 87.9726 92.2757 95.8953V135.709H97.1218V95.8953C97.1218 88.5482 98.631 83.3425 101.29 79.9206C104.013 76.523 107.607 74.8191 112.321 74.8191V69.9598Z"></path>
                            </svg>
                        </div>

                        <div className="mt-2 font-bold text-md">
                            Modifications non enregistrées
                        </div>

                        <div className="flex flex-row">
                            <div onClick={() => handleResetData()} className='border-[1px] border-gray-200 hover:bg-[#eb2f06] hover:border-[#eb2f06] rounded  hover:opacity-[1] cursor-pointer flex flex-row px-4 py-2 mr-2'>
                                <i className="fa fa-times text-gray-200 text-[27px] relative top-[.33rem]" />
                                <span className="text-gray-200 ml-1 mt-[.1rem] font-bold">Annuler</span>
                            </div>
                            <div onClick={id === undefined ? handleCreateProduct : handleEdit} className='bg-indigo-700 hover:bg-indigo-500 rounded  hover:opacity-[1] cursor-pointer flex flex-row px-4 py-2'>
                                <i className="fa fa-save text-[white] text-[25px]" />
                                <span className="text-[white] ml-2 mt-[.1rem] font-bold">Enregistrer</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-margin">
                    <div className="flex row">
                        <button onClick={() => navigate('/products', { state: { from: "/add/product" } })} className="text-white cursor-pointer border-[1.2px] border-[#babfc3] bg-transparent transition duration-150 ease-in-out w-10 h-10 rounded flex items-center justify-center ">
                            <svg viewBox="0 0 20 20" height={25} width={25} fill='#5c5f62' focusable="false" aria-hidden="true"><path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path></svg>
                        </button>

                        {(id === undefined)
                            ? <h1 className="text-xl font-semibold mt-1 ml-4">Ajouter un produit</h1>
                            : <h1 className="text-xl font-semibold mt-1 ml-4">Éditer un produit</h1>
                        }
                    </div>

                    <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-4">
                        <div className="col-span-2">
                            <div className="flex justify-between w-full">
                                <div className="w-full rounded bg-white shadow p-6">

                                    {/* TITLE INPUT */}
                                    <div className="relative w-full">
                                        <label className="text-gray-700">
                                            Nom <span className="relative left-[-3px] text-red-500">*</span>
                                        </label>
                                        <input type="text" value={product?.name || ''} name="product_name" onChange={e => setProduct({ ...product, name: e.target.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="Nom du produit" />
                                    </div>

                                    <div className="relative w-full mb-4 mt-4">
                                        <label className="text-gray-700">
                                            Description courte <span className="relative left-[-3px] text-red-500">*</span>
                                        </label>
                                        <textarea rows={4} type="text" value={product?.shortDescription || ''} name="short_description" onChange={(e) => setProduct({ ...product, shortDescription: e.target.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" maxLength={500} />
                                        <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-1">{!product?.shortDescription?.length ? '0' : product?.shortDescription?.length} sur 500 caractère(s) utilisé(s)</p>
                                    </div>

                                    {/* DESCRIPTION INPUT */}
                                    <div className="mt-4">
                                        <label className="text-gray-700">
                                            Description longue <span className="relative left-[-3px] text-red-500">*</span>
                                        </label>
                                        <form>
                                            <div className="mb-1 w-full bg-gray-50 rounded-lg border border-gray-200 mt-2">
                                                <div className="flex justify-between items-center py-2 px-3 border-b">
                                                    <div className="flex flex-wrap items-center divide-gray-200 sm:divide-x">
                                                        <div className="flex items-center space-x-1 sm:pr-4">
                                                            <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd"></path></svg>
                                                            </button>
                                                            <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path></svg>
                                                            </button>
                                                            <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd"></path></svg>
                                                            </button>
                                                            <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                            </button>
                                                            <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z" clipRule="evenodd"></path></svg>
                                                            </button>
                                                        </div>
                                                        <div className="flex flex-wrap items-center space-x-1 sm:pl-4">
                                                            <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                                                            </button>
                                                            <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path></svg>
                                                            </button>
                                                            <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                                                            </button>
                                                            <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <button type="button" data-tooltip-target="tooltip-fullscreen" className="p-2 text-gray-500 rounded cursor-pointer sm:ml-auto hover:text-gray-900 hover:bg-gray-100">
                                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 11-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 010-2h4a1 1 0 011 1v4a1 1 0 01-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 012 0v1.586l2.293-2.293a1 1 0 111.414 1.414L6.414 15H8a1 1 0 010 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.586l-2.293-2.293a1 1 0 111.414-1.414L15 13.586V12a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                                    </button>
                                                    <div id="tooltip-fullscreen" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
                                                        Show full screen
                                                        <div className="tooltip-arrow" data-popper-arrow></div>
                                                    </div>
                                                </div>
                                                <div className="py-2 px-4 bg-white rounded-b-lg">
                                                    <label className="sr-only">Publish post</label>
                                                    <textarea value={product?.longDescription || ''} name="description" onChange={e => setProduct({ ...product, longDescription: e.target.value })} id="editor" rows="8" className="block px-0 w-full text-sm text-gray-800 bg-white border-0 focus:ring-0" required maxLength={3500}></textarea>
                                                    <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-2">{!product?.longDescription?.length ? '0' : product?.longDescription?.length} sur 3500 caractère(s) utilisé(s)</p>
                                                </div>
                                            </div>

                                        </form>
                                    </div>



                                    <div className="relative w-full mt-4">
                                        <label className="text-gray-700">
                                            Slug
                                        </label>
                                        <input type="text" value={product?.slug || ''} onChange={(e) => setProduct({ ...product, slug: e.target.value })} name="slug" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="" />
                                    </div>
                                    <p className="mt-1 text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold">Il s'agit de l'adresse URL où votre produit sera disponible. Si vous laissez le champ vide sur votre site, l'adresse sera générée automatiquement.</p>

                                </div>
                            </div>

                            {product?.simpleItem &&
                                <div className="flex justify-between w-full mt-4">
                                    <div className="w-full rounded bg-white shadow p-6">
                                        <h1 className="font-semibold mb-4">Informations</h1>
                                        <div>
                                            <div className="grid grid-cols-2 gap-4 mt-8">
                                                <div className="flex flex-col">
                                                    <label className="text-gray-700">
                                                        Prix <span className="relative left-[-3px] text-red-500">*</span>
                                                    </label>

                                                    <div className="placeholder2" data-placeholder="€">
                                                        <input type="number" min={0} value={product.price !== undefined ? product.price : (product.Variants && product.Variants[0]?.price) || ""}
                                                            onChange={(e) => setProduct({ ...product, price: e.target.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="price" />
                                                    </div>
                                                </div>
                                                <div className="flex flex-col">
                                                    <label className="text-gray-700">
                                                        Prix avec remise
                                                    </label>
                                                    <div className="placeholder2" data-placeholder="€">
                                                        <input type="number" min={0} value={product.rebatedPrice !== undefined ? product.rebatedPrice : (product.Variants && product.Variants[0]?.rebatedPrice) || ""} onChange={(e) => setProduct({ ...product, rebatedPrice: e.target.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="price" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="text-gray-700">
                                                        Stock <span className="relative left-[-3px] text-red-500">*</span>
                                                    </label>
                                                    <input type="number" min={0} value={product.quantity !== undefined ? product.quantity : (product.Variants && product.Variants[0]?.quantity) || ""} onChange={(e) => setProduct({ ...product, quantity: e.target.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="price" />
                                                </div>
                                                <div className="flex flex-col">
                                                    <label className="text-gray-700">
                                                        Poids <span className="relative left-[-3px] text-red-500">*</span>
                                                    </label>
                                                    <div className="flex mt-2 relative">
                                                        <div className="relative w-full">
                                                            <input type="number" min={0} value={product.weight !== undefined ? product.weight : (product.Variants && product.Variants[0]?.weight) || ""} onChange={(e) => setProduct({ ...product, weight: e.target.value })} className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-white rounded-l-lg border-r-gray-100 border-l-1 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" />
                                                        </div>
                                                        <div onClick={() => setDropdownUnitWeight(!dropdownUnitWeight)} data-dropdown-toggle="dropdown" className="flex-shrink-0 z-0 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 dark:border-gray-700 dark:text-white rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 cursor-pointer" >{unitWeight} <svg aria-hidden="true" className="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></div>
                                                        {dropdownUnitWeight &&
                                                            <div id="dropdown" className={`z-10 cursor-pointer absolute right-0 top-12 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}>
                                                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
                                                                    <li>
                                                                        <a onClick={() => handleSetUnitWeight('Milligramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Milligramme</a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={() => handleSetUnitWeight('Gramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">Gramme</a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={() => handleSetUnitWeight('Kilogramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Kilogramme</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="flex flex-col">
                                                    <label className="text-gray-700">
                                                        Poids net
                                                    </label>

                                                    <div className="flex mt-2 relative">
                                                        <div className="relative w-full">
                                                            <input min={0} value={product.netWeight !== undefined ? product.netWeight : (product.Variants && product.Variants[0]?.netWeight) || ""} onChange={(e) => setProduct({ ...product, netWeight: e.target.value })} type="number" className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-white rounded-l-lg border-r-gray-100 border-l-1 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" />
                                                        </div>
                                                        <div onClick={() => setDropdownUnitWeightNet(!dropdownUnitWeightNet)} data-dropdown-toggle="dropdown" className="flex-shrink-0 z-0 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 dark:border-gray-700 dark:text-white rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 cursor-pointer" >{unitWeightNet} <svg aria-hidden="true" className="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></div>
                                                        {dropdownUnitWeightNet &&
                                                            <div id="dropdown" className={`z-10 cursor-pointer absolute right-0 top-12 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}>
                                                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
                                                                    <li>
                                                                        <a onClick={() => handleSetUnitWeightNet('Milligramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Milligramme</a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={() => handleSetUnitWeightNet('Gramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">Gramme</a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={() => handleSetUnitWeightNet('Kilogramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Kilogramme</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="flex flex-col">
                                                    <label className="text-gray-700">
                                                        Volume
                                                    </label>

                                                    <div className="flex mt-2 relative">
                                                        <div className="relative w-full">
                                                            <input type="number" min={0} value={product.volume !== undefined ? product.volume : (product.Variants && product.Variants[0]?.volume) || ""} onChange={(e) => setProduct({ ...product, volume: e.target.value })} className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-white rounded-l-lg border-r-gray-100 border-l-1 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" />
                                                        </div>
                                                        <div onClick={() => setDropdownUnitVolume(!dropdownUnitVolume)} data-dropdown-toggle="dropdown" className="flex-shrink-0 z-0 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 dark:border-gray-700 dark:text-white rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 cursor-pointer" >{unitVolume} <svg aria-hidden="true" className="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></div>
                                                        {dropdownUnitVolume &&
                                                            <div id="dropdown" className={`z-10 cursor-pointer absolute right-0 top-12 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}>
                                                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
                                                                    <li>
                                                                        <a onClick={() => handleSetUnitVolume('Millilitre')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Millilitre</a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={() => handleSetUnitVolume('Centilitre')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">Centilitre</a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={() => handleSetUnitVolume('Litre')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Litre</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-6">
                                                <label className="text-gray-700">
                                                    SKU
                                                </label>
                                                <div className="flex w-full">
                                                    <input placeholder="TSH-000-S" value={product?.sku ? product?.sku : product?.Variants && product?.Variants[0]?.sku || ''} onChange={(e) => setProduct({ ...product, sku: e.target.value })} type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="price" />
                                                </div>
                                            </div>
                                            <div className="mt-4 mb-4">
                                                <label className="text-gray-700">
                                                    Images
                                                </label>
                                            </div>

                                            <div className="flex flex-row">
                                                {product?.images?.map((img, key) => (
                                                    <div key={key} className="border-2 flex items-center p-1 rounded relative mr-4">
                                                        <img src={img} width={(80)} />
                                                        <div onClick={() => { handleDeleteImageSimpleItem(key) }} className="w-[20px] h-[20px] rounded-full flex justify-center items-center bg-gray-300 cursor-pointer absolute right-[-10px] top-[-10px]">
                                                            <span>-</span>
                                                        </div>
                                                    </div>
                                                ))}

                                                {product?.images?.length < 5 &&
                                                    <label className="border-2 flex items-center text-gray-400 justify-center p-1 rounded relative mr-4 w-[84px] cursor-pointer">
                                                        +
                                                        <input multiple accept="image/png, image/gif, image/jpeg, image/webp, image/jpg" onChange={(e) => handleUploadImageSimpleItem(e.target.files[0])} type="file" style={{ display: 'none' }} required />
                                                    </label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {!product?.simpleItem &&
                                <div>
                                    <div className="flex justify-between w-full mt-4">
                                        <div className="w-full rounded bg-white shadow p-6">

                                            <h1 className="font-semibold">Attributs</h1>
                                            <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mb-4">Ajouter les attributs tels que les couleurs, tailles de votre produit afin d'accéder aux déclinaisons.</p>

                                            <div className="grid">
                                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">
                                                    <div>
                                                        <label className="text-gray-700">
                                                            Titre
                                                        </label>

                                                        <div>
                                                            <input type="text" value={propertie ? propertie : ''} onChange={(e) => setPropertie(e.target.value)} className="mt-2 rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base" name="old-password" placeholder="Couleur" />
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label className="text-gray-700">
                                                            Attribut(s)
                                                        </label>

                                                        <div className="mt-[.5rem]">
                                                            <CreatableSelect
                                                                isClearable
                                                                className="rounded-2xl"
                                                                isMulti
                                                                value={propertieValue}
                                                                placeholder={"Bleu"}
                                                                onChange={(item) => handleSetPropertieValue(item)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex justify-end">
                                                    <button onClick={handleCreateProperties} className="bg-indigo-500 font-semibold text-center transition duration-150 ease-in-out focus:outline-none border border-transparent hover:bg-indigo-700 rounded p-2 mt-[.42rem] text-sm cursor-pointer text-white">Ajouter vos attributs</button>
                                                </div>
                                            </div>

                                            <table className="min-w-full bg-white mt-10 w-[10%]">
                                                <thead>
                                                    <tr className="w-full h-16 border-gray-300 border-b py-8">
                                                        <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Titre</th>
                                                        <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Attribut(s)</th>
                                                        <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {product?.properties &&
                                                        <>
                                                            {Object.keys(product?.properties).map((it, key) => (
                                                                <tr key={key} className="h-24 border-gray-300 border-b">
                                                                    <td className="text-left whitespace-no-wrap mr-6 text-sm text-gray-800 tracking-normal leading-4">
                                                                        {it}
                                                                    </td>
                                                                    <td className="text-left flex flex-wrap text-sm ml-6 py-3 text-gray-800 tracking-normal leading-4 items-center">
                                                                        {product?.properties[it].map((item, key) => (
                                                                            <span key={key} style={{ backgroundColor: "#e5e7eb", padding: 10, marginRight: 5, marginTop: 4, marginBottom: 4 }}>
                                                                                {item}
                                                                            </span>
                                                                        ))}
                                                                    </td>
                                                                    <td className="text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                                        <i onClick={() => handleRemovePropertie(it)} style={{ fontSize: 17 }} className="fa fa-trash cursor-pointer text-[#9ca3af] hover:text-[black]"></i>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    }
                                                </tbody>
                                            </table>

                                            <div className="flex flex-row text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-8">
                                                <i className="fa fa-info-circle mt-1" aria-hidden="true"></i>
                                                <p className="ml-[.25rem]">Pour ajouter un ou des attribut(s) supplémentaires à un élément déjà créé, il vous suffit de réécrire son titre dans la zone de texte et d'y ajouter ses nouvelles valeurs.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {!product?.simpleItem &&
                                <div className={`flex justify-between w-full mt-4  ${product?.properties && Object.keys(product?.properties).length === 0 ? 'blur-[2px]' : 'blur-none'} `}>
                                    <div className="w-full rounded bg-white shadow p-6">
                                        <div className="flex justify-between">
                                            <h1 className="font-semibold mb-8">Déclinaisons</h1>

                                            <div className={`relative top-[-10px] ${Object.keys(declinaison).length === 0 && "hidden"}`}>
                                                <button onClick={() => { setModalVariantShow(true); document.body.style.overflow = 'hidden' }} className="bg-indigo-500 font-semibold text-center transition duration-150 ease-in-out focus:outline-none border border-transparent hover:bg-indigo-700 rounded p-2 text-sm cursor-pointer text-white">Ajouter une déclinaison</button>
                                            </div>
                                        </div>

                                        {product?.properties &&
                                            <>
                                                {Object.keys(product?.properties).length !== 0 &&
                                                    <>
                                                        <div className="hidden">
                                                            <div className="grid grid-cols-2 gap-4">
                                                                {Object.keys(product?.properties).map((it, idx) => (
                                                                    <div key={idx}>
                                                                        <span className="text-[14px]">{it.charAt(0).toUpperCase() + it.slice(1)}</span><span className="relative text-red-500">*</span>
                                                                        <div className="mt-2">
                                                                            <Select
                                                                                ref={ref => (divRef.current[idx] = ref)}
                                                                                options={options(product?.properties[it], it)}
                                                                                placeholder={"Selectionnez vos " + it}
                                                                                onChange={(item) => handleSetDeclinaison(item)}
                                                                                className="z-[999]"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>

                                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-6">

                                                                <div className="flex flex-col">
                                                                    <label className="text-gray-700">
                                                                        <span>Prix</span><span className="relative text-red-500">*</span>
                                                                    </label>
                                                                    <div className="placeholder3" data-placeholder="€">
                                                                        <input min={0} type="number" onChange={(e) => setPriceProps(e.target.value)} value={priceProps || ''} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-3" name="old-password" placeholder="" />
                                                                    </div>
                                                                </div>

                                                                <div className="flex flex-col">
                                                                    <label className="text-gray-700">
                                                                        Prix avec remise
                                                                    </label>
                                                                    <div className="placeholder3" data-placeholder="€">
                                                                        <input min={0} type="number" onChange={(e) => setRebatedPriceProps(e.target.value)} value={rebatedPriceProps || ''} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-3" name="old-password" placeholder="" />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="mt-6 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                                                                    <div>
                                                                        <span>Stock</span><span className="relative text-red-500">*</span>
                                                                        <input min={0} type="number" onChange={(e) => setQuantityProps(e.target.value)} value={quantityProps || ''} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-3" name="old-password" placeholder="" />
                                                                    </div>

                                                                    <div className="flex flex-col">
                                                                        <label className="text-gray-700">
                                                                            Poids <span className="relative left-[-3px] text-red-500">*</span>
                                                                        </label>

                                                                        <div className="flex mt-3 relative">
                                                                            <div className="relative w-full">
                                                                                <input min={0} type="number" onChange={(e) => setWeightProps(e.target.value)} value={weightProps || ''} className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-white rounded-l-lg border-r-gray-100 border-l-1 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" />
                                                                            </div>
                                                                            <div onClick={() => setDropdownUnitWeightVariant(!dropdownUnitWeightVariant)} data-dropdown-toggle="dropdown" className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 dark:border-gray-700 dark:text-white rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 cursor-pointer" >{unitWeightVariant} <svg aria-hidden="true" className="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></div>
                                                                            {dropdownUnitWeightVariant &&
                                                                                <div id="dropdown" className={`z-10 cursor-pointer absolute right-0 top-12 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}>
                                                                                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
                                                                                        <li>
                                                                                            <a onClick={() => handleSetUnitWeightVariant('Milligramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Milligramme</a>
                                                                                        </li>
                                                                                        <li>
                                                                                            <a onClick={() => handleSetUnitWeightVariant('Gramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">Gramme</a>
                                                                                        </li>
                                                                                        <li>
                                                                                            <a onClick={() => handleSetUnitWeightVariant('Kilogramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Kilogramme</a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="flex flex-col mt-6">
                                                                        <span>Poids net</span>
                                                                        <div className="flex mt-2 relative">
                                                                            <div className="relative w-full">
                                                                                <input min={0} onChange={(e) => setNetWeightProps(e.target.value)} value={netWeightProps || ''} type="number" className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-white rounded-l-lg border-r-gray-100 border-l-1 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" />
                                                                            </div>
                                                                            <div onClick={() => setDropdownUnitWeightNetVariant(!dropdownUnitWeightNetVariant)} data-dropdown-toggle="dropdown" className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 dark:border-gray-700 dark:text-white rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 cursor-pointer" >{unitWeightNetVariant} <svg aria-hidden="true" className="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></div>

                                                                            {dropdownUnitWeightNetVariant &&
                                                                                <div id="dropdown" className={`z-10 cursor-pointer absolute right-0 top-12 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}>
                                                                                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
                                                                                        <li>
                                                                                            <a onClick={() => handleSetUnitWeightNetVariant('Milligramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Milligramme</a>
                                                                                        </li>
                                                                                        <li>
                                                                                            <a onClick={() => handleSetUnitWeightNetVariant('Gramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">Gramme</a>
                                                                                        </li>
                                                                                        <li>
                                                                                            <a onClick={() => handleSetUnitWeightNetVariant('Kilogramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Kilogramme</a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="flex flex-col mt-6">
                                                                        <span>Volume</span>
                                                                        <div className="flex mt-2 relative">
                                                                            <div className="relative w-full">
                                                                                <input min={0} type="number" onChange={(e) => setVolumeProps(e.target.value)} value={volumeProps || ''} className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-white rounded-l-lg border-r-gray-100 border-l-1 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" />
                                                                            </div>
                                                                            <div onClick={() => setDropdownUnitVolumeVariant(!dropdownUnitVolumeVariant)} data-dropdown-toggle="dropdown" className="flex-shrink-0 z-0 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 dark:border-gray-700 dark:text-white rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 cursor-pointer" >{unitVolumeVariant} <svg aria-hidden="true" className="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></div>
                                                                            {dropdownUnitVolumeVariant &&
                                                                                <div id="dropdown" className={`z-10 cursor-pointer absolute right-0 top-12 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}>
                                                                                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
                                                                                        <li>
                                                                                            <a onClick={() => handleSetUnitVolumeVariant('Millilitre')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Millilitre</a>
                                                                                        </li>
                                                                                        <li>
                                                                                            <a onClick={() => handleSetUnitVolumeVariant('Centilitre')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">Centilitre</a>
                                                                                        </li>
                                                                                        <li>
                                                                                            <a onClick={() => handleSetUnitVolumeVariant('Litre')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Litre</a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="mt-6">
                                                                <span>SKU</span>
                                                                <input type="text" onChange={(e) => setSkuProps(e.target.value)} value={skuProps || ''} placeholder="TSH-000-S" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-3" name="sku" />
                                                            </div>

                                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-6">
                                                                <div style={{ width: '100%' }}>
                                                                    <span>Images du produit (5 maximum)</span>
                                                                    <div className="flex items-center justify-center w-full">
                                                                        <label className="flex flex-col w-full h-12 border-2 mt-[.8rem] border-dashed rounded hover:bg-gray-100 hover:border-gray-300">
                                                                            <div className="flex flex-row items-center justify-center mt-2 cursor-pointer">
                                                                                <div className="flex flex-row">

                                                                                    <div className="flex flex-row">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                            className="w-6 h-6 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                                                                                            fill="currentColor">
                                                                                            <path fillRule="evenodd"
                                                                                                d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                                                                                clipRule="evenodd" />
                                                                                        </svg>
                                                                                        <p className="text-gray-400 group-hover:text-gray-600 ml-1 mt-[.2rem]">Choisir vos photos</p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <input id="uploadPictureVariant" type="file" onChange={(e) => uploadDeclinaisonPictures(e.target.files[0])} accept="image/png, image/gif, image/jpeg, image/webp, image/jpg" className="opacity-0" />
                                                                        </label>
                                                                    </div>

                                                                    <div className="flex flex-row">
                                                                        {imageDeclinaison.map((previewImg, idx) => (
                                                                            <div key={idx} className="flex flex-row justify-center">
                                                                                <span className="border-2 flex items-center p-4 rounded relative mt-2 mr-2">
                                                                                    <img style={{ width: 40 }} src={previewImg} />
                                                                                    <div onClick={() => handleDeleteDeclinaisonImage(idx)} className="w-[20px] h-[20px] bg-red rounded-full flex justify-center items-center bg-gray-300 cursor-pointer absolute right-[-10px] top-[-10px]">
                                                                                        <span>-</span>
                                                                                    </div>
                                                                                </span>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        {Object.keys(declinaison).length === 0 &&
                                                            <div className="w-full">
                                                                <div className="flex justify-center ml-5 mb-4">
                                                                    <img src="../../no_data.png" width={100} />
                                                                </div>
                                                                <div className="flex justify-center">
                                                                    <span className="text-gray-500 text-center mb-4">Aucune déclinaison n'a été ajoutée pour le moment.</span>

                                                                </div>
                                                                <div className="flex justify-center" >
                                                                    <button onClick={() => { setModalVariantShow(true); document.body.style.overflow = 'hidden' }} className="bg-indigo-500 font-semibold text-center transition duration-150 ease-in-out focus:outline-none border border-transparent hover:bg-indigo-700 rounded p-2 text-sm cursor-pointer text-white">Ajouter une déclinaison</button>
                                                                </div>
                                                            </div>
                                                        }


                                                        <div className="mt-6 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">

                                                            {Object.keys(declinaison).length !== 0 &&
                                                                <table className="min-w-full bg-white mt-10">
                                                                    <thead>
                                                                        <tr className="w-full h-16 border-gray-300 border-b py-8">
                                                                            <td className="text-gray-600 pr-3 text-left text-sm tracking-normal leading-4 font-semibold">Défaut</td>

                                                                            <td className="text-gray-600 pr-10 text-left text-sm tracking-normal leading-4 font-semibold">Déclinaison</td>
                                                                            <td className="text-gray-600 pr-10 text-left text-sm tracking-normal leading-4 font-semibold">Prix</td>
                                                                            <td className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Poids</td>
                                                                            {/* <td className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 whitespace-nowrap font-semibold">Poids net</td> */}
                                                                            <td className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Stock</td>
                                                                            <td className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Images</td>
                                                                            <td className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Miniature</td>
                                                                            <td className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Actions</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <>
                                                                            {Object.keys(declinaison).slice(0, declinaisonsToShow).map((key, index) => (
                                                                                <tr key={key} className="h-24 border-gray-300 border-b">
                                                                                    <td className="text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                                                        <input type="radio" id="huey" onChange={() => handleCheckedDefaultVariant(key)} name="drone" value="huey" defaultChecked={product?.defaultVariantId ? product?.Variants?.indexOf(product.Variants?.find((item) => item?._id === product?.defaultVariantId)) === index : index === 0} />
                                                                                    </td>
                                                                                    <td className="text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                                                        <div className="capitalize mr-2">
                                                                                            {Object.keys(declinaison[key]?.properties).map((it, idx) => (
                                                                                                <div key={idx}>{it}: {declinaison[key]?.properties[it]}</div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="text-left whitespace-nowrap text-sm text-gray-800 tracking-normal leading-4">
                                                                                        {declinaison[key]?.price ? declinaison[key]?.price.toFixed(2) + " €" : '/'}
                                                                                    </td>
                                                                                    <td className="text-left whitespace-no-wrap  text-sm text-gray-800 tracking-normal leading-4">
                                                                                        {declinaison[key]?.weight}g
                                                                                    </td>
                                                                                    {/* <td className="text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                                                    {declinaison[key]?.netWeight ? declinaison[key]?.netWeight + "g" : '/'}
                                                                                </td> */}

                                                                                    <td className="text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                                                        {declinaison[key]?.quantity}
                                                                                    </td>
                                                                                    <td className="text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">

                                                                                        {declinaison[key]?.images &&
                                                                                            <div className="flex flex-row">

                                                                                                {Object.keys(declinaison[key]?.images).map((itKeys, idx) => (
                                                                                                    <div key={idx}>
                                                                                                        {itKeys !== "thumbnailId" &&
                                                                                                            <>
                                                                                                                {itKeys !== "thumbnail" &&
                                                                                                                    <div key={idx} className="flex flex-row justify-center">
                                                                                                                        <div className="border-2 flex items-center p-1 rounded h-[70px] relative mr-2">
                                                                                                                            {declinaison[key].images[itKeys].split("/")[0] === "Variants"
                                                                                                                                ? <img style={{ width: 50 }} src={IMG_LINK + declinaison[key].images[itKeys]} />
                                                                                                                                : <img style={{ width: 50 }} src={declinaison[key].images[itKeys]} />
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </>
                                                                                                        }
                                                                                                    </div>
                                                                                                ))}

                                                                                            </div>
                                                                                        }
                                                                                    </td>
                                                                                    <td className="text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                                                        <div className="flex flex-row">

                                                                                            {declinaison[key]?.images !== undefined &&
                                                                                                (declinaison[key]?.images?.thumbnailId &&
                                                                                                    declinaison[key]?.images?.thumbnailId?.split("/")[0] === "Variants") &&
                                                                                                <span className="border-2 p-1 h-[70px] flex justify-center items-center rounded relative">
                                                                                                    <img style={{ width: 50 }} src={IMG_LINK + declinaison[key]?.images?.thumbnailId} />
                                                                                                </span>
                                                                                            }

                                                                                            {declinaison[key]?.images !== undefined &&
                                                                                                declinaison[key]?.images?.thumbnail &&
                                                                                                <span className="border-2 p-1 h-[70px] flex justify-center items-center rounded relative">
                                                                                                    <img style={{ width: 50 }} src={declinaison[key]?.images?.thumbnail} />
                                                                                                </span>
                                                                                            }

                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="flex flex-col text-sm text-gray-800 mt-4">
                                                                                        <button onClick={() => handleEditDeclinaison(declinaison[key], key)} className="text-[13px] mb-1 bg-indigo-500 font-semibold text-center transition duration-150 ease-in-out focus:outline-none border border-transparent hover:bg-indigo-700 rounded p-1 text-sm cursor-pointer text-white"><i className="fa fa-edit" /> Modifier</button>
                                                                                        <button onClick={() => handleRemoveDeclinaison(key)} className="text-[13px] bg-red-500 font-semibold text-center transition duration-150 ease-in-out whitespace-nowrap focus:outline-none border border-transparent hover:bg-red-700 rounded p-1 text-sm cursor-pointer text-white"><i className="fa fa-trash" /> Supprimer</button>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </>



                                                                    </tbody>
                                                                </table>
                                                            }


                                                            {declinaisonsToShow < Object.keys(declinaison).length && (
                                                                <div className="flex w-full mt-3">
                                                                    <button className="text-[13px] flex justify-center w-full opacity-[.75] bg-gray-400 font-semibold text-center transition duration-150 ease-in-out whitespace-nowrap focus:outline-none border border-transparent hover:bg-gray-500 rounded p-1 text-sm cursor-pointer text-white" onClick={() => setDeclinaisonsToShow(prev => prev + 5)}><i className="fa fa-plus mt-1" /> <span className="ml-1">Voir plus</span></button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            }

                            <div className="flex justify-between w-full mt-4">
                                <div className="w-full rounded bg-white shadow p-6">
                                    <h1 className="font-semibold">Caractéristiques</h1>
                                    <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold">Renseignez un titre et une valeur, afin d'ajouter des caractéristiques à votre produit.</p>
                                    <div className="mt-8">
                                        <div className="flex grid grid-cols-2 gap-4">
                                            <div>
                                                <label className="text-gray-700">
                                                    Titre
                                                </label>

                                                <input type="text" value={titleSpec} onChange={(e) => setTitleSpec(e?.target?.value)} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="price" />
                                            </div>
                                            <div>
                                                <label className="text-gray-700">
                                                    Valeur
                                                </label>

                                                <input type="text" value={valueSpec} onChange={(e) => setValueSpec(e?.target?.value)} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="price" />
                                            </div>
                                        </div>
                                        <div className="flex justify-end">
                                            <button onClick={handleAddSpecification} className="bg-indigo-500 w-[125px] font-semibold text-center transition duration-150 ease-in-out focus:outline-none border border-transparent hover:bg-indigo-700 rounded p-2 mt-5 text-sm cursor-pointer text-white">Ajouter</button>
                                        </div>
                                        {product?.specifications && Object.keys(product?.specifications).length !== 0 &&

                                            <table className="min-w-full bg-white mt-10">
                                                <thead>
                                                    <tr className="w-full h-16 border-gray-300 border-b py-8">
                                                        <td className="text-gray-600 pr-14 text-left text-sm tracking-normal leading-4 font-semibold">Titre</td>
                                                        <td className="text-gray-600 pr-10 text-left text-sm tracking-normal leading-4 font-semibold">Valeur</td>
                                                        <td className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Actions</td>
                                                    </tr>
                                                </thead>
                                                {product?.specifications !== undefined &&
                                                    <tbody>
                                                        {Object.keys(product?.specifications).map((it, key) => (
                                                            <tr key={key} className="h-24 border-gray-300 border-b">

                                                                <td className="text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                                    {it}
                                                                </td>

                                                                <td className="text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                                    {product?.specifications[it]}
                                                                </td>

                                                                <td className="text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                                    <span className="cursor-pointer" onClick={() => handleDeleteSpecification(it)}><i className="fa fa-trash text-gray-500" /></span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                }
                                            </table>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-between w-full">
                                <div className="w-full rounded bg-white shadow mt-4 mb-8">
                                    <div className="p-6">
                                        <h1 className="font-semibold mb-2">Aperçu du résultat sur les moteurs de recherche</h1>
                                        <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold">Ajoutez un titre et une description pour voir comment votre produit peut apparaître dans les résultats des moteurs de recherche.</p>
                                    </div>
                                    <div className="w-full border-[.5px] border-gray-200" />
                                    <div className="px-6 mt-4">
                                        <label className="text-gray-700">
                                            Méta-titre de la page
                                        </label>
                                        <input type="text" value={product?.metaTitle || ''} onChange={(e) => setProduct({ ...product, metaTitle: e.target.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="seo_title" />
                                        <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-2">0 sur 70 caractère(s) utilisé(s)</p>

                                        <div className="mt-4 mb-4">
                                            <label className="text-gray-700">
                                                Méta-description
                                            </label>
                                            <textarea rows="4" value={product?.metaDescription || ''} onChange={(e) => setProduct({ ...product, metaDescription: e.target.value })} className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mt-2"></textarea>
                                            <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-2">0 sur 320 caractère(s) utilisé(s)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="">
                                <div className="flex items-center">
                                    <div className="flex-col w-full items-start lg:items-center rounded bg-white shadow">
                                        <div className="p-6">
                                            <h1 className="font-semibold mb-4">Statut</h1>

                                            <Select
                                                isMulti={false}
                                                menuPortalTarget={document.body}
                                                menuPosition="fixed"
                                                styles={{
                                                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                                    menu: (provided) => ({ ...provided, zIndex: 9999 })
                                                }}
                                                value={product?.disabled ? { name: "Inactif" } : { name: "Actif" }}
                                                onChange={(item) => item?.name === "Actif" ? setProduct({ ...product, disabled: false }) : setProduct({ ...product, disabled: true })}
                                                options={status}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.name}
                                                placeholder={"Actif"}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full rounded bg-white shadow p-6 mt-4">
                                    <h1 className="font-semibold mb-4">Déclinaisons</h1>

                                    <div className="flex flex-wrap w-full whitespace-nowrap">
                                        <span className="mr-4 mb-2">Votre produit comporte t-il des variantes ? </span>

                                        <div>
                                            <Switch
                                                onChange={handleChangeSwitch}
                                                checked={typeof product?.simpleItem === "boolean" && product?.simpleItem ? false : true}
                                                className="react-switch"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-3">
                                        <i className="fa fa-info-circle mt-1" aria-hidden="true"></i>
                                        <p className="ml-[.25rem]">Si votre produit comporte des déclinaisons (couleurs, tailles ...) veuillez activer la fonctionnalité.</p>
                                    </div>
                                </div>

                                <div className="flex items-center mt-4">
                                    <div className="flex-col w-full items-start lg:items-center rounded bg-white shadow">
                                        <div className="p-6">
                                            <h1 className="font-semibold mb-4">Taxes</h1>

                                            <Select
                                                isMulti={false}
                                                menuPortalTarget={document.body}
                                                menuPosition="fixed"
                                                styles={{
                                                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                                    menu: (provided) => ({ ...provided, zIndex: 9999 })
                                                }}
                                                value={allTaxes.find((item) => item._id == product?.taxe)}
                                                onChange={(item) => handleSelectTaxe(item)}
                                                options={allTaxes}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.name}
                                                placeholder={"Choisir une taxe"}
                                            />

                                            <div className="flex flex-row text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-4">
                                                <i className="fa fa-info-circle mt-1" aria-hidden="true"></i>
                                                <p className="ml-[.25rem]">Veuillez choisir une taxe afin de créer votre produit.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between mt-4">
                                    <div className="flex-col w-full items-start lg:items-center rounded bg-white shadow">
                                        <div className="p-6">
                                            <h1 className="font-semibold mb-4">Fournisseurs</h1>
                                            <Select
                                                isMulti={false}
                                                menuPortalTarget={document.body}
                                                menuPosition="fixed"
                                                styles={{
                                                    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                                    menu: (provided) => ({ ...provided, zIndex: 9999 })
                                                }}
                                                value={product?.Brand}
                                                onChange={(item) => handleSelectBrandForProduct(item)}
                                                options={allBrands}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.name}
                                                placeholder={"Choisir un fournisseur"}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between w-full mt-4">
                                    <div className="flex-col w-full items-start lg:items-center rounded bg-white shadow">
                                        <div className="p-6">
                                            <h1 className="font-semibold mb-4">Collections</h1>

                                            {chooseCollections &&
                                                <Select
                                                    isMulti={true}
                                                    menuPortalTarget={document.body}
                                                    menuPosition="fixed"
                                                    styles={{
                                                        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                                        menu: (provided) => ({ ...provided, zIndex: 9999 })
                                                    }}
                                                    value={chooseCollections}
                                                    onChange={(item) => handleSelectCollectionsForProduct(item)}
                                                    options={allCollections}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.name}
                                                    placeholder={"Choisir une collection"}
                                                />
                                            }

                                            {!chooseCollections &&
                                                <Select
                                                    isMulti={true}
                                                    menuPortalTarget={document.body}
                                                    menuPosition="fixed"
                                                    styles={{
                                                        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                                        menu: (provided) => ({ ...provided, zIndex: 9999 })
                                                    }}
                                                    value={chooseCollections}
                                                    onChange={(item) => handleSelectCollectionsForProduct(item)}
                                                    options={allCollections}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.name}
                                                    placeholder={"Choisir une collection"}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between w-full mt-4">
                                    <div className="w-full rounded bg-white shadow p-6">
                                        <h1 className="font-semibold mb-4">Catégorie parent <span className="relative left-[-1px] text-red-500">*</span></h1>

                                        <div className="w-full rounded border p-4">
                                            {renderCategories(allCategories)}
                                        </div>

                                        <div className="flex flex-row text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-4">
                                            <i className="fa fa-info-circle mt-1" aria-hidden="true"></i>
                                            <p className="ml-[.25rem]">Veuillez choisir la catégorie ou sous-catégorie dans laquelle vous souhaitez afficher votre produit.</p>
                                        </div>
                                    </div>
                                </div>

                                {product?.simpleItem &&
                                    <div className="flex items-center justify-between w-full mt-4">
                                        <div className="flex-col w-full items-start lg:items-center rounded bg-white shadow">
                                            <div className="p-6">
                                                <h1 className="font-semibold mb-4">Image à la une</h1>

                                                <div className="w-full rounded border-dashed hover:border-indigo-300 border-2 border-gray-300 p-8 text-center">
                                                    <label className="btn btn-default bg-indigo-700 text-[white] px-5 py-2 mb-2 rounded cursor-pointer">
                                                        {product?.Variants && product?.Variants[0]?.images?.thumbnailId ? "Modifier l'image à la une" : "Ajouter une image à la une"}
                                                        <input multiple onChange={(e) => handleThumbnailUpload(e.target.files[0])} accept="image/*" type="file" style={{ display: 'none' }} required />
                                                    </label>

                                                    <p className="text-[.9rem] opacity-[.5] mt-4">PNG & JPG ACCEPTÉS</p>

                                                    {product?.thumbnail &&
                                                        <div className="flex flex-row justify-center">
                                                            <span className="border-2 flex items-center p-1 rounded relative mt-6">
                                                                <img style={{ width: 80 }} src={product?.thumbnail} />
                                                                <div className="w-[20px] h-[20px] bg-red rounded-full flex justify-center items-center bg-gray-300 cursor-pointer absolute right-[-10px] top-[-10px]">
                                                                    <span onClick={() => setProduct({ ...product, thumbnail: null })}>-</span>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="flex flex-row text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-4">
                                                    <i className="fa fa-info-circle mt-1" aria-hidden="true"></i>
                                                    <p className="ml-[.25rem]">Image par défaut de la fiche produit.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {modalVariantShow &&
                <div className="justify-center z-[999] items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">

                    <div className="m-auto">
                        <div onClick={() => setModalVariantShow(false)} className="myModal"></div>
                        <div className="center-absolutely2 bg-[white] h-[79%] overflow-hidden overflow-x-hidden overflow-y-auto rounded w-11/12 md:w-5/12 lg:w-5/12 xl:w-5/12">

                            <div className="flex row justify-between">
                                <div className="px-6 pb-2 mt-4">
                                    <h1 className="text-xl font-semibold mb-1">Ajouter une déclinaison</h1>
                                </div>

                                <div className="px-6 mt-4">
                                    <span data-state="closed" className="cursor-pointer" onClick={() => handleCancelModalVariant()}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex text-grey-50">
                                            <path d="M15 5L5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M5 5L15 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center p-6 border-t border-b border-solid border-slate-200 rounded-b">
                                <div className="flex w-full flex-col">
                                    <div className="grid grid-cols-2 gap-4">
                                        {Object.keys(product?.properties).map((it, idx) => (
                                            <div key={idx}>
                                                <span className="text-[14px]">{it.charAt(0).toUpperCase() + it.slice(1)}</span><span className="relative text-red-500">*</span>
                                                <div className="mt-2">
                                                    <Select
                                                        ref={ref => (divRef.current[idx] = ref)}
                                                        value={{ label: tempDeclinaisonValue[it], value: tempDeclinaisonValue[it], key: tempDeclinaisonValue[it] }}
                                                        options={options(product?.properties[it], it)}
                                                        placeholder={"Selectionnez vos " + it}
                                                        onChange={(item) => handleSetDeclinaison(item)}
                                                        className="z-[999]"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-4">
                                        <div className="flex flex-col">
                                            <label className="text-gray-700">
                                                <span className="text-sm">Prix</span><span className="relative text-red-500">*</span>
                                            </label>
                                            <div className="placeholder3 flex" data-placeholder="€">
                                                <input min={0} type="number" onChange={(e) => setPriceProps(e.target.value)} value={priceProps || ''} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-3" name="old-password" placeholder="" />
                                            </div>
                                        </div>

                                        <div className="flex flex-col">
                                            <label className="text-gray-700">
                                                <span className="text-sm">Prix avec remise</span>
                                            </label>
                                            <div className="placeholder3" data-placeholder="€">
                                                <input min={0} type="number" onChange={(e) => setRebatedPriceProps(e.target.value)} value={rebatedPriceProps || ''} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-3" name="old-password" placeholder="" />
                                            </div>
                                        </div>

                                        <div>
                                            <span className="text-sm">Stock</span><span className="relative text-red-500">*</span>
                                            <input min={0} type="number" onChange={(e) => setQuantityProps(e.target.value)} value={quantityProps || ''} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-3" name="old-password" placeholder="" />
                                        </div>

                                        <div className="flex flex-col mt-1">
                                            <label className="text-gray-700 text-sm">
                                                Poids <span className="relative left-[-3px] text-red-500">*</span>
                                            </label>

                                            <div className="flex relative mt-3">
                                                <div className="relative w-full">
                                                    <input min={0} type="number" onChange={(e) => setWeightProps(e.target.value)} value={weightProps || ''} className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-white rounded-l-lg border-r-gray-100 border-l-1 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" />
                                                </div>
                                                <div onClick={() => setDropdownUnitWeightVariant(!dropdownUnitWeightVariant)} data-dropdown-toggle="dropdown" className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 dark:border-gray-700 dark:text-white rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 cursor-pointer" >{unitWeightVariant} <svg aria-hidden="true" className="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></div>
                                                {dropdownUnitWeightVariant &&
                                                    <div id="dropdown" className={`z-10 cursor-pointer absolute right-0 top-12 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}>
                                                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
                                                            <li>
                                                                <a onClick={() => handleSetUnitWeightVariant('Milligramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Milligramme</a>
                                                            </li>
                                                            <li>
                                                                <a onClick={() => handleSetUnitWeightVariant('Gramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">Gramme</a>
                                                            </li>
                                                            <li>
                                                                <a onClick={() => handleSetUnitWeightVariant('Kilogramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Kilogramme</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                    <div className="mt-4 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">

                                            <div className="flex flex-col">
                                                <span>Poids net</span>
                                                <div className="flex mt-3 relative">
                                                    <div className="relative w-full">
                                                        <input min={0} onChange={(e) => setNetWeightProps(e.target.value)} value={netWeightProps || ''} type="number" className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-white rounded-l-lg border-r-gray-100 border-l-1 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" />
                                                    </div>
                                                    <div onClick={() => setDropdownUnitWeightNetVariant(!dropdownUnitWeightNetVariant)} data-dropdown-toggle="dropdown" className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 dark:border-gray-700 dark:text-white rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 cursor-pointer" >{unitWeightNetVariant} <svg aria-hidden="true" className="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></div>

                                                    {dropdownUnitWeightNetVariant &&
                                                        <div id="dropdown" className={`z-10 cursor-pointer absolute right-0 top-12 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}>
                                                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
                                                                <li>
                                                                    <a onClick={() => handleSetUnitWeightNetVariant('Milligramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Milligramme</a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => handleSetUnitWeightNetVariant('Gramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">Gramme</a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => handleSetUnitWeightNetVariant('Kilogramme')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Kilogramme</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="flex flex-col">
                                                <span className="text-sm">Volume</span>
                                                <div className="flex mt-2 relative">
                                                    <div className="relative w-full">
                                                        <input min={0} type="number" onChange={(e) => setVolumeProps(e.target.value)} value={volumeProps || ''} className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-white rounded-l-lg border-r-gray-100 border-l-1 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" />
                                                    </div>
                                                    <div onClick={() => setDropdownUnitVolumeVariant(!dropdownUnitVolumeVariant)} data-dropdown-toggle="dropdown" className="flex-shrink-0 z-0 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 dark:border-gray-700 dark:text-white rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 cursor-pointer" >{unitVolumeVariant} <svg aria-hidden="true" className="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></div>
                                                    {dropdownUnitVolumeVariant &&
                                                        <div id="dropdown" className={`z-10 cursor-pointer absolute right-0 top-12 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}>
                                                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
                                                                <li>
                                                                    <a onClick={() => handleSetUnitVolumeVariant('Millilitre')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Millilitre</a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => handleSetUnitVolumeVariant('Centilitre')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">Centilitre</a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => handleSetUnitVolumeVariant('Litre')} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white  cursor-pointer">Litre</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <span className="text-sm">SKU</span>
                                        <input type="text" onChange={(e) => setSkuProps(e.target.value)} value={skuProps || ''} placeholder="TSH-000-S" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-3" name="sku" />
                                    </div>

                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                                        <div style={{ width: '100%' }} className="col-span-2">
                                            <span className="text-sm">Images du produit (5 max.)</span>

                                            <div className="flex flex-row mt-2">

                                                {imageDeclinaison.map((previewImg, idx) => (
                                                    <div key={idx} className="flex flex-row justify-center">
                                                        <span className="border-2 flex items-center p-4 rounded relative mt-2 mr-2">
                                                            <img crossorigin="anonymous" style={{ width: 40 }} src={previewImg} />
                                                            <div onClick={() => handleDeleteDeclinaisonImage(idx)} className="w-[20px] h-[20px] bg-red rounded-full flex justify-center items-center bg-gray-300 cursor-pointer absolute right-[-10px] top-[-10px]">
                                                                <span>-</span>
                                                            </div>
                                                        </span>
                                                    </div>
                                                ))}


                                                {imageDeclinaison?.length < 5 &&
                                                    <label className="border-dashed border-2 flex items-center hover:border-indigo-300  text-gray-400 justify-center p-4 rounded relative mr-4 w-[80px] h-18 mt-2 cursor-pointer">
                                                        +
                                                        <input multiple accept="image/png, image/gif, image/jpeg, image/webp, image/jpg" type="file" onChange={(e) => uploadDeclinaisonPictures(e.target.files[0])} style={{ display: 'none' }} required />
                                                    </label>
                                                }
                                            </div>
                                            <div className="mt-6">
                                                <input id="addThumbnail" checked={showThumbnailVariant} type="checkbox" onChange={() => setShowThumbnailVariant(!showThumbnailVariant)} value="" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                <label htmlFor="addThumbnail" className="text-gray-700 ml-2 text-sm">Paramétrer une image à la une</label>
                                            </div>
                                        </div>

                                        {showThumbnailVariant &&
                                            <div className={`flex flex-col`}>
                                                <span className="text-sm">Image à la une</span>

                                                <div className="w-full rounded border-dashed cursor-pointer hover:border-indigo-300 border-2 border-gray-300 p-4 text-center mt-5">
                                                    {imageThumbnail?.length === 0 &&
                                                        <label className="px-5 py-2 mb-2 rounded cursor-pointer text-gray-400">
                                                            +
                                                            <input multiple onChange={(e) => handleThumbnailUploadVariant(e.target.files[0])} accept="image/*" type="file" style={{ display: 'none' }} required />
                                                        </label>
                                                    }

                                                    {imageThumbnail?.length !== 0 &&
                                                        <div className="flex flex-row justify-center">
                                                            <span className="border-2 flex items-center p-4 rounded relative">
                                                                <img style={{ width: 50 }} src={imageThumbnail} />

                                                                <div className="w-[20px] bg-red rounded-full flex justify-center items-center bg-gray-300 cursor-pointer absolute right-[-10px] top-[-10px]">
                                                                    <span onClick={() => setImageThumbnail([])}>-</span>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center justify-end px-6 py-4 border-t  border-solid border-slate-200 rounded-b">
                                <div className="mt-2">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => handleCancelModalVariant()}
                                    >
                                        Annuler
                                    </button>
                                    <button
                                        onClick={() => handleCreateDeclinaison(editDeclinaison)}
                                        className="bg-indigo-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                    >
                                        {editDeclinaison ? 'Modifier' : 'Ajouter'}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>

    );
};

export default AddProduct;
