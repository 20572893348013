import { apiStripe } from './ApiStripe'

function login({ password, email }) {
    return apiStripe().post('/user/login', { password, email })
}

function register(data) {
    return apiStripe().post('/user/register', data)
}

function loginToBackOffice(id) {
    return apiStripe().get('/user/access/' + id)
}

function logout(token) {
    return apiStripe().post('/user/logout', token)
}

function forgotPass(email) {
    return apiStripe().post('/user/resetPass', email)
}

function getUserLogged() {
    return apiStripe().get('/user')
}

function editUserLogged(data) {
    return apiStripe().patch('/user', data)
}

function changePassword(data) {
    return apiStripe().patch('/user/pass', data)
}

export default {
    login,
    register,
    loginToBackOffice,
    logout,
    forgotPass,
    getUserLogged,
    editUserLogged,
    changePassword
}
