import React from 'react';
import { createRoot } from 'react-dom/client';
import { useContext, useEffect, useCallback } from 'react';
import {
    UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom';


export function useBlocker(blocker, when = true) {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (!when) return;

        const unblock = (navigator).block(
            tx => {
                const autoUnblockingTx = {
                    ...tx,
                    retry() {
                        unblock();
                        tx.retry();
                    },
                };

                blocker(autoUnblockingTx);
            }
        );

        return unblock;
    }, [navigator, blocker, when]);
}

export function usePrompt(when) {
    const blocker = useCallback(
        tx => {
            const element = document.createElement('div');
            element.setAttribute('id', 'prompt-dialog-container');
            element.setAttribute('aria-hidden', 'true');

            const root = createRoot(element);

            const closePrompt = (state) => {
                if (element) {
                    root.unmount()
                }
                if (!state) {
                    document.body.removeChild(element);
                } else {
                    tx.retry();
                }
            };

            document.body.appendChild(element);

            root.render(
                <>
                    <div>
                        <div onClick={() => console.log(false)} className="myModal"></div>
                        <div className="center-absolutely bg-[white] rounded w-4/12 mt-10">

                            <div className="flex row justify-between">
                                <div className="px-6 mt-4">
                                    <h1 className="text-xl font-semibold mb-1">Quitter la page avec des modifications non enregistrées ?</h1>
                                    <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mb-4">Si vous quittez cette page, toutes les modifications non enregistrées seront supprimées.</p>
                                </div>

                                <div className="px-6 mt-4">
                                    <span data-state="closed" className="cursor-pointer" onClick={() => closePrompt(false)}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex text-grey-50">
                                            <path d="M15 5L5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M5 5L15 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-indigo-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => closePrompt(false)}
                                >
                                    Annuler
                                </button>
                                <button
                                    className="bg-[#e74c3c] text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => closePrompt(true)}
                                >
                                    Quitter
                                </button>
                            </div>


                        </div>
                    </div>
                </>
            );
        },
        []
    );

    useBlocker(blocker, when);
}
