import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { addRegion, editRegion, getAllShipper, getRegionById } from "../actions/regions";
import countries from '../utils/countries.json';
import { usePrompt } from '../utils/prompt';
import { NotificationManager } from 'react-notifications';

const AddDelivery = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const id = location?.pathname?.split("/")[3];
    const [dropdown, setDropdown] = useState(false);
    const [countriesRegions, setCountriesRegions] = useState([]);
    const [modalAddCountriesRegions, setModalAddCountriesRegions] = useState();
    const [modalAddShippingMethod, setModalAddShippingMethod] = useState(false);
    const [shippingMethod, setShippingMethod] = useState([]);
    const [shipperMethod, setShipperMethod] = useState({});
    const [allShipper, setAllShipper] = useState([]);
    const [editIdShipper, setEditIdShipper] = useState()
    const status = [{ name: "Actif" }, { name: "Inactif" }];
    const [price, setPrice] = useState();
    const [treshold, setTreshold] = useState();

    const [dataObj, setDataObj] = useState({});
    const [dataChanged, setDataChanged] = useState(false);
    const [errorInput, setErrorInput] = useState([]);
    const [labelNumber, setLabelNumber] = useState();
    const [editTreshold, setEditTreshold] = useState();

    useEffect(() => {
        if (id) {
            getRegionById(id).then((res) => {
                setShippingMethod(res);
                setDataObj(res);
            })
        }

        getAllShipper().then((res) => {
            setAllShipper(res);
        })
    }, [id])

    useEffect(() => {
        compareObjects()
    }, [shippingMethod]);

    const compareObjects = () => {

        const shippingKeys = Object.keys(shippingMethod);

        const isDifferent = shippingKeys.some((key) => {
            const shippingValue = shippingMethod[key];
            const dataObjValue = dataObj[key];

            if (typeof shippingValue !== "object" && typeof dataObjValue !== "object") {
                return shippingValue !== dataObjValue;
            }

            return JSON.stringify(shippingValue) !== JSON.stringify(dataObjValue);
        });

        setDataChanged(isDifferent);
    };

    const handleSetCountriesRegions = (item) => {
        setCountriesRegions(item)
    }

    const handleRemoveCountriesRegions = (index) => {
        let tempsArr = [...shippingMethod?.country];

        tempsArr.splice(index, 1);

        setShippingMethod({ ...shippingMethod, country: tempsArr })
    }

    const handleApplyCountriesRegions = () => {
        let arr = [];

        if (Array.isArray(shippingMethod?.country)) {
            arr = [...shippingMethod?.country];
        }

        countriesRegions.forEach(function (item) {
            if (!arr.some(e => e === item.code)) {
                arr.push(item?.code)
            }
        })

        setShippingMethod({ ...shippingMethod, country: arr })
        setModalAddCountriesRegions(false);
    }

    const handleAddShippingMethod = () => {
        const data = {
            name: shippingMethod?.name,
            country: shippingMethod?.country,
            delivery: shippingMethod?.delivery,
            disabled: shippingMethod?.disabled
        }

        addRegion(data).then((res) => {
            setShippingMethod([]);
            setDataObj([]);
            setDataChanged(false);
        }).catch((err) => {
            Object.keys(err?.response?.data?.error).map((key) => {
                NotificationManager.error(err?.response?.data?.error[key], 'Erreur');
                setErrorInput((state) => [...state, key]);
            })
        })
    }

    const handleRemoveShippingMethod = (index) => {
        setDropdown(false);

        let tempsArr = [...shippingMethod?.delivery];

        if (index >= 0 && index < tempsArr.length) {
            tempsArr.splice(index, 1);

            setShippingMethod({
                ...shippingMethod,
                delivery: tempsArr
            });
        }
    };

    const handleEditShippingMethod = () => {

        const data = {
            name: shippingMethod?.name,
            shipper: shippingMethod?.delivery,
            country: shippingMethod?.country,
            delivery: shippingMethod?.delivery,
            id: id,
            disabled: shippingMethod?.disabled
        }

        editRegion(data).then((res) => {
            setShippingMethod(res);
            setDataObj(res);
            setDataChanged(false);

            NotificationManager.success("Votre zone d'expédition a été éditer avec succès !", 'Succès');
        }).catch((error) => {
            NotificationManager.error(error?.response?.data?.error, 'Erreur');
        })
    }

    const handleApplyShippingMethod = () => {
        if (!shipperMethod?.name || !shipperMethod?.shipperId || !shipperMethod?.defaultPrice) {
            NotificationManager.error("Merci de bien vouloir remplir tous les champs!", 'Erreur');
            return;
        }

        const data = {
            name: shipperMethod.name,
            description: shipperMethod.description,
            shipperId: shipperMethod.shipperId,
            defaultPrice: Number(shipperMethod.defaultPrice),
            defaultLabelNumber: Number(shipperMethod.defaultLabelNumber),
            price: shipperMethod.price,
        };

        let arr = [data];

        if (Array.isArray(shippingMethod?.delivery)) {
            arr = [...shippingMethod.delivery, data];
        }

        setShippingMethod({ ...shippingMethod, delivery: arr });
        setModalAddShippingMethod(false);
        setDropdown(false);
    };

    const handleDropdownForeach = (row) => {
        setDropdown(row);
    }

    const handleShowModalEditShipper = (id) => {
        setShipperMethod(shippingMethod?.delivery[id])
        setModalAddShippingMethod(true);
        setDropdown(null);

        setEditIdShipper(id)
    }

    const handleShowModalAddShipperMethod = () => {
        setModalAddShippingMethod(true);
        setEditIdShipper(null);
        setShipperMethod()
    }

    const handleApplyEditShipperMethod = () => {
        const data = {
            name: shipperMethod?.name,
            shipperId: shipperMethod?.shipperId,
            defaultPrice: Number(shipperMethod?.defaultPrice),
            description: shipperMethod?.description,
            defaultLabelNumber: Number(shipperMethod.defaultLabelNumber),
            price: shipperMethod.price,
        }
    
        // Copie en profondeur de shippingMethod
        let tempArr = JSON.parse(JSON.stringify(shippingMethod));
    
        tempArr.delivery[editIdShipper] = data;
    
        setShippingMethod(tempArr);
        setModalAddShippingMethod(false);
        setDropdown(false);
        // setShipperMethod();
    }

    const handleResetData = () => {
        setShippingMethod(dataObj);
        setErrorInput([]);
    }

    const handleAddTreshold = () => {
        if (!price || !treshold) {
            NotificationManager.error("Merci de bien vouloir remplir tous les champs!", 'Erreur');
            return;
        }

        const newPrice = {
            price: Number(price),
            weight: Number(treshold),
            labelNumber: Number(labelNumber)
        };

        setShipperMethod((prevShipperMethod) => ({
            ...prevShipperMethod,
            price: prevShipperMethod?.price ? [...prevShipperMethod.price, newPrice] : [newPrice],
        }));

        setPrice();
        setTreshold();
        setLabelNumber()
    };

    const handleDeleteTest = (itemToDelete) => {
        setShipperMethod((prevShipperMethod) => ({
            ...prevShipperMethod,
            price: prevShipperMethod.price.filter((item) => item !== itemToDelete),
          }));
    }

    const handleEditTreshold = (itemToEdit, key) => {
        setTreshold(itemToEdit?.weight);
        setPrice(itemToEdit?.price);

    if(itemToEdit?.labelNumber){
        setLabelNumber(itemToEdit?.labelNumber);
    }

        setEditTreshold(key);
    }

    const handleApplyEditTreshold = (key) => {
        const updatedShipperMethod = {...shipperMethod};
      
        // Update the object at the specified index (key) with new threshold and price
        updatedShipperMethod.price[key] = {
          ...updatedShipperMethod[key],
          weight: Number(treshold), // Assuming treshold holds the new weight value
          price: Number(price),
          labelNumber: Number(labelNumber),    // Assuming price holds the new price value
        };

        // Set the state with the updated array
        setShipperMethod(updatedShipperMethod);
      
        // Reset the treshold and price state variables
        setTreshold(0); // Replace 0 with the default value for treshold
        setPrice(0);
        setLabelNumber(0);   // Replace 0 with the default value for price
        setEditTreshold(null)
    };      

    return (
        usePrompt(dataChanged),

        <>
            <div className={`fixed bg-[#080404] top-0 py-[.7rem] px-5 w-full left-0 z-[999] m-0 text-center test ${dataChanged ? "transition ease-in-out delay-250 opacity-1 duration-500 z-[999]" : "opacity-0 delay-250 z-[0] duration-500"}`}>
                <div className="flex justify-between text-[white]">
                    <div className="cursor-pointer" onClick={() => navigate('/')}>
                        <svg fill="currentColor" width="38" height="38" viewBox="0 0 174 174" xmlns="http://www.w3.org/2000/svg">
                            <path fill="white" fillRule="evenodd" clipRule="evenodd" d="M0 9.23318C0 4.13387 4.13275 0 9.23075 0H164.769C169.867 0 174 4.13387 174 9.23318V41.7256C168.389 42.1805 163.181 43.5167 158.377 45.7341C152.334 48.4078 147.394 52.1858 143.559 57.0681C140.421 51.9533 136.12 48.1172 130.658 45.5598C125.312 42.8861 119.152 41.5493 112.178 41.5493C105.786 41.5493 99.9745 42.6537 94.7445 44.8623C89.5144 47.0711 85.1559 50.2678 81.6691 54.4526V42.9386H60.9231V135.708H82.7151V88.4543C82.7151 79.3872 84.8653 72.4706 89.1657 67.7045C93.5822 62.9385 99.5097 60.5555 106.948 60.5555C113.689 60.5555 118.861 62.6479 122.464 66.8327C126.067 70.9013 127.868 77.0041 127.868 85.1414V135.708H149.661V88.4543C149.661 79.5034 151.869 72.6449 156.285 67.8789C160.702 62.9966 166.571 60.5555 173.893 60.5555L174 60.5557V164.767C174 169.866 169.867 174 164.769 174H9.23075C4.13275 174 0 169.866 0 164.767V9.23318ZM112.321 69.9598C106.222 69.9598 101.199 72.2675 97.4993 76.8928L97.4895 76.9051L97.4798 76.9175C93.89 81.527 92.2757 87.9726 92.2757 95.8953V135.709H97.1218V95.8953C97.1218 88.5482 98.631 83.3425 101.29 79.9206C104.013 76.523 107.607 74.8191 112.321 74.8191V69.9598Z"></path>
                        </svg>
                    </div>

                    <div className="mt-2 font-bold text-md">
                        Modifications non enregistrées
                    </div>

                    <div className="flex flex-row">
                        <div onClick={() => handleResetData()} className='border-[1px] border-gray-200 hover:bg-[#eb2f06] hover:border-[#eb2f06] rounded  hover:opacity-[1] cursor-pointer flex flex-row px-4 py-2 mr-2'>
                            <i className="fa fa-times text-gray-200 text-[27px] relative top-[.33rem]" />
                            <span className="text-gray-200 ml-1 mt-[.1rem] font-bold">Annuler</span>
                        </div>
                        <div onClick={() => id ? handleEditShippingMethod() : handleAddShippingMethod()} className='bg-indigo-700 hover:bg-indigo-500 rounded  hover:opacity-[1] cursor-pointer flex flex-row px-4 py-2'>
                            <i className="fa fa-save text-[white] text-[25px]" />
                            <span className="text-[white] ml-2 mt-[.1rem] font-bold">Enregistrer</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-margin">
                <div onClick={() => setDropdown(false)} className={`${dropdown !== false && "mydropdown"}`}></div>

                {modalAddCountriesRegions &&
                    <>
                        <div style={{ zIndex: 999 }}
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
                        >
                            <div className="relative w-1/3 my-6 mx-auto">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-3xl font-semibold">
                                            Ajouter des pays
                                        </h3>
                                    </div>
                                    <div className="relative p-6 flex-auto">
                                        <Select
                                            options={countries}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.name}
                                            isMulti
                                            placeholder={"Pays (obligatoire)"}
                                            onChange={(item) => handleSetCountriesRegions(item)}
                                        />
                                    </div>
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setModalAddCountriesRegions(false)}
                                        >
                                            Annuler
                                        </button>
                                        <button
                                            className="bg-indigo-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={handleApplyCountriesRegions}
                                        >
                                            Enregistrer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                }


                {modalAddShippingMethod &&
                    <>
                        <div style={{ zIndex: 999 }}
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
                        >
                            <div className="relative w-1/3 my-6 mx-auto">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-3xl font-semibold">
                                            Ajouter une méthode d'expédition
                                        </h3>
                                    </div>
                                    <div className="relative p-6 flex-auto max-h-[50rem] overflow-y-auto">
                                        
                                        <div className="grid grid-cols-2 gap-4">
                                            <div>
                                                <label className="text-gray-700">
                                                    Nom
                                                </label>
                                                <input type="text" defaultValue={shipperMethod?.name} onChange={(e) => setShipperMethod({ ...shipperMethod, name: e?.target?.value })} className={`rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2`} name="price" placeholder="Delivery" />
                                            </div>

                                            <div className="">
                                                <label className="text-gray-700">
                                                    Description
                                                </label>
                                                <input type="text" defaultValue={shipperMethod?.description} onChange={(e) => setShipperMethod({ ...shipperMethod, description: e?.target?.value })} className={`rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2`} name="price" placeholder="Description delivery" />
                                            </div>
                                        </div>

                                        <div className="mt-4">
                                            <div>
                                                <label className="text-gray-700">
                                                    Expéditeur
                                                </label>

                                                <div className="mt-[.6rem]">
                                                    <Select
                                                        options={allShipper}
                                                        value={allShipper?.find((item) => (item?._id === shipperMethod?.shipperId))}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.name}
                                                        isMulti={false}
                                                        placeholder={"Méthode d'expédition"}
                                                        onChange={(item) => setShipperMethod({ ...shipperMethod, shipperId: item?._id, needLabel: item?.needLabel })}
                                                    />
                                                </div>
                                            </div>
                                            <hr  className="mt-10 mb-10"/>
                                            <div className="grid grid-cols-2 gap-4 mt-4">
                                                <div>
                                                    <label className="text-gray-700">
                                                        Prix par défaut
                                                    </label>
                                                    <input type="number" defaultValue={shipperMethod?.defaultPrice} onChange={(e) => setShipperMethod({ ...shipperMethod, defaultPrice: e?.target?.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="price" placeholder="24" />
                                                </div>
                                                {shipperMethod?.needLabel &&
                                                    <div>
                                                        <label className="text-gray-700">
                                                            Nb étiquette par défaut
                                                        </label>
                                                        <input type="number" defaultValue={shipperMethod?.defaultLabelNumber} onChange={(e) => setShipperMethod({ ...shipperMethod, defaultLabelNumber: e?.target?.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="price" placeholder="24" />
                                                    </div>
                                                }
                                            </div>
                                            <div className="flex flex-row text-[#6d7175] text-[.9rem] font-semibold mt-2">
                                        <i className="fa fa-info-circle mt-1 opacity-[.5] mr-2" aria-hidden="true"></i>
                                        <p className="text-[.9rem] opacity-[.5] font-semibold ml-1">Si nous ne spécifions aucune condition ci-dessous, les informations par défaut sont utilisées pour ce.s champ.s.</p>
                                    </div>
                                        </div>


                                        <hr className="mt-4 mb-4" />

                                        <div className="grid grid-cols-3 gap-4">
                                            <div>
                                                <label className="text-gray-700">
                                                    Prix (€)
                                                </label>
                                                <input type="number" value={price || ''} onChange={(e) => setPrice(e?.target?.value)} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="price" placeholder="24" />
                                            </div>
                                            <div>
                                                <label className="text-gray-700">
                                                    Seuil (grammes)
                                                </label>
                                                <input type="number" value={treshold || ''} onChange={(e) => setTreshold(e?.target?.value)} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="price" placeholder="24" />
                                            </div>

                                            {shipperMethod?.needLabel &&
                                                <div>
                                                    <label className="text-gray-700">
                                                        Nb etiquette
                                                    </label>
                                                    <input type="number" value={labelNumber || ''} onChange={(e) => setLabelNumber(e?.target?.value)} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="price" placeholder="24" />
                                                </div>
                                            }
                                            <div>
                                                <button onClick={() => {editTreshold ? handleApplyEditTreshold(editTreshold) : handleAddTreshold()}} className="mt-2 text-white cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 font-bold focus:shadow-outline-gray bg-indigo-500 transition duration-150 ease-in-out p-4 h-8 rounded flex text-[14px] items-center justify-center">
                                                    {editTreshold ? "Modifier" : "Ajouter"}
                                                </button>
                                            </div>
                                        </div>


                                        <table className={`min-w-full bg-white mt-4 ${shipperMethod?.price === undefined && "hidden"}`}>
                                            <thead>
                                                <tr className="w-full h-16 border-gray-300 border-b py-8">
                                                    <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Prix (€)</th>
                                                    <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Seuil (grammes)</th>
                                                    {shipperMethod?.needLabel &&
                                                        <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Nb étiquette</th>
                                                    }
                                                    <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {shipperMethod?.price &&
                                                    <>
                                                        {Object.keys(shipperMethod?.price).map((key, idx) => (
                                                            <tr key={idx} className="h-24 border-gray-300 border-b">
                                                                <td className="pr-6 whitespace-no-wrap">{shipperMethod?.price[key]?.price} €</td>
                                                                <td className="pr-6 whitespace-no-wrap">{shipperMethod?.price[key]?.weight} grammes</td>
                                                                {shipperMethod?.needLabel &&
                                                                    <td className="pr-6 whitespace-no-wrap">{shipperMethod?.price[key]?.labelNumber} étiquette</td>
                                                                }
                                                                <td className="pr-6 whitespace-no-wrap">
                                                                   <i onClick={() => handleEditTreshold(shipperMethod?.price[key], key)} className="fa fa-edit cursor-pointer" /> 
                                                                   <i onClick={() => handleDeleteTest(shipperMethod?.price[key])} className="fa fa-trash cursor-pointer" /> 
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                }

                                            </tbody>
                                        </table>

                                    </div>

                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => { setModalAddShippingMethod(false); setDropdown(false) }}
                                        >
                                            Annuler
                                        </button>
                                        <button
                                            className="bg-indigo-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => editIdShipper !== null ? handleApplyEditShipperMethod() : handleApplyShippingMethod()}
                                        >
                                            Enregistrer
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                }


                <div className="flex row">
                    <button onClick={() => navigate('/settings/delivery')} className="text-white cursor-pointer border-[1.2px] border-[#babfc3] bg-transparent transition duration-150 ease-in-out w-10 h-10 rounded flex items-center justify-center ">
                        <svg viewBox="0 0 20 20" height={25} width={25} fill='#5c5f62' focusable="false" aria-hidden="true"><path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path></svg>
                    </button>

                    {!id
                        ? <h1 className="text-xl font-semibold mt-1 ml-4">Ajouter une zone d'expédition</h1>

                        : <h1 className="text-xl font-semibold mt-1 ml-4">Éditer une zone d'expédition</h1>
                    }
                </div>

                <div className="grid grid-cols-3 gap-4 mt-10">
                    <div className="col-span-2">

                        <div>
                            <div className="flex justify-between w-full">
                                <div className="w-full rounded bg-white shadow p-6">

                                    {/* TITLE INPUT */}
                                    <div className="relative w-full">
                                        <label className="text-gray-700">
                                            Nom <span className="text-[red]">*</span>
                                        </label>
                                        <input value={shippingMethod?.name || ""} onChange={(e) => setShippingMethod({ ...shippingMethod, name: e?.target?.value })} type="text" className={`rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2 ${!shippingMethod?.name && errorInput.includes("name") && 'border-[1px] border-[red] rounded'} `} placeholder="Nom (obligatoire)" />

                                        <div className="flex flex-row text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-4">
                                            <i className="fa fa-info-circle mt-1" aria-hidden="true"></i>
                                            <p className="ml-[.25rem]">Le nom de la zone est réservé à un usage interne et les clients ne le verront pas.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <div>
                                <div className="flex justify-between w-full">
                                    <div className={`w-full rounded bg-white shadow p-6 ${!shippingMethod?.country && errorInput.includes("country") && 'border-[1px] border-[red] rounded'} `}>

                                        {/* TITLE INPUT */}
                                        <div className="flex justify-between">
                                            <div className="mt-1">
                                                <span className="font-semibold">Pays</span><span className="text-[red] ml-1">*</span>
                                            </div>
                                            <div>
                                                <button onClick={() => setModalAddCountriesRegions(true)} className="bg-gray-200 text-center transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-gray-300 rounded p-2 text-sm cursor-pointer">Ajouter</button>
                                            </div>
                                        </div>

                                        <table className={`min-w-full bg-white mt-8 ${!shippingMethod?.country ? "hidden" : ""}`}>
                                            <thead>
                                                <tr className="w-full h-16 border-gray-300 border-b py-8">
                                                    <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Pays</th>
                                                    <td className="text-gray-600 pr-8 text-left text-sm tracking-normal leading-4 font-semibold">Actions</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shippingMethod?.country && shippingMethod?.country.map((it, key) => (
                                                    <tr key={key} className="h-24 border-gray-300 border-b">
                                                        <td className="pr-6 whitespace-no-wrap">
                                                            {countries.filter((data) => { return data.code == it })[0]?.name}
                                                        </td>
                                                        <td className="pr-8">
                                                            <span className="cursor-pointer" onClick={() => handleRemoveCountriesRegions(key)}><i className="fa fa-trash text-gray-500" /></span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        {!shippingMethod?.country &&
                                            <div className="w-full">
                                                <div className="flex justify-center ml-5 mt-4 mb-4">
                                                    <img src="../../no_data.png" width={100} />
                                                </div>
                                                <div className="flex text-center px-14 justify-center">

                                                    <span className="text-gray-500 mb-10">Aucun pays ou région n'a été ajouté à votre zone. Commencez à ajouter des pays ou des régions à votre zone avant d'enregistrer.</span>

                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="mt-4">
                            <div>
                                <div className="flex justify-between w-full">
                                    <div className={`w-full rounded bg-white shadow p-6 ${!shippingMethod?.delivery && errorInput.includes("delivery") && 'border-[1px] border-[red] rounded'} `}>

                                        {/* TITLE INPUT */}
                                        <div className="flex justify-between">
                                            <div className="mt-1">
                                                <span className="font-semibold">Méthode(s) d’expédition</span><span className="text-[red] ml-1">*</span>
                                            </div>
                                            <div>
                                                <button onClick={() => handleShowModalAddShipperMethod()} className="bg-gray-200 text-center transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-gray-300 rounded p-2 text-sm cursor-pointer">Ajouter</button>
                                            </div>
                                        </div>

                                        <table className={`min-w-full bg-white mt-8 ${!shippingMethod?.delivery ? "hidden" : ""}`}>
                                            <thead>
                                                <tr className="w-full h-16 border-gray-300 border-b py-8">
                                                    <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Nom</th>
                                                    <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Description</th>
                                                    <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Expéditeur</th>
                                                    <td className="text-gray-600 pr-8 text-left text-sm tracking-normal leading-4 font-semibold">Prix (€) / Seuil (grammes) / Etiquettes</td>
                                                    <td className="text-gray-600 pr-8 text-left text-sm tracking-normal leading-4 font-semibold">Actions</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shippingMethod?.delivery && Object.keys(shippingMethod?.delivery).map((key) => (
                                                    <tr key={key} className="h-24 border-gray-300 border-b">
                                                        <td className="pr-6 whitespace-no-wrap">
                                                            {shippingMethod?.delivery[key]?.name}
                                                        </td>
                                                        <td className="pr-6 whitespace-no-wrap w-1/4">
                                                            {shippingMethod?.delivery[key]?.description}
                                                        </td>
                                                        <td className="pr-6 whitespace-no-wrap">
                                                            {shippingMethod?.delivery[key]?.shipperName === undefined
                                                                ? <span className="bg-gray-200 p-1">{allShipper.find((shipper) => shipper._id === shippingMethod?.delivery[key]?.shipperId)?.name}</span>
                                                                : <span className="bg-gray-200 p-1">{shippingMethod?.delivery[key]?.shipperName}</span>
                                                            }
                                                        </td>
                                                        <td className="pr-16 whitespace-no-wrap">
                                                            <table className="w-full mt-4 mb-4">
                                                                <thead>
                                                                    <tr className="border-b border-gray-300" />
                                                                </thead>


                                                                <tbody>
                                                                    {shippingMethod?.delivery[key]?.price?.map((idx) => (
                                                                        <tr key={idx?.treshold} className="border-b border-gray-300">
                                                                            <td className="px-6 py-4 text-center whitespace-nowrap">{idx?.price?.toFixed(2)} €</td>
                                                                            <td className="px-6 py-4 text-center whitespace-nowrap">{idx?.weight} grammes</td>

                                                                            {!isNaN(shippingMethod?.delivery[key]?.defaultLabelNumber) &&
                                                                                <>
                                                                                    {shippingMethod?.delivery[key]?.defaultLabelNumber !== 0 &&
                                                                                        <td className="px-6 py-4 text-center whitespace-nowrap">  {idx?.labelNumber} étiquette{idx?.labelNumber > 1 ? 's' : ''}</td>
                                                                                    }
                                                                                </>
                                                                            }

                                                                        </tr>
                                                                    ))}
                                                                </tbody>



                                                            </table>
                                                        </td>
                                                        <td>
                                                            <div className={`dropdown-content mt-8 absolute right-18 -ml-12 shadow-md z-[999] w-32 ${dropdown === key ? "block" : "hidden"}`}>
                                                                <ul className="bg-white shadow rounded py-1">
                                                                    <li onClick={() => handleShowModalEditShipper(key)} className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-edit"></i> Éditer</li>
                                                                    <li onClick={() => handleRemoveShippingMethod(key)} className="cursor-pointer text-red-500 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-trash"></i> Supprimer</li>
                                                                </ul>
                                                            </div>

                                                            <button onClick={() => handleDropdownForeach(key)} className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" />
                                                                    <circle cx={12} cy={12} r={1} />
                                                                    <circle cx={12} cy={19} r={1} />
                                                                    <circle cx={12} cy={5} r={1} />
                                                                </svg>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>


                                        {!shippingMethod?.delivery &&
                                            <div className="w-full">
                                                <div className="flex justify-center ml-5 mt-4 mb-4">
                                                    <img src="../../no_data.png" width={100} />
                                                </div>
                                                <div className="flex text-center px-14 justify-center">

                                                    <span className="text-gray-500 mb-10">Aucunes méhodes d'expéditions n'a été ajouté à votre zone. Commencez à ajouter des méthodes d'expéditions à votre zone avant d'enregistrer.</span>

                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center justify-between w-full">
                            <div className="flex-col w-full items-start lg:items-center rounded bg-white shadow">
                                <div className="p-6">
                                    <h1 className="font-semibold mb-4">Statut</h1>

                                    <Select
                                        isMulti={false}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        styles={{
                                            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                            menu: (provided) => ({ ...provided, zIndex: 9999 })
                                        }}
                                        value={shippingMethod?.disabled ? { name: "Inactif" } : { name: "Actif" }}
                                        onChange={(item) => item?.name === "Actif" ? setShippingMethod({ ...shippingMethod, disabled: false }) : setShippingMethod({ ...shippingMethod, disabled: true })}
                                        options={status}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.name}
                                        placeholder={"Actif"}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default AddDelivery;
