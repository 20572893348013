import { apiClient } from './ApiClient'

function getAllLanguages() {
    return apiClient().get('/admin/languages/')
}

function addLanguage(data) {
    return apiClient().post('/admin/languages/', data)
}

function getLanguageById(id) {
    return apiClient().get('/admin/languages/' + id)
}

function editLanguage(data) {
    return apiClient().patch('/admin/languages/', data)
}

function deleteLanguage(id) {
    return apiClient().delete('/admin/languages/' + id)
}

export default {
    getAllLanguages,
    addLanguage,
    getLanguageById,
    editLanguage,
    deleteLanguage
}
