import { GET_ALL_USERS, CREATE_USER_SUCCESS, CREATE_USER_ERROR } from '../actions/types'

const defaultState = {}

export default (state = defaultState, action) => {
    const { type, payload, error = {} } = action

    switch (type) {
        case GET_ALL_USERS:
            return { ...state, ...payload }
        case CREATE_USER_SUCCESS:
            return { ...state, ...payload }
        case CREATE_USER_ERROR:
            return { ...state, error: error }
        default:
            return state
    }
}