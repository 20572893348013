import { GET_ALL_ORDERS, GET_ALL_CART } from "./types";
import apiAuth from "../api/orders";

// GET ALL ORDERS
export const getAllOrders = () =>
    async (dispatch) => {
        return apiAuth
            .getAllOrders()
            .then(async (result) => {
                if (result?.data) {
                    await dispatch({ type: GET_ALL_ORDERS, payload: result.data })
                    return result.data
                }
            })
    };

export const getAllCart = () =>
    async (dispatch) => {
        return apiAuth
            .getAllCart()
            .then(async (result) => {
                if (result?.data) {
                    await dispatch({ type: GET_ALL_CART, payload: result.data })
                    return result.data
                }
            })
    };

// GET ORDER BY ID
export const getOrderById = async (id) => {
    const result = await apiAuth
        .getOrderById(id);

    if (result?.data) {
        return result.data;
    }
};

// EDIT ORDER
export const editOrder = async (data) => {
    return apiAuth
        .editOrder(data)
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};


// PRINT LABEL
export const printLabel = async (data) => {
    return apiAuth
        .printLabel(data)
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};
