import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteCategoryById, getAllCategories, getAllCategoriesFlat } from "../actions/categories";
import { useNavigate } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import { IMG_LINK } from "../config/key";

const Categories = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [dropdown, setDropdown] = useState(false);
    const [subCat, setSubCat] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categoriesClone, setCategoriesClone] = useState([]);
    const [categoriesItem, setCategoriesItem] = useState([]);
    const [showModalDeleteCat, setShowModalDeleteCat] = useState(false);
    const [idCatToDelete, setIdCatToDelete] = useState();
    const [typeOfCatToDelete, setTypeOfCatToDelete] = useState("toParent");

    const [searchInput, setSearchInput] = useState();
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    useEffect(() => {
        setLoadingSpinner(true);
        dispatch(getAllCategories()).then((categories) => {
            setLoadingSpinner(false);
            setCategories(categories);
            setCategoriesClone(categories);
        })

        getAllCategoriesFlat().then((categories) => {
            setCategoriesItem(categories);
        })

    }, []);

    useEffect(() => {
        handleBackCategories(categories);
    }, [categories])

    // PAGINATION
    const [itemPerPagePaginate, setItemPerPagePaginate] = useState(10);
    let itemsPerPage = itemPerPagePaginate;
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = categories?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(categories?.length / itemsPerPage);
    // PAGINATION

    const handleDropdownForeach = (row) => {
        setDropdown(row);
    }

    const handleShowSubCategories = (categorie) => {
        if (categorie.subCat.length !== 0)
            setSubCat(categorie.subCat);
    }

    const handleBackCategories = (categorie) => {
        setSubCat(categorie, true)
    }

    const handleRedirectEdit = (id) => {
        navigate(`/edit/category/${id}`);
    }

    const handlePagePaginateClick = (event) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const newOffset = (event.selected * itemsPerPage) % categories?.length;

        setItemOffset(newOffset);
    };

    const handleShowModalDeleteCategory = (id) => {
        setDropdown(null);
        setShowModalDeleteCat(true);
        setIdCatToDelete(id);
    }

    const handleDeleteCategory = async () => {
        await dispatch(deleteCategoryById(idCatToDelete, typeOfCatToDelete)).then((res) => {
            setShowModalDeleteCat(false);
            setCategories(res);
        })
    }

    const handleSmallSearch = (query) => {
        setSearchInput(query);
        var arrItemSearch = []
        const arrSearch = [];

        arrSearch.push(categoriesItem)

        for (var index = 0; index < arrSearch.length; index++) {
            if (!!arrSearch[index]) {
                arrItemSearch.push(Object.values(arrSearch[index]));
            }
        }

        const results = arrItemSearch[0].filter((item) => {
            return (item?.description.toString().toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                item?.name.toString().toLowerCase().indexOf(query.toLowerCase()) > -1);
        })

        if (query.length !== 0) {
            setCategories(results)
        } else {
            setCategories(categoriesClone)
        }
    }

    return (
        <>

            <div onClick={() => setDropdown(false)} className={`${dropdown !== false && "mydropdown"}`}></div>

            {showModalDeleteCat &&
                <>
                    <div>
                        <div onClick={() => setShowModalDeleteCat(false)} className="myModal backdrop-blur-sm"></div>
                        <div className="center-absolutely bg-[white] rouded w-4/12 mt-10">

                            <div className="flex row justify-between">
                                <div className="px-6 mt-4">
                                    <h1 className="text-xl font-semibold mb-1">Que souhaitez-vous faire avec les produits actuellement liés à cette catégorie ?</h1>
                                </div>

                                <div className="px-6 mt-4">
                                    <span data-state="closed" className="cursor-pointer" onClick={() => setShowModalDeleteCat(false)}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex text-grey-50">
                                            <path d="M15 5L5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M5 5L15 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>

                            <div className="relative p-6 flex-auto ">
                                <div>
                                    <input onChange={(e) => setTypeOfCatToDelete(e?.target?.value)} type="radio" id="toParent" name="deleteCat" value="toParent" defaultChecked={true} />
                                    <label htmlFor="associateCat" className="ml-2">S'ils n'ont plus de catégorie, je souhaite les associer à la catégorie parente.</label>
                                </div>

                                <div className="mt-3">
                                    <input onChange={(e) => setTypeOfCatToDelete(e?.target?.value)} type="radio" id="toDelete" name="deleteCat" value="toDelete" />
                                    <label htmlFor="deleteProduct" className="ml-2">S'ils n'ont plus de catégorie, je souhaite les désactiver.</label>
                                </div>
                            </div>

                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-indigo-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => setShowModalDeleteCat(false)}
                                >
                                    Fermer
                                </button>
                                <button
                                    className="bg-red-500 text-white  font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => handleDeleteCategory()}
                                >
                                    Supprimer
                                </button>
                            </div>


                        </div>
                    </div>
                </>
            }

            <div className="flex row ml-8">

                <div className="flex mb-4 w-full md:w-1/6">
                    <div
                        className="flex-grow md:flex-none inter-small-regular mt-1 transition-color transition-width duration-150 ease-in-out flex text-grey-50 flex items-center mb-1 pl-1 py-1.5 rounded border border-grey-0 w-full focus-within:bg-grey-5">
                        <span className="px-2.5 py-0.5">
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M20.4696 21.5303C20.7625 21.8232 21.2373 21.8232 21.5302 21.5303C21.8231 21.2374 21.8231 20.7626 21.5302 20.4697L20.4696 21.5303ZM17.1802 16.1197C16.8873 15.8268 16.4125 15.8268 16.1196 16.1197C15.8267 16.4126 15.8267 16.8874 16.1196 17.1803L17.1802 16.1197ZM18.25 11C18.25 15.0041 15.0041 18.25 11 18.25V19.75C15.8325 19.75 19.75 15.8325 19.75 11H18.25ZM11 18.25C6.99594 18.25 3.75 15.0041 3.75 11H2.25C2.25 15.8325 6.16751 19.75 11 19.75V18.25ZM3.75 11C3.75 6.99594 6.99594 3.75 11 3.75V2.25C6.16751 2.25 2.25 6.16751 2.25 11H3.75ZM11 3.75C15.0041 3.75 18.25 6.99594 18.25 11H19.75C19.75 6.16751 15.8325 2.25 11 2.25V3.75ZM21.5302 20.4697L17.1802 16.1197L16.1196 17.1803L20.4696 21.5303L21.5302 20.4697Z"
                                    fill="currentColor">
                                </path>
                            </svg>
                        </span>
                        <input type="text" onChange={(e) => handleSmallSearch(e.target.value)}
                            className="border-transparent focus:border-transparent  focus:ring-0 mini-search w-full focus:bg-grey-5 focus:text-grey-90 caret-violet-60 w-full"
                            placeholder="Rechercher..." size="6" />
                    </div>
                </div>

                <div>
                    <button className="flex border border-grey-0 p-[.6rem] rounded mt-[.25rem] ml-2 mr-8" type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-33" data-state="closed">

                        <svg width={16} height={16}
                            viewBox="0 0 64 64" >
                            <path d="M41,64l-18-7.7V37.5L0.5,0h63.1L41,37.6V64z M27,53.7L37,58V36.4L56.5,4H7.5L27,36.4V53.7z" fill="currentColor" />
                        </svg>

                    </button>
                </div>
            </div>


            {categories.length === 0 &&
                <>
                    {loadingSpinner
                        ? <div className="flex justify-center items-center content-center">
                            <svg className="w-11 h-11 animate-spin mt-14 mb-14" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#000000" />
                                <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z" fill="#000000" />
                            </svg>
                        </div>
                        : <div>
                            <div className="w-full">
                                <div className="flex justify-center ml-5 mb-4">
                                    <img src="../../no_data.png" width={100} />
                                </div>
                                <div className="flex justify-center">

                                    {searchInput
                                        ? <span className="text-gray-500 mb-10">Aucunes catégories ne corresponds à votre recherche "<span className="font-semibold italic">{searchInput}</span>".</span>
                                        : <span className="text-gray-500 mb-10">Aucunes catégories.</span>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </>
            }

            <div>
                <div className={`w-full overflow-x-scroll xl:overflow-x-hidden ${categories.length === 0 ? "hidden" : "block"}`}>

                    <table className="min-w-full bg-white">
                        <thead>
                            <tr className="w-full h-16 border-gray-300 border-b py-8">
                                <th className="pl-8 text-gray-600 font-normal pr-6 text-left text-sm tracking-normal leading-4">
                                    <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                                </th>
                                <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold"></th>
                                <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Nom</th>
                                <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Description</th>
                                <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Nb. sous catégories</th>
                                <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Nb. de produits</th>
                                {/* <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Statut</th> */}
                                <td className="text-gray-600 pr-8 text-left text-sm tracking-normal leading-4 font-semibold">Actions</td>
                            </tr>
                        </thead>


                        {(searchInput)
                            ?
                            <tbody>
                                {Object.keys(currentItems).map((i) => (
                                    <tr key={currentItems[i]._id} className={`lg:h-20 border-gray-300 border-b ${currentItems[i]?.subCat?.length !== 0 && "cursor-pointer"} `}>
                                        <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                            <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                                        </td>

                                        <td className="text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                            <img src={currentItems[i]?.imageId ? IMG_LINK + currentItems[i]?.imageId : "../../noimg.png"} width={50} />
                                        </td>

                                        <td onClick={() => handleShowSubCategories(currentItems[i])} className="text-sm whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                            <span className={` ${currentItems[i]?.subCat?.length !== 0 && "border-dotted border-b-2 border-[#c4cdd5]"} `}>{currentItems[i]?.name}</span>
                                        </td>



                                        <td className="text-sm whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                            <span>{currentItems[i]?.description}</span>
                                        </td>


                                        <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">{currentItems[i]?.subCat?.length}</td>

                                        <td className="pr-6 whitespace-no-wrap">
                                            <div className="flex items-center">
                                                <div className={`w-2 h-2 rounded-full ${currentItems[i]?.disabled === false && "bg-green-600"} ${currentItems[i]?.disabled === true && "bg-red-600"}`} />
                                                <span className="text-sm pr-2 whitespace-no-wrap text-gray-800 tracking-normal leading-4 ml-1">{currentItems[i]?.disabled === true ? 'Inactif' : "Actif"}</span>
                                            </div>
                                        </td>

                                        <td className="pr-8">
                                            <div className={`dropdown-content mt-8 absolute right-18 -ml-12 shadow-md z-[999] w-32 ${dropdown === currentItems[i]._id ? "block" : "hidden"}`}>
                                                <ul className="bg-white shadow rounded py-1">
                                                    <li onClick={() => handleRedirectEdit(currentItems[i]._id)} className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-edit"></i> Éditer</li>
                                                    <li onClick={() => handleShowModalDeleteCategory(currentItems[i]._id)} className="cursor-pointer text-red-500 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-trash"></i> Supprimer</li>
                                                </ul>
                                            </div>

                                            <button onClick={() => handleDropdownForeach(currentItems[i]._id)} className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" />
                                                    <circle cx={12} cy={12} r={1} />
                                                    <circle cx={12} cy={19} r={1} />
                                                    <circle cx={12} cy={5} r={1} />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>

                            : <>
                                {!subCat &&
                                    <tbody>
                                        {Object.keys(currentItems).map((i) => (
                                            <tr key={currentItems[i]._id} className={`lg:h-20 border-gray-300 border-b ${currentItems[i]?.subCat?.length !== 0 && "cursor-pointer"} `}>
                                                <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                    <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                                                </td>

                                                <td className="text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                    <img src={currentItems[i]?.imageId ? IMG_LINK + currentItems[i]?.imageId : "../../noimg.png"} width={50} />
                                                </td>

                                                <td onClick={() => handleShowSubCategories(currentItems[i])} className="text-sm whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                    <span className={` ${currentItems[i]?.subCat?.length !== 0 && "border-dotted border-b-2 border-[#c4cdd5]"} `}>{currentItems[i]?.name}</span>
                                                </td>


                                                <td className="text-sm whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                    <span>{currentItems[i]?.description}</span>
                                                </td>


                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">{currentItems[i]?.subCat?.length}</td>


                                                <td className="pr-8">
                                                    <div className={`dropdown-content mt-8 absolute right-18 -ml-12 shadow-md z-[999] w-32 ${dropdown === currentItems[i]._id ? "block" : "hidden"}`}>
                                                        <ul className="bg-white shadow rounded py-1">
                                                            <li onClick={() => handleRedirectEdit(currentItems[i]._id)} className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-edit"></i> Éditer</li>
                                                            <li onClick={() => handleShowModalDeleteCategory(currentItems[i]._id)} className="cursor-pointer text-red-500 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-trash"></i> Supprimer</li>
                                                        </ul>
                                                    </div>

                                                    <button onClick={() => handleDropdownForeach(currentItems[i]._id)} className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <circle cx={12} cy={12} r={1} />
                                                            <circle cx={12} cy={19} r={1} />
                                                            <circle cx={12} cy={5} r={1} />
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                }

                                {subCat &&
                                    <tbody>
                                        {Object.keys(subCat).map((i) => (
                                            <tr key={subCat[i]?._id} className="lg:h-20 border-gray-300 border-b cursor-pointer">
                                                <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                    <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                                                </td>

                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                    <div className="relative w-10 text-gray-600">
                                                        <div className="h-12 w-12">
                                                            {subCat[i]?.imageId
                                                                ? <img src={IMG_LINK + subCat[i]?.imageId} alt="produit" className="h-full w-full overflow-hidden shadow" />
                                                                : <img src={"../../placeholder.jpeg"} />
                                                            }
                                                        </div>
                                                    </div>
                                                </td>

                                                <td onClick={() => handleShowSubCategories(subCat[i])} className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 cursor-pointer">
                                                    <span className={` ${subCat[i]?.subCat?.length !== 0 && 'border-b-2 border-[#c4cdd5] border-dotted'} `}>{subCat[i]?.name}</span>
                                                </td>

                                                <td className="text-sm whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                    {subCat[i]?.description
                                                        ? <>{subCat[i]?.description?.slice(0, 50)}{subCat[i]?.description?.length > 16 ? '...' : ''}</>
                                                        : "/"
                                                    }
                                                </td>


                                                <td onClick={() => handleShowSubCategories(subCat[i])} className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 cursor-pointer">{subCat[i]?.subCat?.length}</td>

                                                <td onClick={() => handleShowSubCategories(subCat[i])} className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 cursor-pointer">{subCat[i]?.itemNumber}</td>


                                                <td className="pr-8">
                                                    <div className={`dropdown-content mt-8 absolute right-18 -ml-12 shadow-md z-[999] w-32 ${dropdown === subCat[i]?._id ? "block" : "hidden"}`}>
                                                        <ul className="bg-white shadow rounded py-1">
                                                            <li onClick={() => handleRedirectEdit(subCat[i]._id)} className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-edit"></i> Éditer</li>
                                                            <li onClick={() => handleShowModalDeleteCategory(subCat[i]._id)} className="cursor-pointer text-red-500 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-trash"></i> Supprimer</li>
                                                        </ul>
                                                    </div>

                                                    <button onClick={() => handleDropdownForeach(subCat[i]._id)} className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <circle cx={12} cy={12} r={1} />
                                                            <circle cx={12} cy={19} r={1} />
                                                            <circle cx={12} cy={5} r={1} />
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                }
                            </>
                        }
                    </table>
                </div>

                <div className={`flex justify-between px-4 py-4 mt-4 ${categories.length !== 0 ? "block" : "hidden"} `}>
                    <div className="">
                        <span>Afficher:</span>

                        <select name="pets" id="pet-select" value={itemPerPagePaginate ? itemPerPagePaginate : 10} onChange={(e) => setItemPerPagePaginate(e?.target?.value)} className="border-[1px] ml-1 rounded p-1">
                            <option value={1}>1</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                        </select>
                    </div>

                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePagePaginateClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="<"
                        containerClassName={'containerPaginate'}
                        previousClassName="previousBtnPaginate"
                        nextClassName="nextBtnPaginate"
                        activeClassName="activeBtnPaginate"
                        pageClassName="pageBtnPaginate"
                    />

                </div>

            </div>
        </>
    );
};

export default Categories;
