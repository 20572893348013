import apiRegion from "../api/shipper";
import { GET_ALL_SHIPPER } from "./types";

// GET ALL SHIPPER
export const getAllShipper = () =>
    async (dispatch) => {
        return apiRegion
            .getAllShipper()
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_SHIPPER, payload: result.data })
                    return result?.data
                }
            })
    };


// ADD SHIPPER
export const addShipper = async (data) => {
    return apiRegion
        .addShipper(data)
        .then(async (result) => {

            if (result.data) {
                return result.data
            }
        })
};

// DELETE SHIPPER
export const deleteShipper = async (data) => {
    return apiRegion
        .deleteShipper(data)
        .then(async (result) => {

            if (result.data) {
                return result.data
            }
        })
};

// EDIT SHIPPER
export const editShipper = async (data) => {
    return apiRegion
        .editShipper(data)
        .then(async (result) => {

            if (result.data) {
                return result.data
            }
        })
};

// GET SHIPPER BY ID
export const getShipperById = async (id) => {
    return apiRegion
        .getShipperById(id)
        .then(async (result) => {

            if (result.data) {
                return result.data
            }
        })
};
