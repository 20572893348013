import React, { useEffect, useState } from "react";
import { editStore, getStore } from "../actions/stores";
import { NotificationManager } from 'react-notifications';
import { usePrompt } from '../utils/prompt';
import { useNavigate } from 'react-router-dom';
import AutoComplete from "react-google-autocomplete";
import { GOOGLE_MAP_API, IMG_LINK } from "../config/key";
import Creatable from 'react-select/creatable';

const Merchant = () => {
    const [store, setStore] = useState({});
    const [dataObj, setDataObj] = useState({});
    const [dataChanged, setDataChanged] = useState(false);
    const navigate = useNavigate();
    const [billingAddress, setBillingAddress] = useState({});
    const [addressBilling, setAddressBilling] = useState({});

    const [logisticAddress, setLogisticAddress] = useState({});
    const [addressLogistic, setAddressLogistic] = useState({});

    useEffect(() => {
        getStore()
            .then((res) => {
                setStore(res);
                setDataObj(res);
            })
            .catch(() => {
                NotificationManager.error('Une erreur est survenue lors de la récupération de la boutique', 'Erreur')
            })
    }, [])

    useEffect(() => {
        compareObjects()
    }, [store]);

    useEffect(() => {
        if (billingAddress) {
            let result = {}

            billingAddress?.address_components?.map((it) => {
                if (it.types[0] === "country") {
                    result['country'] = it.long_name
                }
                if (it.types[0] == "locality") {
                    result['city'] = it.long_name
                }
                if (it.types[0] == "postal_code") {
                    result['postalCode'] = it.long_name
                }

                result['address'] = billingAddress?.formatted_address?.split(',')[0]
                result['defaut'] = true;

                if (billingAddress?.geometry) {
                    result['lat'] = billingAddress?.geometry?.location.lat();
                    result['long'] = billingAddress?.geometry?.location.lng();
                }

            })

            setAddressBilling(result)
        }
    }, [billingAddress])

    useEffect(() => {
        if (logisticAddress) {
            let result = {}

            logisticAddress?.address_components?.map((it) => {
                if (it.types[0] === "country") {
                    result['country'] = it.long_name
                }
                if (it.types[0] == "locality") {
                    result['city'] = it.long_name
                }
                if (it.types[0] == "postal_code") {
                    result['postalCode'] = it.long_name
                }

                result['address'] = logisticAddress?.formatted_address?.split(',')[0]
                result['defaut'] = true;

                if (logisticAddress?.geometry) {
                    result['lat'] = logisticAddress?.geometry?.location.lat();
                    result['long'] = logisticAddress?.geometry?.location.lng();
                }

            })

            setAddressLogistic(result)
        }
    }, [logisticAddress])

    const handleEditStore = () => {
        const data = {
            name: store?.name,
            name2: store?.name2,
            email: store?.email,
            phone: store?.phone,
            siret: store?.siret,
            frontUrl: store?.frontUrl,
            address: Object.keys(addressBilling).length !== 0 ? addressBilling : store?.address,
            logo: store?.logo,
            logisticEmail: store?.logisticEmail,
            logisticName1: store?.logisticName1,
            logisticName2: store?.logisticName1,
            logisticAddress: Object.keys(addressLogistic).length !== 0 ? addressLogistic : store?.logisticAddress
        }

        editStore(data)
            .then((res) => {
                NotificationManager.success("Votre boutique a bien été modifié !", 'Succès');
                setStore(res);
                setDataObj(res);
                setDataChanged(false);
            })
            .catch((err) => {
                NotificationManager.error("Une erreur est survenue lors de la modification de votre boutique !", 'Erreur')
            })
    }

    const compareObjects = () => {

        let isDifferent = false;

        for (const key in store) {
            if (store.hasOwnProperty(key) && store[key] !== dataObj[key]) {
                isDifferent = true;
                break;
            }
        }

        setDataChanged(isDifferent);
    };

    const handleResetData = () => {
        setStore(dataObj)
        setDataChanged(false);
    }

    const handleUploadStoreLogo = (img) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const base64Img = event.target.result;

            setStore({ ...store, logo: base64Img });
        };

        reader.readAsDataURL(img);
    }

    const handleEmailLogisticChange = (selectedItems) => {
        const selectedValues = selectedItems.map((item) => item.value);
        setStore({ ...store, logisticEmail: selectedValues });
    };

    return (
        usePrompt(dataChanged),

        <>

            <div className={`fixed bg-[#080404] top-0 py-[.7rem] px-5 w-full left-0 z-[999] m-0 text-center test ${dataChanged ? "transition ease-in-out delay-250 opacity-1 duration-500 z-[999]" : "opacity-0 delay-250 duration-500 z-[0]"}`}>
                <div className="flex justify-between text-[white]">
                    <div className="cursor-pointer" onClick={() => navigate('/')}>
                        <svg fill="currentColor" width="38" height="38" viewBox="0 0 174 174" xmlns="http://www.w3.org/2000/svg">
                            <path fill="white" fillRule="evenodd" clipRule="evenodd" d="M0 9.23318C0 4.13387 4.13275 0 9.23075 0H164.769C169.867 0 174 4.13387 174 9.23318V41.7256C168.389 42.1805 163.181 43.5167 158.377 45.7341C152.334 48.4078 147.394 52.1858 143.559 57.0681C140.421 51.9533 136.12 48.1172 130.658 45.5598C125.312 42.8861 119.152 41.5493 112.178 41.5493C105.786 41.5493 99.9745 42.6537 94.7445 44.8623C89.5144 47.0711 85.1559 50.2678 81.6691 54.4526V42.9386H60.9231V135.708H82.7151V88.4543C82.7151 79.3872 84.8653 72.4706 89.1657 67.7045C93.5822 62.9385 99.5097 60.5555 106.948 60.5555C113.689 60.5555 118.861 62.6479 122.464 66.8327C126.067 70.9013 127.868 77.0041 127.868 85.1414V135.708H149.661V88.4543C149.661 79.5034 151.869 72.6449 156.285 67.8789C160.702 62.9966 166.571 60.5555 173.893 60.5555L174 60.5557V164.767C174 169.866 169.867 174 164.769 174H9.23075C4.13275 174 0 169.866 0 164.767V9.23318ZM112.321 69.9598C106.222 69.9598 101.199 72.2675 97.4993 76.8928L97.4895 76.9051L97.4798 76.9175C93.89 81.527 92.2757 87.9726 92.2757 95.8953V135.709H97.1218V95.8953C97.1218 88.5482 98.631 83.3425 101.29 79.9206C104.013 76.523 107.607 74.8191 112.321 74.8191V69.9598Z"></path>
                        </svg>
                    </div>

                    <div className="mt-2 font-bold text-md">
                        Modifications non enregistrées
                    </div>

                    <div className="flex flex-row">
                        <div onClick={() => handleResetData()} className='border-[1px] border-gray-200 hover:bg-[#eb2f06] hover:border-[#eb2f06] rounded  hover:opacity-[1] cursor-pointer flex flex-row px-4 py-2 mr-2'>
                            <i className="fa fa-times text-gray-200 text-[27px] relative top-[.33rem]" />
                            <span className="text-gray-200 ml-1 mt-[.1rem] font-bold">Annuler</span>
                        </div>
                        <div onClick={() => handleEditStore()} className='bg-indigo-700 hover:bg-indigo-500 rounded  hover:opacity-[1] cursor-pointer flex flex-row px-4 py-2'>
                            <i className="fa fa-save text-[white] text-[25px]" />
                            <span className="text-[white] ml-2 mt-[.1rem] font-bold">Enregistrer</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-margin">
                {/* BREADCRUMBS */}
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                    <li className="inline-flex items-center">
                        <a href="/" className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-900">
                            <svg width="15px" height="15px" viewBox="0 0 50 50" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.8889 0H8.33333C6.04167 0 3.95833 0.936111 2.44722 2.44722C0.936111 3.95833 0 6.04167 0 8.33333V13.8889C0 16.1806 0.936111 18.2639 2.44722 19.775C3.95833 21.2861 6.04167 22.2222 8.33333 22.2222H13.8889C16.1806 22.2222 18.2639 21.2861 19.775 19.775C21.2861 18.2639 22.2222 16.1806 22.2222 13.8889V8.33333C22.2222 6.04167 21.2861 3.95833 19.775 2.44722C18.2639 0.936111 16.1806 0 13.8889 0Z" />
                                <path d="M41.6667 0H36.1112C33.8195 0 31.7362 0.936111 30.2251 2.44722C28.7139 3.95833 27.7778 6.04167 27.7778 8.33333V13.8889C27.7778 16.1806 28.7139 18.2639 30.2251 19.775C31.7362 21.2861 33.8195 22.2222 36.1112 22.2222H41.6667C43.9584 22.2222 46.0417 21.2861 47.5528 19.775C49.0639 18.2639 50.0001 16.1806 50.0001 13.8889V8.33333C50.0001 6.04167 49.0639 3.95833 47.5528 2.44722C46.0417 0.936111 43.9584 0 41.6667 0Z" />
                                <path d="M13.8889 27.7778H8.33333C6.04167 27.7778 3.95833 28.7139 2.44722 30.225C0.936111 31.7361 0 33.8194 0 36.1111V41.6667C0 43.9583 0.936111 46.0417 2.44722 47.5528C3.95833 49.0639 6.04167 50 8.33333 50H13.8889C16.1806 50 18.2639 49.0639 19.775 47.5528C21.2861 46.0417 22.2222 43.9583 22.2222 41.6667V36.1111C22.2222 33.8194 21.2861 31.7361 19.775 30.225C18.2639 28.7139 16.1806 27.7778 13.8889 27.7778Z" />
                                <path d="M41.6667 27.7778H36.1112C33.8195 27.7778 31.7362 28.7139 30.2251 30.225C28.7139 31.7361 27.7778 33.8194 27.7778 36.1111V41.6667C27.7778 43.9583 28.7139 46.0417 30.2251 47.5528C31.7362 49.0639 33.8195 50 36.1112 50H41.6667C43.9584 50 46.0417 49.0639 47.5528 47.5528C49.0639 46.0417 50.0001 43.9583 50.0001 41.6667V36.1111C50.0001 33.8194 49.0639 31.7361 47.5528 30.225C46.0417 28.7139 43.9584 27.7778 41.6667 27.7778Z" />
                                <clipPath id="clip0_308_250">
                                    <rect width="15px" height="15px" />
                                </clipPath>
                            </svg>
                            <span className="ml-2">Tableau de bord</span>
                        </a>
                    </li>
                    <li aria-current="page">
                        <div className="flex items-center">
                            <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                            <span className="ml-1 text-sm font-medium text-gray-900 md:ml-2z">Informations boutique</span>
                        </div>
                    </li>
                </ol>
                {/* BREADCRUMBS */}

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-10">
                    <div className="col-span-2">
                        <div className="flex justify-between w-full">
                            <div className="w-full rounded bg-white shadow p-6">
                                <h1 className="text-xl font-semibold mb-6">Détails</h1>

                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                    <div className="relative w-full">
                                        <label className="text-gray-700">
                                            Nom de la boutique
                                        </label>
                                        <input type="text" value={store?.name || ''} onChange={(e) => setStore({ ...store, name: e?.target?.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="name" placeholder="John-Store" />

                                        <div className="flex flex-row text-[#6d7175] text-[.9rem] font-semibold mt-2">
                                            <i className="fa fa-info-circle mt-1 opacity-[.5] mr-2" aria-hidden="true"></i>
                                            <p className="text-[.9rem] opacity-[.5] font-semibold ml-1">Le nom de votre boutique sous lequel vous allez vendre.</p>
                                        </div>
                                    </div>
                                    <div className="relative w-full">
                                        <label className="text-gray-700">
                                            Nom de la société
                                        </label>
                                        <input type="text" value={store?.name2 || ''} onChange={(e) => setStore({ ...store, name2: e?.target?.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="name" placeholder="John-Store" />

                                        <div className="flex flex-row text-[#6d7175] text-[.9rem] font-semibold mt-2">
                                            <i className="fa fa-info-circle mt-1 opacity-[.5] mr-2" aria-hidden="true"></i>
                                            <p className="text-[.9rem] opacity-[.5] font-semibold ml-1">Le nom de votre société sera visible sur les factures.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <label className="text-gray-700">
                                        Adresse e-mail - service client
                                    </label>
                                    <input type="text" value={store?.email || ''} onChange={(e) => setStore({ ...store, email: e?.target?.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="email" placeholder="john.doe@gmail.com" />

                                    <div className="flex flex-row text-[#6d7175] text-[.9rem] font-semibold mt-2">
                                        <i className="fa fa-info-circle mt-1 opacity-[.5] mr-2" aria-hidden="true"></i>
                                        <p className="text-[.9rem] opacity-[.5] font-semibold ml-1">Cet e-mail est visible par vos clients. Les e-mails du support iront ici.</p>
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 md:mt-0 gap-4 lg:mt-4">
                                    <div>
                                        <label className="text-gray-700">
                                            Numéro de téléphone
                                        </label>
                                        <input type="text" value={store?.phone || ''} onChange={(e) => setStore({ ...store, phone: e?.target?.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="phone" placeholder="+33 663453645" />

                                        <div className="flex flex-row text-[#6d7175] text-[.9rem] font-semibold mt-2">
                                            <i className="fa fa-info-circle mt-1 opacity-[.5] mr-2" aria-hidden="true"></i>
                                            <p className="text-[.9rem] opacity-[.5] font-semibold ml-1">Cet numéro de téléphone sera visible par vos clients.</p>
                                        </div>
                                    </div>

                                    <div>
                                        <label className="text-gray-700">
                                            N° Siret
                                        </label>
                                        <input type="text" value={store?.siret || ''} onChange={(e) => setStore({ ...store, siret: e?.target?.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="phone" placeholder="123 568 941 00056" />
                                        <div className="flex flex-row text-[#6d7175] text-[.9rem] font-semibold mt-2">
                                            <i className="fa fa-info-circle mt-1 opacity-[.5] mr-2" aria-hidden="true"></i>
                                            <p className="text-[.9rem] opacity-[.5] font-semibold ml-1">Le numéro de SIRET est un identifiant unique pour votre entreprise, composé de 14 chiffres.</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="relative w-full mt-4">
                                    <label className="text-gray-700">
                                        URL boutique
                                    </label>
                                    <input type="text" value={store?.frontUrl || ''} onChange={(e) => setStore({ ...store, frontUrl: e?.target?.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="phone" placeholder="https://milango.io/" />
                                    <div className="flex flex-row text-[#6d7175] text-[.9rem] font-semibold mt-2">
                                        <i className="fa fa-info-circle mt-1 opacity-[.5] mr-2" aria-hidden="true"></i>
                                        <p className="text-[.9rem] opacity-[.5] font-semibold ml-1">Ceci est l'adresse url de votre boutique en ligne.</p>
                                    </div>
                                </div>



                                <div className="mt-4">
                                    <label className="text-gray-700">
                                        Adresse de la boutique
                                    </label>

                                    <AutoComplete
                                        apiKey={GOOGLE_MAP_API}
                                        onPlaceSelected={(place) => {
                                            setBillingAddress(place);
                                            setDataChanged(true)
                                        }}
                                        defaultValue={
                                            (store?.address && !billingAddress) ?
                                                <></> :
                                                (store?.address?.address ? store.address.address + ", " + store?.address?.postalCode + " " + store?.address?.city : "")
                                        }
                                        placeholder="Saisissez l'adresse de votre boutique"
                                        options={{
                                            types: ["geocode", "establishment"],
                                        }}
                                        className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                    />
                                    <div className="flex flex-row text-[#6d7175] text-[.9rem] font-semibold mt-2">
                                        <i className="fa fa-info-circle mt-1 opacity-[.5] mr-2" aria-hidden="true"></i>
                                        <p className="text-[.9rem] opacity-[.5] font-semibold ml-1">Ceci est l'adresse physique de votre boutique en ligne.</p>
                                    </div>
                                </div>


                                {billingAddress !== 0 &&
                                    <>
                                        {Object.keys(addressBilling).length !== 0 &&
                                            <>
                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                    <div className="w-full mt-4">
                                                        <label>Code postal</label>
                                                        <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 bg-gray-100 cursor-not-allowed w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                                            value={addressBilling['postalCode'] || ''} disabled />
                                                    </div>

                                                    <div className="w-full mt-0 md:mt-4">
                                                        <label>Ville</label>
                                                        <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 bg-gray-100 cursor-not-allowed w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                                            value={addressBilling['city'] || ''} disabled />
                                                    </div>
                                                </div>

                                                <div className="mt-4 w-full mr-2">
                                                    <label>Pays</label>
                                                    <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full bg-gray-100 cursor-not-allowed py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                                        value={addressBilling['country'] || ''} disabled />
                                                </div>
                                            </>
                                        }

                                    </>
                                }





                                <hr className="mt-6 mb-6" />

                                <h1 className="text-xl font-semibold mb-6 mt-6">Logistique</h1>

                                    <div>
                                        <label className="text-gray-700">
                                            Nom de l'expéditeur
                                        </label>
                                        <input type="text" value={store?.logisticName1 || ''} onChange={(e) => setStore({ ...store, logisticName1: e?.target?.value, logisticName2: e?.target?.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="phone" placeholder="" />

                                        <div className="flex flex-row text-[#6d7175] text-[.9rem] font-semibold mt-2">
                                            <i className="fa fa-info-circle mt-1 opacity-[.5] mr-2" aria-hidden="true"></i>
                                            <p className="text-[.9rem] opacity-[.5] font-semibold ml-1">Le nom de l'expéditeur qui sera afficher sur l'étiquette.</p>
                                        </div>
                                    </div>

                                <div className="mt-4">
                                    <label className="text-gray-700">
                                        Adresse e-mail - logistique
                                    </label>

                                    <Creatable
                                        isMulti={true}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        styles={{
                                            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                            menu: (provided) => ({ ...provided, zIndex: 9999 })
                                        }}
                                        value={store?.logisticEmail?.map((it) => ({
                                            label: it,
                                            value: it,
                                            key: it,
                                        }))}
                                        onChange={(item) => handleEmailLogisticChange(item)}
                                        placeholder={"Choisir une ou plusieurs adresse e-mail"}
                                        className="mt-1"
                                    />


                                    <div className="flex flex-row text-[#6d7175] text-[.9rem] font-semibold mt-2">
                                        <i className="fa fa-info-circle mt-1 opacity-[.5] mr-2" aria-hidden="true"></i>
                                        <p className="text-[.9rem] opacity-[.5] font-semibold ml-1">Cet e-mail sera utilisé à des fins logistiques pour recevoir des alertes concernant toutes les nouvelles commandes.</p>
                                    </div>
                                </div>


                                <div className="mt-4">
                                    <label className="text-gray-700">
                                        Adresse de logistique
                                    </label>

                                    <AutoComplete
                                        apiKey={GOOGLE_MAP_API}
                                        onPlaceSelected={(place) => {
                                            setLogisticAddress(place);
                                            setDataChanged(true)
                                        }}
                                        defaultValue={
                                            (store?.logisticAddress && !logisticAddress) ?
                                                <></> :
                                                (store?.logisticAddress?.address ? store.logisticAddress.address + ", " + store?.logisticAddress?.postalCode + " " + store?.logisticAddress?.city : "")
                                        }
                                        placeholder="Saisissez l'adresse de logistique"
                                        options={{
                                            types: ["geocode", "establishment"],
                                        }}
                                        className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                    />
                                    <div className="flex flex-row text-[#6d7175] text-[.9rem] font-semibold mt-2">
                                        <i className="fa fa-info-circle mt-1 opacity-[.5] mr-2" aria-hidden="true"></i>
                                        <p className="text-[.9rem] opacity-[.5] font-semibold ml-1">Ceci est l'adresse de logistique qui apparaitra au niveau de la génération de vos étiquettes de colis.</p>
                                    </div>
                                </div>


                                {logisticAddress !== 0 &&
                                    <>
                                        {Object.keys(addressLogistic).length !== 0 &&
                                            <>
                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                    <div className="w-full mt-4">
                                                        <label>Code postal</label>
                                                        <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 bg-gray-100 cursor-not-allowed w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                                            value={addressLogistic['postalCode'] || ''} disabled />
                                                    </div>

                                                    <div className="w-full mt-0 md:mt-4">
                                                        <label>Ville</label>
                                                        <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 bg-gray-100 cursor-not-allowed w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                                            value={addressLogistic['city'] || ''} disabled />
                                                    </div>
                                                </div>

                                                <div className="mt-4 w-full mr-2">
                                                    <label>Pays</label>
                                                    <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full bg-gray-100 cursor-not-allowed py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                                        value={addressLogistic['country'] || ''} disabled />
                                                </div>
                                            </>
                                        }

                                    </>
                                }



                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between w-full">
                            <div className="flex-col w-full items-start lg:items-center rounded bg-white shadow">
                                <div className="p-6">
                                    <h1 className="font-semibold mb-4">Identifiant marchand </h1>

                                    <input type="text" value={store?._id || ''} className="mb-2 rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="f-name" placeholder="#47586" disabled />


                                    <div className="flex flex-row text-[#6d7175] text-[.9rem] font-semibold mt-2">
                                        <i className="fa fa-info-circle mt-2 opacity-[.5] mr-2" aria-hidden="true"></i>
                                        <p className="mt-1 text-[.9rem] opacity-[.5] font-semibold ml-1">Il s'agit de votre identifiant marchand unique. Nous pouvons vous le demander lorsque vous nous contactez.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between w-full mt-4">
                            <div className="w-full rounded bg-white shadow p-6 cursor-pointer">
                                <h1 className="font-semibold mb-4">Logo de la boutique</h1>


                                <div className="w-full rounded border-dashed hover:border-indigo-300 border-2 border-gray-300 p-14 text-center">
                                    {store?.logoId
                                        ? <div className="flex flex-row justify-center ">
                                            <div className="border-2 p-2 relative">
                                                <img style={{ width: 125 }} src={IMG_LINK + store?.logoId} />
                                                <div onClick={() => setStore({ ...store, logoId: null })} className="w-[20px] h-[20px] bg-red rounded-full flex justify-center items-center bg-gray-300 cursor-pointer absolute right-[-9px] top-[-10px]">
                                                    <span>-</span>
                                                </div>
                                            </div>
                                        </div>
                                        : <>
                                            {store?.logo
                                                ? <div className="flex flex-row justify-center ">
                                                    <div className="border-2 p-2 relative">
                                                        <img style={{ width: 125 }} src={store?.logo} />
                                                        <div onClick={() => setStore({ ...store, logo: null })} className="w-[20px] h-[20px] bg-red rounded-full flex justify-center items-center bg-gray-300 cursor-pointer absolute right-[-9px] top-[-10px]">
                                                            <span>-</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <>
                                                    <label className="btn btn-default bg-indigo-700 text-[white] px-5 py-2 mb-2 rounded cursor-pointer">
                                                        Ajouter une image
                                                        <input multiple onChange={(e) => handleUploadStoreLogo(e.target.files[0])} accept="image/*" type="file" style={{ display: 'none' }} required />
                                                    </label>

                                                    <p className="mt-3">Accepte les image (.png, .jpg, jpeg)</p>
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Merchant;
