import React from 'react';
import { CSVLink } from 'react-csv';

function ExportCSV({ data, filename }) {
  const headers = Object.keys(data[0]);
  const csvData = [
    headers,
    ...data.map((item) => headers.map((key) => item[key])),
  ];
  
  return (
    <CSVLink data={csvData} filename={filename}>
        <button className="bg-gray-200 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-gray-300 rounded text-indigo-700 p-4 h-8 flex items-center text-sm">
            Exporter
      </button>
    </CSVLink>
  );
}

export default ExportCSV;
