export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const LOGOUT = "LOGOUT";
export const GET_ALL_STORES = "GET_ALL_STORES"
export const GET_ALL_ITEMS = "GET_ALL_ITEMS"
export const GET_ALL_USERS = "GET_ALL_USERS"
export const CREATE_USER_ERROR = "CREATE_USER_ERROR"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES"
export const GET_ALL_DISCOUNTS = "GET_ALL_DISCOUNTS"
export const GET_ALL_ORDERS = "GET_ALL_ORDERS"
export const GET_ALL_CART = "GET_ALL_CART"
export const GET_ALL_BRANDS = "GET_ALL_BRANDS"
export const GET_ALL_COLLECTIONS = "GET_ALL_COLLECTIONS"
export const GET_ALL_REGIONS = "GET_ALL_REGIONS"
export const GET_ALL_INTEGRATIONS = "GET_ALL_INTEGRATIONS"
export const GET_COUNT_ANALYTICS = "GET_COUNT_ANALYTICS"
export const GET_ALL_TAXES = "GET_ALL_TAXES"
export const GET_ALL_LANGUAGES = "GET_ALL_LANGUAGES"
export const GET_ALL_CARDS = "GET_ALL_CARDS"
export const GET_ACTUAL_SUBSCRIPTION = "GET_ACTUAL_SUBSCRIPTION"
export const GET_ALL_INVOICES = "GET_ALL_INVOICES"
export const LOGIN_BACKEND_SUCCESS = "LOGIN_BACKEND_SUCCESS"
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS"
export const GET_ALL_SHIPPER = "GET_ALL_SHIPPER"
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS"