import apiAuth from "../api/stores";

// GET ALL STORES
export const getAllStores = () => {
    return apiAuth
        .getAllStores()
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};

// ADD STORE
export const addStore = (data) =>
    async (dispatch) => {
        return apiAuth
            .addStore(data)
            .then(async (result) => {
                if (result.data) {


                }
            })
    };

// GET STORE
export const getStore = async () => {
    return apiAuth
        .getStore()
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};

// EDIT STORE
export const editStore = async (data) => {
    return apiAuth
        .editStore(data)
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};