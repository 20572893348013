import React from "react";
import { useNavigate } from 'react-router-dom';

const AddPage = () => {
    const navigate = useNavigate();

    return (
        <div className="px-40">
            <div className="flex row">
                <button onClick={() => navigate('/settings/pages')} className="text-white cursor-pointer border-[1.2px] border-[#babfc3] bg-transparent transition duration-150 ease-in-out w-10 h-10 rounded flex items-center justify-center ">
                    <svg viewBox="0 0 20 20" height={25} width={25} fill='#5c5f62' focusable="false" aria-hidden="true"><path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path></svg>
                </button>

                <h1 className="text-xl font-semibold mt-1 ml-4">Ajouter une page</h1>
            </div>

            <div className="grid grid-cols-3 gap-4 mt-10">
                <div className="col-span-2">
                    <div className="flex justify-between w-full">
                        <div className="w-full rounded bg-white shadow p-6">

                            {/* TITLE INPUT */}
                            <div className="relative w-full">
                                <label className="text-gray-700">
                                    Titre
                                </label>
                                <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="email" placeholder="Titre de la page" />
                            </div>

                            {/* DESCRIPTION INPUT */}
                            <div className="mt-4">
                                <label className="text-gray-700">
                                    Contenu
                                </label>
                                <form>
                                    <div className="mb-4 w-full bg-gray-50 rounded-lg border border-gray-200 mt-2">
                                        <div className="flex justify-between items-center py-2 px-3 border-b">
                                            <div className="flex flex-wrap items-center divide-gray-200 sm:divide-x">
                                                <div className="flex items-center space-x-1 sm:pr-4">
                                                    <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd"></path></svg>
                                                    </button>
                                                    <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path></svg>
                                                    </button>
                                                    <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd"></path></svg>
                                                    </button>
                                                    <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                    </button>
                                                    <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z" clipRule="evenodd"></path></svg>
                                                    </button>
                                                </div>
                                                <div className="flex flex-wrap items-center space-x-1 sm:pl-4">
                                                    <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                                                    </button>
                                                    <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path></svg>
                                                    </button>
                                                    <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                                                    </button>
                                                    <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <button type="button" data-tooltip-target="tooltip-fullscreen" className="p-2 text-gray-500 rounded cursor-pointer sm:ml-auto hover:text-gray-900 hover:bg-gray-100">
                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 11-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 010-2h4a1 1 0 011 1v4a1 1 0 01-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 012 0v1.586l2.293-2.293a1 1 0 111.414 1.414L6.414 15H8a1 1 0 010 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.586l-2.293-2.293a1 1 0 111.414-1.414L15 13.586V12a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                            </button>
                                            <div id="tooltip-fullscreen" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
                                                Show full screen
                                                <div className="tooltip-arrow" data-popper-arrow></div>
                                            </div>
                                        </div>
                                        <div className="py-2 px-4 bg-white rounded-b-lg">
                                            <label className="sr-only">Publish post</label>
                                            <textarea id="editor" rows="8" className="block px-0 w-full text-sm text-gray-800 bg-white border-0 focus:ring-0" placeholder="Write an article..." required></textarea>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between w-full mt-4">
                        <div className="w-full rounded bg-white shadow p-6">
                            <h1 className="font-semibold mb-4">Image à la une</h1>

                            <div className="w-full rounded border-dashed hover:border-indigo-300 border-2 border-gray-300 p-14 text-center">
                                <h2>Ajouter un fichier</h2>
                                <p>Accepte les images, les vidéos ou les modèles 3D</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between w-full">
                        <div className="w-full rounded bg-white shadow mt-4 mb-8">
                            <div className="p-6">
                                <h1 className="font-semibold mb-2">Aperçu du résultat sur les moteurs de recherche</h1>
                                <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold">Ajoutez un titre et une description pour voir comment votre produit peut apparaître dans les résultats des moteurs de recherche.</p>
                            </div>

                            <div className="w-full border-[.5px] border-gray-200" />

                            <div className="px-6 mt-4">
                                <label className="text-gray-700">
                                    Méta-titre de la page
                                </label>
                                <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="seo_title" />
                                <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-2">0 sur 70 caractère(s) utilisé(s)</p>

                                <div className="mt-4">
                                    <label className="text-gray-700">
                                        Méta-description
                                    </label>
                                    <textarea rows="4" className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mt-2"></textarea>
                                    <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-2">0 sur 320 caractère(s) utilisé(s)</p>
                                </div>

                                <div className="mt-4 mb-8">
                                    <label className="text-gray-700">
                                        Ancre de l'URL
                                    </label>
                                    <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="seo_title" />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div>
                    <div className="flex items-center justify-between w-full">
                        <div className="flex-col w-full items-start lg:items-center rounded bg-white shadow">
                            <div className="p-6">
                                <h1 className="font-semibold mb-4">Publication</h1>

                                <select id="countries" defaultValue={""} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">Publier</option>
                                    <option value="US">Brouillon</option>
                                </select>

                                <p className="mt-1 text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold">Ce produit sera disponible sur 1 canal de vente.</p>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* FIXED SAVE BTN */}
            <div className='fixed bottom-[1rem] right-[1rem] p-2 bg-[#4338ca] rounded opacity-[.80] hover:opacity-[1] cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" height={32} width={32}><path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" /></svg>
            </div>
        </div>
    );
};
export default AddPage;
