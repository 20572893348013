import React, { useState } from "react";

const Permissions = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <div>

            {/* BREADCRUMBS */}
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                    <a href="/" className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-900">
                        <svg width="15px" height="15px" viewBox="0 0 50 50" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.8889 0H8.33333C6.04167 0 3.95833 0.936111 2.44722 2.44722C0.936111 3.95833 0 6.04167 0 8.33333V13.8889C0 16.1806 0.936111 18.2639 2.44722 19.775C3.95833 21.2861 6.04167 22.2222 8.33333 22.2222H13.8889C16.1806 22.2222 18.2639 21.2861 19.775 19.775C21.2861 18.2639 22.2222 16.1806 22.2222 13.8889V8.33333C22.2222 6.04167 21.2861 3.95833 19.775 2.44722C18.2639 0.936111 16.1806 0 13.8889 0Z" />
                            <path d="M41.6667 0H36.1112C33.8195 0 31.7362 0.936111 30.2251 2.44722C28.7139 3.95833 27.7778 6.04167 27.7778 8.33333V13.8889C27.7778 16.1806 28.7139 18.2639 30.2251 19.775C31.7362 21.2861 33.8195 22.2222 36.1112 22.2222H41.6667C43.9584 22.2222 46.0417 21.2861 47.5528 19.775C49.0639 18.2639 50.0001 16.1806 50.0001 13.8889V8.33333C50.0001 6.04167 49.0639 3.95833 47.5528 2.44722C46.0417 0.936111 43.9584 0 41.6667 0Z" />
                            <path d="M13.8889 27.7778H8.33333C6.04167 27.7778 3.95833 28.7139 2.44722 30.225C0.936111 31.7361 0 33.8194 0 36.1111V41.6667C0 43.9583 0.936111 46.0417 2.44722 47.5528C3.95833 49.0639 6.04167 50 8.33333 50H13.8889C16.1806 50 18.2639 49.0639 19.775 47.5528C21.2861 46.0417 22.2222 43.9583 22.2222 41.6667V36.1111C22.2222 33.8194 21.2861 31.7361 19.775 30.225C18.2639 28.7139 16.1806 27.7778 13.8889 27.7778Z" />
                            <path d="M41.6667 27.7778H36.1112C33.8195 27.7778 31.7362 28.7139 30.2251 30.225C28.7139 31.7361 27.7778 33.8194 27.7778 36.1111V41.6667C27.7778 43.9583 28.7139 46.0417 30.2251 47.5528C31.7362 49.0639 33.8195 50 36.1112 50H41.6667C43.9584 50 46.0417 49.0639 47.5528 47.5528C49.0639 46.0417 50.0001 43.9583 50.0001 41.6667V36.1111C50.0001 33.8194 49.0639 31.7361 47.5528 30.225C46.0417 28.7139 43.9584 27.7778 41.6667 27.7778Z" />
                            <clipPath id="clip0_308_250">
                                <rect width="15px" height="15px" />
                            </clipPath>
                        </svg>
                        <span className="ml-2">Tableau de bord</span>
                    </a>
                </li>
                <li>
                    <div className="flex items-center">
                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                        <a href="/settings" className="ml-1 text-sm font-medium text-gray-500 hover:text-gray-900 md:ml-2 ">Paramètres</a>
                    </div>
                </li>
                <li aria-current="page">
                    <div className="flex items-center">
                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                        <span className="ml-1 text-sm font-medium text-gray-900 md:ml-2z">Permissions</span>
                    </div>
                </li>
            </ol>
            {/* BREADCRUMBS */}

            <div className="flex items-center justify-between w-full">

                <div className="w-full lg:w-3/3">
                    <div className="py-0">
                        <div className="mx-auto bg-white shadow rounded">
                            <div className="flex row">

                                <h1 className="text-xl font-semibold mt-1 ml-2 cursor-pointer px-6 pt-6">Permissions</h1>

                                <div className="flex flex-col lg:flex-row p-4 lg:p-8 justify-between items-start lg:items-stretch w-full">
                                    <div className="w-full lg:w-1/3 flex flex-col lg:flex-row items-start lg:items-center"></div>
                                    <div className="w-full lg:w-2/3 flex flex-col lg:flex-row items-start lg:items-center justify-end">
                                        <div className="lg:ml-6 flex items-center">
                                            <button className="bg-gray-200 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-gray-300 rounded text-indigo-700 p-4 h-8 flex items-center text-sm">Exporter</button>
                                            {/* <button onClick={handleShowModal} className="text-white ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                                                Ajouter un rôle
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* SEARCH BAR */}
                            <div className="flex ml-8 mb-4">
                                <div
                                    className="inter-small-regular mt-1 transition-color transition-width duration-150 ease-in-out flex text-grey-50 flex items-center mb-1 pl-1 py-1.5 rounded border border-grey-0 min-w-content focus-within:mr-1 focus-within:w-60 focus-within:shadow-input focus-within:border-violet-60 focus-within:bg-grey-5">
                                    <span className="px-2.5 py-0.5">
                                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                                            <title>Search</title>
                                            <path
                                                d="M20.4696 21.5303C20.7625 21.8232 21.2373 21.8232 21.5302 21.5303C21.8231 21.2374 21.8231 20.7626 21.5302 20.4697L20.4696 21.5303ZM17.1802 16.1197C16.8873 15.8268 16.4125 15.8268 16.1196 16.1197C15.8267 16.4126 15.8267 16.8874 16.1196 17.1803L17.1802 16.1197ZM18.25 11C18.25 15.0041 15.0041 18.25 11 18.25V19.75C15.8325 19.75 19.75 15.8325 19.75 11H18.25ZM11 18.25C6.99594 18.25 3.75 15.0041 3.75 11H2.25C2.25 15.8325 6.16751 19.75 11 19.75V18.25ZM3.75 11C3.75 6.99594 6.99594 3.75 11 3.75V2.25C6.16751 2.25 2.25 6.16751 2.25 11H3.75ZM11 3.75C15.0041 3.75 18.25 6.99594 18.25 11H19.75C19.75 6.16751 15.8325 2.25 11 2.25V3.75ZM21.5302 20.4697L17.1802 16.1197L16.1196 17.1803L20.4696 21.5303L21.5302 20.4697Z"
                                                fill="currentColor">
                                            </path>
                                        </svg>
                                    </span>
                                    <span className="hidden inline-block">Rechercher</span>
                                    <input type="text" style={{ width: '7rem' }}
                                        className="focus:outline-none mini-search focus:border-none w-full focus:w-50 focus:bg-grey-5 focus:text-grey-90 caret-violet-60"
                                        placeholder="Rechercher..." size="6" />
                                </div>
                            </div>

                            <div className="w-full overflow-x-scroll xl:overflow-x-hidden mb-4">
                                <table className="min-w-full bg-white">
                                    <thead>
                                        <tr className="w-full h-16 border-gray-300 border-b py-8" >
                                            <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 pl-8 font-semibold">Rôle</th>
                                            <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 pl-8">
                                                <span className="bg-gray-100 p-[.35rem] rounded font-semibold">Membre</span>
                                            </th>
                                            <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 pl-8">
                                                <span className="bg-gray-100 p-[.35rem] rounded font-semibold">Modérateur</span>
                                            </th>
                                            <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 pl-8">
                                                <span className="bg-gray-100 p-[.35rem] rounded font-semibold">Administrateur</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <>
                                            <tr className="h-[4rem] border-gray-300 border-b bg-[#f9fafb]">
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8 font-bold">Report</td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8"></td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8"></td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8"></td>
                                            </tr>
                                            <tr className="h-[4rem] border-gray-300 border-b">
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">report_view</td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' checked className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                            </tr>

                                            <tr className="h-[4rem] border-gray-300 border-b bg-[#f9fafb]">
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8 font-bold">Contact</td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8"></td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8"></td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8"></td>
                                            </tr>
                                            <tr className="h-[4rem] border-gray-300 border-b">
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">contact_manage</td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' checked className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                            </tr>

                                            <tr className="h-[4rem] border-gray-300 border-b bg-[#f9fafb]">
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8 font-bold">Newsletter</td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8"></td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8"></td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8"></td>
                                            </tr>
                                            <tr className="h-[4rem] border-gray-300 border-b">
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">newsletter_manage</td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' checked className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                            </tr>


                                            <tr className="h-[4rem] border-gray-300 border-b bg-[#f9fafb]">
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8 font-bold">Language</td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8"></td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8"></td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8"></td>
                                            </tr>
                                            <tr className="h-[4rem] border-gray-300 border-b">
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">language_manager</td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' checked className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                            </tr>
                                            <tr className="h-[4rem] border-gray-300 border-b">
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">language_translation</td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' checked className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                            </tr>

                                            <tr className="h-[4rem] border-gray-300 border-b bg-[#f9fafb]">
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8 font-bold">Booking</td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8"></td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8"></td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8"></td>
                                            </tr>
                                            <tr className="h-[4rem] border-gray-300 border-b">
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">booking_view</td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' checked className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                            </tr>
                                            <tr className="h-[4rem] border-gray-300 border-b">
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">booking_update</td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' checked className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                            </tr>
                                            <tr className="h-[4rem] border-gray-300 border-b">
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">booking_manage_others</td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4 pl-8">
                                                    <input type='checkbox' checked className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                </td>
                                            </tr>
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* MODAL CONTENT */}

            {showModal &&
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-2xl font-semibold">
                                        Ajouter un rôle
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <div className="relative w-full">
                                        <label className="text-gray-700">
                                            Nom
                                        </label>
                                        <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="f-name" placeholder="Modérateur" />
                                    </div>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Fermer
                                    </button>
                                    <button
                                        className="bg-indigo-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Enregistrer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            }


            {/* FIXED SAVE BTN */}
            <div className='fixed bottom-[1rem] right-[1rem] p-2 bg-[#4338ca] rounded opacity-[.80] hover:opacity-[1] cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" height={32} width={32}><path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" /></svg>
            </div>
        </div>
    );
};

export default Permissions;
