import React, { useEffect, useState } from "react";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllStores } from "../../actions/stores";
import { loginToBackOffice } from "../../actions/auth";
import { logout } from "../../actions/auth";
import { addStore } from "../../actions/stores";
import { getAllOrders } from "../../actions/orders";

const Stores = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { auth } = useSelector(state => state);
    const [dropdown, setDropdown] = useState(false);
    const [modal, setModal] = useState(false);
    const [stores, setStores] = useState([]);
    const [store, setStore] = useState({ type: "e-commerce" });
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [loadingSpinner2, setLoadingSpinner2] = useState(false);

    useEffect(() => {
        setLoadingSpinner(true);
        getAllStores()
            .then((res) => {
                setLoadingSpinner(false);
                setStores(res);
            })
    }, [])

    const handleChooseStore = (id) => {
        dispatch(loginToBackOffice(id))
            .then((res) => {
                dispatch(getAllOrders());
                navigate('/');
            })
            .catch((err) => {
                NotificationManager.error(err?.response?.data?.error, 'Erreur');
            })
    };

    const handleShowDropdown = () => {
        setDropdown(true);

        if (dropdown) {
            setDropdown(false);
        }
    }

    const handleShowModal = () => {
        setModal(true);

        if (modal) {
            setModal(false);
        }
    }

    const handleLogout = async (e) => {
        e.preventDefault();

        dispatch(logout()).then(() => {
            navigate('/login')
        })
    };

    const handleAddStore = () => {
        const data_store = {
            name: store?.name,
            name2: store?.name,
            email: auth?.user?.email,
            phone: auth?.user?.phone,
            address: {
                address: "10 rue myhra",
                city: "Paris",
                postalCode: "75018",
                country: "France",
                lat: "123",
                long: "123"
            }
        }
        setLoadingSpinner2(true);
        dispatch(addStore(data_store))
            .then(() => {
                setLoadingSpinner2(false);
                setModal(false);
                NotificationManager.success("Félicitations ! Votre boutique a été créé avec succès !", 'Succès');
                getAllStores()
                    .then((res) => {
                        setStores(res)
                    })
            })
            .catch((err) => {
                NotificationManager.error(err?.response?.data?.error, 'Erreur');
            })
    }

    return (
        <>
            <div onClick={() => setDropdown(false)} className={`${dropdown !== false && "mydropdown"}`}></div>
            <div className="flex justify-center bg-gray-100 mt-8">
                <div className="flex flex-col w-full max-w-lg px-4 py-8 bg-white rounded-lg shadow  sm:px-6 md:px-8 lg:px-10">
                    <div className="flex relative justify-between items-center text-xl font-light text-gray-600 sm:text-2xl font-[400]">
                        <div>
                            <img className="w-1/3 ml-2 mt-1" src='/milango-logo.svg' />
                        </div>
                        <div className={`cursor-pointer flex flex-row hover:bg-[#eff0f0] ${dropdown ? 'bg-[#eff0f0]' : ''} p-2 rounded`} onClick={() => handleShowDropdown()}>
                            <div className="w-[50px] h-[30px] bg-[#ffc4b1] rounded-full flex justify-center items-center">
                                <h1 className="text-[#4c1020] text-[14px] capitalize">{auth?.user?.firstname[0]}</h1>
                            </div>
                            <img className={`w-[20px] ml-1 mt-1 ${dropdown ? 'rotate-180' : 'rotate-0'}`} src='/arrow-down.svg' />
                        </div>

                        {dropdown &&
                            <div className="bg-[white] shadow absolute right-0 top-[50px] p-2 rounded text-[13px] z-[999]">
                                <div onClick={handleLogout} className="cursor-pointer hover:bg-gray-200 px-2 rounded">
                                    <h1>Déconnexion</h1>
                                </div>
                            </div>
                        }
                    </div>



                    <div className="flex justify-between mt-8">
                        <h1 className="text-[20px] font-semibold">Boutiques</h1>
                        <button onClick={() => handleShowModal()} className="text-white cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 font-bold focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex text-[14px] items-center justify-center">
                            Ajouter une boutique
                        </button>
                    </div>

                    <div className="mt-10">


                        {loadingSpinner
                            ? <div className="flex justify-center items-center content-center">
                                <svg className="w-11 h-11 animate-spin mt-4 mb-4" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#000000" />
                                    <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z" fill="#000000" />
                                </svg>
                            </div>
                            : <>
                                {Object.keys(stores).length === 0 &&
                                    <div className="flex flex-col w-full mt-14">
                                        <div className="flex justify-center ml-5 mb-4">
                                            <img src="../../no_data.png" width={100} />
                                        </div>
                                        <div className="flex justify-center">
                                            <span className="text-gray-500 text-center">Aucune boutique n'a été trouvée, cliquez sur le bouton «Ajouter une boutique» pour commencez !</span>
                                        </div>
                                    </div>
                                }
                            </>
                        }

                        {Object.keys(stores)
                            .filter(key => stores[key]?.deployed === true)
                            .map((key, idx) => (
                                <div key={idx} onClick={() => handleChooseStore(stores[key]?._id)} className="border-[1px] border-[#efefef] p-3 rounded cursor-pointer mt-4">
                                    <div className="flex justify-between">
                                        <div className="flex flex-row">
                                            <div className="w-[42px] h-[42px] bg-[#f6f6f7] rounded-full flex justify-center items-center">
                                                <img className="w-[25px]" src='/market.svg' />
                                            </div>
                                            <div className="ml-3">
                                                <div className="flex flex-row">
                                                    <h1 className="font-semibold">{stores[key]?.storeInfo?.name}</h1>
                                                </div>
                                                <p className="text-[#717679] text-[13px]">{stores[key]?._id}</p>
                                            </div>
                                        </div>
                                        <div className="flex items-center">
                                            <img className="w-[25px]" src='/arrow.svg' />
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>


                {modal &&
                    <>
                        <div style={{ zIndex: 999 }}
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
                        >
                            <div className="relative w-1/3 my-6 mx-auto">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-3xl font-semibold">
                                            {!loadingSpinner2 ? 'Ajouter une boutique' : 'Création de la boutique ....'}
                                        </h3>
                                    </div>


                                    {loadingSpinner2 &&
                                        <>
                                            <div className="flex justify-center items-center content-center">
                                                <svg className="w-11 h-11 animate-spin mt-4 mb-4" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24" fill="none">
                                                    <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#000000" />
                                                    <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z" fill="#000000" />
                                                </svg>
                                            </div>
                                        </>
                                    }


                                    {!loadingSpinner2 &&
                                        <div>
                                            <div className="px-8 py-8">
                                                <div>
                                                    <label htmlFor="lastname">Type</label>
                                                    <div className="flex justify-between m-auto mt-2">
                                                        <div onClick={() => setStore({ ...store, type: "e-commerce" })} className={`p-3 border-[1px] rounded cursor-pointer w-full text-center ${store?.type === "e-commerce" && 'bg-indigo-700 text-[white]'}`}>
                                                            E-commerce
                                                        </div>

                                                        <div onClick={() => setStore({ ...store, type: "marketplace" })} className={`p-3 border-[1px] rounded cursor-pointer w-full text-center ml-2 ${store?.type === "marketplace" && 'bg-indigo-700 text-[white]'}`}>
                                                            Marketplace
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="relative w-full mt-4">
                                                    <label className="text-gray-700">
                                                        Nom
                                                    </label>
                                                    <input type="text" onChange={(e) => setStore({ ...store, name: e?.target?.value })} className={`rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2`} placeholder="Milango Jeans" />
                                                </div>
                                            </div>

                                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                <button
                                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                    type="button"
                                                    onClick={() => setModal(false)}
                                                >
                                                    Annuler
                                                </button>
                                                <button
                                                    className="bg-indigo-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                    type="button"
                                                    onClick={() => handleAddStore()}
                                                >
                                                    Créer la boutique
                                                </button>

                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                }


                <NotificationContainer />
            </div>
        </>
    );
};

export default Stores;