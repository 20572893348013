import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { editOrder, getOrderById, printLabel } from "../actions/orders";
import { getUserById } from "../actions/users";
import Moment from 'moment';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'; // Assurez-vous d'importer Leaflet
import Select from 'react-select';
import { PDFDownloadLink } from '@react-pdf/renderer'
import Pdf from '../utils/pdf/pdf';
import { getStore } from "../actions/stores";
import { NotificationManager } from 'react-notifications';
import status from '../utils/status.json';
import jsPDF from 'jspdf';
import { IMG_LINK } from "../config/key";

const DetailAbandonnedCart = () => {
    let location = useLocation();

    const id = location?.pathname?.split('/')[2];
    const [order, setOrder] = useState({})
    const [user, setUser] = useState({})
    const navigate = useNavigate();

    const customIcon = L.icon({
        iconUrl: '../../marker-icon-2x.png',
        iconSize: [20, 30],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32]
    });

    const daysOfWeekFr = {
        "MON": "Lundi",
        "TUE": "Mardi",
        "WED": "Mercredi",
        "THU": "Jeudi",
        "FRI": "Vendredi",
        "SAT": "Samedi",
        "SUN": "Dimanche"
    };

    useEffect(() => {
        setOrder(location?.state?.order)
        setUser(location?.state?.order.user)
    }, [])

    return (
        <div className="container-margin">

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-4 md:gap-4 lg:gap-4 mt-6">
                <div className="col-span-2">
                    <div className="flex justify-between w-full">
                        <div className="w-full rounded bg-white shadow p-6">
                            <div className="flex flex-wrap justify-between">
                                <div>
                                    <div>

                                        <h1 className="text-3xl font-semibold"> Pannier abandonné {user?.name} {user?.lastname}</h1>
                                    </div>
                                    <p className="text-sm text-[#6d7175] opacity-[.45] font-semibold mt-2">{Moment(order?.createdAt).format("LL")} à {Moment(order?.createdAt).format("LT")}</p>
                                </div>



                            </div>

                            <hr className="mt-5" />

                            <div className="grid grid-cols-1 lg:grid-cols-4">
                                <div className="mt-5">

                                    <div>

                                        <div className="opacity-[.45] font-semibold text-sm text-[#6d7175] mb-1">
                                            Client
                                        </div>

                                        <div className="break-words ">
                                            <span onClick={() => navigate("/edit/user/" + order?.userId)} className="border-dotted border-b-2 border-[#c4cdd5] cursor-pointer">{user?.name} {user?.lastname}</span>
                                        </div>
                                    </div>

                                    <div>

                                        <div className="opacity-[.45] font-semibold text-sm text-[#6d7175] mt-4">
                                            Adresse mail
                                        </div>

                                        <div className="break-words">
                                            {user?.email}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <div className="opacity-[.45] font-semibold text-sm text-[#6d7175] mb-1">
                                        Téléphone
                                    </div>
                                    <div>
                                        {user?.phone}
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <div className="opacity-[.45] font-semibold text-sm text-[#6d7175] mb-1">
                                        Livraison
                                    </div>
                                    <div>
                                        {order?.delivery?.relayId
                                            ? <>
                                                {order?.delivery?.relayPointInfo?.Address
                                                    ? <>
                                                        <div>{order?.delivery?.relayPointInfo?.Address?.Name1}</div>
                                                        <div>{order?.delivery?.relayPointInfo?.Address?.Street}</div>
                                                        <div>{order?.delivery?.relayPointInfo?.Address?.City} {order?.delivery?.relayPointInfo?.Address?.ZIPCode}, {order?.delivery?.relayPointInfo?.Address?.CountryCode}</div>
                                                    </>
                                                    : <>
                                                        <div>{order?.delivery?.relayPointInfo[0]?.LgAdr1}</div>
                                                        <div>{order?.delivery?.relayPointInfo[0]?.LgAdr2}</div>
                                                        <div>{order?.delivery?.relayPointInfo[0]?.LgAdr3}</div>
                                                        <div>{order?.delivery?.relayPointInfo[0]?.LgAdr4}</div>
                                                        <div>{order?.delivery?.relayPointInfo[0]?.Ville} {order?.delivery?.relayPointInfo[0]?.CP}, {order?.delivery?.relayPointInfo[0]?.Pays}</div>
                                                    </>
                                                }
                                            </>

                                            : order?.shippingAddress ? <>
                                                {order?.shippingAddress?.address} <br /> {order?.shippingAddress?.postalCode} {order?.shippingAddress?.city}, {order?.shippingAddress?.country}
                                            </> : <></>
                                        }
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <div className="opacity-[.45] font-semibold text-sm text-[#6d7175] mb-1">
                                        Facturation
                                    </div>
                                    {order?.billingAddress ? <div>
                                        {order?.billingAddress?.address} <br /> {order?.billingAddress?.postalCode} {order?.billingAddress?.city}, {order?.billingAddress?.country}
                                    </div> : <></>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between w-full mt-4">
                        <div className="w-full rounded bg-white shadow">
                            <h1 className="font-semibold mb-10 text-xl px-6 pt-6">Récapitulatif</h1>

                            {order?.item &&
                                <div className="ppx-0 md:px-6 lg:px-6 xl:px-6">
                                    <table className="min-w-full bg-white mt-10">
                                        <thead>
                                            <tr className="w-full h-16 border-gray-300 border-b py-8">
                                                <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Article</th>
                                                <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold"></th>
                                                <th className="text-gray-600 pr-6 text-right text-sm tracking-normal leading-4 font-semibold whitespace-nowrap">Prix unitaire</th>
                                                <th className="text-gray-600 pr-6 text-right text-sm tracking-normal leading-4 font-semibold">Quantité</th>
                                                <th className="text-gray-600 text-right text-sm tracking-normal leading-4 font-semibold whitespace-nowrap">Prix total</th>
                                            </tr>
                                        </thead>

                                        {Object.keys(order?.item).map((key, index) => (
                                            <tbody key={index}>
                                                <tr key={id} className="md:h-24 lg:h-24 xl:h-24 border-gray-300 border-b cursor-pointer">
                                                    <td className="pr-6 whitespace-no-wrap">
                                                        <div className="flex justify-between" >
                                                            <div className="flex items-center responsive-thead hidden">
                                                                <span className="font-semibold text-sm">Image du produit</span>
                                                            </div>
                                                            <div className="flex items-center">
                                                                {order?.item[key]?.itemRef?.images?.thumbnailId || order?.item[key]?.itemRef?.images?.image1Id ? (
                                                                    <img
                                                                        src={
                                                                            IMG_LINK +
                                                                            (order?.item[key]?.itemRef?.images?.thumbnailId || order?.item[key]?.itemRef?.images?.image1Id)
                                                                        }
                                                                        width={50}
                                                                        alt="produit"
                                                                    />
                                                                ) : (
                                                                    <img src="../../placeholder.jpeg" width={50} />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="pr-6 whitespace-no-wrap pr-40 text-right">
                                                        <div className="flex justify-between">
                                                            <span className="responsive-thead hidden font-semibold text-sm">Nom</span>
                                                            {order?.item[key]?.name}
                                                        </div>
                                                    </td>
                                                    <td className="pr-6 whitespace-no-wrap text-right">
                                                        <div className="flex justify-between">
                                                            <span className="responsive-thead hidden font-semibold text-sm">Prix unitaire</span>
                                                            {order?.item[key]?.priceWithDiscount?.toFixed(2) || order?.item[key]?.rebatedPrice?.toFixed(2) || order?.item[key]?.priceWithoutTaxe?.toFixed(2) || order?.item[key]?.price?.toFixed(2)}
                                                        </div>
                                                    </td>
                                                    <td className="whitespace-no-wrap text-center">
                                                        <div className="flex justify-between">
                                                            <span className="responsive-thead hidden font-semibold text-sm">Quantité</span>
                                                            {order?.item[key]?.quantity}
                                                        </div>
                                                    </td>

                                                    <td className="whitespace-no-wrap text-right">
                                                        <div className="flex justify-between">
                                                            <span className="responsive-thead hidden font-semibold text-sm">Prix total</span>
                                                            {order?.item[key]?.cartPriceWithoutTaxe?.toFixed(2)} €
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        ))}

                                    </table>
                                </div>
                            }

                            <div className="px-6 pb-6">
                                <div className="flex justify-between items-center mt-10">

                                    <div className="flex flex-col">
                                        <div className="text-grey-90 inter-small-regular">
                                            Code Promo
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="inter-small-regular mr-3 ">
                                            {order?.discountCode || "Aucun"}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center mt-4">

                                    <div className="flex flex-col">
                                        <div className="text-grey-90 inter-small-regular">
                                            Prix HT
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="inter-small-regular mr-3">
                                            {order?.cartPriceWithoutTaxe?.toFixed(2)}
                                        </div>
                                        <div className="opacity-[.45] font-semibold text-[#6d7175]">
                                            €
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between items-center mt-4">

                                    <div className="flex flex-col">
                                        <div className="text-grey-90 inter-small-regular">
                                            Prix TTC
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="inter-small-regular mr-3">
                                            {order?.discountedPrice?.toFixed(2)}
                                        </div>
                                        <div className="opacity-[.45] font-semibold text-[#6d7175]">
                                            €
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between mt-4 items-center">
                                    <div className="flex flex-col">
                                        <div className="inter-small-regular">
                                            Livraison
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="inter-small-regular mr-3">
                                            {order?.deliveryPrice?.toFixed(2)}
                                        </div>
                                        <div className="opacity-[.45] font-semibold text-[#6d7175]">
                                            €
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-between mt-4 items-center">
                                    <div className="flex flex-col">
                                        <div className="font-semibold">
                                            Total
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="font-semibold text-2xl">
                                            {order?.totalPrice?.toFixed(2) || order?.discountedPrice?.toFixed(2)}
                                        </div>
                                        <div className="opacity-[.45] font-semibold text-[#6d7175] mt-1 ml-3">
                                            €
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between w-full mt-4">
                        <div className="w-full rounded bg-white shadow">
                            <h1 className="font-semibold mb-4 text-xl px-6 pt-6">Livraison</h1>
                            <hr />

                            <div className="mt-10 px-6 pb-6">

                                {order?.delivery?.delivery?.relayPoint === true
                                    ?
                                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 ">
                                        <div className="mr-5 mb-8">
                                            <div>{order?.delivery?.delivery?.shipperName}</div>

                                            <div className="mb-6">{order?.delivery?.delivery?.description}</div>

                                            {order?.delivery?.relayPointInfo[0] !== undefined

                                                ? <>
                                                    <div>{order?.delivery?.relayPointInfo[0]?.LgAdr1}</div>
                                                    <div>{order?.delivery?.relayPointInfo[0]?.LgAdr2}</div>
                                                    <div>{order?.delivery?.relayPointInfo[0]?.LgAdr3}</div>
                                                    <div>{order?.delivery?.relayPointInfo[0]?.LgAdr4}</div>
                                                    <div>{order?.delivery?.relayPointInfo[0]?.Ville} {order?.delivery?.relayPointInfo[0]?.CP}, {order?.delivery?.relayPointInfo[0]?.Pays}</div>
                                                </>

                                                : <>
                                                    <div>{order?.delivery?.relayPointInfo?.Address?.Name1}</div>
                                                    <div>{order?.delivery?.relayPointInfo?.Address?.Street}</div>
                                                    <div>{order?.delivery?.relayPointInfo?.Address?.City} {order?.delivery?.relayPointInfo?.Address?.ZIPCode}, {order?.delivery?.relayPointInfo?.Address?.CountryCode}</div>


                                                </>
                                            }
                                        </div>

                                        {order?.delivery?.relayPointInfo[0]?.URL_Plan &&
                                            <div className="z-0 flex justify-end">
                                                <iframe src={order?.delivery?.relayPointInfo[0]?.URL_Plan}></iframe>
                                            </div>
                                        }

                                        {order?.delivery?.relayPointInfo?.Address?.Name1 &&
                                            <div className="z-0 flex justify-end">
                                                <MapContainer
                                                    center={[order?.delivery?.relayPointInfo?.Location?.Latitude, order?.delivery?.relayPointInfo?.Location?.Longitude]}
                                                    zoom={13}
                                                    className="z-[0]"
                                                    scrollWheelZoom={true}
                                                    id="mapGLS"
                                                >
                                                    <TileLayer
                                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    />
                                                    <Marker
                                                        icon={customIcon}
                                                        key={order?.delivery?.ParcelShopID}
                                                        position={[Number(order?.delivery?.relayPointInfo?.Location?.Latitude), Number(order?.delivery?.relayPointInfo?.Location?.Longitude)]}
                                                    >
                                                        <Popup className="m-0">
                                                            <h1 className="mb-2">{order?.delivery?.relayPointInfo?.Address?.Name1}</h1>
                                                            <h1>{order?.delivery?.relayPointInfo?.Address?.Street}</h1>
                                                            <h1 className="mb-2">{order?.delivery?.relayPointInfo?.Address?.City} {order?.delivery?.relayPointInfo?.Address?.ZIPCode}</h1>

                                                            <h1 className="mb-1">Horaires :</h1>
                                                            <ul>
                                                                {Object.keys(order?.delivery?.relayPointInfo?.WorkingDay)?.map((key) => (
                                                                    <li key={key}>
                                                                        {daysOfWeekFr[order?.delivery?.relayPointInfo?.WorkingDay[key].DayOfWeek]}: {Moment(order?.delivery?.relayPointInfo?.WorkingDay[key]?.OpeningHours?.OpeningHours[0]?.From).format("HH:mm")} - {Moment(order?.delivery?.relayPointInfo?.WorkingDay[key]?.OpeningHours?.OpeningHours[0]?.To).format("HH:mm")}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </Popup>
                                                    </Marker>

                                                </MapContainer>
                                            </div>
                                        }
                                    </div>

                                    : <>
                                        <>
                                            {order?.delivery?.delivery?.name || "Aucune livraison sélectionnée"}
                                        </>


                                    </>
                                }


                            </div>
                        </div>

                    </div>
                </div>

                <div>

                </div>
            </div>

        </div>
    );
};

export default DetailAbandonnedCart;
