import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { changeIntegrationStatus, getAllIntegrations } from "../actions/integrations";
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';

const Integrations = () => {
    const navigate = useNavigate();
    let dispatch = useDispatch();

    const [allIntegrations, setAllIntegrations] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [idIntegration, setIdIntegration] = useState();
    const [allFiltredIntegrations, setAllFiltredIntegrations] = useState([]);
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const optionsSelect = [
        { label: "Tout", value: "all" },
        { label: "Paiement", value: "Paiement" },
        { label: "Recherche", value: "Recherche" },
        { label: "Communication", value: "Communication" },
        { label: "Taxes", value: "Taxes" },
        { label: "Livraison", value: "Livraison" },
        { label: "Contenu", value: "Contenu" },
        { label: "Avis", value: "Avis" },
        { label: "Cookies", value: "Cookies" },
        { label: "Tracking", value: "Tracking" },
    ];

    const [filterIntegration, setFilterIntegration] = useState(optionsSelect[0])

    const handleAddPlugin = () => {
        navigate(`/add/integration`);
    }

    useEffect(() => {
        setLoadingSpinner(true);
        dispatch(getAllIntegrations()).then((res) => {
            setLoadingSpinner(false);
            setAllIntegrations(res);
            setAllFiltredIntegrations(res);
        });
    }, []);

    const handleDisableIntegration = (id) => {
        const data = {
            id: id,
            status: 'disabled'
        }

        dispatch(changeIntegrationStatus(data)).then((res) => {
            dispatch(getAllIntegrations()).then((res) => {
                setAllIntegrations(res);
                setAllFiltredIntegrations(res);
            });

            setShowConfirmModal(false);

            NotificationManager.success("Votre intégration a été désactiver avec succès !", 'Succès');
        }).catch((err) => {
            NotificationManager.error(err?.response?.data?.error, 'Erreur')
        })
    }

    const handleShowConfirmModal = (id) => {
        setIdIntegration(id);
        setShowConfirmModal(true);

        if (showConfirmModal) {
            setShowConfirmModal(false);
        }
    }

    useEffect(() => {
        if (filterIntegration.value === 'all') {
            setAllFiltredIntegrations(allIntegrations);
        } else {
            setAllFiltredIntegrations(allIntegrations.filter((it) => it?.keyWords.includes(filterIntegration?.value)));
        }


    }, [filterIntegration])

    return (
        <div className="container-margin">

            {/* MODAL CONTENT */}
            {showConfirmModal &&
                <>
                    <div>
                        <div onClick={() => setShowConfirmModal(false)} className="myModal backdrop-blur-sm"></div>
                        <div className="center-absolutely bg-[white] rounded w-4/12 mt-10">

                            <div className="flex row justify-between">
                                <div className="px-6 mt-4">
                                    <h1 className="text-xl font-semibold mb-1">Désactiver l'intégration</h1>
                                    <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mb-4">Attention en désactivant cette intégration, nous supprimerons toutes les données liées à cette intégration.</p>
                                </div>

                                <div className="px-6 mt-4">
                                    <span data-state="closed" className="cursor-pointer" onClick={() => setShowConfirmModal(false)}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex text-grey-50">
                                            <path d="M15 5L5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M5 5L15 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-indigo-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => setShowConfirmModal(false)}
                                >
                                    Annuler
                                </button>
                                <button
                                    className="bg-red-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => handleDisableIntegration(idIntegration)}
                                >
                                    Désactiver
                                </button>
                            </div>


                        </div>
                    </div>
                </>
            }

            <div className="flex items-center justify-between w-full">
                <div className="w-full lg:w-3/3">
                    <div className="py-0">
                        <div className="mx-auto bg-white shadow rounded">
                            <div className="flex grid grid-cols-1 md:grid-cols-2">
                                <h1 className="text-xl font-semibold mt-1 ml-2 px-6 pt-6">Intégrations</h1>

                                <div className="flex flex-col lg:flex-row p-4 lg:p-8 justify-between items-start lg:items-stretch w-full">
                                    <div className="w-full lg:w-1/3 flex flex-col lg:flex-row items-start lg:items-center"></div>
                                    <div className="w-full lg:w-2/3 flex flex-col lg:flex-row items-start lg:items-center justify-end">
                                        <div className="lg:ml-6 flex items-center">
                                            <div className="flex justify-end">
                                                <div className="flex flex-row">
                                                    <span className="mt-[.4rem] mr-2">Filtre: </span>
                                                    <div className="w-[200px]">
                                                        <Select
                                                            className="border-transparent focus:border-transparent focus:ring-0"
                                                            options={optionsSelect}
                                                            onChange={(item) => setFilterIntegration(item)}
                                                            defaultValue={optionsSelect[0]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <button onClick={() => handleAddPlugin()} className="text-white whitespace-nowrap ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                                                Ajouter une intégration
                                            </button>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            {loadingSpinner &&
                                <div className="flex justify-center items-center content-center">
                                    <svg className="w-11 h-11 animate-spin mt-14 mb-14" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#000000" />
                                        <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z" fill="#000000" />
                                    </svg>
                                </div>
                            }


                            {allIntegrations?.filter(it => it.status === "enabled").length <= 0
                                ? <></>

                                : allFiltredIntegrations?.filter(it => it.status === "enabled").length <= 0
                                    ?


                                    <div className="py-20">
                                        <div className="flex justify-center mb-4 ml-8">
                                            <img src="../../no_data.png" width={150} />
                                        </div>
                                        <div className="flex justify-center">
                                            <span className="text-gray-500 text-center text-[18px]">Aucune intégration ne correspond à ce filtre.</span>
                                        </div>
                                    </div>


                                    : <div className="px-6">

                                        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
                                            {Object.keys(allFiltredIntegrations).map((key) => (
                                                allFiltredIntegrations[key]?.status === "enabled" &&
                                                <div key={key} className='mb-4'>
                                                    <div className="shadow-sm border-[2px] border-[#f1f6fc] rounded">
                                                        <div className="p-4">
                                                            <div onClick={() => navigate('/integration/' + allFiltredIntegrations[key]?._id)} className="flex flex-row cursor-pointer">

                                                                <img width={50} className='mt-[.2rem]' src={allFiltredIntegrations[key]?.image} />

                                                                <h1 className="text-[20px] font-[500] mt-4 ml-2 cursor-pointer">
                                                                    {allFiltredIntegrations[key]?.name}
                                                                </h1>
                                                            </div>

                                                            <div className="mt-4">
                                                                {allFiltredIntegrations[key]?.keyWords.map((it, key) => (
                                                                    <span key={key} style={{ backgroundColor: '#e5e7eb', padding: 4, marginRight: 7 }}>{it}</span>
                                                                ))}
                                                            </div>

                                                            <p className="mt-4">
                                                                {allFiltredIntegrations[key]?.description}
                                                            </p>
                                                        </div>

                                                        <div className="bg-[#f1f6fc] p-4 flex justify-between">
                                                            <span onClick={() => navigate('/integration/' + allFiltredIntegrations[key]?._id)} className="hover:underline mt-3 cursor-pointer">Configurer →</span>
                                                            
                                                            {allFiltredIntegrations[key]?.name === "Stripe"                                                           
                                                                ? <button onClick={() => handleShowConfirmModal(allFiltredIntegrations[key]?._id)} className="h-8 px-3 m-2 border-[#283341] hover:bg-[#283341] border-[1px] rounded text-[black] hover:text-[white] cursor-not-allowed" disabled>Désactiver</button>
                                                                : <button onClick={() => handleShowConfirmModal(allFiltredIntegrations[key]?._id)} className="h-8 px-3 m-2 border-[#283341] hover:bg-[#283341] border-[1px] rounded text-[black] hover:text-[white]">Désactiver</button>
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Integrations;
