import apiStripe from "../api/subscription";
import { GET_ALL_CARDS, GET_ACTUAL_SUBSCRIPTION } from "./types";

// ADD CARD
export const addCard = (data) =>
    async (dispatch) => {
        return apiStripe
            .addCard(data)
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_CARDS, payload: result.data })
                    return result?.data
                }
            })
    };

// GET ALL CARDS
export const getAllCards = () =>
    async (dispatch) => {
        return apiStripe
            .getAllCards()
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_CARDS, payload: result.data })
                    return result?.data?.data
                }
            })
    };

// DELETE CARD BY ID
export const deleteCardById = (id) =>
    async (dispatch) => {
        return apiStripe
            .deleteCardById(id)
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_CARDS, payload: result.data })
                    return result?.data
                }
            })
    };

// GET ACTUAL SUBSCRIPTION
export const getActualSubscription = (storeId) =>
    async (dispatch) => {
        return apiStripe
            .getActualSubscription(storeId)
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ACTUAL_SUBSCRIPTION, payload: result.data })
                    return result?.data
                }
            })
    };

// GET ALL PLANS SUBSCRIPTION
export const getAllPlans = async () => {
    return apiStripe
        .getAllPlans()
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};

// GET PLAN BY ID
export const getPlanById = async (id) => {
    return apiStripe
        .getPlanById(id)
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};

// CHANGE SUBSCRIPTION
export const changeSubscription = async (id) => {
    return apiStripe
        .changeSubscription(id)
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};

// GET CUSTOMER
export const getCustomer = async () => {
    return apiStripe
        .getCustomer()
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};

// SET DEFAULT CARD
export const setDefaultCard = async (data) => {
    return apiStripe
        .setDefaultCard(data)
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};