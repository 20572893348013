import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

const AddMenu = () => {
    const navigate = useNavigate();

    const [choosePage, setChoosePage] = useState(true);
    const [chooseLocalisation, setChooseLocalisation] = useState(false);
    const [chooseAnnouncement, setChooseAnnouncement] = useState(false);
    const [chooseCustomLink, setChooseCustomLink] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [data, setData] = useState([]);

    const handleChoosePage = () => {
        setChoosePage(true);

        if (choosePage)
            setChoosePage(false);

    }

    const subItemPage = [
        { id: 0, title: "Accueil" },
        { id: 1, title: "Conditions générales de Services" },
        { id: 2, title: "Conditions générales d'utilisation du site Internet" },
        { id: 3, title: "Politique de confidentialité" },
        { id: 4, title: "Foires aux questions" },
        { id: 5, title: "À propos de Marketplace" },
    ];

    const subItemLocalisation = [
        { title: "Valais (VS)" },
        { title: "Genève (GE)" },
        { title: "Argovie (AG)" },
        { title: "Grisons (GR)" },
    ];

    const subItemAnnouncement = [
        { title: "Grille pain" },
        { title: "Tondeuse à gazon" },
        { title: "Tondeuse thermique" },
        { title: "Motobineuse électrique" },
        { title: "Téléphone iPhone 7" },
        { title: "Machine a cafe Rancilio" },
    ];

    const handleChooseLocalisation = () => {
        setChooseLocalisation(true);

        if (chooseLocalisation)
            setChooseLocalisation(false);

    }

    const handleChooseAnnouncement = () => {
        setChooseAnnouncement(true);

        if (chooseAnnouncement)
            setChooseAnnouncement(false);
    }

    const handleChooseCustomLink = () => {
        setChooseCustomLink(true);

        if (chooseCustomLink)
            setChooseCustomLink(false);
    }

    const arr = [];

    const handleCheck = (item) => {
        arr.push(subItemPage[item]);
    }

    const handleApplyMenu = () => {
        setShowMenu(true);
        setData(arr);

        const ele = document.getElementsByName('checkbox');
        for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == 'checkbox')
                ele[i].checked = false;

        }
    }

    return (
        <div className="px-40">
            <div className="flex row">
                <button onClick={() => navigate('/settings/menus')} className="text-white cursor-pointer border-[1.2px] border-[#babfc3] bg-transparent transition duration-150 ease-in-out w-10 h-10 rounded flex items-center justify-center ">
                    <svg viewBox="0 0 20 20" height={25} width={25} fill='#5c5f62' focusable="false" aria-hidden="true"><path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path></svg>
                </button>

                <h1 className="text-xl font-semibold mt-1 ml-4">Ajouter un menu</h1>
            </div>

            <div className="mt-10">
                <div className="col-span-2">
                    <div className="flex justify-between w-full">
                        <div className="w-full rounded bg-white shadow p-6">

                            {/* TITLE INPUT */}
                            <div className="relative w-full">
                                <label className="text-gray-700">
                                    Titre
                                </label>
                                <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="email" placeholder="Titre du menu" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 grid grid-cols-4 ">
                <div className="col-span-2 mr-4">
                    <div className="flex justify-between w-full">
                        <div className="w-full rounded bg-[#f9fafb] shadow p-6">

                            {/* TITLE INPUT */}
                            <div className="relative w-full">
                                <ul>
                                    <div className="flex row justify-between cursor-pointer" onClick={handleChoosePage}>
                                        <li>Page</li>
                                        <div>
                                            <svg className={`w-6 h-6 ml-1 mt-[.1rem] ${choosePage === true && "arrow-sub-menu"} `} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </div>

                                    {choosePage &&
                                        <>
                                            <div className="bg-white p-4 border rounded mt-2">
                                                {subItemPage.map(page => (

                                                    <div>
                                                        <input id="checkbox" type="checkbox" name="checkbox" onChange={() => handleCheck(page.id)} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                        <label className="text-gray-700 ml-2">{page.title}</label>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="flex justify-end mt-2">
                                                <button onClick={handleApplyMenu} className="text-white cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-2 h-8 rounded flex items-center justify-center">
                                                    Ajouter au menu
                                                </button>
                                            </div>

                                            <div className="w-full border-[.5px] border-gray-200 mb-4 mt-4" />
                                        </>
                                    }


                                    <div className="flex row mt-4 cursor-pointer justify-between" onClick={handleChooseLocalisation}>
                                        <li>Localisation</li>
                                        <div>
                                            <svg className={`w-6 h-6 ml-1 mt-[.1rem] ${chooseLocalisation === true && "arrow-sub-menu"} `} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </div>

                                    {chooseLocalisation &&
                                        <>
                                            <div className="bg-white p-4 border rounded mt-2">
                                                {subItemLocalisation.map(page => (

                                                    <div>
                                                        <input type="checkbox" onChange={() => console.log("checkbox")} value="" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                        <label className="text-gray-700 ml-2">{page.title}</label>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="flex justify-end mt-2">
                                                <button className="text-white cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-2 h-8 rounded flex items-center justify-center">
                                                    Ajouter au menu
                                                </button>
                                            </div>

                                            <div className="w-full border-[.5px] border-gray-200 mb-4 mt-4" />
                                        </>
                                    }



                                    <div className="flex row mt-4 cursor-pointer justify-between" onClick={handleChooseAnnouncement}>
                                        <li>Annonce</li>
                                        <div>
                                            <svg className={`w-6 h-6 ml-1 mt-[.1rem] ${chooseAnnouncement === true && "arrow-sub-menu"} `} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </div>

                                    {chooseAnnouncement &&
                                        <>
                                            <div className="bg-white p-4 border rounded mt-2">
                                                {subItemAnnouncement.map(page => (

                                                    <div>
                                                        <input type="checkbox" onChange={() => console.log("checkbox")} value="" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                                        <label className="text-gray-700 ml-2">{page.title}</label>
                                                    </div>
                                                ))}

                                            </div>
                                            <div className="flex justify-end mt-2">
                                                <button className="text-white cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-2 h-8 rounded flex items-center justify-center">
                                                    Ajouter au menu
                                                </button>
                                            </div>

                                            <div className="w-full border-[.5px] border-gray-200 mb-4 mt-4" />
                                        </>
                                    }

                                    <div className="flex row mt-4 cursor-pointer justify-between" onClick={handleChooseCustomLink}>
                                        <li>Lien personnalisé</li>
                                        <div>
                                            <svg className={`w-6 h-6 ml-1 mt-[.1rem] ${chooseCustomLink === true && "arrow-sub-menu"} `} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </div>

                                    {chooseCustomLink &&
                                        <>
                                            <div className="bg-white p-4 border rounded mt-2">
                                                <div>
                                                    <label className="text-gray-700">
                                                        Lien
                                                    </label>
                                                    <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="email" placeholder="Titre du menu" />
                                                </div>

                                                <div className="mt-4">
                                                    <label className="text-gray-700">
                                                        Texte du lien
                                                    </label>
                                                    <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="email" placeholder="Titre du menu" />
                                                </div>
                                            </div>

                                            <div className="flex justify-end mt-2">
                                                <button className="text-white cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-2 h-8 rounded flex items-center justify-center">
                                                    Ajouter au menu
                                                </button>
                                            </div>

                                        </>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-span-2">
                    <div className="flex justify-between w-full">
                        <div className="w-full rounded bg-white shadow p-6">
                            <h1 className="font-semibold mb-4">Éléments du menu</h1>

                            {/* TITLE INPUT */}
                            <div className="relative w-full">
                                {showMenu &&
                                    <>
                                        {data.map(item => (
                                            <div className="max-w-sm bg-white border border-gray-200 shadow-md mt-2 mb-4">
                                                <div className="flex row p-3 border-b-2 justify-between">
                                                    <span>{item.title}</span>
                                                    <div className="flex row">
                                                        <span>Page</span>
                                                        <svg className={`w-6 h-6 ml-1 mt-[.1rem]`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                        </svg>
                                                    </div>

                                                </div>

                                                <div className="p-4">
                                                    <label className="text-gray-700">
                                                        Label
                                                    </label>
                                                    <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="email" placeholder={item.title} />

                                                    <div className="flex row mt-4 mb-4">
                                                        <div className="">
                                                            <label className="text-gray-700">
                                                                className
                                                            </label>

                                                            <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="email" placeholder={item.title} />
                                                        </div>
                                                        <div className="ml-3">
                                                            <label className="text-gray-700">
                                                                Cible
                                                            </label>

                                                            <div className="mt-2">
                                                                <select id="countries" defaultValue={""} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                                                    <option value="">Normal</option>
                                                                    <option value="US">Ouvrir un nouvel onglet</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <span className="hover:underline text-red-500 cursor-pointer">Supprimer</span>
                                                </div>
                                            </div>
                                        ))}

                                    </>
                                }
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            {/* FIXED SAVE BTN */}
            <div className='fixed bottom-[1rem] right-[1rem] p-2 bg-[#4338ca] rounded opacity-[.80] hover:opacity-[1] cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" height={32} width={32}><path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" /></svg>
            </div>
        </div>
    );
};
export default AddMenu;
