import { apiClient } from './ApiClient'

function getAllBrands() {
    return apiClient().get('/admin/brand/')
}

function deleteBrandById(id) {
    return apiClient().delete('/admin/brand/' + id)
}

function getBrandById(id) {
    return apiClient().get('/admin/brand/' + id)
}

function editBrand(data) {
    return apiClient().patch('/admin/brand/', data)
}

function addBrand(data) {
    return apiClient().post('/admin/brand/', data)
}

export default {
    getAllBrands,
    deleteBrandById,
    getBrandById,
    editBrand,
    addBrand
}
