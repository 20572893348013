import React, { useState } from "react";
import Pagination from '../components/Pagination';

const Roles = () => {

    const [showModal, setShowModal] = useState(false);
    const [dropdown, setDropdown] = useState(false);

    function handleShowModal() {
        setShowModal(true);

        if (showModal)
            setShowModal(false);
    }

    const rolesObj = [
        { id: 1, name: 'Administrateur', create_at: "2022-29-08" },
        { id: 2, name: 'Modérateur', create_at: "2022-22-08" },
        { id: 3, name: 'Membre', create_at: "2022-26-08" },
    ]


    // PAGINATION STATE AND CONSTANT
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    const currentRecords = rolesObj.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(rolesObj.length / recordsPerPage)

    const handleDropdownForeach = (row) => {
        setDropdown(row);
    }

    return (
        <div>
            <div onClick={() => setDropdown(false)} className={`${dropdown !== false && "mydropdown"}`}></div>



            {/* BREADCRUMBS */}
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                    <a href="/" className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-900">
                        <svg width="15px" height="15px" viewBox="0 0 50 50" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.8889 0H8.33333C6.04167 0 3.95833 0.936111 2.44722 2.44722C0.936111 3.95833 0 6.04167 0 8.33333V13.8889C0 16.1806 0.936111 18.2639 2.44722 19.775C3.95833 21.2861 6.04167 22.2222 8.33333 22.2222H13.8889C16.1806 22.2222 18.2639 21.2861 19.775 19.775C21.2861 18.2639 22.2222 16.1806 22.2222 13.8889V8.33333C22.2222 6.04167 21.2861 3.95833 19.775 2.44722C18.2639 0.936111 16.1806 0 13.8889 0Z" />
                            <path d="M41.6667 0H36.1112C33.8195 0 31.7362 0.936111 30.2251 2.44722C28.7139 3.95833 27.7778 6.04167 27.7778 8.33333V13.8889C27.7778 16.1806 28.7139 18.2639 30.2251 19.775C31.7362 21.2861 33.8195 22.2222 36.1112 22.2222H41.6667C43.9584 22.2222 46.0417 21.2861 47.5528 19.775C49.0639 18.2639 50.0001 16.1806 50.0001 13.8889V8.33333C50.0001 6.04167 49.0639 3.95833 47.5528 2.44722C46.0417 0.936111 43.9584 0 41.6667 0Z" />
                            <path d="M13.8889 27.7778H8.33333C6.04167 27.7778 3.95833 28.7139 2.44722 30.225C0.936111 31.7361 0 33.8194 0 36.1111V41.6667C0 43.9583 0.936111 46.0417 2.44722 47.5528C3.95833 49.0639 6.04167 50 8.33333 50H13.8889C16.1806 50 18.2639 49.0639 19.775 47.5528C21.2861 46.0417 22.2222 43.9583 22.2222 41.6667V36.1111C22.2222 33.8194 21.2861 31.7361 19.775 30.225C18.2639 28.7139 16.1806 27.7778 13.8889 27.7778Z" />
                            <path d="M41.6667 27.7778H36.1112C33.8195 27.7778 31.7362 28.7139 30.2251 30.225C28.7139 31.7361 27.7778 33.8194 27.7778 36.1111V41.6667C27.7778 43.9583 28.7139 46.0417 30.2251 47.5528C31.7362 49.0639 33.8195 50 36.1112 50H41.6667C43.9584 50 46.0417 49.0639 47.5528 47.5528C49.0639 46.0417 50.0001 43.9583 50.0001 41.6667V36.1111C50.0001 33.8194 49.0639 31.7361 47.5528 30.225C46.0417 28.7139 43.9584 27.7778 41.6667 27.7778Z" />
                            <clipPath id="clip0_308_250">
                                <rect width="15px" height="15px" />
                            </clipPath>
                        </svg>
                        <span className="ml-2">Tableau de bord</span>
                    </a>
                </li>
                <li>
                    <div className="flex items-center">
                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                        <a href="/settings" className="ml-1 text-sm font-medium text-gray-500 hover:text-gray-900 md:ml-2 ">Paramètres</a>
                    </div>
                </li>
                <li aria-current="page">
                    <div className="flex items-center">
                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                        <span className="ml-1 text-sm font-medium text-gray-900 md:ml-2z">Rôles</span>
                    </div>
                </li>
            </ol>
            {/* BREADCRUMBS */}

            {showModal &&
                <>
                    <div>
                        <div onClick={() => setShowModal(false)} className="myModal backdrop-blur-sm"></div>
                        <div className="center-absolutely bg-[white] rounded w-4/12 mt-10">

                            <div className="flex row justify-between">
                                <div className="px-6 mt-4">
                                    <h1 className="text-xl font-semibold mb-1">Ajouter un rôle</h1>
                                    <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mb-4">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium </p>
                                </div>

                                <div className="px-6 mt-4">
                                    <span data-state="closed" className="cursor-pointer" onClick={() => setShowModal(false)}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex text-grey-50">
                                            <path d="M15 5L5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M5 5L15 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>

                            <div className="relative p-6 flex-auto">
                                <div className="relative w-full">
                                    <label className="text-gray-700">
                                        Nom du rôle
                                    </label>
                                    <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="f-name" placeholder="Rédacteur" />
                                </div>
                            </div>
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => setShowModal(false)}
                                >
                                    Fermer
                                </button>
                                <button
                                    className="bg-indigo-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => setShowModal(false)}
                                >
                                    Enregistrer
                                </button>
                            </div>


                        </div>
                    </div>
                </>
            }




            <div className="flex items-center justify-between w-full">
                <div className="w-full lg:w-3/3">
                    <div className="py-0">
                        <div className="mx-auto bg-white shadow rounded">

                            <div className="flex row">
                                <h1 className="text-xl font-semibold mt-1 ml-2 cursor-pointer px-6 pt-6">Rôles</h1>
                                <div className="flex flex-col lg:flex-row p-4 lg:p-8 justify-between items-start lg:items-stretch w-full">
                                    <div className="w-full lg:w-1/3 flex flex-col lg:flex-row items-start lg:items-center"></div>
                                    <div className="w-full lg:w-2/3 flex flex-col lg:flex-row items-start lg:items-center justify-end">
                                        <div className="lg:ml-6 flex items-center">
                                            <button className="bg-gray-200 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-gray-300 rounded text-indigo-700 p-4 h-8 flex items-center text-sm">Exporter</button>
                                            <button onClick={handleShowModal} className="text-white ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                                                Ajouter un rôle
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* SEARCH BAR */}
                            <div className="flex row">

                                <div className="flex ml-8 mb-4 mr-2">
                                    <div
                                        className="inter-small-regular transition-color transition-width duration-150 ease-in-out flex text-grey-50 flex items-center mb-1 pl-1 py-1.5 rounded border border-grey-0 min-w-content focus-within:mr-1 focus-within:w-60 focus-within:shadow-input focus-within:border-violet-60 focus-within:bg-grey-5">
                                        <span className="px-2.5 py-0.5">
                                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                                                <title>Search</title>
                                                <path
                                                    d="M20.4696 21.5303C20.7625 21.8232 21.2373 21.8232 21.5302 21.5303C21.8231 21.2374 21.8231 20.7626 21.5302 20.4697L20.4696 21.5303ZM17.1802 16.1197C16.8873 15.8268 16.4125 15.8268 16.1196 16.1197C15.8267 16.4126 15.8267 16.8874 16.1196 17.1803L17.1802 16.1197ZM18.25 11C18.25 15.0041 15.0041 18.25 11 18.25V19.75C15.8325 19.75 19.75 15.8325 19.75 11H18.25ZM11 18.25C6.99594 18.25 3.75 15.0041 3.75 11H2.25C2.25 15.8325 6.16751 19.75 11 19.75V18.25ZM3.75 11C3.75 6.99594 6.99594 3.75 11 3.75V2.25C6.16751 2.25 2.25 6.16751 2.25 11H3.75ZM11 3.75C15.0041 3.75 18.25 6.99594 18.25 11H19.75C19.75 6.16751 15.8325 2.25 11 2.25V3.75ZM21.5302 20.4697L17.1802 16.1197L16.1196 17.1803L20.4696 21.5303L21.5302 20.4697Z"
                                                    fill="currentColor">
                                                </path>
                                            </svg>
                                        </span>
                                        <span className="hidden inline-block">Rechercher</span>
                                        <input type="text" style={{ width: '7rem' }}
                                            className="focus:outline-none mini-search focus:border-none w-full focus:w-50 focus:bg-grey-5 focus:text-grey-90 caret-violet-60"
                                            placeholder="Rechercher..." size="6" />
                                    </div>
                                </div>

                            </div>

                            <div className="w-full overflow-x-scroll xl:overflow-x-hidden">
                                <table className="min-w-full bg-white">
                                    <thead>
                                        <tr className="w-full h-16 border-gray-300 border-b py-8">
                                            <th className="pl-8 text-gray-600 font-normal pr-6 text-left text-sm tracking-normal leading-4">
                                                <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                                            </th>
                                            <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Nom</th>
                                            <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Date de création</th>
                                            <td className="text-gray-600 pr-8 text-left text-sm tracking-normal leading-4 font-semibold">Actions</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentRecords.map(role => (
                                            <tr className="h-20 border-gray-300 border-b hover:bg-gray-100">
                                                <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                    <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                                                </td>
                                                <td className="pr-6 whitespace-no-wrap">
                                                    <div className="flex items-center">
                                                        <div className="w-2 h-2 rounded-full" />
                                                        <div className="text-sm pr-2 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                            <span className="bg-gray-100 p-[.35rem] rounded">{role.name}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">{role.create_at}</td>
                                                <td className="pr-8">
                                                    <div className={`dropdown-content mt-8 absolute right-18 -ml-12 shadow-md z-[999] w-32 ${dropdown === role.id ? "block" : "hidden"}`}>
                                                        <ul className="bg-white shadow rounded py-1">
                                                            <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-edit"></i> Éditer</li>
                                                            <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-clone"></i> Dupliquer</li>
                                                            <li className="cursor-pointer text-red-500 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-trash"></i> Supprimer</li>
                                                        </ul>
                                                    </div>

                                                    <button onClick={() => handleDropdownForeach(role.id)} className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <circle cx={12} cy={12} r={1} />
                                                            <circle cx={12} cy={19} r={1} />
                                                            <circle cx={12} cy={5} r={1} />
                                                        </svg>
                                                    </button>

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="flex row justify-between  p-6 mt-6">
                                <span className="mt-1 text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold">Affichage de {indexOfFirstRecord === 0 ? 1 : indexOfFirstRecord} sur {indexOfLastRecord} de {rolesObj.length} résultats</span>

                                <Pagination
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roles;
