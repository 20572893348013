import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Navbar from "../components/Navbar";
import { useDispatch } from "react-redux";
import { Buffer } from 'buffer';

import "../styles.css";

import Orders from "../routes/Orders";
import AbandonnedCart from "../routes/AbandonnedCart";
import Products from "../routes/Products";
import Dashboard from "../routes/Dashboard";
import Stores from "../routes/auth/Stores";
import AddProduct from "../routes/AddProduct";
import Login from "../routes/auth/Login";
// import Register from "../routes/auth/Register";
import Account from "../routes/Account";
import AddUser from "../routes/AddUser";
import AddStore from '../routes/AddStore';
import AddCollection from "../routes/AddCollection";
import Users from "../routes/Users";
import Settings from "../routes/Settings";
import Regions from "../routes/Regions";
import Discounts from "../routes/Discounts";
import AddDiscount from "../routes/AddDiscount";
// import Teams from "../routes/Teams";
import Permissions from "../routes/Permissions";
import Roles from "../routes/Roles";
import DetailOrder from "../routes/DetailOrder";
import DetailAbandonnedCart from "../routes/DetailAbandonnedCart";

import Devices from "../routes/Devices";
import Taxes from "../routes/Taxes";
import AddCategorie from "../routes/AddCategorie";
import Delivery from "../routes/Delivery";
import AddDelivery from "../routes/AddDelivery";
import Pages from "../routes/Pages";
import AddPage from "../routes/AddPage";
import AddMenu from "../routes/AddMenu";
import Menus from "../routes/Menus";
import { Navigate } from "react-router-dom";
import { logout } from "../actions/auth";
import Integrations from "../routes/Integrations";
import AddIntegration from "../routes/AddIntegration";
import Merchant from "../routes/Merchant";
import AboutIntegration from "../routes/AboutIntegration";
import Payments from "../routes/Payments";
import Domains from "../routes/Domains";
import AddBrand from "../routes/AddBrand";
import AddTax from "../routes/AddTax";
// import Subscription from "../routes/Subscription";
// import Billing from "../routes/Billing";
import PaymentInformation from "../routes/PaymentInformation";
import Languages from "../routes/Languages";
import AddLanguage from "../routes/AddLanguage";
import { getNotifications } from "../actions/notifications";
import AddShipper from "../routes/AddShipper";
import Shipper from "../routes/Shipper";
import ForgotPasswd from "../routes/auth/ForgotPasswd";
import AddTranslation from "../routes/AddTranslation";

import NotFoundPage from "../components/NotFoundPage";

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('token_milango_back_office');
  const tokenBO = localStorage.getItem('back_end_token');

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const authenticate = (component) => {
    return token ? component : <Navigate to="/login" />;
  }

  const notAuthenticate = (component) => {
    return !token ? component : <Navigate to="/stores" />;
  }

  const authenticateBackOffice = (component) => {
    return tokenBO ? component : <Navigate to="/stores" />;
  }

  useEffect(() => {
    checkIfTokenHasExpired(token);
  }, []);

  useEffect(() => {
    handleScrollToTop();
    checkIfTokenHasExpired(token);
  }, [location.pathname])

  useEffect(() => {
    if (tokenBO) {
      dispatch(getNotifications());
    }
  }, [tokenBO])

  const checkIfTokenHasExpired = () => {
    const refreshToken = localStorage.getItem('back_end_token');

    if (refreshToken) {
      const payloadBase64 = refreshToken.split('.')[1];
      const decodedJson = Buffer.from(payloadBase64, 'base64').toString();
      const decoded = JSON.parse(decodedJson)

      try {
        if (decoded.exp < (new Date().getTime() + 1) / 1000) {
          localStorage.removeItem('back_end_token');
          localStorage.clear();
          dispatch(logout());
          navigate('/login');
        }
      }
      catch { }
    }
  };

  return (
    <Routes>

      <Route path="login" element={notAuthenticate(<Login />)} />
      <Route path="*" element={<NotFoundPage />} />

      {/* <Route path="register" element={notAuthenticate(<Register />)} /> */}
      <Route path="forgot-password" element={notAuthenticate(<ForgotPasswd />)} />
      <Route path="stores" element={authenticate(<Stores />)} />

      <Route element={<Navbar />}>
        <Route path="/" element={authenticateBackOffice(<Dashboard />)} />
        <Route path="dashboard" element={authenticateBackOffice(<Dashboard />)} />
        <Route path="orders" element={authenticateBackOffice(<Orders />)} />
        <Route path="abandonnedCart" element={authenticateBackOffice(<AbandonnedCart />)} />
        <Route path="abandonnedCart/:id" element={authenticateBackOffice(<DetailAbandonnedCart />)} />
        <Route path="products" element={authenticateBackOffice(<Products />)} />
        <Route path="users" element={authenticateBackOffice(<Users />)} />
        <Route path="discounts" element={authenticateBackOffice(<Discounts />)} />
        <Route path="integrations" element={authenticateBackOffice(<Integrations />)} />
        <Route path="/order/:id" element={authenticateBackOffice(<DetailOrder />)} />
        <Route path="/integration/:id" element={authenticateBackOffice(<AboutIntegration />)} />

        {/* AJOUT FORM ROUTES */}
        <Route path="add/product" element={authenticateBackOffice(<AddProduct />)} />
        <Route path="add/user" element={authenticateBackOffice(<AddUser />)} />
        <Route path="add/store" element={authenticateBackOffice(<AddStore />)} />
        <Route path="add/brand" element={authenticateBackOffice(<AddBrand />)} />
        <Route path="add/collection" element={authenticateBackOffice(<AddCollection />)} />
        <Route path="add/discount" element={authenticateBackOffice(<AddDiscount />)} />
        <Route path="add/categorie" element={authenticateBackOffice(<AddCategorie />)} />
        <Route path="add/delivery" element={authenticateBackOffice(<AddDelivery />)} />
        <Route path="add/page" element={authenticateBackOffice(<AddPage />)} />
        <Route path="add/menu" element={authenticateBackOffice(<AddMenu />)} />
        <Route path="add/integration" element={authenticateBackOffice(<AddIntegration />)} />
        <Route path="add/taxe" element={authenticateBackOffice(<AddTax />)} />
        <Route path="add/language" element={authenticateBackOffice(<AddLanguage />)} />
        <Route path="add/shipper" element={authenticateBackOffice(<AddShipper />)} />
        {/* AJOUT FORM ROUTES */}

        {/* EDIT FORM ROUTES */}
        <Route path="edit/user/:id" element={authenticateBackOffice(<AddUser />)} />
        {/* <Route path="edit/store/" element={authenticateBackOffice(<AddStore />)} /> */}
        <Route path="edit/brand/:id" element={authenticateBackOffice(<AddBrand />)} />
        <Route path="edit/delivery/:id" element={authenticateBackOffice(<AddDelivery />)} />
        <Route path="edit/shipper/:id" element={authenticateBackOffice(<AddShipper />)} />
        <Route path="edit/product/:id" element={authenticateBackOffice(<AddProduct />)} />
        <Route path="edit/category/:id" element={authenticateBackOffice(<AddCategorie />)} />
        <Route path="edit/collection/:id" element={authenticateBackOffice(<AddCollection />)} />
        <Route path="edit/discount/:id" element={authenticateBackOffice(<AddDiscount />)} />
        <Route path="edit/tax/:id" element={authenticateBackOffice(<AddTax />)} />
        <Route path="edit/language/:id" element={authenticateBackOffice(<AddLanguage />)} />
        <Route path="edit/translation/:id" element={authenticateBackOffice(<AddTranslation />)} />
        {/* EDIT FORM ROUTES */}

        {/* DUPLICATE FORM ROUTES */}
        <Route path="duplicate/product/" element={authenticateBackOffice(<AddProduct />)} />
        {/* DUPLICATE FORM ROUTES */}

        {/* SETTINGS ROUTES */}
        <Route path="settings" element={authenticateBackOffice(<Settings />)} />
        <Route path="settings/regions" element={authenticateBackOffice(<Regions />)} />
        {/* <Route path="settings/teams" element={authenticateBackOffice(<Teams />)} /> */}
        <Route path="settings/permissions" element={authenticateBackOffice(<Permissions />)} />
        <Route path="settings/roles" element={authenticateBackOffice(<Roles />)} />
        <Route path="settings/devices" element={authenticateBackOffice(<Devices />)} />
        <Route path="settings/taxes" element={authenticateBackOffice(<Taxes />)} />
        <Route path="settings/delivery" element={authenticateBackOffice(<Delivery />)} />
        <Route path="settings/pages" element={authenticateBackOffice(<Pages />)} />
        <Route path="settings/menus" element={authenticateBackOffice(<Menus />)} />
        <Route path="settings/payments" element={authenticateBackOffice(<Payments />)} />
        <Route path="settings/domains" element={authenticateBackOffice(<Domains />)} />
        <Route path="settings/merchant" element={authenticateBackOffice(<Merchant />)} />
        {/* <Route path="settings/billing" element={authenticateBackOffice(<Billing />)} /> */}
        {/* <Route path="settings/subscription" element={authenticateBackOffice(<Subscription />)} /> */}
        <Route path="settings/languages" element={authenticateBackOffice(<Languages />)} />
        <Route path="settings/account" element={authenticateBackOffice(<Account />)} />
        <Route path="settings/shipper" element={authenticateBackOffice(<Shipper />)} />
        {/* SETTINGS ROUTES */}

        <Route path="subscribe/:id" element={authenticateBackOffice(<PaymentInformation />)} />

      </Route>

    </Routes>
  );
}
