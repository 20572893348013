import { GET_ALL_NOTIFICATIONS, UPDATE_NOTIFICATIONS} from "./types";
import apiAuth from "../api/notifications";

// GET ALL NOTIFICATIONS
export const getNotifications = () =>
    async (dispatch) => {
        return apiAuth
            .getNotifications()
            .then(async (result) => {
                if (result) {
                    dispatch({ type: GET_ALL_NOTIFICATIONS, payload: result.data })
                    return result?.data
                }
            })
    };

// UPDATE NOTIFICATIONS
export const updateNotifications = () =>
    async (dispatch) => {
        return apiAuth
            .getNotifications()
            .then(async (result) => {
                if (result) {
                    dispatch({ type: UPDATE_NOTIFICATIONS, payload: result?.data })
                    return result?.data
                }
            })
    };