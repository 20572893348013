import { apiClient } from './ApiClient'

function getAllUsers() {
    return apiClient().get('/admin/user/')
}

function getUserById(id) {
    return apiClient().get('/admin/user/' + id)
}

function editUser(data) {
    return apiClient().patch('/admin/user', data)
}

function createUser(data) {
    return apiClient().post('/admin/user', data)
}

function deleteUserById(id) {
    return apiClient().delete('/admin/user/' + id)
}

function editShippingAddressByUserId(id, data) {
    return apiClient().patch('/admin/user/' + id + '/address', data)
}

function createShippingAddressByUserId(id, data) {
    return apiClient().put('/admin/user/' + id + '/address', data)
}

function deleteShippingAddressByUserId(userId, addressId) {
    return apiClient().delete('admin/user/' + userId + '/address/' + addressId)
}



export default {
    getAllUsers,
    getUserById,
    editUser,
    deleteUserById,
    createUser,
    editShippingAddressByUserId,
    createShippingAddressByUserId,
    deleteShippingAddressByUserId,
}
