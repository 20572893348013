import { GET_ALL_TAXES } from "./types";
import apiTax from "../api/tax";

// CREATE DISCOUNT
export const createTax = async (data) => {
    return apiTax
        .createTax(data)
        .then(async (result) => {
            if (result) {
                return result
            }
        })
};

// GET ALL TAXES
export const getAllTaxes = () =>
    async (dispatch) => {
        return apiTax
            .getAllTaxes()
            .then(async (result) => {
                if (result?.data) {
                    await dispatch({ type: GET_ALL_TAXES, payload: result.data })
                    return result.data
                }
            })
    };

// GET TAX BY ID
export const getTaxById = async (id) => {
    return apiTax
        .getTaxById(id)
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};

// EDIT TAX
export const editTax = async (data) => {
    return apiTax
        .editTax(data)
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};

// DELETE TAX BY ID
export const deleteTaxById = async (id) => {
    return apiTax
        .deleteTaxById(id)
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};
