import { apiClient } from './ApiClient'

function getCountAnalytics(data) {
    return apiClient().post('/admin/analytics/', data);
}

function getRecentOrder() {
    return apiClient().post('admin/analytics/recentorder/7');
}

function getBestSeller(data) {
    return apiClient().post('admin/analytics/bestSeller/', data);
}

export default {
    getCountAnalytics,
    getRecentOrder,
    getBestSeller
}
