import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { createStore, editStore, getStoreById } from "../actions/stores";
import { getAllUsers } from "../actions/users";
import Select from 'react-select';

const AddStore = () => {
  const navigate = useNavigate()
  const chooseForm = window.location.href.split("?id=")[1] === undefined;
  const dispatch = useDispatch();

  const [storeName, setStoreName] = useState();
  const [description, setDescription] = useState();
  const [slug, setSlug] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [address, setAddress] = useState();
  const [tva, setTva] = useState();
  const [selectUser, setSelectUser] = useState(false);
  const [metaDescription, setMetaDescription] = useState(false);
  const [metaTitle, setMetaTitle] = useState(false);
  const [isDisabled, setIsDisabled] = useState();

  const [userId, setUserId] = useState(false);


  const { users } = useSelector(state => state)


  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  // useEffect(() => {
  //   dispatch(getAllUsers())

  //   if (!chooseForm) {
  //     dispatch(getStoreById(window.location.href.split("?id=")[1])).then(response => {
  //       setStoreName(response.data.name);
  //       setDescription(response.data.description);
  //       setSlug(response.data.slug);
  //       setEmail(response.data.email);
  //       setPhone(response.data.phone);
  //       setAddress(response.data.address);
  //       setTva(response.data.tva);
  //       setSelectUser(response.data)
  //       setUserId(response.data.userId);
  //       setMetaDescription(response.data.metaDescription);
  //       setMetaTitle(response.data.metaTitle);
  //       setIsDisabled(response.data.disabled);
  //     })
  //   }
  // }, []);

  const arr = [];

  arr.push(users)

  let i;

  const arrUser = [];

  for (i = 0; i < Object.keys(users).length; i++) {
    if (users[i]?.status === "Vendor")
      arrUser.push(users[i])
  }

  function handleEdit() {
    const data = {
      _id: window.location.href.split("?id=")[1],
      name: storeName,
      description: description,
      slug: slug,
      email: email,
      phone: phone,
      tva: tva,
      address: address,
      disabled: isDisabled
    }

    // dispatch(editStore(window.location.href.split("?id=")[1], data))
    //   .then(res => {
    //     setSuccessMessage("La boutique a été modifier avec succès !");

    //     setTimeout(() => {
    //       setSuccessMessage(null);
    //     }, 4000);
    //   })
    //   .catch(err => {
    //     setErrorMessage(err.response.data.error);

    //     setTimeout(() => {
    //       setErrorMessage(null);
    //     }, 4000);
    //   })
  }

  function handleCreate() {
    const data = {
      userId: selectUser._id,
      name: storeName,
      description: description,
      slug: slug,
      email: email,
      phone: phone,
      tva: tva,
      address: address,
      disabled: isDisabled
    }

    // dispatch(createStore(data))
    //   .then(res => {
    //     setSuccessMessage("La boutique a crée avec succès !");

    //     setTimeout(() => {
    //       setSuccessMessage(null);
    //     }, 4000);
    //   })
    //   .catch(err => {
    //     setErrorMessage(err.response.data.error);

    //     setTimeout(() => {
    //       setErrorMessage(null);
    //     }, 4000);
    //   })
  }

  const formatOptionLabel = ({ email }) => (
    <div className="flex row">
      <div className="rounded-full">
        <div className="relative">
          <img className="rounded-full h-7 w-7 mr-2 object-cover" src="https://tuk-cdn.s3.amazonaws.com/assets/components/sidebar_layout/sl_2.png" alt="avatar" />
        </div>
      </div>
      <>{email}</>
    </div>
  );

  return (
    <div className="px-40">
    {/* SUCCESS ALERT */}
      {successMessage &&
        <div className="w-full bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-6" role="alert">
          <p className="font-bold">Succès !</p>
          <p>{successMessage}</p>
        </div>
      }

      {/* ERROR ALERT */}
      {errorMessage &&
        <div className="w-full bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <p className="font-bold">Erreur !</p>
          <p>{errorMessage}</p>
        </div>
      }

      <div className="flex row">
        <button onClick={() => navigate('/stores')} className="text-white cursor-pointer border-[1.2px] border-[#babfc3] bg-transparent transition duration-150 ease-in-out w-10 h-10 rounded flex items-center justify-center ">
          <svg viewBox="0 0 20 20" height={25} width={25} fill='#5c5f62' focusable="false" aria-hidden="true"><path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path></svg>
        </button>
        {chooseForm
          ? <h1 className="text-xl font-semibold mt-1 ml-4">Ajouter une boutique</h1>
          : <h1 className="text-xl font-semibold mt-1 ml-4">Éditer une boutique</h1>
        }
      </div>

      <div className="grid grid-cols-3 gap-4 mt-10">
        <div className="col-span-2">
          <div className="flex justify-between w-full">
            <div className="w-full rounded bg-white shadow p-6">

              {/* TITLE INPUT */}
              <div className="relative w-full">
                <label className="text-gray-700">
                  Nom
                </label>
                <input defaultValue={storeName} name="store_name" onChange={e => setStoreName(e.target.value)} type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="" />
              </div>

              {/* DESCRIPTION INPUT */}
              <div className="mt-4">
                <label className="text-gray-700">
                  Description
                </label>
                <form>
                  <div className="mb-4 w-full bg-gray-50 rounded-lg border border-gray-200 mt-2">
                    <div className="flex justify-between items-center py-2 px-3 border-b">
                      <div className="flex flex-wrap items-center divide-gray-200 sm:divide-x">
                        <div className="flex items-center space-x-1 sm:pr-4">
                          <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd"></path></svg>
                          </button>
                          <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path></svg>
                          </button>
                          <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd"></path></svg>
                          </button>
                          <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                          </button>
                          <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z" clipRule="evenodd"></path></svg>
                          </button>
                        </div>
                        <div className="flex flex-wrap items-center space-x-1 sm:pl-4">
                          <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                          </button>
                          <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path></svg>
                          </button>
                          <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                          </button>
                          <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                          </button>
                        </div>
                      </div>
                      <button type="button" data-tooltip-target="tooltip-fullscreen" className="p-2 text-gray-500 rounded cursor-pointer sm:ml-auto hover:text-gray-900 hover:bg-gray-100">
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 11-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 010-2h4a1 1 0 011 1v4a1 1 0 01-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 012 0v1.586l2.293-2.293a1 1 0 111.414 1.414L6.414 15H8a1 1 0 010 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.586l-2.293-2.293a1 1 0 111.414-1.414L15 13.586V12a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                      </button>
                      <div id="tooltip-fullscreen" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
                        Show full screen
                        <div className="tooltip-arrow" data-popper-arrow></div>
                      </div>
                    </div>
                    <div className="py-2 px-4 bg-white rounded-b-lg">
                      <label className="sr-only">Publish post</label>
                      <textarea defaultValue={description} onChange={e => setDescription(e.target.value)} name="description" id="editor" rows="8" className="block px-0 w-full text-sm text-gray-800 bg-white border-0 focus:ring-0" placeholder="" required></textarea>
                    </div>
                  </div>
                </form>
              </div>

              <div className="relative w-full">
                <label className="text-gray-700">
                  Slug
                </label>
                <input defaultValue={slug} name="slug" onChange={e => setSlug(e.target.value)} type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="/" />
              </div>

            </div>
          </div>


          <div className="flex justify-between w-full mt-4">
            <div className="w-full rounded bg-white shadow mb-4">
              <div className="p-6">
                <h1 className="font-semibold mb-4">Information de la boutique</h1>


                <div className="mb-3">
                  <div className="mb-2 ">
                    <label className="text-gray-700">
                      Utilisateur
                    </label>
                  </div>
                  <div>
                    <Select
                      menuPortalTarget={document.body}
                      styles={{
                        input: (provided) => ({ ...provided, padding: 4 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused && "rgb(243 244 246)",
                          color: state.isFocused && "black"
                        }),
                        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                        menu: (provided) => ({ ...provided, zIndex: 9999 })

                      }}
                      noOptionsMessage={() => 'Aucun utilisateur n\'a été trouvé !'}
                      value={!chooseForm ? arrUser.filter(option => option._id === userId) : selectUser}
                      formatOptionLabel={formatOptionLabel}
                      onChange={(option) => setSelectUser(option)}
                      options={arrUser}
                      getOptionLabel={(option) => option.email}
                      placeholder={"Ajouter l'utilisateur propriétaire de la boutique"}
                      isDisabled={!chooseForm}
                    />
                  </div>
                </div>

                <div>
                  <label className="text-gray-700">
                    Email
                  </label>
                  <input type="text" defaultValue={email} onChange={e => setEmail(e.target.value)} name="email" className="rounded-lg flex-1	 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="john.doe@gmail.com" />
                </div>

                <div className="mt-4">
                  <label className="text-gray-700">
                    Numéro de téléphone
                  </label>
                  <input type="text" defaultValue={phone} onChange={e => setPhone(e.target.value)} name="phone" className="rounded-lg flex-1	 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="+33" />
                </div>
                {/* 
                <div className="mt-4">
                  <label className="text-gray-700">
                    URL du site
                  </label>
                  <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="email" placeholder="https://www.google.fr/" />
                </div> */}
              </div>

              <div className="w-full border-[.5px] border-gray-200 mb-6" />

              <div className='px-6 mb-4'>
                <h1 className="font-semibold text-sm mb-2 uppercase">Adresse de la boutique</h1>

                <div className="mt-6">
                  <label className="text-gray-700">
                    Pays/région d'origine
                  </label>

                  <select style={{ backgroundColor: 'white', fontSize: 15.6 }} id="countries" value={address?.country} onChange={e => setAddress({ ...address, country: e.target.value, lat: 1, long: 1 })} name="country" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mt-2 mb-2">
                    <option value="">Sélectionner un pays/une région</option>
                    <option value="France">France</option>
                    <option value="Allemagne">Allemagne</option>
                    <option value="Anglais">Anglais</option>
                  </select>
                </div>

                {/* <div className="relative w-full mt-4">
                  <label className="text-gray-700">
                    Entreprise
                  </label>
                  <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="entreprise" placeholder="" />
                </div> */}

                <div className="relative w-full mt-4">
                  <label className="text-gray-700">
                    Adresse
                  </label>
                  <input type="text" defaultValue={address?.address} onChange={e => setAddress({ ...address, address: e.target.value })} name="address" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="" />
                </div>

                {/* <div className="relative w-full mt-4">
                  <label className="text-gray-700">
                    Appartement, suite, etc.
                  </label>
                  <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="appartement" placeholder="" />
                </div> */}

                <div className="flex row mt-4">
                  <div className="relative w-full">
                    <label className="text-gray-700">
                      Ville
                    </label>
                    <input type="text" defaultValue={address?.city} onChange={e => setAddress({ ...address, city: e.target.value })} name="city" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="Paris" />
                  </div>

                  <div className="relative w-full ml-4">
                    <label className="text-gray-700">
                      Code postal
                    </label>
                    <input type="text" defaultValue={address?.postalCode} onChange={e => setAddress({ ...address, postalCode: e.target.value })} name="postal_code" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="75001" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full">
            <div className="w-full rounded bg-white shadow mb-4">
              <div className="p-6">
                <h1 className="font-semibold mb-4">TVA</h1>

                <div className="w-full mt-4 w-2/6 mb-4">
                  <label className="text-gray-700">
                    Taux de TVA
                  </label>
                  <input type="text" defaultValue={tva} name="tva" onChange={e => setTva(e.target.value)} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder='20%' />
                </div>
              </div>
            </div>
          </div>


          <div className="flex justify-between w-full">
            <div className="w-full rounded bg-white shadow mb-8">
              <div className="p-6">
                <h1 className="font-semibold mb-2">Aperçu du résultat sur les moteurs de recherche</h1>
                <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold">Ajoutez un titre et une description pour voir comment votre boutique peut apparaître dans les résultats des moteurs de recherche.</p>
              </div>

              <div className="w-full border-[.5px] border-gray-200" />

              <div className="px-6 mt-4">
                <label className="text-gray-700">
                  Méta-titre de la page
                </label>
                <input type="text" defaultValue={metaTitle} name="meta_title" onChange={e => setMetaTitle(e.target.value)} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" />
                <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-2">0 sur 70 caractère(s) utilisé(s)</p>

                <div className="mt-4 mb-4">
                  <label className="text-gray-700">
                    Méta-description
                  </label>
                  <textarea rows="4" defaultValue={metaDescription} name="meta_description" onChange={e => setMetaDescription(e.target.value)} className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mt-2"></textarea>
                  <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-2">0 sur 320 caractère(s) utilisé(s)</p>
                </div>
              </div>

            </div>
          </div>


        </div>
        <div>
          <div className="flex items-center justify-between w-full">
            <div className="flex-col w-full items-start lg:items-center rounded bg-white shadow">
              <div className="p-6">
                <h1 className="font-semibold mb-4">Status de la boutique</h1>

                <select id="countries" value={isDisabled} onChange={e => setIsDisabled(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                  <option onClick={() => setIsDisabled(false)} value={"false"}>Actif</option>
                  <option onClick={() => setIsDisabled(true)} value={"true"}>Inactif</option>
                </select>

              </div>
            </div>
          </div>
        </div>

      </div>

      {/* FIXED SAVE BTN */}
      <div onClick={!chooseForm ? handleEdit : handleCreate} className='fixed bottom-[1rem] right-[1rem] p-2 bg-[#4338ca] rounded opacity-[.80] hover:opacity-[1] cursor-pointer'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" height={32} width={32}><path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" /></svg>
      </div>
    </div>
  );
};

export default AddStore;

