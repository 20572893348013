// import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import store from './store/index'
import { Provider } from 'react-redux'
import App from "./navigation/App";
import 'react-notifications/lib/notifications.css';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    {/* <StrictMode> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </StrictMode> */}
  </Provider>
);
