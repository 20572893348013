import { apiClient } from './ApiClient';

function createTax(data) {
    return apiClient().post('/admin/taxes/', data);
}

function getAllTaxes() {
    return apiClient().get('/admin/taxes/');
}

function getTaxById(id) {
    return apiClient().get('/admin/taxes/' + id);
}

function editTax(data) {
    return apiClient().patch('/admin/taxes/', data);
}

function deleteTaxById(id) {
    return apiClient().delete('/admin/taxes/' + id);
}

export default {
    createTax,
    getAllTaxes,
    getTaxById,
    editTax,
    deleteTaxById
}
