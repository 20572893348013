import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { changeIntegrationStatus, getAllIntegrations } from "../actions/integrations";
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';

const AddIntegration = () => {
    const navigate = useNavigate();
    let dispatch = useDispatch();

    const [allIntegrations, setAllIntegrations] = useState([]);
    const [allFiltredIntegrations, setAllFiltredIntegrations] = useState([])

    const optionsSelect = [
        { label: "Tout", value: "all" },
        { label: "Paiement", value: "Paiement" },
        { label: "Recherche", value: "Recherche" },
        { label: "Communication", value: "Communication" },
        { label: "Taxe", value: "Taxe" },
        { label: "Livraison", value: "Livraison" },
        { label: "Contenu", value: "Contenu" },
        { label: "Avis", value: "Avis" },
        { label: "Cookies", value: "Cookies" },
        { label: "Tracking", value: "Tracking" },
    ]

    const [filterIntegration, setFilterIntegration] = useState(optionsSelect[0])

    useEffect(() => {
        dispatch(getAllIntegrations()).then((res) => {
            setAllIntegrations(res)
            setAllFiltredIntegrations(res)
        })
    }, [])

    const handleEnableIntegration = (id) => {
        const data = {
            id: id,
            status: 'enabled'
        }

        dispatch(changeIntegrationStatus(data)).then((res) => {
            dispatch(getAllIntegrations()).then((res) => {
                setAllIntegrations(res);
                setAllFiltredIntegrations(res)
            });

            NotificationManager.success("Votre intégration a été activée avec succès !", 'Succès');

        }).catch((err) => {
            NotificationManager.error(err?.response?.data?.erro, 'Erreur')

        })
    }

    useEffect(() => {
        if (filterIntegration.value === 'all') {
            setAllFiltredIntegrations(allIntegrations);
        } else {
            setAllFiltredIntegrations(allIntegrations.filter((it) => it?.keyWords.includes(filterIntegration?.value)));
        }


    }, [filterIntegration])

    return (
        <div className="container-margin">
            <div className="">


                <div className="grid md:grid-cols-2 grid-cols-1 w-full">
                    <div className="flex flex-row">

                        <button onClick={() => navigate('/integrations')} className="text-white cursor-pointer border-[1.2px] border-[#babfc3] bg-transparent transition duration-150 ease-in-out w-10 h-10 rounded flex items-center justify-center ">
                            <svg viewBox="0 0 20 20" height={25} width={25} fill='#5c5f62' focusable="false" aria-hidden="true"><path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path></svg>
                        </button>
                        <h1 className="text-xl font-semibold mt-1 ml-4">Ajouter une intégration</h1>
                    </div>
                    <div className="flex md:justify-end mt-4 md:mt-0">
                        <div className="flex flex-row">
                            <span className="mt-[.4rem] mr-2">Filtre: </span>
                            <div className="w-[200px]">
                                <Select
                                    options={optionsSelect}
                                    defaultValue={optionsSelect[0]}
                                    onChange={(item) => setFilterIntegration(item)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {allIntegrations?.filter(it => it.status === "disabled").length <= 0

                ? <div className="bg-[white] mt-4 rounded"><div className="flex flex-col w-full">
                    <div className="mb-10 mt-10">
                        <div className="flex justify-center mb-8 ml-7">
                            <img src="../../no_data.png" width={150} />
                        </div>
                        <div className="flex justify-center">
                            <span className="text-gray-500 text-center text-[18px]">Aucune intégration n'a été trouvée, revenez plus tard<br /> afin de voir si de nouvelles sont disponibles.</span>
                        </div>
                    </div>
                </div>
                </div>

                : allFiltredIntegrations?.filter(it => it.status === "disabled").length <= 0
                    ? <div className="flex items-center justify-between w-full mt-4">
                        <div className="w-full lg:w-3/3">
                            <div className="py-0">
                                <div className="mx-auto bg-white shadow rounded">


                                    <div className="py-20">
                                        <div className="flex justify-center mb-4 ml-8">
                                            <img src="../../no_data.png" width={150} />
                                        </div>
                                        <div className="flex justify-center">
                                            <span className="text-gray-500 text-center text-[18px]">Aucune intégration ne correspond à ce filtre.</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="grid mt-6">
                        <div className="col">
                            <div className="flex justify-between w-full">
                                <div className="w-full rounded bg-white shadow p-6">
                                    <div className="grid grid-cols-1 md:grid-cols-4">

                                        {Object.keys(allFiltredIntegrations)
                                            .map((key) => allFiltredIntegrations[key])
                                            .filter((integration) => integration.status === "disabled")
                                            .sort((a, b) => (a.commingSoon && !b.commingSoon) ? 1 : -1) // Tri en fonction de commingSoon
                                            .map((integration, key) => (
                                                <div key={integration._id} className="mr-4 mb-4">
                                                    <div className="shadow-sm border-[2px] border-[#f1f6fc] rounded">
                                                        <div className="p-4">
                                                            <div className="flex flex-row">
                                                                <img width={60} src={integration.image} />
                                                                <h1 className="text-[20px] font-[500] mt-4 ml-2">
                                                                    {integration.name}
                                                                </h1>
                                                            </div>
                                                            <div className="mt-4">
                                                                {integration.keyWords.map((it, key) => (
                                                                    <span key={key} style={{ backgroundColor: '#e5e7eb', padding: 4, marginRight: 7 }}>{it}</span>
                                                                ))}
                                                            </div>
                                                            <p className="mt-4">
                                                                {integration.description}
                                                            </p>
                                                        </div>
                                                        <div className="bg-[#f1f6fc] p-4 flex justify-end">
                                                            <button
                                                                onClick={() => handleEnableIntegration(integration._id)}
                                                                className={`h-8 px-3 m-2 rounded text-[white] ${integration.commingSoon ? "cursor-not-allowed bg-gray-300" : "bg-[#283341] cursor-pointer"}`}
                                                                disabled={integration.commingSoon}
                                                            >
                                                                {integration.commingSoon ? "Bientôt disponible" : "Activer"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
};

export default AddIntegration;
