import React, { useState } from "react";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forgotPass } from "../../actions/auth";

const ForgotPasswd = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");

    const handleResetPasswd = () => {
        const data = {
            email: email
        }
        dispatch(forgotPass(data))
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        handleResetPasswd();
    }
  };

    return (
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 flex min-h-screen"> {/* Ajouter la classe min-h-screen */}

            <div className="relative bg-indigo-600 h-full w-full flex flex-col overflow-hidden hidden md:hidden lg:block xl:block">
                <div className="px-5 py-5 mt-14">
                    <h1 className="text-[40px] text-[white]"><span className="relative top-[-15px] mr-3">_</span>Connectez-vous à votre compte Milango</h1>
                    <p className="text-[20px] text-[#bbbfff] xl:max-w-[76%] ml-9">Accédez à votre espace administrateur pour gérer en toute simplicité votre boutique en ligne.</p>
                </div>
                <div className="absolute bottom-0 hidden md:block lg:block xl:block">
                    <img src="/dashboard.png" />
                </div>
            </div>

            <div className="bg-[white]">
                <div className="flex flex-row justify-between">
                    
                    <img className="w-[130px] ml-8 mt-8" src='/milango-logo.svg' />

                    <div onClick={() => navigate("/login")} className="flex mr-8 mt-8 hover:underline cursor-pointer text-gray-500 opacity-[.7]">
                        <h1>Se connecter</h1>
                    </div>
                </div>
                <div className="w-[100%] flex justify-center">
                <div className="mt-28 w-full px-16 lg:px-0 md:px-14 lg:px-14 xl:px-32">
                        <div className="text-center">
                            <div className="flex justify-center items-center">
                                <div className="border-[1px] border-[#ededef] rounded-lg p-1 mb-3">
                                    <img width={35} src="/password.svg" />
                                </div>
                            </div>
                            <h1 className="text-[30px] font-semibold">Rénitialiser votre mot passe</h1>
                            <p className="text-gray-500 text-[15px]">Renseigner votre e-mail pour rénitialiser votre mot de passe.</p>
                        </div>

                        <div className="mt-10">
                            <div className="flex flex-col mt-4">
                                <label className="font-semibold" htmlFor="email">Adresse e-mail</label>
                                <input onKeyDown={(e) => handleKeyDown(e)} type="text" id="email" name="email" onChange={(e) => setEmail(e?.target?.value)} className={`rounded-lg mt-1 flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:indigo-700 focus:border-transparent custom-input`} placeholder="Saisisser votre adresse e-mail" />
                            </div>
                        </div>

                        <div>
                            <div className="flex m-auto flex flex-row">
                                <button onClick={() => handleResetPasswd()} className="py-2 px-4 mt-4  bg-indigo-700 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                                    Rénitialiser
                                </button>
                            </div>
                            <div onClick={() => navigate("../")} className="flex mt-6 hover:underline cursor-pointer text-gray-500 opacity-[.7]">
                                <h1>← Retour </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <NotificationContainer />
        </div>
    );
};

export default ForgotPasswd;
