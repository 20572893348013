import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { createUser, editUser, getUserById, createShippingAddressByUserId } from "../actions/users";
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import { usePrompt } from '../utils/prompt';
import AutoComplete from "react-google-autocomplete";
import { GOOGLE_MAP_API } from "../config/key";

const AddCustomer = () => {
    let location = useLocation();
    const id = location?.pathname?.split("/")[3];

    const gender = [{ name: "Homme", value: "M" }, { name: "Femme", value: "F" }, { name: "Non défini", value: "NF" }];

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [user, setUser] = useState({});
    const [modalEdit, setModalEdit] = useState();
    const [modalCreate, setModalCreate] = useState();
    const [selectShippingAddress, setSelectShippingAddress] = useState();
    const [dataObj, setDataObj] = useState({});
    const [dataChanged, setDataChanged] = useState(false);
    const [idUserCreated, setIdUserCreated] = useState();
    const [billingAddress, setBillingAddress] = useState({});
    const [shippingAddress, setShippingAddress] = useState({});
    const [editShippingAddress, setEditShippingAddress] = useState({});
    const [addressShipping, setAddressShipping] = useState({});
    const [addressBilling, setAddressBilling] = useState({});

    useEffect(() => {

        let result = {}

        billingAddress?.address_components?.map((it) => {
            if (it.types[0] === "country") {
                result['country'] = it.long_name
            }
            if (it.types[0] == "locality") {
                result['city'] = it.long_name
            }
            if (it.types[0] == "postal_code") {
                result['postalCode'] = it.long_name
            }

            result['address'] = billingAddress?.formatted_address?.split(',')[0]

            if (billingAddress?.geometry) {
                result['lat'] = billingAddress?.geometry?.location.lat();
                result['long'] = billingAddress?.geometry?.location.lng();
            }

        })

        setAddressBilling(result);

    }, [billingAddress])

    useEffect(() => {

        let result = {}

        shippingAddress?.address_components?.map((it) => {
            if (it.types[0] === "country") {
                result['country'] = it.long_name
            }
            if (it.types[0] == "locality") {
                result['city'] = it.long_name
            }
            if (it.types[0] == "postal_code") {
                result['postalCode'] = it.long_name
            }

            result['address'] = shippingAddress?.formatted_address?.split(',')[0]

            if (shippingAddress?.geometry) {
                result['lat'] = shippingAddress?.geometry?.location.lat();
                result['long'] = shippingAddress?.geometry?.location.lng();
            }

        })

        setAddressShipping(result);

    }, [shippingAddress])

    useEffect(() => {

        let result = {}

        editShippingAddress?.address_components?.map((it) => {
            if (it.types[0] === "country") {
                result['country'] = it.long_name
            }
            if (it.types[0] == "locality") {
                result['city'] = it.long_name
            }
            if (it.types[0] == "postal_code") {
                result['postalCode'] = it.long_name
            }

            result['address'] = editShippingAddress?.formatted_address?.split(',')[0]

            if (editShippingAddress?.geometry) {
                result['lat'] = editShippingAddress?.geometry?.location.lat();
                result['long'] = editShippingAddress?.geometry?.location.lng();
            }

        })

        setAddressShipping(result);

    }, [editShippingAddress])

    useEffect(() => {
        if (id) {
            getUserById(id).then(response => {
                setUser(response);
                if (response?.billingAddress) {
                    setAddressBilling(response?.billingAddress)
                }
                setDataObj(response);
            })
        }
    }, []);

    // useEffect(() => {

    //     setUser({ ...user, billingAddress: addressBilling })
    // }, [addressBilling])

    useEffect(() => {
        compareObjects()
    }, [user]);

    useEffect(() => {
        if (idUserCreated) {
            navigate('/edit/user/' + idUserCreated)
        }
    }, [idUserCreated])

    const compareObjects = () => {

        const userKeys = Object.keys(user);

        const isDifferent = userKeys.some((key) => {
            const userValue = user[key];
            const dataObjValue = dataObj[key];

            if (typeof userValue !== "object" && typeof dataObjValue !== "object") {
                return userValue !== dataObjValue;
            }

            return JSON.stringify(userValue) !== JSON.stringify(dataObjValue);
        });

        setDataChanged(isDifferent);
    };

    const handleResetData = () => {
        setUser(dataObj);
    }

    const handleEdit = () => {
        const data = {
            _id: id,
            name: user?.name,
            lastname: user?.lastname,
            civility: user?.civility,
            email: user?.email,
            phone: user?.phone,
            birthDate: user?.birthDate,
            partnerOffer: user?.partnerOffer,
            newsletter: user?.newsletter,
            rgpd: user?.rgpd,
            termsPrivacyPolicy: user?.termsPrivacyPolicy,
            billingAddress: addressBilling,
        }

        editUser(data)
            .then((res) => {
                setUser(res);
                setDataObj(res);
                setDataChanged(false);

                if (user?.shippingAddress) {
                    dispatch(createShippingAddressByUserId(res?._id, user?.shippingAddress)).then((result) => {
                        setUser({ ...res, shippingAddress: result?.data?.shippingAddress });
                        setDataObj({ ...res, shippingAddress: result?.data?.shippingAddress });
                    })
                }

                NotificationManager.success("L'utilisateur a été modifié avec succès !", 'Succès')
            })
            .catch(err => {
                NotificationManager.error(err.response.data.error, 'Erreur')
            })
    }

    const handleCreate = () => {
        const data = {
            name: user?.name,
            lastname: user?.lastname,
            civility: user?.civility,
            email: user?.email,
            phone: user?.phone,
            partnerOffer: user?.partnerOffer,
            newsletter: user?.newsletter,
            rgpd: user?.rgpd,
            termsPrivacyPolicy: user?.termsPrivacyPolicy,
            birthDate: user?.birthDate,
            billingAddress: addressBilling,
        }

        dispatch(createUser(data))
            .then((res) => {
                setDataChanged(false);
                setIdUserCreated(res?.data?._id);
                NotificationManager.success("L'utilisateur a été crée avec succès !", 'Succès')

            })
            .catch((err) => {
                NotificationManager.error(err.response.data.error, 'Erreur')
            })
    }

    const handleEditShippingAddress = (index) => {
        const newShippingAddress = [...user.shippingAddress];

        newShippingAddress.splice(index, 1, addressShipping);

        setUser(prevState => ({ ...prevState, shippingAddress: newShippingAddress }));

        setModalEdit(false);
    };

    const handleCreateShippingAddress = () => {
        const newShippingAddress = [...(user?.shippingAddress ?? []), addressShipping];

        setUser({ ...user, shippingAddress: newShippingAddress })

        NotificationManager.success("L'adresse a été ajouter avec succès !", 'Succès')


        setModalCreate(false);
    }

    const handleDeleteShippingAddress = (index) => {
        const newShippingAddresses = [...user.shippingAddress];
        newShippingAddresses.splice(index, 1);
        setUser({ ...user, shippingAddress: newShippingAddresses });

        setModalEdit(false);
    };

    const handleModal = (id) => {
        setModalEdit(true)
        setSelectShippingAddress(id);
    }

    return (
        usePrompt(dataChanged),

        <>
            <div className={`fixed bg-[#080404] top-0 py-[.7rem] px-5 w-full left-0 z-[999] m-0 text-center test ${dataChanged ? "transition ease-in-out delay-250 opacity-1 duration-500" : "opacity-0 delay-250 duration-500 z-[0]"}`}>
                <div className="flex justify-between text-[white]">
                    <div className="cursor-pointer" onClick={() => navigate('/')}>
                        <svg fill="currentColor" width="38" height="38" viewBox="0 0 174 174" xmlns="http://www.w3.org/2000/svg">
                            <path fill="white" fillRule="evenodd" clipRule="evenodd" d="M0 9.23318C0 4.13387 4.13275 0 9.23075 0H164.769C169.867 0 174 4.13387 174 9.23318V41.7256C168.389 42.1805 163.181 43.5167 158.377 45.7341C152.334 48.4078 147.394 52.1858 143.559 57.0681C140.421 51.9533 136.12 48.1172 130.658 45.5598C125.312 42.8861 119.152 41.5493 112.178 41.5493C105.786 41.5493 99.9745 42.6537 94.7445 44.8623C89.5144 47.0711 85.1559 50.2678 81.6691 54.4526V42.9386H60.9231V135.708H82.7151V88.4543C82.7151 79.3872 84.8653 72.4706 89.1657 67.7045C93.5822 62.9385 99.5097 60.5555 106.948 60.5555C113.689 60.5555 118.861 62.6479 122.464 66.8327C126.067 70.9013 127.868 77.0041 127.868 85.1414V135.708H149.661V88.4543C149.661 79.5034 151.869 72.6449 156.285 67.8789C160.702 62.9966 166.571 60.5555 173.893 60.5555L174 60.5557V164.767C174 169.866 169.867 174 164.769 174H9.23075C4.13275 174 0 169.866 0 164.767V9.23318ZM112.321 69.9598C106.222 69.9598 101.199 72.2675 97.4993 76.8928L97.4895 76.9051L97.4798 76.9175C93.89 81.527 92.2757 87.9726 92.2757 95.8953V135.709H97.1218V95.8953C97.1218 88.5482 98.631 83.3425 101.29 79.9206C104.013 76.523 107.607 74.8191 112.321 74.8191V69.9598Z"></path>
                        </svg>
                    </div>

                    <div className="mt-2 font-bold text-md">
                        Modifications non enregistrées
                    </div>

                    <div className="flex flex-row">
                        <div onClick={() => handleResetData()} className='border-[1px] border-gray-200 hover:bg-[#eb2f06] hover:border-[#eb2f06] rounded  hover:opacity-[1] cursor-pointer flex flex-row lg:px-4 lg:py-2 mr-2'>
                            <i className="fa fa-times text-gray-200 text-[27px] relative top-[.33rem]" />
                            <span className="text-gray-200 ml-1 mt-[.1rem] font-bold">Annuler</span>
                        </div>
                        <div onClick={id ? handleEdit : handleCreate} className='bg-indigo-700 hover:bg-indigo-500 rounded  hover:opacity-[1] cursor-pointer flex flex-row lg:px-4 lg:py-2'>
                            <i className="fa fa-save text-[white] text-[25px]" />
                            <span className="text-[white] ml-2 mt-[.1rem] font-bold">Enregistrer</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-margin">


                {modalCreate &&
                    <>
                        <div style={{ zIndex: 999 }}
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
                        >
                            <div className="relative w-auto my-6 mx-auto">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-3xl font-semibold">
                                            Adresse de livraison
                                        </h3>
                                    </div>
                                    <div className="relative p-6 flex-auto">
                                        <label>Adresse</label>

                                        <AutoComplete
                                            apiKey={GOOGLE_MAP_API}
                                            onPlaceSelected={(place) => { setShippingAddress(place) }}
                                            placeholder="Saisissez votre adresse de livraison"
                                            options={{ types: ["geocode", "establishment"] }}
                                            className="rounded-lg w-full flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                        />

                                        <div>
                                            {addressShipping?.city &&
                                                <div className="relative w-full mt-4">
                                                    <label className="text-gray-700">
                                                        Ville
                                                    </label>
                                                    <input type="text" value={addressShipping?.city || ''} name="city" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-gray-200 cursor-not-allowed text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" disabled />
                                                </div>
                                            }

                                            {addressShipping?.postalCode &&
                                                <div className="relative w-full mt-4">
                                                    <label className="text-gray-700">
                                                        Code postal
                                                    </label>
                                                    <input type="text" value={addressShipping?.postalCode || ''} name="city" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-gray-200 cursor-not-allowed text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" disabled />
                                                </div>
                                            }


                                            {addressShipping?.country &&
                                                <div className="relative w-full mt-4">
                                                    <label className="text-gray-700">
                                                        Pays
                                                    </label>
                                                    <input type="text" value={addressShipping?.country || ''} name="city" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-gray-200 cursor-not-allowed text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" disabled />
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => { setModalCreate(false); setAddressShipping({}) }}
                                        >
                                            Annuler
                                        </button>
                                        <button
                                            className="bg-indigo-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => { handleCreateShippingAddress(id); setAddressShipping({}) }}
                                        >
                                            Enregistrer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                }

                {modalEdit &&
                    <>
                        <div style={{ zIndex: 999 }}
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
                        >
                            <div className="relative w-auto my-6 mx-auto">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-3xl font-semibold">
                                            Adresse de livraison
                                        </h3>
                                    </div>
                                    <div className="relative p-6 flex-auto">


                                        <AutoComplete
                                            apiKey={GOOGLE_MAP_API}
                                            onPlaceSelected={(place) => { setEditShippingAddress(place) }}
                                            defaultValue={user?.shippingAddress && user?.shippingAddress[selectShippingAddress]?.address}
                                            placeholder="Saisissez votre adresse de livraison"
                                            options={{ types: ["geocode", "establishment"] }}
                                            className="rounded-lg w-full flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                        />

                                        <div>
                                            <div className="relative w-full mt-4">
                                                <label className="text-gray-700">
                                                    Ville
                                                </label>
                                                <input type="text" value={addressShipping?.city ? addressShipping?.city : user?.shippingAddress[selectShippingAddress]?.city || ''} name="city" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-gray-200 cursor-not-allowed text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" disabled />
                                            </div>


                                            <div className="relative w-full mt-4">
                                                <label className="text-gray-700">
                                                    Code postal
                                                </label>
                                                <input type="text" value={addressShipping?.postalCode ? addressShipping?.postalCode : user?.shippingAddress[selectShippingAddress]?.postalCode || ''} name="postalCode" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-gray-200 cursor-not-allowed text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" disabled />
                                            </div>


                                            <div className="relative w-full mt-4">
                                                <label className="text-gray-700">
                                                    Pays
                                                </label>
                                                <input type="text" value={addressShipping?.country ? addressShipping?.country : user?.shippingAddress[selectShippingAddress]?.country || ''} name="country" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-gray-200 cursor-not-allowed text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" disabled />
                                            </div>


                                        </div>

                                    </div>
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setModalEdit(false)}
                                        >
                                            Annuler
                                        </button>
                                        <button
                                            className="bg-red-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => handleDeleteShippingAddress(selectShippingAddress)}
                                        >
                                            Supprimer
                                        </button>
                                        <button
                                            className="bg-indigo-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => handleEditShippingAddress(selectShippingAddress)}
                                        >
                                            Enregistrer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                }

                <div className="flex row">

                    <button onClick={() => navigate('/users')} className="text-white cursor-pointer border-[1.2px] border-[#babfc3] bg-transparent transition duration-150 ease-in-out w-10 h-10 rounded flex items-center justify-center ">
                        <svg viewBox="0 0 20 20" height={25} width={25} fill='#5c5f62' focusable="false" aria-hidden="true"><path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path></svg>
                    </button>

                    {!id
                        ? <h1 className="text-xl font-semibold mt-1 ml-4">Ajouter un utilisateur</h1>
                        : <h1 className="text-xl font-semibold mt-1 ml-4">Éditer un utilisateur</h1>
                    }
                </div>

                {/* SEPARATOR */}
                <div className="w-full border-[.5px] border-gray-200 mt-4 mb-4" />

                {/* APERCU CLIENT */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-6">
                    <div>
                        <h1 className="font-semibold mt-4">Aperçu client
                        </h1>
                    </div>

                    <div className="col-span-2">
                        <div className="flex justify-between w-full">
                            <div className="w-full rounded bg-white shadow p-6">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="relative w-full">
                                        <label className="text-gray-700">
                                            Prénom
                                        </label>
                                        <input type="text" value={user?.name || ''} name="firstname" onChange={e => setUser({ ...user, name: e.target.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="John" />
                                    </div>

                                    <div className="relative w-full">
                                        <label className="text-gray-700">
                                            Nom
                                        </label>
                                        <input type="text" value={user?.lastname || ''} name="lastname" onChange={e => setUser({ ...user, lastname: e.target.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="Doe" />
                                    </div>
                                </div>

                                <h1 className="text-gray-700 mt-4 mb-2">Civilité</h1>

                                <Select
                                    isMulti={false}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    styles={{
                                        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                        menu: (provided) => ({ ...provided, zIndex: 9999 })
                                    }}
                                    value={user?.civility === "F" ? gender[1] : user?.civility === "NF" ? gender[2] : gender[0] || ''}
                                    onChange={(item) => setUser({ ...user, civility: item?.value })}
                                    options={gender}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.name}
                                    placeholder={"Actif"}
                                />


                                <h1 className="text-gray-700 mt-4 mb-2">Date de naissance</h1>
                                <div>
                                    <input type="text" value={user?.birthDate?.split('-').reverse().join('-') || ''} name="birthdate" onChange={e => setUser({ ...user, birthDate: e.target.value?.split('-').reverse().join('-') })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="JJ-MM-YYY" />
                                </div>

                                <div className="relative w-full mt-4">
                                    <label className="text-gray-700">
                                        Email
                                    </label>
                                    <input type="text" value={user?.email || ''} name="email" onChange={e => setUser({ ...user, email: e.target.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="john.doe@gmail.com" />
                                </div>

                                <div className="relative w-full mt-4">
                                    <label className="text-gray-700">
                                        Numéro de téléphone
                                    </label>
                                    <input type="text" value={user?.phone || ''} name="phone" onChange={e => setUser({ ...user, phone: e.target.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="0606060606" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* SEPARATOR */}
                <div className="w-full border-[.5px] border-gray-200 mt-4 mb-4" />

                {/* ADRESSE */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-6">
                    <div>
                        <h1 className="font-semibold mt-4">Adresse de facturation</h1>
                        <p className="text-[#6d7175] opacity-[.85]">L'adresse de facturation de ce client</p>
                    </div>

                    <div className="col-span-2">
                        <div className="flex justify-between w-full">
                            <div className="w-full rounded bg-white shadow p-6">
                                <label>Adresse</label>

                                <AutoComplete
                                    apiKey={GOOGLE_MAP_API}
                                    onPlaceSelected={(place) => {
                                        setBillingAddress(place)
                                    }}
                                    defaultValue={addressBilling['address']}
                                    placeholder="Saisissez votre adresse de facturation"
                                    options={{
                                        types: ["geocode", "establishment"],
                                    }}
                                    className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                />

                                {billingAddress !== 0 &&
                                    <>
                                        {Object.keys(addressBilling).length !== 0 &&
                                            <>
                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                    <div className="w-full mt-4">
                                                        <label>Code postal</label>
                                                        <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 bg-gray-100 cursor-not-allowed w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                                            value={addressBilling['postalCode'] || ''} disabled />
                                                    </div>

                                                    <div className="w-full mt-0 md:mt-4">
                                                        <label>Ville</label>
                                                        <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 bg-gray-100 cursor-not-allowed w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                                            value={addressBilling['city'] || ''} disabled />
                                                    </div>
                                                </div>

                                                <div className="mt-4 w-full mr-2">
                                                    <label>Pays</label>
                                                    <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full bg-gray-100 cursor-not-allowed py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                                        value={addressBilling['country'] || ''} disabled />
                                                </div>
                                            </>
                                        }

                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>

                {/* SEPARATOR */}
                <div className={`w-full border-[.5px] border-gray-200 mt-4 mb-4 ${!id && 'hidden'}`} />


                {/* ADRESSE */}
                {id &&

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                        <div>
                            <h1 className="font-semibold mt-4">Adresse de livraison</h1>

                            <p className="text-[#6d7175] opacity-[.85]">Les adresses de livraison de ce client</p>
                        </div>

                        <div className="col-span-2">

                            {(id && user?.shippingAddress) &&
                                <>
                                    {user?.shippingAddress.map((item, index) => (
                                        <div key={index} onClick={() => handleModal(item?._id)} className="flex justify-between w-full mb-2 cursor-pointer">
                                            <div className="w-full rounded bg-white shadow p-6">
                                                <div className="flex row justify-between">
                                                    <div>
                                                        <h1>{item?.address}, {item?.country}</h1>
                                                        <h1>{item?.city}, {item?.postalCode}</h1>
                                                    </div>

                                                    <div className="mt-3">
                                                        <i className="fa fa-edit cursor-pointer" style={{ fontSize: '1.2rem' }}></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            }

                            <div onClick={() => setModalCreate(true)} className={`flex justify-between w-full mb-2  cursor-pointer ${(id && user?.shippingAddress) && Object.keys(user?.shippingAddress).length !== 0 && 'mt-8'}`}>
                                <div className="w-full rounded bg-white shadow p-6">
                                    <div className="flex row justify-between">
                                        <div>
                                            <h1>Ajouter une adresse de livraison</h1>
                                        </div>

                                        <div>
                                            <i className="fa fa-plus cursor-pointer" style={{ fontSize: '1.2rem' }}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* SEPARATOR */}
                <div className="w-full border-[.5px] border-gray-200 mt-4 mb-4" />

                {/* EXONERATIONS */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-6">
                    <div>
                        <h1 className="font-semibold mt-4">Confidentialité</h1>
                    </div>

                    <div className="col-span-2">
                        <div className="flex justify-between w-full">
                            <div className="w-full rounded bg-white shadow p-6">
                                <div className="mt-2">
                                    <input type="checkbox" checked={user?.termsPrivacyPolicy || false} onChange={() => setUser({ ...user, termsPrivacyPolicy: !user?.termsPrivacyPolicy })} name="termsPrivacyPolicy" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                    <label className="text-gray-700 ml-2">Accepter les termes et conditions</label>
                                </div>

                                <div className="mt-2">
                                    <input checked={user?.rgpd || false} onChange={() => setUser({ ...user, rgpd: !user?.rgpd })} name="rgpd" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                    <label className="text-gray-700 ml-2">RGPD</label>
                                </div>

                                <div className="mt-2">
                                    <input type="checkbox" checked={user?.newsletter || false} onChange={() => setUser({ ...user, newsletter: !user?.newsletter })} name="newsletter" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                    <label className="text-gray-700 ml-2">Newsletter</label>
                                </div>

                                <div className="mt-2">
                                    <input type="checkbox" checked={user?.partnerOffer || false} onChange={() => setUser({ ...user, partnerOffer: !user?.partnerOffer })} name="partnerOffer" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                    <label className="text-gray-700 ml-2">Les offres de nos partenaires</label>
                                </div>
                                <p className="mt-1 text-[#6d7175] opacity-[.45] font-semibold mt-3 text-sm">Demandez la permission à vos clients avant de les abonner à vos e-mails ou SMS de marketing.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddCustomer;
