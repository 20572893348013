import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { addCollection, editCollection, getCollectionById } from "../actions/collections";
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import { usePrompt } from '../utils/prompt';

const AddCollection = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const id = location?.pathname?.split('/')[3];
    const [collection, setCollection] = useState([]);
    const [dataObj, setDataObj] = useState({});
    const [dataChanged, setDataChanged] = useState(false);
    const status = [{ name: "Actif" }, { name: "Inactif" }];
    const [errorInput, setErrorInput] = useState([]);
    const [idCollectionCreated, setIdCollectionCreated] = useState();

    useEffect(() => {

        if (id) {
            getCollectionById(id).then((res) => {
                setCollection(res);
                setDataObj(res);
            })
        }
    }, []);

    useEffect(() => {
        compareObjects()
    }, [collection]);

    const compareObjects = () => {

        const collectionKeys = Object.keys(collection);

        const isDifferent = collectionKeys.some((key) => {
            const collectionValue = collection[key];
            const dataObjValue = dataObj[key];

            if (typeof collectionValue !== "object" && typeof dataObjValue !== "object") {
                return collectionValue !== dataObjValue;
            }

            return JSON.stringify(collectionValue) !== JSON.stringify(dataObjValue);
        });

        setDataChanged(isDifferent);
    };

    const handleResetData = () => {
        setCollection(dataObj);
        setErrorInput([]);
    }

    const handleEditCollection = () => {
        const data = {
            name: collection?.name,
            slug: collection?.slug,
            description: collection?.description,
            disabled: collection?.disabled,
            id: id
        }

        editCollection(data)
            .then((res) => {
                NotificationManager.success("Votre collection a été modifier avec succès !", 'Succès');
                setDataChanged(false);
            })
            .catch((err) => {
                NotificationManager.error(err?.response?.data?.error, 'Erreur')
            })
    }

    const handleCreateCollection = () => {
        const data = {
            name: collection?.name,
            slug: collection?.slug,
            description: collection?.description,
            disabled: collection?.disabled
        }

        addCollection(data)
            .then((res) => {
                NotificationManager.success("Votre collection a été créer avec succès !", 'Succès');
                setDataChanged(false);
                setIdCollectionCreated(res?._id);
            })
            .catch((err) => {
                Object.keys(err?.response?.data?.error).map((key) => {
                    NotificationManager.error(err?.response?.data?.error[key], 'Erreur');
                    setErrorInput((state) => [...state, key]);
                })
            })
    }

    useEffect(() => {
        if (idCollectionCreated) {
            navigate('/edit/collection/' + idCollectionCreated)
        }
    }, [idCollectionCreated])

    return (
        usePrompt(dataChanged),
        <>
            <div className={`fixed bg-[#080404] top-0 py-[.7rem] px-5 w-full left-0 z-[999] m-0 text-center test ${dataChanged ? "transition ease-in-out delay-250 opacity-1 duration-500" : "opacity-0 delay-250 duration-500"}`}>
                <div className="flex justify-between text-[white]">
                    <div className="cursor-pointer" onClick={() => navigate('/')}>
                        <svg fill="currentColor" width="38" height="38" viewBox="0 0 174 174" xmlns="http://www.w3.org/2000/svg">
                            <path fill="white" fillRule="evenodd" clipRule="evenodd" d="M0 9.23318C0 4.13387 4.13275 0 9.23075 0H164.769C169.867 0 174 4.13387 174 9.23318V41.7256C168.389 42.1805 163.181 43.5167 158.377 45.7341C152.334 48.4078 147.394 52.1858 143.559 57.0681C140.421 51.9533 136.12 48.1172 130.658 45.5598C125.312 42.8861 119.152 41.5493 112.178 41.5493C105.786 41.5493 99.9745 42.6537 94.7445 44.8623C89.5144 47.0711 85.1559 50.2678 81.6691 54.4526V42.9386H60.9231V135.708H82.7151V88.4543C82.7151 79.3872 84.8653 72.4706 89.1657 67.7045C93.5822 62.9385 99.5097 60.5555 106.948 60.5555C113.689 60.5555 118.861 62.6479 122.464 66.8327C126.067 70.9013 127.868 77.0041 127.868 85.1414V135.708H149.661V88.4543C149.661 79.5034 151.869 72.6449 156.285 67.8789C160.702 62.9966 166.571 60.5555 173.893 60.5555L174 60.5557V164.767C174 169.866 169.867 174 164.769 174H9.23075C4.13275 174 0 169.866 0 164.767V9.23318ZM112.321 69.9598C106.222 69.9598 101.199 72.2675 97.4993 76.8928L97.4895 76.9051L97.4798 76.9175C93.89 81.527 92.2757 87.9726 92.2757 95.8953V135.709H97.1218V95.8953C97.1218 88.5482 98.631 83.3425 101.29 79.9206C104.013 76.523 107.607 74.8191 112.321 74.8191V69.9598Z"></path>
                        </svg>
                    </div>

                    <div className="mt-2 font-bold text-md">
                        Modifications non enregistrées
                    </div>

                    <div className="flex flex-row">
                        <div onClick={() => handleResetData()} className='border-[1px] border-gray-200 hover:bg-[#eb2f06] hover:border-[#eb2f06] rounded  hover:opacity-[1] cursor-pointer flex flex-row px-4 py-2 mr-2'>
                            <i className="fa fa-times text-gray-200 text-[27px] relative top-[.33rem]" />
                            <span className="text-gray-200 ml-1 mt-[.1rem] font-bold">Annuler</span>
                        </div>
                        <div onClick={() => id ? handleEditCollection() : handleCreateCollection()} className='bg-indigo-700 hover:bg-indigo-500 rounded  hover:opacity-[1] cursor-pointer flex flex-row px-4 py-2'>
                            <i className="fa fa-save text-[white] text-[25px]" />
                            <span className="text-[white] ml-2 mt-[.1rem] font-bold">Enregistrer</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-margin">
                <div className="flex row">
                    <button onClick={() => navigate('/products', { state: { from: "/add/collection" }} )} className="text-white cursor-pointer border-[1.2px] border-[#babfc3] bg-transparent transition duration-150 ease-in-out w-10 h-10 rounded flex items-center justify-center ">
                        <svg viewBox="0 0 20 20" height={25} width={25} fill='#5c5f62' focusable="false" aria-hidden="true"><path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path></svg>
                    </button>

                    {!id
                        ? <h1 className="text-xl font-semibold mt-1 ml-4">Ajouter une collection</h1>
                        : <h1 className="text-xl font-semibold mt-1 ml-4">Éditer une collection</h1>
                    }

                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-4 mt-10">
                    <div className="col-span-2">
                        <div className="flex justify-between w-full">
                            <div className="w-full rounded bg-white shadow p-6">

                                {/* TITLE INPUT */}
                                <div className="relative w-full">
                                    <label className="text-gray-700">
                                        Nom <span className="text-[red]">*</span>
                                    </label>
                                    <input type="text" value={collection?.name || ''} onChange={(e) => setCollection({ ...collection, name: e?.target?.value })} className={`rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2 ${!collection?.name && errorInput.includes("name") && 'border-[1px] border-[red]'}`} name="collection_name" placeholder="Automne & Hiver" />
                                </div>

                                {/* DESCRIPTION INPUT */}
                                <div className="mt-4">
                                    <label className="text-gray-700">
                                        Description
                                    </label>
                                    <form>
                                        <div className="mb-4 w-full bg-gray-50 rounded-lg border border-gray-200 mt-2">
                                            <div className="flex justify-between items-center py-2 px-3 border-b">
                                                <div className="flex flex-wrap items-center divide-gray-200 sm:divide-x">
                                                    <div className="flex items-center space-x-1 sm:pr-4">
                                                        <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd"></path></svg>
                                                        </button>
                                                        <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path></svg>
                                                        </button>
                                                        <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd"></path></svg>
                                                        </button>
                                                        <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                        </button>
                                                        <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z" clipRule="evenodd"></path></svg>
                                                        </button>
                                                    </div>
                                                    <div className="flex flex-wrap items-center space-x-1 sm:pl-4">
                                                        <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                                                        </button>
                                                        <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path></svg>
                                                        </button>
                                                        <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                                                        </button>
                                                        <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
                                                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                <button type="button" data-tooltip-target="tooltip-fullscreen" className="p-2 text-gray-500 rounded cursor-pointer sm:ml-auto hover:text-gray-900 hover:bg-gray-100">
                                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 11-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 010-2h4a1 1 0 011 1v4a1 1 0 01-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 012 0v1.586l2.293-2.293a1 1 0 111.414 1.414L6.414 15H8a1 1 0 010 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.586l-2.293-2.293a1 1 0 111.414-1.414L15 13.586V12a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                                </button>
                                                <div id="tooltip-fullscreen" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
                                                    Show full screen
                                                    <div className="tooltip-arrow" data-popper-arrow></div>
                                                </div>
                                            </div>
                                            <div className="py-2 px-4 bg-white rounded-b-lg">
                                                <label className="sr-only">Publish post</label>
                                                <textarea id="editor" value={collection?.description || ''} onChange={(e) => setCollection({ ...collection, description: e?.target?.value })} rows="8" className="block px-0 w-full text-sm text-gray-800 bg-white border-0 focus:ring-0" placeholder="" required></textarea>
                                            </div>
                                        </div>
                                    </form>
                                </div>


                                <div className="relative w-full">
                                    <label className="text-gray-700">
                                        Slug
                                    </label>
                                    <input type="text" value={collection?.slug || ''} onChange={(e) => setCollection({ ...collection, slug: e?.target?.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="email" placeholder="/arts-et-loisirs" />

                                    <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-3">Sera généré automatiquement si vous laissez le champ vide.</p>

                                </div>


                            </div>
                        </div>

                        <div className="flex justify-between w-full mt-4 mb-4">
                            <div className="w-full rounded bg-white shadow p-6">
                                <h1 className="font-semibold mb-4">Support multimédia</h1>

                                <div className="w-full rounded border-dashed hover:border-indigo-300 border-2 border-gray-300 p-14 text-center cursor-pointer">
                                    <h2>Ajouter un fichier</h2>
                                    <p>Accepte les images, les vidéos ou les modèles 3D</p>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div>
                        <div className="flex items-center justify-between w-full">
                            <div className="flex-col w-full items-start lg:items-center rounded bg-white shadow">
                                <div className="p-6">
                                    <h1 className="font-semibold mb-4">Statut</h1>

                                    <Select
                                        isMulti={false}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        styles={{
                                            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                            menu: (provided) => ({ ...provided, zIndex: 9999 })
                                        }}
                                        value={collection?.disabled ? { name: "Inactif" } : { name: "Actif" }}
                                        onChange={(item) => item?.name === "Actif" ? setCollection({ ...collection, disabled: false }) : setCollection({ ...collection, disabled: true })}
                                        options={status}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.name}
                                        placeholder={"Actif"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default AddCollection;

