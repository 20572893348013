import { GET_ALL_USERS } from "./types";
import apiAuth from "../api/users";

// GET ALL USERS
export const getAllUsers = () =>
    async (dispatch) => {
        return apiAuth
            .getAllUsers()
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_USERS, payload: result.data })
                    return result?.data
                }
            })
    };

// GET USER BY ID
export const getUserById = async (id) => {
    const result = await apiAuth
        .getUserById(id);

    if (result?.data) {
        return result.data;
    }
};

// EDIT USER
export const editUser = async (data) => {
    return apiAuth
        .editUser(data)
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};

// CREATE USER
export const createUser = (data) =>
    async (dispatch) => {
        return apiAuth
            .createUser(data)
            .then(async (result) => {
                if (result) {
                    return result
                }
            })
    }

// DELETE USER BY ID
export const deleteUserById = async (id) => {
    return apiAuth
        .deleteUserById(id)
        .then(async (result) => {
            if (result) {
                return result
            }
        })
}

// EDIT SHIPPING ADDRESSE BY USER ID
export const editShippingAddressByUserId = (id, data) =>
    async () => {
        return apiAuth
            .editShippingAddressByUserId(id, data)
            .then(async (result) => {
                if (result) {
                    return result
                }
            })
    }

export const createShippingAddressByUserId = (id, data) =>
    async () => {
        return apiAuth
            .createShippingAddressByUserId(id, data)
            .then(async (result) => {
                if (result) {
                    return result
                }
            })
    }

// DELETE SHIPPING ADDRESS BY USER ID
export const deleteShippingAddressByUserId = (userId, addressId) =>
    async () => {
        return apiAuth
            .deleteShippingAddressByUserId(userId, addressId)
            .then(async (result) => {
                if (result) {
                    return result
                }
            })
    }
