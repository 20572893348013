import { apiClient } from './ApiClient'

function getAllCollections() {
    return apiClient().get('/admin/collection/')
}

function deleteCollectionById(id) {
    return apiClient().delete('/admin/collection/' + id)
}

function getCollectionById(id) {
    return apiClient().get('/admin/collection/' + id)
}

function editCollection(data) {
    return apiClient().patch('/admin/collection/', data)
}

function addCollection(data) {
    return apiClient().post('/admin/collection/', data)
}

export default {
    getAllCollections,
    deleteCollectionById,
    getCollectionById,
    editCollection,
    addCollection
}
