import apiSearch from "../api/search";


// SEARCH 
export const search = (query) =>
    async () => {
        return apiSearch
            .search(query)
            .then(async (result) => {
                if (result) {
                    return result
                }
            })
    }