import { GET_ALL_LANGUAGES } from "./types";
import apiAuth from "../api/languages";

// GET ALL LANGUAGES
export const getAllLanguages = () =>
    async (dispatch) => {
        return apiAuth
            .getAllLanguages()
            .then(async (result) => {
                if (result) {
                    dispatch({ type: GET_ALL_LANGUAGES, payload: result.data })
                    return result?.data
                }
            })
    };

// ADD LANGUAGE
export const addLanguage = async (data) => {
    const result = await apiAuth
        .addLanguage(data);
    if (result) {
        return result;
    }
}

// EDIT LANGUAGE BY ID
export const editLanguage = async (data) => {
    const result = await apiAuth
        .editLanguage(data);
    if (result) {
        return result?.data;
    }
}

// GET LANGUAGE BY ID
export const getLanguageById = async (id) => {
    const result = await apiAuth
        .getLanguageById(id);
    if (result) {
        return result?.data;
    }
}
// DELETE LANGUAGE BY ID
export const deleteLanguage = async (id) => {
    const result = await apiAuth
        .deleteLanguage(id);
    if (result) {
        return result?.data;
    }
}
