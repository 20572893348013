import { GET_ALL_DISCOUNTS } from '../actions/types'

const defaultState = {}

export default (state = defaultState, action) => {
    const { type, payload, error = {} } = action

    switch (type) {
        case GET_ALL_DISCOUNTS:
            return { ...state, ...payload }
        default:
            return state
    }
}