import { GET_COUNT_ANALYTICS } from "./types";
import apiAnalytics from "../api/analytics";

// GET COUNT ANALYTICS
export const getCountAnalytics = (data) =>
    async (dispatch) => {
        return apiAnalytics
            .getCountAnalytics(data)
            .then(async (result) => {
                if (result?.data) {
                    await dispatch({ type: GET_COUNT_ANALYTICS, payload: result.data })
                    return result.data
                }
            })
    };

// GET RECENT ORDER
export const getRecentOrder = async () => {
    const result = await apiAnalytics
        .getRecentOrder();

    if (result) {
        return result?.data;
    }
};

// GET BEST SELLER
export const getBestSeller = async (data) => {
    const result = await apiAnalytics
        .getBestSeller(data);

    if (result) {
        return result?.data;
    }
};