import axios from "axios";

export const apiClient = (contentType) => {

    // const token = localStorage.getItem('tokenBO');
    const token = localStorage.getItem('back_end_token')
    
    const options = {
        baseURL: localStorage.getItem('back_end_url'),
        responseType: "json",
        
        headers: {
            post: { 
                "Content-Type": contentType || "application/json"
             },
            Accept: "application/json",

            'Access-Control-Allow-Origin': '*',
            'locale': 'FR',
            'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept",
            'Access-Control-Allow-Credentials': "true",
            'x-access-token': token && token
        },
    };

    const instance = axios.create(options);

    return instance;
};
