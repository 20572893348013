import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { editOrder, getOrderById, printLabel } from "../actions/orders";
import { getUserById } from "../actions/users";
import Moment from 'moment';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'; // Assurez-vous d'importer Leaflet
import Select from 'react-select';
import { PDFDownloadLink } from '@react-pdf/renderer'
import Pdf from '../utils/pdf/pdf';
import { getStore } from "../actions/stores";
import { NotificationManager } from 'react-notifications';
import status from '../utils/status.json';
import jsPDF from 'jspdf';
import { IMG_LINK } from "../config/key";

const DetailOrder = () => {
    let location = useLocation();
    let prevDate = null;

    const id = location?.pathname?.split('/')[2];
    const navigate = useNavigate();
    const [order, setOrder] = useState([]);
    const [user, setUser] = useState([]);
    const [events, setEvents] = useState([]);
    const [formPdf, setFormPdf] = useState();
    const [selectStatusOrder, setSelectStatusOrder] = useState();
    const [parcel, setParcel] = useState([]);
    const statusOptions = Object.entries(status).map(([value, { icon, color, text }]) => ({ value, label: text, icon, color }));
    const [modalPrintLabel, setModalPrintLabel] = useState(false);
    const [nbLabel, setNbLabel] = useState(0);
    const [nbLabelItem, setNbLabelItem] = useState(0);

    const customIcon = L.icon({
        iconUrl: '../../marker-icon-2x.png',
        iconSize: [20, 30],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32]
    });

    const daysOfWeekFr = {
        "MON": "Lundi",
        "TUE": "Mardi",
        "WED": "Mercredi",
        "THU": "Jeudi",
        "FRI": "Vendredi",
        "SAT": "Samedi",
        "SUN": "Dimanche"
    };

    useEffect(() => {
        getOrderById(id).then((order) => {
            setOrder(order);

            const objTemp = []

            Object.keys(order?.status).map((key) => {
                const newObj = { ts: order?.status[key]?.updatedAt, text: order?.status[key]?.status, admin: order?.status[key]?.admin };

                objTemp.push(newObj);
            })

            setEvents(objTemp);

            getStore()
                .then((res) => {
                    getUserById(order?.userId).then((user) => {
                        setUser(user)

                        setFormPdf({
                            initialValues: {
                                id: order?._id,
                                cart: order?.cart,
                                discount: order?.discount,
                                order: order,
                                store: res,
                                user: user
                            },
                        })
                    })

                })
                .catch(() => {
                    NotificationManager.error('Une erreur est survenue lors de la récupération de la boutique', 'Erreur')
                })




        })
    }, [])

    const handleChangeStateOrder = (item) => {
        setSelectStatusOrder(item?.value);
    }

    const handleSubmitStatusOrder = () => {
        const data = {
            "orderId": id,
            "status": selectStatusOrder
        }

        editOrder(data).then((res) => {
            setOrder(res);

            NotificationManager.success("Votre statut de commande a bien été changé !", 'Succès')

            const objTemp = []

            Object.keys(res?.status).map((key) => {
                const newObj = { ts: res?.status[key]?.updatedAt, text: res?.status[key]?.status, admin: res?.status[key]?.admin };

                objTemp.push(newObj);
            })

            setEvents(objTemp)

        }).catch(() => {
            NotificationManager.error("Une erreur est survenue lors de la modification du statut de la commande !", 'Erreur')
        })
    }

    const handlePrintLabel = () => {
        const data = {
            orderId: id,
            parcel: parcel
        }

        printLabel(data)
            .then((res) => {
                NotificationManager.success("Étiquette(s) générée(s)", 'Succès');
                setModalPrintLabel(false);
                getOrderById(id).then((order) => {
                    setOrder(order);
                })
            })
            .catch((err) => {
                NotificationManager.error(err?.response?.data?.error, 'Erreur');
            })
    }

    const handleAddLabel = (labelNumber) => {
        if (nbLabel > labelNumber) {
            NotificationManager.error(`Pour cette commande vous n'avez le droit qu'à ${labelNumber} étiquettes maximum !`, 'Erreur')
        } else {
            setNbLabelItem(nbLabel)
        }
    }

    const handleChangeWeight = (e, index) => {
        const { value } = e.target;
        setParcel((prevParcel) => {
            const updatedParcel = [...prevParcel];
            updatedParcel[index] = {
                ...updatedParcel[index],
                weight: Number(value),
            };
            return updatedParcel;
        });
    };

    const handleChangeWidth = (e, index) => {
        const { value } = e.target;
        setParcel((prevParcel) => {
            const updatedParcel = [...prevParcel];
            updatedParcel[index] = {
                ...updatedParcel[index],
                width: Number(value),
            };
            return updatedParcel;
        });
    };

    const handleChangeHeight = (e, index) => {
        const { value } = e.target;
        setParcel((prevParcel) => {
            const updatedParcel = [...prevParcel];
            updatedParcel[index] = {
                ...updatedParcel[index],
                height: Number(value),
            };
            return updatedParcel;
        });
    };

    const handleChangeLength = (e, index) => {
        const { value } = e.target;
        setParcel((prevParcel) => {
            const updatedParcel = [...prevParcel];
            updatedParcel[index] = {
                ...updatedParcel[index],
                length: Number(value),
            };
            return updatedParcel;
        });
    };

    const handlePrintLabelOrder = (key) => {
        const base64Image = `data:image/png;base64, ${order?.labelReturn?.PrintData[key]?.Data}`;

        const doc = new jsPDF();
        const img = new Image();
        img.src = base64Image;

        img.onload = function () {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            context.drawImage(img, 0, 0, img.width, img.height);

            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            doc.addImage(imgData, 'JPEG', 10, 10, 190, 250); // Ajustez les paramètres selon vos besoins

            const pdfFileName = `ETQ_${order?.labelReturn?.ParcelData[key]?.TrackID}.pdf`;
            doc.save(pdfFileName);
        };
    };

    return (
        <div className="container-margin">

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-4 md:gap-4 lg:gap-4 mt-6">
                <div className="col-span-2">
                    <div className="flex justify-between w-full">
                        <div className="w-full rounded bg-white shadow p-6">
                            <div className="flex flex-wrap justify-between">
                                <div>
                                    <div>

                                        <h1 className="text-3xl font-semibold"> COMMANDE-{Moment(order?.createdAt).format("YYYY")}{Moment(order?.createdAt).format("MM")}-{order?.publicOrderId}</h1>
                                    </div>
                                    <p className="text-sm text-[#6d7175] opacity-[.45] font-semibold mt-2">{Moment(order?.createdAt).format("LL")} à {Moment(order?.createdAt).format("LT")}</p>
                                </div>

                                <div className="flex flex-row">


                                    {formPdf !== undefined &&
                                        order?.status[order?.status.length - 1]?.status !== "payment_requires_payment_method" &&
                                        order?.status[order?.status.length - 1]?.status !== "payment_requires_confirmation" &&
                                        order?.status[order?.status.length - 1]?.status !== "payment_requires_action" &&
                                        order?.status[order?.status.length - 1]?.status !== "payment_processing" &&
                                        order?.status[order?.status.length - 1]?.status !== "order_cancelled" && (
                                            <PDFDownloadLink
                                                className="mr-2"
                                                document={<Pdf {...formPdf} />}
                                                fileName={`COMMANDE-${Moment(order?.createdAt).format("YYYYMM")}-${order?.publicOrderId}.pdf`}
                                            >
                                                <button className="text-[black] hover:bg-[black] hover:text-[white] mt-[.2rem] w-full whitespace-nowrap cursor-pointer focus:outline-none border border-[black]  focus:shadow-outline-gray bg-[transparent] transition duration-150 ease-in-out p-4 h-8 rounded flex items-center justify-center">
                                                    Facture
                                                </button>
                                            </PDFDownloadLink>
                                        )
                                    }



                                    {order?.status &&
                                        <div className="mt-2 whitespace-no-wrap">

                                            <span className={`font-[500] p-2 rounded text-[15px] text-[white]`} style={{ backgroundColor: status[order?.status[order?.status.length - 1]?.status].color }}>
                                                <i className={`${status[order?.status[order?.status.length - 1]?.status].icon}`}></i>
                                                <span className="ml-1">{status[order?.status[order?.status.length - 1]?.status].text}</span>
                                            </span>
                                        </div>
                                    }
                                </div>


                            </div>

                            <hr className="mt-5" />

                            <div className="grid grid-cols-1 lg:grid-cols-4">
                                <div className="mt-5">

                                    <div>

                                        <div className="opacity-[.45] font-semibold text-sm text-[#6d7175] mb-1">
                                            Client
                                        </div>

                                        <div className="break-words ">
                                            <span onClick={() => navigate("/edit/user/" + order?.userId)} className="border-dotted border-b-2 border-[#c4cdd5] cursor-pointer">{user?.name} {user?.lastname}</span>
                                        </div>
                                    </div>

                                    <div>

                                        <div className="opacity-[.45] font-semibold text-sm text-[#6d7175] mt-4">
                                            Adresse mail
                                        </div>

                                        <div className="break-words">
                                            {user?.email}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <div className="opacity-[.45] font-semibold text-sm text-[#6d7175] mb-1">
                                        Téléphone
                                    </div>
                                    <div>
                                        {user?.phone}
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <div className="opacity-[.45] font-semibold text-sm text-[#6d7175] mb-1">
                                        Livraison
                                    </div>
                                    <div>
                                        {order?.delivery?.relayId
                                            ? <>
                                                {order?.delivery?.relayPointInfo?.Address
                                                    ? <>
                                                        <div>{order?.delivery?.relayPointInfo?.Address?.Name1}</div>
                                                        <div>{order?.delivery?.relayPointInfo?.Address?.Street}</div>
                                                        <div>{order?.delivery?.relayPointInfo?.Address?.City} {order?.delivery?.relayPointInfo?.Address?.ZIPCode}, {order?.delivery?.relayPointInfo?.Address?.CountryCode}</div>
                                                    </>
                                                    : <>
                                                        <div>{order?.delivery?.relayPointInfo[0]?.LgAdr1}</div>
                                                        <div>{order?.delivery?.relayPointInfo[0]?.LgAdr2}</div>
                                                        <div>{order?.delivery?.relayPointInfo[0]?.LgAdr3}</div>
                                                        <div>{order?.delivery?.relayPointInfo[0]?.LgAdr4}</div>
                                                        <div>{order?.delivery?.relayPointInfo[0]?.Ville} {order?.delivery?.relayPointInfo[0]?.CP}, {order?.delivery?.relayPointInfo[0]?.Pays}</div>
                                                    </>
                                                }
                                            </>
                                            : <>
                                                {order?.shippingAddress?.address} <br /> {order?.shippingAddress?.postalCode} {order?.shippingAddress?.city}, {order?.shippingAddress?.country}
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <div className="opacity-[.45] font-semibold text-sm text-[#6d7175] mb-1">
                                        Facturation
                                    </div>
                                    <div>
                                        {order?.billingAddress?.address} <br /> {order?.billingAddress?.postalCode} {order?.billingAddress?.city}, {order?.billingAddress?.country}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between w-full mt-4">
                        <div className="w-full rounded bg-white shadow">
                            <h1 className="font-semibold mb-10 text-xl px-6 pt-6">Récapitulatif</h1>

                            {order?.cart?.item &&
                                <div className="ppx-0 md:px-6 lg:px-6 xl:px-6">
                                    <table className="min-w-full bg-white mt-10">
                                        <thead>
                                            <tr className="w-full h-16 border-gray-300 border-b py-8">
                                                <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Article</th>
                                                <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold"></th>
                                                <th className="text-gray-600 pr-6 text-right text-sm tracking-normal leading-4 font-semibold whitespace-nowrap">Prix unitaire</th>
                                                <th className="text-gray-600 pr-6 text-right text-sm tracking-normal leading-4 font-semibold">Quantité</th>
                                                <th className="text-gray-600 text-right text-sm tracking-normal leading-4 font-semibold whitespace-nowrap">Prix total</th>
                                            </tr>
                                        </thead>

                                        {Object.keys(order?.cart?.item).map((key, index) => (
                                            <tbody key={index}>
                                                <tr key={id} className="md:h-24 lg:h-24 xl:h-24 border-gray-300 border-b cursor-pointer">
                                                    <td className="pr-6 whitespace-no-wrap">
                                                        <div className="flex justify-between" >
                                                            <div className="flex items-center responsive-thead hidden">
                                                                <span className="font-semibold text-sm">Image du produit</span>
                                                            </div>
                                                            <div className="flex items-center">
                                                                {order?.cart?.item[key]?.itemRef?.images?.thumbnailId || order?.cart?.item[key]?.itemRef?.images?.image1Id ? (
                                                                    <img
                                                                        src={
                                                                            IMG_LINK +
                                                                            (order?.cart?.item[key]?.itemRef?.images?.thumbnailId || order?.cart?.item[key]?.itemRef?.images?.image1Id)
                                                                        }
                                                                        width={50}
                                                                        alt="produit"
                                                                    />
                                                                ) : (
                                                                    <img src="../../placeholder.jpeg" width={50} />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="pr-6 whitespace-no-wrap pr-40 text-right">
                                                        <div className="flex justify-between">
                                                            <span className="responsive-thead hidden font-semibold text-sm">Nom</span>
                                                            {order?.cart?.item[key]?.name}
                                                        </div>
                                                    </td>
                                                    <td className="pr-6 whitespace-no-wrap text-right">
                                                        <div className="flex justify-between">
                                                            <span className="responsive-thead hidden font-semibold text-sm">Prix unitaire</span>
                                                            {order?.cart?.item[key]?.priceWithDiscount !== undefined && order?.cart?.item[key]?.priceWithDiscount !== order?.cart?.item[key]?.price
                                                                ? <>
                                                                    <span className="line-through text-[#e74c3c] opacity-[.75]">{order?.cart?.item[key]?.price?.toFixed(2)} €</span>

                                                                    <span className="ml-3 mr-1 bg-gray-300 p-1 rounded text-[13px]">- {order?.discount?.amount} {order?.discount?.type === "percentage" ? "%" : "€"}</span>

                                                                    <span className="ml-2">{order?.cart?.item[key]?.priceWithDiscount?.toFixed(2)} €</span>
                                                                </>
                                                                : order?.cart?.item[key]?.rebatedPrice
                                                                    ? <>
                                                                        <span className="ml-2">{order?.cart?.item[key]?.rebatedPrice?.toFixed(2)} €</span>
                                                                        <span className="ml-2 text-red-500 line-through">{order?.cart?.item[key]?.price?.toFixed(2)} €</span>
                                                                    </>
                                                                    : <span className="ml-2">{order?.cart?.item[key]?.price?.toFixed(2)} €</span>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="whitespace-no-wrap text-center">
                                                        <div className="flex justify-between">
                                                            <span className="responsive-thead hidden font-semibold text-sm">Quantité</span>
                                                            {order?.cart?.item[key]?.quantity}
                                                        </div>
                                                    </td>

                                                    <td className="whitespace-no-wrap text-right">
                                                        <div className="flex justify-between">
                                                            <span className="responsive-thead hidden font-semibold text-sm">Prix total</span>
                                                            {order?.discount ? order?.cart?.item[key]?.totalPriceWithDiscount?.toFixed(2) : order?.cart?.item[key]?.totalPrice?.toFixed(2)} €
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        ))}

                                    </table>
                                </div>
                            }

                            <div className="px-6 pb-6">
                                <div className="flex justify-between items-center mt-10">

                                    <div className="flex flex-col">
                                        <div className="text-grey-90 inter-small-regular">
                                            Code Promo
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="inter-small-regular mr-3 ">
                                            {order?.discountCode || "Aucun"}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center mt-4">

                                    <div className="flex flex-col">
                                        <div className="text-grey-90 inter-small-regular">
                                            Prix HT
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="inter-small-regular mr-3">
                                            {order?.priceWithoutTaxe?.toFixed(2)}
                                        </div>
                                        <div className="opacity-[.45] font-semibold text-[#6d7175]">
                                            €
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between items-center mt-4">

                                    <div className="flex flex-col">
                                        <div className="text-grey-90 inter-small-regular">
                                            Prix TTC
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="inter-small-regular mr-3">
                                            {order?.discountedPrice?.toFixed(2)}
                                        </div>
                                        <div className="opacity-[.45] font-semibold text-[#6d7175]">
                                            €
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between mt-4 items-center">
                                    <div className="flex flex-col">
                                        <div className="inter-small-regular">
                                            Livraison
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="inter-small-regular mr-3">
                                            {order?.deliveryPrice?.toFixed(2)}
                                        </div>
                                        <div className="opacity-[.45] font-semibold text-[#6d7175]">
                                            €
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-between mt-4 items-center">
                                    <div className="flex flex-col">
                                        <div className="font-semibold">
                                            Total
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="font-semibold text-2xl">
                                            {order?.totalPrice?.toFixed(2)}
                                        </div>
                                        <div className="opacity-[.45] font-semibold text-[#6d7175] mt-1 ml-3">
                                            €
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between w-full mt-4">
                        <div className="w-full rounded bg-white shadow">
                            <h1 className="font-semibold mb-4 text-xl px-6 pt-6">Livraison</h1>
                            <hr />

                            <div className="mt-10 px-6 pb-6">

                                {order?.delivery?.delivery?.relayPoint === true
                                    ? <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 ">
                                        <div className="mr-5 mb-8">
                                            <div>{order?.delivery?.delivery?.shipperName}</div>

                                            <div className="mb-6">{order?.delivery?.delivery?.description}</div>

                                            {order?.delivery?.relayPointInfo[0] !== undefined

                                                ? <>
                                                    <div>{order?.delivery?.relayPointInfo[0]?.LgAdr1}</div>
                                                    <div>{order?.delivery?.relayPointInfo[0]?.LgAdr2}</div>
                                                    <div>{order?.delivery?.relayPointInfo[0]?.LgAdr3}</div>
                                                    <div>{order?.delivery?.relayPointInfo[0]?.LgAdr4}</div>
                                                    <div>{order?.delivery?.relayPointInfo[0]?.Ville} {order?.delivery?.relayPointInfo[0]?.CP}, {order?.delivery?.relayPointInfo[0]?.Pays}</div>
                                                </>

                                                : <>
                                                    <div>{order?.delivery?.relayPointInfo?.Address?.Name1}</div>
                                                    <div>{order?.delivery?.relayPointInfo?.Address?.Street}</div>
                                                    <div>{order?.delivery?.relayPointInfo?.Address?.City} {order?.delivery?.relayPointInfo?.Address?.ZIPCode}, {order?.delivery?.relayPointInfo?.Address?.CountryCode}</div>


                                                </>
                                            }
                                        </div>

                                        {order?.delivery?.relayPointInfo[0]?.URL_Plan &&
                                            <div className="z-0 flex justify-end">
                                                <iframe src={order?.delivery?.relayPointInfo[0]?.URL_Plan}></iframe>
                                            </div>
                                        }

                                        {order?.delivery?.relayPointInfo?.Address?.Name1 &&
                                            <div className="z-0 flex justify-end">
                                                <MapContainer
                                                    center={[order?.delivery?.relayPointInfo?.Location?.Latitude, order?.delivery?.relayPointInfo?.Location?.Longitude]}
                                                    zoom={13}
                                                    className="z-[0]"
                                                    scrollWheelZoom={true}
                                                    id="mapGLS"
                                                >
                                                    <TileLayer
                                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    />
                                                    <Marker
                                                        icon={customIcon}
                                                        key={order?.delivery?.ParcelShopID}
                                                        position={[Number(order?.delivery?.relayPointInfo?.Location?.Latitude), Number(order?.delivery?.relayPointInfo?.Location?.Longitude)]}
                                                    >
                                                        <Popup className="m-0">
                                                            <h1 className="mb-2">{order?.delivery?.relayPointInfo?.Address?.Name1}</h1>
                                                            <h1>{order?.delivery?.relayPointInfo?.Address?.Street}</h1>
                                                            <h1 className="mb-2">{order?.delivery?.relayPointInfo?.Address?.City} {order?.delivery?.relayPointInfo?.Address?.ZIPCode}</h1>

                                                            <h1 className="mb-1">Horaires :</h1>
                                                            <ul>
                                                                {Object.keys(order?.delivery?.relayPointInfo?.WorkingDay)?.map((key) => (
                                                                    <li key={key}>
                                                                        {daysOfWeekFr[order?.delivery?.relayPointInfo?.WorkingDay[key].DayOfWeek]}: {Moment(order?.delivery?.relayPointInfo?.WorkingDay[key]?.OpeningHours?.OpeningHours[0]?.From).format("HH:mm")} - {Moment(order?.delivery?.relayPointInfo?.WorkingDay[key]?.OpeningHours?.OpeningHours[0]?.To).format("HH:mm")}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </Popup>
                                                    </Marker>

                                                </MapContainer>
                                            </div>
                                        }
                                    </div>

                                    : <>
                                        <>
                                            {order?.delivery?.delivery?.name}
                                        </>


                                    </>
                                }


                            </div>
                        </div>

                    </div>
                </div>

                <div>
                    <div className="flex items-center justify-between w-full">
                        <div className="flex-col w-full items-start lg:items-center rounded bg-white shadow">
                            <div>
                                <h1 className="font-semibold mb-4 text-xl pt-6 px-6">Statut de la commande</h1>
                                <hr />
                                <div className="flex flex-row py-4 px-6">
                                    <div className="w-full">
                                        <Select
                                            isMulti={false}
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            styles={{
                                                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                            }}
                                            value={selectStatusOrder ? { label: status[selectStatusOrder]?.text, key: status[selectStatusOrder]?.text, value: status[selectStatusOrder]?.text } :
                                                statusOptions.find(
                                                    option =>
                                                        option?.value ===
                                                        order?.status?.[order?.status?.length - 1]?.status
                                                )
                                            }
                                            onChange={(item) => handleChangeStateOrder(item)}
                                            options={statusOptions}
                                            placeholder={"Choisir un ou plusieurs pays"}
                                        />
                                    </div>

                                    <button onClick={() => handleSubmitStatusOrder()} className="text-white mt-[.1rem] ml-2 whitespace-nowrap mr-2 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                                        Modifier
                                    </button>
                                </div>

                                <div className="flex flex-row text-[#6d7175] text-[.9rem] px-6 mb-3 opacity-[.45] font-semibold">
                                    <i className="fa fa-info-circle mt-1" aria-hidden="true"></i>
                                    <p className="ml-[.25rem]">Sélectionnez le statut de la commande et cliquez sur modifier.</p>
                                </div>


                            </div>
                        </div>
                    </div>



                    {order?.labelNumber > 0 &&
                        <div className="flex items-center justify-between w-full mt-4">
                            <div className="flex-col w-full items-start lg:items-center rounded bg-white shadow">
                                <div>
                                    <div className="flex flex-wrap justify-between px-6">
                                        <h1 className="font-semibold text-xl pt-6">Étiquettes</h1>

                                        {(order?.labelReturn?.ParcelData ? order?.labelReturn?.ParcelData.length : 0) === order.labelNumber && order.labelNumber
                                            ? <></>
                                            : <button onClick={() => setModalPrintLabel(!modalPrintLabel)} className="text-white mt-3 mb-4 whitespace-nowrap cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                                                Ajouter une/des étiquette(s)
                                            </button>
                                        }


                                    </div>
                                    <hr />
                                    <div className="py-4 px-4 text-[#6d7175] opacity-[.45] font-semibold">
                                        <div className="flex justify-between">
                                            <h1>Nombre(s) d'étiquette(s)</h1>
                                            <h1 className="font-bold">{order?.labelNumber}</h1>
                                        </div>
                                        <div className="flex justify-between mt-1">
                                            <h1>Nombre(s) d'article(s)</h1>
                                            <h1 className="font-bold">{order?.cart?.cartLength}</h1>
                                        </div>
                                        <div className="flex justify-between mt-1">
                                            <h1>Poids total de la commande</h1>
                                            <h1 className="font-bold">{order?.cart?.weight}g</h1>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex flex-row py-4 px-6">
                                        <div className="w-full flex flex-col gap-4">

                                            {order?.labelReturn && Object.keys(order?.labelReturn?.ParcelData).map((key) => (
                                                <div key={key} className="border-[1px] rounded p-3 flex justify-between">
                                                    <h1>{"ETQ_" + order?.labelReturn?.ParcelData[key]?.TrackID}</h1>
                                                    <div className="">

                                                        {order?.labelReturn?.PrintData[key]?.Url
                                                            ? <a target="NO_BLANK" className="mr-4 text-indigo-600 hover:underline" href={`https://www.mondialrelay.fr/suivi-de-colis/?numeroExpedition=${order?.labelReturn?.ParcelData[key]?.TrackID}&codePostal=${order?.shippingAddress?.postalCode}`}>Suivre ce colis</a>
                                                            : <a target="NO_BLANK" className="mr-4 text-indigo-600 hover:underline" href={`https://gls-group.com/FR/fr/suivi-colis?match=${order?.labelReturn?.ParcelData[key]?.TrackID}`}>Suivre ce colis</a>
                                                        }

                                                        {order?.labelReturn?.PrintData[key]?.Url
                                                            ? <a target="NO_BLANK" className="cursor-pointer" href={order?.labelReturn?.PrintData[key]?.Url}><i className="fa cursor-pointer fa-download relative" /></a>
                                                            : <i onClick={() => handlePrintLabelOrder(key)} className="fa cursor-pointer fa-download relative" />
                                                        }

                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="flex flex-row text-[#6d7175] text-[.9rem] px-6 mb-3 opacity-[.45] font-semibold">
                                        <i className="fa fa-info-circle mt-1" aria-hidden="true"></i>
                                        <p className="ml-[.25rem]">Ajoutez une ou plusieurs étiquettes de colis à votre commande.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }





                    <div className="flex items-center justify-between w-full mt-4">
                        <div className="flex-col w-full items-start lg:items-center rounded bg-white shadow">
                            <div>
                                <h1 className="font-semibold mb-4 text-xl pt-6 px-6">Timeline</h1>

                                <hr />

                                <div className="time-line-ctnr pt-6 px-6">
                                    {events.slice().reverse().map((it, key) => {

                                        let sameDate = (Moment(it?.ts).format('LL') === prevDate)
                                        prevDate = Moment(it?.ts).format('LL');

                                        return (
                                            <ul key={key} className="time-line">

                                                {!sameDate &&
                                                    <li className="time-label">
                                                        <span>{Moment(it?.ts).format('LL')}</span>
                                                    </li>
                                                }

                                                <li>
                                                    <i className="fa" />
                                                    <div className="time-line-item">
                                                        <div className="time-line-header font-semibold">

                                                            <span className="ml-1">{status[it?.text].text}</span>

                                                        </div>
                                                        <div className="flex justify-end text-[12px] mt-2 italic">
                                                            <i className="fa fa-clock-o mt-[.2rem]" />
                                                            {Moment(it?.ts).format('LL')} à {Moment(it?.ts).format('LT')} {" "}
                                                            {status[it?.text].text !== "Commande créée" &&
                                                                <>
                                                                    par
                                                                    <span className="underline cursor-pointer ml-1" onClick={() => navigate("/edit/user/" + it?.admin?._id)}>{it?.admin?.email}</span>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        )
                                    })}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {modalPrintLabel &&
                <>
                    <div style={{ zIndex: 999 }}
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button type="button" onClick={() => setModalPrintLabel(!modalPrintLabel)} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-hide="label-modal">
                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                                        Ajouter {nbLabelItem ? (nbLabelItem === 1 ? nbLabelItem + " étiquette" : nbLabelItem + " étiquettes") : "une/des étiquette(s)"}
                                    </h3>
                                </div>
                                <div className="p-6">

                                    <div className={`${nbLabelItem === 0 ? "block" : "hidden"}`}>
                                        <div>
                                            <a href="#" className="inline-flex items-center text-sm font-normal text-gray-500">
                                                <svg aria-hidden="true" className="w-4 h-4 mr-2" focusable="false" data-prefix="far" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"></path></svg>
                                                Combien d'étiquettes avez-vous besoin d'ajouter?</a>
                                        </div>

                                        <div className="w-full">
                                            <input type="number" onChange={(e) => setNbLabel(e?.target?.value)} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" name="old-password" placeholder="200" />
                                        </div>
                                    </div>

                                    <div className={`${nbLabelItem === 0 ? "hidden" : "block"}`}>
                                        <table className="min-w-full bg-white">
                                            <thead>
                                                <tr className="w-full h-16 border-gray-300 border-b">
                                                    <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Étiquette</th>
                                                    <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Poids (en grammes)<span className="text-[red] ml-1">*</span></th>
                                                    <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Largueur </th>
                                                    <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Hauteur</th>
                                                    <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Longueur</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {nbLabelItem && (
                                                    <>
                                                        {Array.from({ length: nbLabelItem }).map((_, index) => (
                                                            <tr className="lg:h-20 border-gray-300 border-b" key={index}>
                                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                                    {index + 1}
                                                                </td>
                                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                                    <input
                                                                        type="number"
                                                                        onChange={(e) => handleChangeWeight(e, index)}
                                                                        className="rounded-lg flex-1 appearance-none border border-gray-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                                                        name="old-password"
                                                                        placeholder="200"
                                                                    />
                                                                </td>
                                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                                    <input
                                                                        type="number"
                                                                        onChange={(e) => handleChangeWidth(e, index)}
                                                                        className="rounded-lg flex-1 appearance-none border border-gray-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                                                        name="old-password"
                                                                        placeholder="200"
                                                                    />
                                                                </td>
                                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                                    <input
                                                                        type="number"
                                                                        onChange={(e) => handleChangeHeight(e, index)}
                                                                        className="rounded-lg flex-1 appearance-none border border-gray-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                                                        name="old-password"
                                                                        placeholder="200"
                                                                    />
                                                                </td>
                                                                <td className="text-sm pr-6 whitespace-no-wrap text-gray-800 tracking-normal leading-4">
                                                                    <input
                                                                        type="number"
                                                                        onChange={(e) => handleChangeLength(e, index)}
                                                                        className="rounded-lg flex-1 appearance-none border border-gray-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                                                        name="old-password"
                                                                        placeholder="200"
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className={`flex justify-end ${nbLabelItem === 0 ? "block" : "hidden"}`}>
                                        <button onClick={() => handleAddLabel(order?.labelNumber)} className="text-white whitespace-nowrap mt-5 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                                            Ajouter
                                        </button>
                                    </div>
                                    <div className={`flex justify-end ${nbLabelItem === 0 ? "hidden" : "block"}`}>
                                        <button onClick={() => handlePrintLabel()} className="text-white whitespace-nowrap mt-5 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                                            Ajouter {nbLabelItem ? (nbLabelItem === 1 ? nbLabelItem + " étiquette" : nbLabelItem + " étiquettes") : ""}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            }
        </div>
    );
};

export default DetailOrder;
