import React, { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { getPlanById, getAllCards, changeSubscription, getCustomer, addCard, setDefaultCard } from "../actions/subscription";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from 'react-notifications';

import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PK_STRIPE } from "../config/key";

const PaymentInformation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const stripePromise = loadStripe(PK_STRIPE);
    const { auth } = useSelector(state => state)
    const id = location?.pathname?.split('/')[2];

    const [showPromoCode, setShowPromoCode] = useState(false);
    const [chooseCard, setChooseCard] = useState(false);
    const [allCards, setAllCards] = useState([]);
    const [subscription, setSubscription] = useState([]);
    const [priceId, setPriceId] = useState();
    const [recurring, setRecurring] = useState('month');
    const [addCardModal, setAddCardModal] = useState(false);

    // LOADING SPINNER 
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    useEffect(() => {
        getPlanById(id)
            .then((res) => {
                setSubscription(res)
            })
            .catch((err) => {
                console.log(err)
            })

        dispatch(getAllCards())
            .then((res) => {
                setAllCards(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    useEffect(() => {
        const matchingPrice = subscription?.price?.data?.find(price => {
            return price.recurring.interval === recurring;
        });

        if (matchingPrice) {
            setPriceId(matchingPrice.id);
        }

    }, [recurring, subscription])

    const handleChooseCard = (item) => {
        setChooseCard(item);
    }

    const handleShowPromoCode = () => {
        setShowPromoCode(true)
    }

    const handleClosePromoCode = () => {
        setShowPromoCode(false)
    }

    const handleShowAddCard = () => {
        setAddCardModal(true);
    }

    const handleCloseAddCardModal = () => {
        setAddCardModal(false);
    }

    const handleSubscribe = (id) => {

        const card = {
            cardId: id,
        }

        setDefaultCard(card)
            .then((res) => {
                setAllCards(res?.data);
                getCustomer()
                    .catch((err) => {
                        NotificationManager.error(err, 'Erreur');
                    })

            })

        const data = {
            priceId: priceId,
            storeId: auth?.backend?.storeId
        }

        setLoadingSpinner(true);

        changeSubscription(data)
            .then((res) => {
                setLoadingSpinner(false);

                navigate('/settings/subscription');
                NotificationManager.success("Votre abonnement a bien été ajouter avec succès !", 'Succès');
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const CheckoutForm = () => {
        const stripe = useStripe();
        const elements = useElements();

        const handleSubmit = async (event) => {
            event.preventDefault();

            const { error, token } = await stripe.createToken(elements.getElement(CardElement));


            if (error) {
                NotificationManager.error(error?.message, 'Erreur');
            } else {
                const data = {
                    cardToken: token?.id
                }

                dispatch(addCard(data))
                    .then((res) => {
                        setAllCards(res?.data);

                        if (allCards.length >= 0) {
                            getCustomer()
                                .catch((error) => {
                                    NotificationManager.error(error?.mesage, 'Erreur');
                                })
                        }

                        NotificationManager.success("Votre carte a bien été ajouter avec succès !", 'Succès');

                        setAddCardModal(false);
                    })
                    .catch((err) => {
                        NotificationManager.error("Une erreur est survenue lors de le création de votre carte !", 'Erreur');
                    })
            }
        };

        const createStyle = () => {
            return {
                base: {
                    fontSize: "15px",
                    "::placeholder": {
                        color: "#c0c2c4",
                    },
                },
                invalid: {
                    color: "#E74C3C",
                    ":focus": {
                        color: "#E74C3C",
                    },
                    "::placeholder": {
                        color: "#E74C3C",
                    },
                },
                complete: {
                    color: "#2ecc71",
                },
            };
        };

        return (
            <div className="border-t border-solid border-slate-200">
                <form className="px-4 pt-6" onSubmit={handleSubmit}>
                    <div className="border-[1px] border-slate-200 p-4 rounded">
                        <CardElement options={{ style: createStyle() }} />
                    </div>

                    <div className="flex items-center justify-end py-4 border-t border-solid border-slate-200 rounded-b mt-6">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => handleCloseAddCardModal()}
                        >
                            Annuler
                        </button>
                        <button
                            className="bg-indigo-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="submit"
                        >
                            Ajouter
                        </button>

                    </div>
                </form>

            </div>
        );
    };

    return (
        <div className="container-margin">

            {addCardModal &&
                <div>
                    <div onClick={() => handleCloseAddCardModal()} className="myModal"></div>
                    <div className="center-absolutely bg-[white] rounded rounded w-4/12 mt-[180px]">

                        <div className="flex row justify-between">
                            <div className="px-6 mt-4">
                                <h1 className="text-xl font-semibold mb-1">Ajouter une carte</h1>
                                <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...</p>
                            </div>

                            <div className="px-6 mt-4">
                                <span data-state="closed" className="cursor-pointer" onClick={() => handleCloseAddCardModal()}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex text-grey-50">
                                        <path d="M15 5L5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M5 5L15 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                            </div>
                        </div>

                        <Elements stripe={stripePromise}>
                            <CheckoutForm />
                        </Elements>

                    </div>
                </div>
            }


            <div className="flex row">
                <button onClick={() => navigate('/settings/subscription')} className="text-white cursor-pointer border-[1.2px] border-[#babfc3] bg-transparent transition duration-150 ease-in-out w-10 h-10 rounded flex items-center justify-center ">
                    <svg viewBox="0 0 20 20" height={25} width={25} fill='#5c5f62' focusable="false" aria-hidden="true"><path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path></svg>
                </button>

                <h1 className="text-xl font-semibold mt-1 ml-4">Information de paiement</h1>
            </div>

            <div className="grid grid-cols-3 gap-4 mt-10">
                <div className="col-span-2">
                    <div className="flex justify-between w-full">
                        <div className="w-full rounded bg-white shadow p-6">

                            <div>
                                <div className="flex justify-between">
                                    <h1 className="font-semibold mb-4 text-lg">Mode de paiement</h1>
                                    <button onClick={() => handleShowAddCard()} className="text-white ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                                        <span className="ml-1"><i className="fa fa-plus mt-1" /><span className="ml-1">Ajouter une carte</span></span>
                                    </button>
                                </div>
                            </div>

                            {allCards.length !== 0 &&
                                <div className="relative w-full mt-3">

                                    <Select
                                        isMulti={false}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        styles={{
                                            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                            menu: (provided) => ({ ...provided, zIndex: 9999 })
                                        }}
                                        getOptionLabel={(option) => option.brand + ' **** ' + option.last4 + ' - ' + option.exp_month + '/' + option.exp_year}
                                        getOptionValue={(option) => option.last4}
                                        value={chooseCard}
                                        onChange={(item) => handleChooseCard(item)}
                                        options={allCards}
                                        placeholder={"Choisir une carte"}
                                    />
                                </div>
                            }

                            <div className="mt-6">
                                <h1 className="font-semibold mb-4 text-lg">Adresse de facturation</h1>
                                <div className="relative w-full mb-4">
                                    <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="Adresse" />
                                </div>
                                <div className="flex row">
                                    <div className="relative w-full">
                                        <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="Ville" />
                                    </div>

                                    <div className="relative w-full ml-4">
                                        <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="Région" />
                                    </div>
                                </div>
                                <div className="flex row mt-4">
                                    <div className="relative w-full">
                                        <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="Code postal" />
                                    </div>

                                    <div className="relative w-full ml-4">
                                        <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="Pays" />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-6 mb-4">
                                <h1 className="font-semibold mb-4 text-lg">Information de la société</h1>

                                <div className="flex row">
                                    <div className="relative w-full">
                                        <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="Nom de la société" />
                                    </div>

                                    <div className="relative w-full ml-4">
                                        <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="SIRET" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div>
                    <div className="flex items-center justify-between w-full">
                        <div className="flex-col w-full items-start lg:items-center rounded bg-white shadow">
                            <div className="py-6 px-8">
                                <h1 className="font-semibold mb-6 text-lg">Mon offre</h1>

                                <div className="flex justify-between">
                                    <div>
                                        <input onChange={(e) => setRecurring(e?.target?.value)} checked={recurring === "month"} type="radio" className="custom-checkbox" name="payment" id="mensuel" value="month" />
                                        <label className="cursor-pointer font-semibold" htmlFor="mensuel">Paiement mensuel</label>
                                    </div>
                                    <div>
                                        <input onChange={(e) => setRecurring(e?.target?.value)} checked={recurring === "year"} type="radio" className="custom-checkbox" name="payment" id="annuel" value="year" />
                                        <label className="cursor-pointer font-semibold" htmlFor="annuel">Paiement annuel</label>
                                    </div>
                                </div>

                                <div className="mt-12 flex justify-between">
                                    <div className="flex flex-row">
                                        <div className="bg-[#6cb3f8] text-[white] text-[25px] flex justify-center items-center px-5 rounded">
                                            {subscription?.product?.name?.substring(0, 1)}
                                        </div>
                                        <div className="ml-3">
                                            <h1 className="font-semibold">Forfait</h1>
                                            <p>{subscription?.product?.name}</p>
                                        </div>
                                    </div>

                                    <div>
                                        <h1 className="text-lg font-bold">
                                            {subscription?.price?.data && Object.keys(subscription?.price?.data).map((key) => (
                                                <Fragment key={key}>

                                                    {recurring}
                                                    {/* {subscription?.price?.data[key]?.recurring?.interval === recurring && subscription?.price?.data[key]?.unit_amount / 100} */}


                                                </Fragment>
                                            ))} €
                                        </h1>
                                    </div>
                                </div>

                                <div className="mt-1 flex justify-between" >
                                    <span className="cursor-pointer text-center mt-3 text-indigo-600 hover:text-indigo-700 font-semibold" onClick={() => handleShowPromoCode()}>+ Ajouter un code promo</span>
                                    {showPromoCode &&
                                        <div onClick={() => handleClosePromoCode()}>
                                            <i className="fa fa-times mt-4 text-indigo-500 hover:text-indigo-600 font-semibold cursor-pointer" />
                                        </div>
                                    }
                                </div>

                                {showPromoCode &&
                                    <div className="mt-4 flex flex-row">
                                        <input type="text" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base" placeholder="Saisissez votre code promo" />
                                        <button onClick={() => navigate("/add/tax/")} className="text-white ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center mt-1">
                                            Ajouter
                                        </button>
                                    </div>
                                }

                                <div className="w-full border-[.5px] border-gray-200 mt-4 mb-4" />

                                <div className="flex justify-between mt-10">
                                    <h1 className="text-2xl font-bold">Total à payer HT</h1>
                                    <h1 className="text-2xl font-bold">
                                        {subscription?.price?.data && Object.keys(subscription?.price?.data).map((key) => (
                                            <Fragment key={key}>
                                                {subscription?.price?.data[key]?.recurring?.interval === recurring && subscription?.price?.data[key]?.unit_amount / 100}
                                            </Fragment>
                                        ))} €
                                    </h1>
                                </div>

                                <div className="mt-8 mb-4">
                                    <button onClick={() => handleSubscribe(chooseCard?.id)} className={` ${chooseCard ? "bg-indigo-700 hover:bg-indigo-500 cursor-pointer" : "bg-indigo-400 hover:bg-indigo-500 cursor-not-allowed"} rounded w-full text-[white] p-3 font-bold`} disabled={chooseCard ? false : true}>
                                        {loadingSpinner
                                            ? <div className="flex justify-center items-center content-center">
                                                <svg className="w-11 h-11 animate-spin" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24" fill="none">
                                                    <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#000000" />
                                                    <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z" fill="white" />
                                                </svg>
                                            </div>
                                            : 'Payer'
                                        }
                                    </button>
                                </div>

                                <div>
                                    <p className="text-center text-[#bebebe] text-[14px] italic">
                                        En poursuivant l'inscription vous acceptez les conditions générales d'utilisation et la politique de confidentialité. Vous serez débité de 75€ HT tous les mois jusqu'à ce que vous décidiez de résilier l'abonnement.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => navigate("/settings/subscription")} className="cursor-pointer underline text-center mt-3 text-[#537bea]">
                        <a>Changer de forfait</a>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PaymentInformation;
