import { apiClient } from './ApiClient'

function getAllDiscounts() {
    return apiClient().get('/admin/discount/')
}

function createDiscount(data) {
    return apiClient().post('/admin/discount/', data)
}

function deleteDiscountById(id) {
    return apiClient().delete('/admin/discount/' + id)
}

function getDiscountById(id) {
    return apiClient().get('/admin/discount/' + id)
}

function editDiscount(data) {
    return apiClient().patch('/admin/discount/', data)
}

export default {
    getAllDiscounts,
    createDiscount,
    deleteDiscountById,
    getDiscountById,
    editDiscount
}
