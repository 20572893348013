import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { getAllShipper } from "../actions/shipper";
import ReactPaginate from "react-paginate";

const Shipper = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [allShipper, setAllShipper] = useState([]);
    const [checkedRow, setCheckedRow] = useState([]);
    const [dropdownActions, setDropdownActions] = useState(false);

    // PAGINATION
    const [itemPerPagePaginate, setItemPerPagePaginate] = useState(10);
    let itemsPerPage = itemPerPagePaginate;
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentRecords = allShipper.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(Object.keys(allShipper)?.length / itemsPerPage);

    useEffect(() => {
        dispatch(getAllShipper())
            .then((res) => {
                setAllShipper(res);
            })
            .catch((err) => {
                NotificationManager.error("Une erreur est survenue lors de la récupération des expéditeurs !", 'Erreur');
            })
    }, [])

    const handleCheckAllRow = () => {
        if (checkedRow.length === Object.keys(allShipper).length) {
            setCheckedRow([])
        } else {
            const allShippersIds = Object.keys(allShipper).reduce((acc, key) => {
                const shipper = allShipper[key]
                if (shipper) {
                    acc.push(shipper._id)
                }
                return acc
            }, []);

            setCheckedRow(allShippersIds)
        }
    }

    const handleCheckRow = (id) => {
        if (checkedRow?.includes(id)) {
            const newCheckedRow = checkedRow.filter((checkedId) => checkedId !== id)
            setCheckedRow(newCheckedRow)
        } else {
            setCheckedRow([...checkedRow, id])
        }
    }

    const handleActivateShipper = () => {
    }

    const handleShowModalConfirmDisable = () => {
    }

    const handleDropdownForeach = (row) => {
        setDropdownActions(row);
    }

    const handlePagePaginateClick = (event) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const newOffset = (event.selected * itemsPerPage) % allShipper?.length;

        setItemOffset(newOffset);
    };

    return (
        <>
            <div onClick={() => setDropdownActions(false)} className={`${dropdownActions !== false && "mydropdown"}`}></div>

            <div className="container-margin">

                {/* BREADCRUMBS */}
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                    <li className="inline-flex items-center">
                        <a href="/" className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-900">
                            <svg width="15px" height="15px" viewBox="0 0 50 50" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.8889 0H8.33333C6.04167 0 3.95833 0.936111 2.44722 2.44722C0.936111 3.95833 0 6.04167 0 8.33333V13.8889C0 16.1806 0.936111 18.2639 2.44722 19.775C3.95833 21.2861 6.04167 22.2222 8.33333 22.2222H13.8889C16.1806 22.2222 18.2639 21.2861 19.775 19.775C21.2861 18.2639 22.2222 16.1806 22.2222 13.8889V8.33333C22.2222 6.04167 21.2861 3.95833 19.775 2.44722C18.2639 0.936111 16.1806 0 13.8889 0Z" />
                                <path d="M41.6667 0H36.1112C33.8195 0 31.7362 0.936111 30.2251 2.44722C28.7139 3.95833 27.7778 6.04167 27.7778 8.33333V13.8889C27.7778 16.1806 28.7139 18.2639 30.2251 19.775C31.7362 21.2861 33.8195 22.2222 36.1112 22.2222H41.6667C43.9584 22.2222 46.0417 21.2861 47.5528 19.775C49.0639 18.2639 50.0001 16.1806 50.0001 13.8889V8.33333C50.0001 6.04167 49.0639 3.95833 47.5528 2.44722C46.0417 0.936111 43.9584 0 41.6667 0Z" />
                                <path d="M13.8889 27.7778H8.33333C6.04167 27.7778 3.95833 28.7139 2.44722 30.225C0.936111 31.7361 0 33.8194 0 36.1111V41.6667C0 43.9583 0.936111 46.0417 2.44722 47.5528C3.95833 49.0639 6.04167 50 8.33333 50H13.8889C16.1806 50 18.2639 49.0639 19.775 47.5528C21.2861 46.0417 22.2222 43.9583 22.2222 41.6667V36.1111C22.2222 33.8194 21.2861 31.7361 19.775 30.225C18.2639 28.7139 16.1806 27.7778 13.8889 27.7778Z" />
                                <path d="M41.6667 27.7778H36.1112C33.8195 27.7778 31.7362 28.7139 30.2251 30.225C28.7139 31.7361 27.7778 33.8194 27.7778 36.1111V41.6667C27.7778 43.9583 28.7139 46.0417 30.2251 47.5528C31.7362 49.0639 33.8195 50 36.1112 50H41.6667C43.9584 50 46.0417 49.0639 47.5528 47.5528C49.0639 46.0417 50.0001 43.9583 50.0001 41.6667V36.1111C50.0001 33.8194 49.0639 31.7361 47.5528 30.225C46.0417 28.7139 43.9584 27.7778 41.6667 27.7778Z" />
                                <clipPath id="clip0_308_250">
                                    <rect width="15px" height="15px" />
                                </clipPath>
                            </svg>
                            <span className="ml-2">Tableau de bord</span>
                        </a>
                    </li>
                    <li aria-current="page">
                        <div className="flex items-center">
                            <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                            <span className="ml-1 text-sm font-medium text-gray-900 md:ml-2z">Expéditeurs</span>
                        </div>
                    </li>
                </ol>
                {/* BREADCRUMBS */}

                <div className="flex items-center justify-between w-full mt-10">
                    <div className="w-full lg:w-3/3">
                        <div className="py-0">
                            <div className="mx-auto bg-white shadow rounded">

                                {/* SELECT TABLE */}
                                <div className="flex row">
                                    <div className='flex row px-6 pt-6 pb-4 md:pb-2'>
                                        <h1 className={`text-xl font-semibold mt-1 ml-2`}>Expéditeurs</h1>
                                    </div>


                                    <div className="flex flex-col lg:flex-row p-4 lg:p-8 justify-between items-start lg:items-stretch w-full">
                                        <div className="w-full lg:w-1/3 flex flex-col lg:flex-row items-start lg:items-center"></div>
                                    </div>
                                </div>


                                <div className={`w-full overflow-x-scroll xl:overflow-x-hidden mt-8`}>
                                    <table className="min-w-full bg-white">
                                        <thead>
                                            <tr className="w-full h-16 border-gray-300 border-b py-8">
                                                <th className="pl-8 text-gray-600 font-normal pr-6 text-left text-sm tracking-normal leading-4">
                                                    <input type="checkbox" onChange={() => handleCheckAllRow()} checked={checkedRow.length === Object.keys(allShipper).length} className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                                                </th>

                                                <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Nom</th>
                                                <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Description</th>
                                                <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Point relais</th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {Object.keys(currentRecords).map((i) => (
                                                <tr key={currentRecords[i]._id} className={`lg:h-20 border-gray-300 border-b`}>
                                                    <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                        <input type="checkbox" onChange={() => handleCheckRow(currentRecords[i]?._id)} checked={checkedRow?.includes(currentRecords[i]?._id)} className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                                                    </td>

                                                    <td className="text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                        {currentRecords[i]?.name}
                                                    </td>

                                                    <td className="text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                        {currentRecords[i]?.description}
                                                    </td>


                                                    <td className="text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                        <div className="flex flex-row">
                                                            <div className={`w-2 h-2 rounded-full relative top-1 mr-1 ${currentRecords[i]?.relayPoint ? "bg-green-600" : "bg-red-600"}`} />
                                                            {currentRecords[i]?.relayPoint ? "Oui" : "Non"}
                                                        </div>
                                                    </td>

                                                </tr>
                                            ))}



                                        </tbody>
                                    </table>
                                </div>

                                <div className={`flex justify-between px-4 py-4 mt-4 ${allShipper.length !== 0 ? "block" : "hidden"} `}>
                                    <div className="">
                                        <span>Afficher:</span>

                                        <select name="pets" id="pet-select" value={itemPerPagePaginate ? itemPerPagePaginate : 10} onChange={(e) => setItemPerPagePaginate(e?.target?.value)} className="border-[1px] ml-1 rounded p-1">
                                            <option value={1}>1</option>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                        </select>
                                    </div>

                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePagePaginateClick}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount}
                                        previousLabel="<"
                                        containerClassName={'containerPaginate'}
                                        previousClassName="previousBtnPaginate"
                                        nextClassName="nextBtnPaginate"
                                        activeClassName="activeBtnPaginate"
                                        pageClassName="pageBtnPaginate"
                                    />

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Shipper;
