import axios from "axios";
import { API_ENDPOINT_STRIPE } from "../config/key";

export const apiStripe = (contentType) => {

    const token = localStorage.getItem('token_milango_back_office');

    const options = {
        baseURL: API_ENDPOINT_STRIPE,
        responseType: "json",

        headers: {
            post: {
                "Content-Type": contentType || "application/json"
            },
            Accept: "application/json",

            'Access-Control-Allow-Origin': '*',
            'locale': 'FR',
            'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept",
            'Access-Control-Allow-Credentials': "true",
            'x-access-token': token && token
        },
    };

    const instance = axios.create(options);

    return instance;
};
