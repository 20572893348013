import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Categories from "./Categories";
import Brands from "./Brands";
import Collections from "./Collections";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { deleteItemById, editItem, getAllItems } from "../actions/items";
import Moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { IMG_LINK } from "../config/key";

const Products = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const componentRef = useRef();
  const dispatch = useDispatch();

  const [tabProduct, setTabProduct] = useState(true);
  const [tabBrand, setTabBrand] = useState(false);
  const [tabCollection, setTabCollection] = useState(false);
  const [tabCategories, setTabCategories] = useState(false);

  const [dropdownActions, setDropdownActions] = useState(false);

  const [idProduct, setIdProduct] = useState();
  const [allProducts, setAllProducts] = useState([]);
  const [allProductsItem, setAllProductsItem] = useState([]);

  // LOADING SPINNER
  const [loadingSpinner, setLoadingSpinner] = useState(true);

  // MODAL FORM
  const [showModalConfirmDisable, setShowModalConfirmDisable] = useState(false);
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
  const [showModalConfirmDuplicateProduct, setShowModalConfirmDuplicateProduct] = useState(false);

  // SMALL SEARCH
  const [searchInput, setSearchInput] = useState();

  // DROPDOWN FILTER
  const [filter, setFilter] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [activeFilter, setActiveFilter] = useState(false);
  const [dropdownFilter, setDropdownFilter] = useState(false);
  const [statusFilterCheckbox, setStatusFilterCheckbox] = useState(false);

  // PAGINATION
  const [itemPerPagePaginate, setItemPerPagePaginate] = useState(10);
  let itemsPerPage = itemPerPagePaginate;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const reversedProducts = allProducts.slice().reverse();
  const currentRecords = reversedProducts?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(Object.keys(allProducts)?.length / itemsPerPage);

  useEffect(() => {
    dispatch(getAllItems()).then((response) => {
      setLoadingSpinner(false);

      Object.keys(response).map((key) => {
        const data = response[key].Variants?.find((item) => item?._id === response[key]?.defaultVariantId)

        response[key] = { ...response[key], variantDefault: data };

        setAllProducts(response);
        setAllProductsItem(response);
      })


      let filteredProducts = filterProducts(response, searchInput, filterData);

      setAllProducts(filteredProducts);
    });


    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);

    function handleClick(e) {
      if (componentRef && componentRef.current) {
        const ref = componentRef.current
        if (!ref.contains(e.target)) {
          setDropdownFilter(false)
        }
      }
    }
  }, [searchInput, filterData]);

  useEffect(() => {

    if (location?.state?.from === "/add/brand"){
      handleBrands();;
    }

    if (location?.state?.from === "/add/collection"){
      handleCollection();
    }

    if (location?.state?.from === "/add/categorie"){
      handleCategories();
    }

    if (location?.state?.from === "/add/product"){
      handleProduct();
    }

  }, [location])

  const filterProducts = (products, searchInput, filterData) => {
    return products.filter(product => {
      if (searchInput && (
        product.name.toLowerCase().indexOf(searchInput?.toLowerCase()) === -1 &&
        product.slug.toLowerCase().indexOf(searchInput?.toLowerCase()) === -1
      )) {
        return false;
      }
      if (filterData) {
        if (filterData.disabled !== undefined && filterData.disabled !== product.disabled) {
          return false;
        }
      }
      return true;
    });
  }

  const handlePagePaginateClick = (event) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const newOffset = (event.selected * itemsPerPage) % allProducts?.length;

    setItemOffset(newOffset);
  };

  const handleClearFilter = () => {
    setStatusFilterCheckbox(false);
    setActiveFilter(false);
    setDropdownFilter(false);
    setFilter({});
    setFilterData();
    setSearchInput();

    dispatch(getAllItems()).then((response) => {
      setLoadingSpinner(false);

      Object.keys(response).map((key) => {
        const data = response[key].Variants?.find((item) => item?._id === response[key]?.defaultVariantId)

        response[key] = { ...response[key], variantDefault: data };

        setAllProducts(response);
        setAllProductsItem(response);
      })


      let filteredProducts = filterProducts(response, searchInput, filterData);

      setAllProducts(filteredProducts);
    });
  }

  const handleFilterDropdown = () => {
    setDropdownFilter(true);

    if (dropdownFilter)
      setDropdownFilter(false);
  }

  const handleStatusCheckbox = () => {
    setStatusFilterCheckbox(true);

    if (statusFilterCheckbox)
      setStatusFilterCheckbox(false);
  }

  const handleProduct = () => {
    setTabCollection(false);
    setTabCategories(false);
    setTabBrand(false);

    setTabProduct(true);
  }

  const handleCollection = () => {
    setTabProduct(false);
    setTabCategories(false);
    setTabBrand(false);

    setTabCollection(true);
    setAllProducts(allProductsItem)
  }

  const handleCategories = () => {
    setTabProduct(false);
    setTabCollection(false);
    setTabBrand(false);

    setTabCategories(true);

    setAllProducts(allProductsItem)
  }

  const handleBrands = () => {
    setTabProduct(false);
    setTabCollection(false);
    setTabCategories(false);

    setTabBrand(true);
    setAllProducts(allProductsItem)
  }

  const handleSmallSearch = (query) => {
    setSearchInput(query);
    var arrItemSearch = []
    const arrSearch = [];

    arrSearch.push(allProductsItem)

    for (var index = 0; index < arrSearch.length; index++) {
      if (!!arrSearch[index]) {
        arrItemSearch.push(Object.values(arrSearch[index]));
      }
    }

    const results = arrItemSearch[0].filter((item) => {
      return (item.name.toString().toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        item.slug.toLowerCase().indexOf(query.toLowerCase()) > -1);
    })

    setAllProducts(results)
  }

  const handleDropdownForeach = (row) => {
    setDropdownActions(row);
  }

  const handleApplyFilter = () => {
    if (Object.keys(filter).length !== 0) {
      setActiveFilter(true);
      setDropdownFilter(false);

      setFilterData(filter)
    } else {
      NotificationManager.error('Merci de bien vouloir sélectionner des filtres avant d\'appliquer !', 'Erreur')
    }
  }

  const handleEdit = (id) => {
    navigate(`/edit/product/${id}`);
  }

  const handleShowModalConfirmDisable = (id) => {
    setDropdownActions(null);
    setIdProduct(id);
    setShowModalConfirmDisable(true);

    if (showModalConfirmDisable) {
      setShowModalConfirmDisable(false);
    }
  }

  const handleShowModalConfirmDelete = (id) => {
    setDropdownActions(null);
    setIdProduct(id);
    setShowModalConfirmDelete(true);

    if (showModalConfirmDelete) {
      setShowModalConfirmDelete(false);
    }
  }

  const handleDisableProduct = () => {
    const data = {
      productId: idProduct,
      disabled: true
    }

    dispatch(editItem(data))
      .then(() => {
        setDropdownActions(false);
        NotificationManager.success('Votre produit a bien été désactiver avec succès !', 'Succès');

        dispatch(getAllItems())
          .then((response) => {

            Object.keys(response).map((key) => {
              const data = response[key].Variants?.find((item) => item?._id === response[key]?.defaultVariantId)

              response[key] = { ...response[key], variantDefault: data };

              setAllProducts(response);
              setAllProductsItem(response);
            })

          });
      })

    setShowModalConfirmDisable(false);
  }

  const handleActivateProduct = (id) => {
    const data = {
      productId: id,
      disabled: false
    }

    setDropdownActions(null);


    dispatch(editItem(data)).then((res) => {
      dispatch(getAllItems()).then((response) => {
        setDropdownActions(false);

        NotificationManager.success('Votre produit a bien été activer avec succès !', 'Succès');

        Object.keys(response).map((key) => {
          const data = response[key].Variants?.find((item) => item?._id === response[key]?.defaultVariantId)

          response[key] = { ...response[key], variantDefault: data };

          setAllProducts(response);
          setAllProductsItem(response);
        })

      });
    })
  }

  const handleDuplicateProduct = (product) => {
    navigate('/duplicate/product', { state: { product: product } });
  }

  const handleDeleteProduct = (id) => {
    setShowModalConfirmDelete(false);

    dispatch(deleteItemById(id))
      .then((res) => {
        Object.keys(res).map((key) => {
          const data = res[key].Variants?.find((item) => item?._id === res[key]?.defaultVariantId)

          res[key] = { ...res[key], variantDefault: data };

          setAllProducts(res);
          setAllProductsItem(res);
        })

        NotificationManager.success('Votre produit a bien été supprimer avec succès !', 'Succès');
      })
  }

  const handleShowModalConfirmDuplicateProduct = (id) => {
    setIdProduct(id);
    setShowModalConfirmDuplicateProduct(true);
  }

  return (
    <div className="container-margin">
      <div onClick={() => setDropdownActions(false)} className={`${dropdownActions !== false && "mydropdown"}`}></div>

      {showModalConfirmDuplicateProduct &&
        <>
          <div>
            <div onClick={() => setShowModalConfirmDuplicateProduct(false)} className="myModal"></div>
            <div className="center-absolutely bg-[white] rounded w-4/12 mt-10">

              <div className="flex row justify-between">
                <div className="px-6 mt-4">
                  <h1 className="text-xl font-semibold mb-1">Dupliquer le produit</h1>
                  <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mb-4">Si nous dupliquons ce produit, toutes les informations associées à celui-ci seront également dupliquées.</p>
                </div>

                <div className="px-6 mt-4">
                  <span data-state="closed" className="cursor-pointer" onClick={() => setShowModalConfirmDuplicateProduct(false)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex text-grey-50">
                      <path d="M15 5L5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                      <path d="M5 5L15 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModalConfirmDuplicateProduct(false)}
                >
                  Annuler
                </button>
                <button
                  className="bg-indigo-700 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => handleDuplicateProduct(idProduct)}
                >
                  Dupliquer
                </button>
              </div>


            </div>
          </div>
        </>
      }

      {showModalConfirmDisable &&
        <>
          <div>
            <div onClick={() => setShowModalConfirmDisable(false)} className="myModal"></div>
            <div className="center-absolutely bg-[white] rounded w-4/12 mt-10">

              <div className="flex row justify-between">
                <div className="px-6 mt-4">
                  <h1 className="text-xl font-semibold mb-1">Désactiver le produit</h1>
                  <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mb-4">Attention en désactivant ce produit, nous désactiverons toutes les données liées à ce produit.</p>
                </div>

                <div className="px-6 mt-4">
                  <span data-state="closed" className="cursor-pointer" onClick={() => setShowModalConfirmDisable(false)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex text-grey-50">
                      <path d="M15 5L5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                      <path d="M5 5L15 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-indigo-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModalConfirmDisable(false)}
                >
                  Annuler
                </button>
                <button
                  className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => handleDisableProduct(idProduct)}
                >
                  Désactiver
                </button>
              </div>


            </div>
          </div>
        </>
      }

      {showModalConfirmDelete &&
        <>
          <div>
            <div onClick={() => setShowModalConfirmDelete(false)} className="myModal"></div>
            <div className="center-absolutely bg-[white] rounded w-4/12 mt-10">

              <div className="flex row justify-between">
                <div className="px-6 mt-4">
                  <h1 className="text-xl font-semibold mb-1">Supprimer le produit</h1>
                  <p className="text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mb-4">Attention en supprimant ce produit, nous supprimerons toutes les données liées à ce produit.</p>
                </div>

                <div className="px-6 mt-4">
                  <span data-state="closed" className="cursor-pointer" onClick={() => setShowModalConfirmDelete(false)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex text-grey-50">
                      <path d="M15 5L5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                      <path d="M5 5L15 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-indigo-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModalConfirmDelete(false)}
                >
                  Annuler
                </button>
                <button
                  className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => handleDeleteProduct(idProduct)}
                >
                  Supprimer
                </button>
              </div>


            </div>
          </div>
        </>
      }

      <div className="flex items-center justify-between w-full">
        <div className="w-full">
          <div className="py-0">
            <div className="mx-auto bg-white shadow rounded margin-responsive-nav">

              {/* SELECT TABLE */}

              <div className="flex row">
                <div className='flex row px-6 pt-6 catalogue-nav'>
                  <h1 onClick={handleProduct} className={`text-xl font-semibold mt-1 ml-2 cursor-pointer ${tabProduct !== true && "text-gray-500"}`}>Produits</h1>
                  <h1 onClick={handleCategories} className={`text-xl font-semibold mt-1 ml-4 cursor-pointer ${tabCategories !== true && "text-gray-500"}`}>Catégories</h1>
                  <h1 onClick={handleCollection} className={`text-xl font-semibold mt-1 ml-4 cursor-pointer ${tabCollection !== true && "text-gray-500"}`}>Collections</h1>
                  <h1 onClick={handleBrands} className={`text-xl font-semibold mt-1 ml-4 cursor-pointer ${tabBrand !== true && "text-gray-500"}`}>Fournisseurs</h1>
                </div>

                <div className="flex flex-col lg:flex-row lg:px-8 lg:py-5 p-4 justify-between items-start lg:items-stretch w-full">
                  <div className="w-full lg:w-1/3 flex flex-col lg:flex-row items-start lg:items-center"></div>
                  <div className="w-full lg:w-2/3 flex flex-col lg:flex-row items-start lg:items-center justify-end">
                    <div className="lg:ml-6 flex items-center w-full">
                      {/* <button className="bg-gray-200 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-gray-300 rounded text-indigo-700 p-4 h-8 flex items-center text-sm">Exporter</button> */}

                      {tabProduct &&
                        <div className="flex w-full justify-between px-4">
                          <h1 className="responsive-catalogue-nav text-xl font-semibold mt-1">Produits</h1>
                          <div className="flex w-full justify-end">
                            <button onClick={() => navigate('/add/product')} className="text-white text-[14px] md:text-[16px] ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                              Ajouter un produit
                            </button>
                          </div>
                        </div>
                      }

                      {tabCategories &&
                        <div className="flex w-full justify-between px-4">
                          <h1 className="responsive-catalogue-nav text-xl font-semibold mt-1">Catégories</h1>
                          <div className="flex w-full justify-end">
                            <button onClick={() => navigate('/add/categorie')} className="text-white whitespace-nowrap text-[14px] md:text-[16px] ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                              Ajouter une catégorie
                            </button>
                          </div>
                        </div>
                      }

                      {tabCollection &&
                        <div className="flex w-full justify-between px-4">
                          <h1 className="responsive-catalogue-nav text-xl font-semibold mt-1">Collections</h1>
                          <div className="flex w-full justify-end">
                            <button onClick={() => navigate('/add/collection')} className="text-white whitespace-nowrap text-[14px] md:text-[16px] ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                              Ajouter une collection
                            </button>
                          </div>
                        </div>
                      }

                      {tabBrand &&
                        <div className="flex w-full justify-between px-4">
                          <h1 className="responsive-catalogue-nav text-xl font-semibold mt-1">Fournisseur</h1>
                          <div className="flex w-full justify-end">
                            <button onClick={() => navigate('/add/brand')} className="text-white whitespace-nowrap text-[14px] md:text-[16px] ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                              Ajouter un fournisseur
                            </button>
                          </div>
                        </div>
                      }

                    </div>
                  </div>
                </div>
              </div>

              <div className="flex row">
                {/* SEARCH BAR */}

                {tabProduct &&
                  <>
                    <div className="flex ml-8 mb-4 w-full md:w-1/6">
                      <div
                        className="flex-grow md:flex-none inter-small-regular mt-1 transition-color transition-width duration-150 ease-in-out flex text-grey-50 flex items-center mb-1 pl-1 py-1.5 rounded border border-grey-0 w-full focus-within:bg-grey-5">
                        <span className="px-2.5 py-0.5">
                          <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                            <path
                              d="M20.4696 21.5303C20.7625 21.8232 21.2373 21.8232 21.5302 21.5303C21.8231 21.2374 21.8231 20.7626 21.5302 20.4697L20.4696 21.5303ZM17.1802 16.1197C16.8873 15.8268 16.4125 15.8268 16.1196 16.1197C15.8267 16.4126 15.8267 16.8874 16.1196 17.1803L17.1802 16.1197ZM18.25 11C18.25 15.0041 15.0041 18.25 11 18.25V19.75C15.8325 19.75 19.75 15.8325 19.75 11H18.25ZM11 18.25C6.99594 18.25 3.75 15.0041 3.75 11H2.25C2.25 15.8325 6.16751 19.75 11 19.75V18.25ZM3.75 11C3.75 6.99594 6.99594 3.75 11 3.75V2.25C6.16751 2.25 2.25 6.16751 2.25 11H3.75ZM11 3.75C15.0041 3.75 18.25 6.99594 18.25 11H19.75C19.75 6.16751 15.8325 2.25 11 2.25V3.75ZM21.5302 20.4697L17.1802 16.1197L16.1196 17.1803L20.4696 21.5303L21.5302 20.4697Z"
                              fill="currentColor">
                            </path>
                          </svg>
                        </span>
                        <input type="text" onChange={(e) => handleSmallSearch(e.target.value)}
                          className="border-transparent focus:border-transparent  focus:ring-0 mini-search w-full focus:bg-grey-5 focus:text-grey-90 caret-violet-60 w-full"
                          placeholder="Rechercher..." size="6" />
                      </div>
                    </div>

                    <div ref={componentRef}>
                      <button className="flex border border-grey-0 p-[.6rem] rounded mt-[.25rem] ml-2 mr-8" type="button" onClick={handleFilterDropdown} aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-33" data-state="closed">

                        <svg width={16} height={16}
                          viewBox="0 0 64 64" >
                          <path d="M41,64l-18-7.7V37.5L0.5,0h63.1L41,37.6V64z M27,53.7L37,58V36.4L56.5,4H7.5L27,36.4V53.7z" fill="currentColor" />
                        </svg>

                        {activeFilter &&
                          <div className={`ml-1 w-2 h-2 rounded-full bg-indigo-500`} />
                        }
                      </button>

                      <div className={`origin-top-right absolute left-[29.8rem] top-[15.5rem] w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${dropdownFilter ? "block" : "hidden"}`} style={{ zIndex: 999 }} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                        <div className="" role="none">
                          <div className='flex row justify-center mr-4 mt-4'>
                            <button onClick={handleClearFilter} className="w-full text-indigo-700 ml-4 cursor-pointer focus:outline-none border border-transparent bg-gray-200 transition duration-150 ease-in-out p-4 h-8 rounded flex items-center justify-center">
                              Effacer
                            </button>
                            <button onClick={handleApplyFilter} className="w-full text-white ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                              Appliquer
                            </button>
                          </div>

                          <div className="w-full border-[.5px] border-gray-200 mt-4" />

                          <div onClick={handleStatusCheckbox} className="flex items-center px-6 hover:bg-gray-100 p-3">
                            <input id="checked-checkbox" type="checkbox" checked={statusFilterCheckbox} onChange={handleStatusCheckbox} value={statusFilterCheckbox} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                            <label className="text-gray-700 ml-2">Statut</label>
                          </div>

                          {statusFilterCheckbox &&
                            <div className="mx-2 my-1">
                              <div className="flex items-center px-6 hover:bg-gray-100 p-3" onClick={() => setFilter({ ...filter, disabled: false })}>
                                <input id="checked-checkbox" name="radio_btn_status" type="radio" checked={filter.disabled === false && true} onChange={() => { }} value="false" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                <label className="text-gray-700 ml-2">Actif</label>
                              </div>
                              <div className="flex items-center px-6 hover:bg-gray-100 p-3" onClick={() => setFilter({ ...filter, disabled: true })}>
                                <input id="checked-checkbox" type="radio" name="radio_btn_status" checked={filter.disabled === true && true} onChange={() => { }} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                                <label className="text-gray-700 ml-2">Inactif</label>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </>
                }
              </div>


              {(allProducts.length === 0 && tabProduct === true) &&

                <>
                  {loadingSpinner
                    ? <div className="flex justify-center items-center content-center">
                      <svg className="w-11 h-11 animate-spin mt-14 mb-14" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#000000" />
                        <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z" fill="#000000" />
                      </svg>
                    </div>
                    : <div>
                      <div className="w-full">
                        <div className="flex justify-center ml-5 mb-4">
                          <img src="../../no_data.png" width={100} />
                        </div>
                        <div className="flex justify-center">

                          {searchInput
                            ? <span className="text-gray-500 mb-10">Aucun produit ne corresponds à votre recherche "<span className="font-semibold italic">{searchInput}</span>".</span>
                            : <span className="text-gray-500 mb-10">Aucun produit.</span>
                          }
                        </div>
                      </div>
                    </div>
                  }
                </>
              }

              {/* TAB PRODUCT */}
              <div className={`${tabProduct === true ? "block" : "hidden"} ${allProducts.length !== 0 ? "block" : "hidden"}`}>

                <div className="w-full overflow-x-scroll xl:overflow-x-hidden">
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr className="w-full h-16 border-gray-300 border-b py-8">
                        <th className="pl-8 text-gray-600 font-normal pr-6 text-left text-sm tracking-normal leading-4">
                          <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                        </th>
                        <th className="text-gray-600 font-normal pr-6 text-left text-sm tracking-normal leading-4">
                          <div className="text-gray-600 opacity-0 cursor-default relative w-10">
                            <div className="absolute top-0 right-0 w-5 h-5 mr-2 -mt-1 rounded-full bg-indigo-700 text-white flex justify-center items-center text-xs">3</div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" />
                              <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                              <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                            </svg>
                          </div>
                        </th>

                        <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Nom</th>
                        <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Slug</th>
                        <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Stock</th>
                        <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Prix</th>
                        <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Date de création</th>
                        <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Statut</th>
                        <td className="text-gray-600 pr-8 text-left text-sm tracking-normal leading-4 font-semibold">Actions</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(currentRecords).map((item) => (
                        <tr key={currentRecords[item]._id} className={`lg:h-20 border-gray-300 border-b hover:bg-gray-100 text-[14px]`}>
                          <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                            <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                          </td>

                          <td className="pr-6 whitespace-no-wrap h-full">
                            <div className="flex justify-between">
                              <div className="flex items-center responsive-thead hidden ">
                                <span className="font-semibold">Image produit</span>
                              </div>
                              <div className="h-12 w-12">
                                <img
                                  src={
                                    currentRecords[item]?.variantDefault?.images?.thumbnailId &&
                                      currentRecords[item]?.variantDefault?.images?.image1Id ?
                                      IMG_LINK + currentRecords[item]?.variantDefault?.images?.thumbnailId :
                                      currentRecords[item]?.variantDefault?.images?.image1Id ?
                                        IMG_LINK + currentRecords[item]?.variantDefault?.images?.image1Id :
                                        "../../placeholder.jpeg"
                                  }
                                  alt="produit"
                                  className="h-full w-full overflow-hidden shadow"
                                />
                              </div>
                            </div>
                          </td>

                          <td className="pr-6 whitespace-no-wrap">
                            <div className="flex justify-between md:justify-start h-full">
                              <div className="flex items-center">
                                <span className="responsive-thead hidden font-semibold">Nom</span>
                              </div>
                              {currentRecords[item].name}
                            </div>
                          </td>


                          <td className="pr-6 whitespace-no-wrap">
                            <div className="flex justify-between md:justify-start h-full">
                              <div className="flex items-center">
                                <span className="responsive-thead hidden font-semibold">Slug</span>
                              </div>
                              /{currentRecords[item].slug}
                            </div>
                          </td>

                          <td className="pr-6 whitespace-no-wrap">
                            <div className="flex justify-between">
                              <span className="responsive-thead hidden font-semibold">Stock</span>
                              {currentRecords[item]?.variantDefault?.quantity} produits
                            </div>
                          </td>

                          <td className="pr-6 whitespace-no-wrap">
                            <div className="flex justify-between">
                              <span className="responsive-thead hidden font-semibold">Prix</span>
                              {currentRecords[item]?.variantDefault?.price?.toFixed(2)} Є
                            </div>
                          </td>

                          <td className="pr-6 whitespace-no-wrap">
                            <div className="flex justify-between">
                              <span className="responsive-thead hidden font-semibold">Date de création</span>
                              {Moment(currentRecords[item]?.variantDefault?.createdAt).format("LL")} à {Moment(currentRecords[item]?.variantDefault?.createdAt).format("LT")}
                            </div>
                          </td>

                          <td className="pr-6 whitespace-no-wrap">
                            <div className="flex justify-between">
                              <span className="responsive-thead hidden font-semibold">Statut</span>
                              <div className="flex flex-row h-full items-center">
                                <div className={`w-2 h-2 rounded-full ${currentRecords[item]?.disabled === false && "bg-green-600"} ${currentRecords[item]?.disabled === true && "bg-red-600"}`} />
                                <span className="text-sm pr-2 whitespace-no-wrap text-gray-800 tracking-normal leading-4 ml-1">{currentRecords[item]?.disabled === true ? 'Inactif' : "Actif"}</span>
                              </div>
                            </div>
                          </td>

                          <td className="pr-6 whitespace-no-wrap">
                            <div className="flex justify-between h-full">
                              <div className="flex items-center responsive-thead hidden">
                                <span className="font-semibold">Actions</span>
                              </div>

                              <div className="responsive-thead hidden">
                                <div className="grid grid-cols-2 place-items-start gap-3 mt-2">
                                  <div onClick={() => handleEdit(currentRecords[item]?._id)} className="hover:underline cursor-pointer text-gray-600 text-sm leading-3 tracking-normal font-semibold"><i className="fa fa-edit"></i> Éditer</div>
                                  <div onClick={() => handleShowModalConfirmDuplicateProduct(currentRecords[item]?._id)} className="hover:underline cursor-pointer text-gray-600 text-sm leading-3 tracking-normal font-semibold"><i className={`fa fa-clone`}></i> Dupliquer</div>
                                  <div onClick={() => currentRecords[item]?.disabled ? handleActivateProduct(currentRecords[item]?._id) : handleShowModalConfirmDisable(currentRecords[item]?._id)} className="hover:underline cursor-pointer text-gray-600 text-sm leading-3 tracking-normal font-semibold"><i className={`fa fa-power-off`}></i> {currentRecords[item]?.disabled ? "Activer" : "Désactiver"}</div>
                                  <div onClick={() => handleShowModalConfirmDelete(currentRecords[item]?._id)} className="hover:underline cursor-pointer text-red-500 text-sm leading-3 tracking-normal font-semibold"><i className="fa fa-trash"></i> Supprimer</div>
                                </div>
                              </div>

                              <div className={`dropdown-content  mt-8 absolute right-18 -ml-12 shadow-md z-[999] w-32 ${dropdownActions === currentRecords[item]._id ? "block" : "hidden"}`}>
                                <ul className="bg-white shadow rounded py-1">
                                  <li onClick={() => handleEdit(currentRecords[item]?._id)} className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-edit"></i> Éditer</li>
                                  <li onClick={() => handleShowModalConfirmDuplicateProduct(currentRecords[item]?._id)} className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className={`fa fa-clone`}></i> Dupliquer</li>
                                  <li onClick={() => currentRecords[item]?.disabled ? handleActivateProduct(currentRecords[item]?._id) : handleShowModalConfirmDisable(currentRecords[item]?._id)} className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className={`fa fa-power-off`}></i> {currentRecords[item]?.disabled ? "Activer" : "Désactiver"}</li>
                                  <li onClick={() => handleShowModalConfirmDelete(currentRecords[item]?._id)} className="cursor-pointer text-red-500 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-semibold"><i className="fa fa-trash"></i> Supprimer</li>
                                </ul>
                              </div>
                              <button onClick={() => handleDropdownForeach(currentRecords[item]._id)} className="hidden md:block text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical dropbtn" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" />
                                  <circle cx={12} cy={12} r={1} />
                                  <circle cx={12} cy={19} r={1} />
                                  <circle cx={12} cy={5} r={1} />
                                </svg>
                              </button>
                            </div>
                          </td>


                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className={`flex justify-between px-4 py-4 mt-4 ${allProducts.length !== 0 ? "block" : "hidden"} `}>
                  <div className="">
                    <span>Afficher:</span>

                    <select name="pets" id="pet-select" value={itemPerPagePaginate ? itemPerPagePaginate : 10} onChange={(e) => setItemPerPagePaginate(Number(e?.target?.value))} className="border-[1px] ml-1 rounded p-1">
                      <option value={1}>1</option>
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                    </select>
                  </div>

                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePagePaginateClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    containerClassName={'containerPaginate'}
                    previousClassName="previousBtnPaginate"
                    nextClassName="nextBtnPaginate"
                    activeClassName="activeBtnPaginate"
                    pageClassName="pageBtnPaginate"
                  />

                </div>


                <div className="fixed responsive-catalogue-nav top-16 z-0 left-14 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
                  <div className="grid h-full w-[87%] grid-cols-4 justify-between font-medium">
                    <button onClick={handleProduct} type="button" className={`inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50  ${tabProduct === true && "bg-[#f3f4f6] text-indigo-600"}  dark:hover:bg-gray-800 group`}>
                      <span className="text-sm dark:text-gray-400 group-hover:text-indigo-700  dark:group-hover:text-blue-500">Produits</span>
                    </button>
                    <button onClick={handleCategories} type="button" className={`inline-flex flex-col items-center justify-center px-5 ${tabCategories === true && "bg-[#f3f4f6] text-indigo-600"} hover:bg-gray-50 dark:hover:bg-gray-800 group`}>
                      <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-indigo-700 dark:group-hover:text-blue-500">Catégories</span>
                    </button>
                    <button onClick={handleCollection} type="button" className={`inline-flex flex-col items-center justify-center px-5 ${tabCollection === true && "bg-[#f3f4f6] text-indigo-600"} hover:bg-gray-50 dark:hover:bg-gray-800 group`}>
                      <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-indigo-700 dark:group-hover:text-blue-500">Collections</span>
                    </button>
                    <button onClick={handleBrands} type="button" className={`inline-flex flex-col items-center justify-center px-5 ${tabBrand === true && "bg-[#f3f4f6] text-indigo-600"} hover:bg-gray-50 dark:hover:bg-gray-800 group`}>
                      <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-indigo-700 dark:group-hover:text-blue-500">Fournisseurs</span>
                    </button>
                  </div>
                </div>



              </div>

              {/* RETURN COMPOSANT COLLECTION.JSX */}
              {tabCollection &&
                <>
                  <div className="fixed responsive-catalogue-nav top-16 left-14 z-0 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
                    <div className="grid h-full w-[87%] grid-cols-4 justify-between font-medium">
                      <button onClick={handleProduct} type="button" className={`inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50  ${tabProduct === true && "bg-[#f3f4f6] text-indigo-600"}  dark:hover:bg-gray-800 group`}>
                        <span className="text-sm dark:text-gray-400 group-hover:text-indigo-700  dark:group-hover:text-blue-500">Produits</span>
                      </button>
                      <button onClick={handleCategories} type="button" className={`inline-flex flex-col items-center justify-center px-5 ${tabCategories === true && "bg-[#f3f4f6] text-indigo-600"} hover:bg-gray-50 dark:hover:bg-gray-800 group`}>
                        <span className="text-sm dark:text-gray-400 group-hover:text-indigo-700 dark:group-hover:text-blue-500">Catégories</span>
                      </button>
                      <button onClick={handleCollection} type="button" className={`inline-flex flex-col items-center justify-center px-5 ${tabCollection === true && "bg-[#f3f4f6] text-indigo-600"} hover:bg-gray-50 dark:hover:bg-gray-800 group`}>
                        <span className="text-sm dark:text-gray-400 group-hover:text-indigo-700 dark:group-hover:text-blue-500">Collections</span>
                      </button>
                      <button onClick={handleBrands} type="button" className={`inline-flex flex-col items-center justify-center px-5 ${tabBrand === true && "bg-[#f3f4f6] text-indigo-600"} hover:bg-gray-50 dark:hover:bg-gray-800 group`}>
                        <span className="text-sm dark:text-gray-400 group-hover:text-indigo-700 dark:group-hover:text-blue-500">Fournisseurs</span>
                      </button>
                    </div>
                  </div>
                  <Collections />
                </>
              }

              {/* RETURN COMPOSANT CATEGORIES.JSX */}
              {tabCategories &&
                <>
                  <div className="fixed responsive-catalogue-nav top-16 left-14 z-0 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
                    <div className="grid h-full w-[87%] grid-cols-4 justify-between font-medium">
                      <button onClick={handleProduct} type="button" className={`inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50  ${tabProduct === true && "bg-[#f3f4f6] text-indigo-600"}  dark:hover:bg-gray-800 group`}>
                        <span className="text-sm dark:text-gray-400 group-hover:text-indigo-700  dark:group-hover:text-blue-500">Produits</span>
                      </button>
                      <button onClick={handleCategories} type="button" className={`inline-flex flex-col items-center justify-center px-5 ${tabCategories === true && "bg-[#f3f4f6] text-indigo-600"} hover:bg-gray-50 dark:hover:bg-gray-800 group`}>
                        <span className="text-sm dark:text-gray-400 group-hover:text-indigo-700 dark:group-hover:text-blue-500">Catégories</span>
                      </button>
                      <button onClick={handleCollection} type="button" className={`inline-flex flex-col items-center justify-center px-5 ${tabCollection === true && "bg-[#f3f4f6] text-indigo-600"} hover:bg-gray-50 dark:hover:bg-gray-800 group`}>
                        <span className="text-sm dark:text-gray-400 group-hover:text-indigo-700 dark:group-hover:text-blue-500">Collections</span>
                      </button>
                      <button onClick={handleBrands} type="button" className={`inline-flex flex-col items-center justify-center px-5 ${tabBrand === true && "bg-[#f3f4f6] text-indigo-600"} hover:bg-gray-50 dark:hover:bg-gray-800 group`}>
                        <span className="text-sm dark:text-gray-400 group-hover:text-indigo-700 dark:group-hover:text-blue-500">Fournisseurs</span>
                      </button>
                    </div>
                  </div>
                  <Categories />
                </>
              }

              {/* RETURN COMPOSANT BRANDS.JSX */}
              {tabBrand &&
                <>
                  <div className="fixed responsive-catalogue-nav top-16 left-14 z-0 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
                    <div className="grid h-full w-[87%] grid-cols-4 justify-between font-medium">
                      <button onClick={handleProduct} type="button" className={`inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50  ${tabProduct === true && "bg-[#f3f4f6] text-indigo-600"}  dark:hover:bg-gray-800 group`}>
                        <span className="text-sm dark:text-gray-400 group-hover:text-indigo-700  dark:group-hover:text-blue-500">Produits</span>
                      </button>
                      <button onClick={handleCategories} type="button" className={`inline-flex flex-col items-center justify-center px-5 ${tabCategories === true && "bg-[#f3f4f6] text-indigo-600"} hover:bg-gray-50 dark:hover:bg-gray-800 group`}>
                        <span className="text-sm dark:text-gray-400 group-hover:text-indigo-700 dark:group-hover:text-blue-500">Catégories</span>
                      </button>
                      <button onClick={handleCollection} type="button" className={`inline-flex flex-col items-center justify-center px-5 ${tabCollection === true && "bg-[#f3f4f6] text-indigo-600"} hover:bg-gray-50 dark:hover:bg-gray-800 group`}>
                        <span className="text-sm dark:text-gray-400 group-hover:text-indigo-700 dark:group-hover:text-blue-500">Collections</span>
                      </button>
                      <button onClick={handleBrands} type="button" className={`inline-flex flex-col items-center justify-center px-5 ${tabBrand === true && "bg-[#f3f4f6] text-indigo-600"} hover:bg-gray-50 dark:hover:bg-gray-800 group`}>
                        <span className="text-sm dark:text-gray-400 group-hover:text-indigo-700 dark:group-hover:text-blue-500">Fournisseurs</span>
                      </button>
                    </div>
                  </div>
                  <Brands />
                </>
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
