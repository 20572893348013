import apiRegion from "../api/regions";
import { GET_ALL_REGIONS } from "./types";

// GET ALL REGIONS
export const getAllRegions = () =>
    async (dispatch) => {
        return apiRegion
            .getAllRegions()
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_REGIONS, payload: result.data })
                    return result?.data
                }
            })
    };

// ADD REGION
export const addRegion = async (data) => {
    return apiRegion
        .addRegion(data)
        .then(async (result) => {

            if (result.data) {
                return result.data
            }
        })
};

// GET REGION BY ID
export const getRegionById = async (id) => {
    const result = await apiRegion
        .getRegionById(id);
    if (result) {
        return result?.data;
    }
};

// EDIT REGION BY ID
export const editRegion = async (data) => {
    const result = await apiRegion
        .editRegion(data);
    if (result) {
        return result?.data;
    }
};

// GET ALL SHIPPER
export const getAllShipper = async () => {
    const result = await apiRegion
        .getAllShipper();
    if (result) {
        return result?.data;
    }
};


// DELETE REGION BY ID
export const deleteRegionById = (id) =>
    async (dispatch) => {
        return apiRegion
            .deleteRegionById(id)
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_REGIONS, payload: result.data })
                    return result?.data
                }
            })
    };
