import { GET_ALL_BRANDS } from "./types";
import apiAuth from "../api/brands";

// GET ALL BRANDS
export const getAllBrands = () =>
    async (dispatch) => {
        return apiAuth
            .getAllBrands()
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_BRANDS, payload: result.data })
                    return result?.data
                }
            })
    };

// DELETE BRAND BY ID
export const deleteBrandById = (id) =>
    async (dispatch) => {
        return apiAuth
            .deleteBrandById(id)
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_BRANDS, payload: result.data })
                    return result?.data
                }
            })
    };

// GET BRAND BY ID
export const getBrandById = async (id) => {
    const result = await apiAuth
        .getBrandById(id);
    if (result) {
        return result?.data;
    }
};




// EDIT BRAND
export const editBrand = async (data) => {
    const result = await apiAuth
        .editBrand(data);
    if (result) {
        return result?.data;
    }
};

// ADD BRAND
export const addBrand = async (data) => {
    const result = await apiAuth
        .addBrand(data);
    if (result) {
        return result?.data;
    }
};