import { apiClient } from './ApiClient';

function getAllOrders() {
    return apiClient().get('/admin/order/');
}

function getAllCart() {
    return apiClient().get('/admin/cart/');
}
function getOrderById(id) {
    return apiClient().get('/admin/order/' + id);
}

function editOrder(data) {
    return apiClient().patch('/admin/order/status', data);
}

function printLabel(data) {
    return apiClient().post('/admin/order/label', data);
}

export default {
    getAllCart,
    getAllOrders,
    getOrderById,
    editOrder,
    printLabel
}
