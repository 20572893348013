import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { getAllOrders } from "../actions/orders";
import ReactPaginate from "react-paginate";
import Moment from 'moment';
import { NotificationManager } from 'react-notifications';
import status from '../utils/status.json';
import ExportCSV from '../components/ExportCSV';

const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const componentRef = useRef();

  const [dropdownActions, setDropdownActions] = useState(false);
  const [dropdownFilter, setDropdownFilter] = useState(false);
  const [statusFilterCheckbox, setStatusFilterCheckbox] = useState(false);
  const [activeFilter, setActiveFilter] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [arrStatus, setArrStatus] = useState([])
  const [allOrders, setAllOrders] = useState([]);
  const [allOrdersItem, setAllOrdersItem] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [checkedRow, setCheckedRow] = useState([]);

  // PAGINATION
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const reversedOrders = allOrders.slice().reverse();
  const paginatedOrders = reversedOrders.slice(startIndex, endIndex);
  const pageCount = Math.ceil(allOrders.length / itemsPerPage);

  useEffect(() => {
    setLoadingSpinner(true);

    dispatch(getAllOrders()).then((orders) => {
      setAllOrders(orders);
      setAllOrdersItem(orders);
      setLoadingSpinner(false);
    })
  }, []);

  useEffect(() => {
    const filteredOrders = allOrders.filter(order => filterData.includes(status[order?.status[order?.status.length - 1]?.status]?.text));

    setAllOrders(filteredOrders)

  }, [filterData])

  const handleClearFilter = () => {
    dispatch(getAllOrders()).then((orders) => {
      setAllOrders(orders);
      setAllOrdersItem(orders);

      setLoadingSpinner(false);
    })

    setStatusFilterCheckbox(false);
    setActiveFilter(false);
    setDropdownFilter(false);
  }

  const handleFilterDropdown = () => {
    setDropdownFilter(true);

    if (dropdownFilter)
      setDropdownFilter(false);
  }

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e) {
      if (componentRef && componentRef.current) {
        const ref = componentRef.current
        if (!ref.contains(e.target)) {
          setDropdownFilter(false)
        }
      }
    }
  }, []);

  const handleStatusCheckbox = () => {
    setStatusFilterCheckbox(true);

    if (statusFilterCheckbox)
      setStatusFilterCheckbox(false);
  }

  const handleApplyFilter = () => {
    if (Object.keys(arrStatus).length !== 0) {
      setActiveFilter(true);
      setDropdownFilter(false);

      setFilterData(arrStatus)
    } else {
      NotificationManager.error('Merci de bien vouloir sélectionner des filtres avant d\'appliquer !', 'Erreur')
    }
  }

  const handleSmallSearch = (query) => {
    if(query.length !== 0){
        setSearchInput(query);
        var arrItemSearch = []
        const arrSearch = [];
    
        arrSearch.push(allOrdersItem)
    
        for (var index = 0; index < arrSearch.length; index++) {
          if (!!arrSearch[index]) {
            arrItemSearch.push(Object.values(arrSearch[index]));
          }
        }
    
        const filteredOrders = allOrdersItem.filter((item) => {
          return (
            (item?.user[0]?.email && item.user[0].email.toString().toLowerCase().indexOf(searchInput?.toLowerCase()) > -1) ||
            (item?.user[0]?.name && item.user[0].name.toString().toLowerCase().indexOf(searchInput?.toLowerCase()) > -1) ||
            (item?.user[0]?.lastname && item.user[0].lastname.toString().toLowerCase().indexOf(searchInput?.toLowerCase()) > -1)
          );
        });
          
        setAllOrders(filteredOrders);
    } else {
      setAllOrders(allOrdersItem)
    }
  }

  const handleCheckRow = (id) => {
    if (checkedRow?.includes(id)) {
      const newCheckedRow = checkedRow.filter((checkedId) => checkedId !== id)
      setCheckedRow(newCheckedRow)
    } else {
      setCheckedRow([...checkedRow, id])
    }
  }

  const handleCheckAllRow = () => {
    if (checkedRow.length === Object.keys(allOrders).length) {
      setCheckedRow([])
    } else {
      const allOrdersIds = Object.keys(allOrders).reduce((acc, key) => {
        const order = allOrders[key]
        if (order) {
          acc.push(order._id)
        }
        return acc
      }, []);

      setCheckedRow(allOrdersIds)
    }
  }

  const handleAddStatusFilter = (value) => {
    if (!arrStatus.includes(value)) {
      setArrStatus([...arrStatus, value]);
    } else {
      setArrStatus(arrStatus.filter((l) => l !== value));
    }
  };
  

  return (
    <div className="container-margin">
      <div onClick={() => setDropdownActions(false)} className={`${dropdownActions !== false && "mydropdown"}`}></div>

      <div className="flex items-center justify-between w-full">
        <div className="w-full lg:w-3/3">
          <div className="py-0">
            <div className="mx-auto bg-white shadow rounded">
              <div className="flex">
                <div className='px-6 pt-6 grid grid-cols-2 w-full'>
                  <h1 className={`text-xl font-semibold mt-1 ml-2`}>Commandes</h1>
                  <div className="flex justify-end">
                    {checkedRow.length >= 2 &&
                      <ExportCSV data={checkedRow} filename="export_orders.csv" />
                    }
                  </div>
                </div>
              </div>

              <div className={`relative flex row mt-4`}>
                <div className="flex ml-8 mb-4 w-full md:w-1/6">
                  <div
                    className="flex-grow md:flex-none inter-small-regular mt-1 transition-color transition-width duration-150 ease-in-out flex text-grey-50 flex items-center mb-1 pl-1 py-1.5 rounded border border-grey-0 w-full focus-within:bg-grey-5">
                    <span className="px-2.5 py-0.5">
                      <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M20.4696 21.5303C20.7625 21.8232 21.2373 21.8232 21.5302 21.5303C21.8231 21.2374 21.8231 20.7626 21.5302 20.4697L20.4696 21.5303ZM17.1802 16.1197C16.8873 15.8268 16.4125 15.8268 16.1196 16.1197C15.8267 16.4126 15.8267 16.8874 16.1196 17.1803L17.1802 16.1197ZM18.25 11C18.25 15.0041 15.0041 18.25 11 18.25V19.75C15.8325 19.75 19.75 15.8325 19.75 11H18.25ZM11 18.25C6.99594 18.25 3.75 15.0041 3.75 11H2.25C2.25 15.8325 6.16751 19.75 11 19.75V18.25ZM3.75 11C3.75 6.99594 6.99594 3.75 11 3.75V2.25C6.16751 2.25 2.25 6.16751 2.25 11H3.75ZM11 3.75C15.0041 3.75 18.25 6.99594 18.25 11H19.75C19.75 6.16751 15.8325 2.25 11 2.25V3.75ZM21.5302 20.4697L17.1802 16.1197L16.1196 17.1803L20.4696 21.5303L21.5302 20.4697Z"
                          fill="currentColor">
                        </path>
                      </svg>
                    </span>
                    <input type="text" onChange={(e) => handleSmallSearch(e.target.value)}
                      className="border-transparent focus:border-transparent  focus:ring-0 mini-search w-full focus:bg-grey-5 focus:text-grey-90 caret-violet-60 w-full"
                      placeholder="Rechercher..." size="6" />
                  </div>
                </div>

                <div ref={componentRef}>
                  <button className="flex border border-grey-0 p-[.6rem] rounded mt-[.25rem] ml-2 mr-8" type="button" onClick={handleFilterDropdown} aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-33" data-state="closed">

                    <svg width={16} height={16}
                      viewBox="0 0 64 64" >
                      <path d="M41,64l-18-7.7V37.5L0.5,0h63.1L41,37.6V64z M27,53.7L37,58V36.4L56.5,4H7.5L27,36.4V53.7z" fill="currentColor" />
                    </svg>

                    {activeFilter &&
                      <div className={`ml-1 w-2 h-2 rounded-full bg-indigo-500`} />
                    }
                  </button>

                  <div className={`origin-top-right absolute left-[19.8rem] top-[2.8rem] w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${dropdownFilter ? "block" : "hidden"}`} style={{ zIndex: 999 }} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                    <div className="" role="none">
                      <div className='flex row justify-center mr-4 mt-4'>
                        <button onClick={handleClearFilter} className="w-full text-indigo-700 ml-4 cursor-pointer focus:outline-none border border-transparent bg-gray-200 transition duration-150 ease-in-out p-4 h-8 rounded flex items-center justify-center">
                          Effacer
                        </button>
                        <button onClick={handleApplyFilter} className="w-full text-white ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                          Appliquer
                        </button>
                      </div>

                      <div className="w-full border-[.5px] border-gray-200 mt-4" />

                      <div onClick={handleStatusCheckbox} className="flex items-center px-6 hover:bg-gray-100 p-3">
                        <input id="checked-checkbox" type="checkbox" checked={statusFilterCheckbox} value={statusFilterCheckbox} onChange={handleStatusCheckbox} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                        <label className="text-gray-700 ml-2">Statut</label>
                      </div>

                      {statusFilterCheckbox &&
                        <div className="ml-2 mb-2">

                          {Object.keys(status)?.map((it, index) => (
                            <div key={index} className="flex items-center px-6 hover:bg-gray-100 p-3 whitespace-nowrap" onClick={() => handleAddStatusFilter(status[it]?.text)}>
                              <input id="checked-checkbox" checked={arrStatus.includes(status[it]?.text)} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500" />
                              <label className="text-gray-700 ml-2">{status[it]?.text.length > 25 ? status[it]?.text.substring(0, 25) + "..." : status[it]?.text}</label>
                            </div>
                          ))}

                        </div>
                      }


                    </div>
                  </div>
                </div>
              </div>


              {allOrders.length === 0 &&
                <>
                  {loadingSpinner
                    ? <div className="flex justify-center items-center content-center">
                      <svg className="w-11 h-11 animate-spin mt-14 mb-14" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#000000" />
                        <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z" fill="#000000" />
                      </svg>
                    </div>
                    : <div>
                      <div className="w-full">
                        <div className="flex justify-center ml-5 mb-4">
                          <img src="../../no_data.png" width={100} />
                        </div>
                        <div className="flex justify-center">
                          {searchInput
                            ? <span className="text-gray-500 mb-10">Aucune commande ne corresponds à votre recherche "<span className="font-semibold italic">{searchInput}</span>".</span>
                            : <span className="text-gray-500 mb-10">Aucune commande.</span>
                          }
                        </div>
                      </div>
                    </div>
                  }
                </>
              }

              <div className={`overflow-x-auto ${allOrders.length === 0 ? "hidden" : "block"}`}>
                <table className="min-w-full bg-white">
                  <thead>
                    <tr className="w-full h-16 border-gray-300 border-b py-8">
                      <th className="pl-8 text-gray-600 font-normal pr-6 text-left text-sm tracking-normal leading-4">
                        <input type="checkbox" onChange={() => handleCheckAllRow()} checked={checkedRow.length === Object.keys(allOrders).length} className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                      </th>

                      <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Commande</th>
                      <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Client</th>
                      <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Total</th>
                      <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Statut</th>
                      <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Date</th>
                      <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {paginatedOrders.map((order, index) => (
                      <tr key={order?._id} className="md:h-20 border-gray-300 border-b hover:bg-gray-100 text-[14px]">

                        <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                          <input type="checkbox" onChange={() => handleCheckRow(order)} checked={checkedRow?.includes(order)} className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                        </td>

                        <td className="pr-6 whitespace-no-wrap">
                          <div className="flex justify-between">
                            <span className="responsive-thead hidden font-semibold">Commande</span>
                            #{Moment(order?.createdAt).format("YYYY")}{Moment(order?.createdAt).format("MM")}-{order?.publicOrderId}
                          </div>
                        </td>

                        <td className="pr-6 whitespace-no-wrap" onClick={() => navigate("/edit/user/" + order?.userId)}>
                          <div className="flex justify-between">
                            <span className="responsive-thead hidden font-semibold">Client</span>
                            <span className="border-dotted border-b-2 border-[#c4cdd5] cursor-pointer">{order?.user[0]?.name} {order?.user[0]?.lastname}</span>
                          </div>
                        </td>

                        <td className="pr-6 whitespace-no-wrap">
                          <div className="flex justify-between">
                            <span className="responsive-thead hidden font-semibold">Total</span>
                            {order?.totalPrice?.toFixed(2)} Є
                          </div>
                        </td>

                        <td className="pr-6 whitespace-no-wrap">
                          <div className="flex justify-between md:justify-start h-full">
                            <div className="flex items-center">
                              <span className="responsive-thead hidden font-semibold">Statut</span>
                            </div>
                            {status[order?.status[order?.status.length - 1]?.status]

                              ? <span className={`font-[500] p-2 rounded text-[14px] text-[white]`} style={{ backgroundColor: status[order?.status[order?.status.length - 1]?.status].color }}>
                                <i className={`${status[order?.status[order?.status.length - 1]?.status].icon}`}></i>
                                <span className="ml-1 text-[14px]">{status[order?.status[order?.status?.length - 1]?.status].text}</span>
                              </span>

                              : <span className={`font-[500] p-2 rounded text-[14px] text-[white] text-[14px]`} style={{ backgroundColor: "grey" }}>
                                <i className={`fa fa-question`}></i>
                                <span className="ml-1">{order?.status[order?.status.length - 1]?.status}</span>
                              </span>
                            }
                          </div>
                        </td>

                        <td className="pr-6 whitespace-no-wrap">
                          <div className="flex justify-between">
                            <span className="responsive-thead hidden font-semibold">Date</span>
                            {Moment(order?.createdAt).format("LL")} à {Moment(order?.createdAt).format("LT")}
                          </div>
                        </td>

                        <td className="pr-8 cursor-pointer">
                          <div className="flex justify-between md:justify-start h-full">
                            <div className="flex items-center">
                              <span className="responsive-thead hidden font-semibold">Action</span>
                            </div>
                            <span onClick={() => navigate("/order/" + order?._id)} className="bg-gray-200 p-2 rounded text-[15px]"><i className="fa fa-search text-gray-700" /></span>
                          </div>
                        </td>

                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>

              <div className={`flex justify-between px-4 py-4 mt-4 ${allOrders.length !== 0 ? "block" : "hidden"} `}>
                <div className="">
                  <span>Afficher:</span>

                  <select name="pets" id="pet-select" value={itemsPerPage ? itemsPerPage : 10} onChange={(e) => setItemsPerPage(Number(e?.target?.value))} className="border-[1px] ml-1 rounded p-1">
                    <option value={1}>1</option>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                  </select>
                </div>

                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="<"
                  containerClassName={'containerPaginate'}
                  previousClassName="previousBtnPaginate"
                  nextClassName="nextBtnPaginate"
                  activeClassName="activeBtnPaginate"
                  pageClassName="pageBtnPaginate"
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
