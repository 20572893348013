import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { editLanguage, getLanguageById } from "../actions/languages";
import { NotificationManager } from 'react-notifications';
import { usePrompt } from '../utils/prompt';

const AddTranslation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [translation, setTranslation] = useState({});
    const [dictionnary, setDictionnary] = useState([]);
    const id = location?.pathname?.split('/')[3];
    const [dataObj, setDataObj] = useState([]);
    const [dataChanged, setDataChanged] = useState(false);

    useEffect(() => {
        getLanguageById(id)
            .then((res) => {
                setDictionnary(res?.dictionnary);
                setDataObj(res?.dictionnary)
            })
            .catch((err) => {
                NotificationManager.error("Une erreur est survenue lors de la récupération de vos traductions !", 'Erreur');
            })
    }, [])

    useEffect(() => {
        compareObjects()
    }, [dictionnary])

    const compareObjects = () => {    
        const dictionnaryKeys = Object.keys(dictionnary);
        const dataObjKeys = Object.keys(dataObj);
    
        const isDifferent = dictionnaryKeys.length !== dataObjKeys.length || dictionnaryKeys.some((key) => {
            const dictionnaryValue = dictionnary[key];
            const dataObjValue = dataObj[key];
    
            if (typeof dictionnaryValue !== "object" && typeof dataObjValue !== "object") {
                return dictionnaryValue !== dataObjValue;
            }
    
            return JSON.stringify(dictionnaryValue) !== JSON.stringify(dataObjValue);
        });
    
        setDataChanged(isDifferent);
    };
    

    const handleAddDictionnary = () => {
        const data = {
            id: id,
            dictionnary: dictionnary
        }

        editLanguage(data)
            .then((res) => {
                setDictionnary(res?.dictionnary);
                setDataObj(res?.dictionnary);
                setDataChanged(false);
                NotificationManager.success("Les traductions ont été ajoutées avec succès !", 'Succès');
            })
            .catch((err) => {
                NotificationManager.error(err?.response?.data?.error, 'Erreur');
            })
    }

    const handleAddTranslation = (key, value) => {
        setDictionnary(Object.assign({}, dictionnary, { [key]: { key, value } }));

        setTranslation({});
    }

    const handleDeleteTranslation = (index) => {
        const updatedDictionnary = { ...dictionnary };
        delete updatedDictionnary[Object.keys(updatedDictionnary)[index]];
        setDictionnary(updatedDictionnary);
    };

    const handleResetData = () => {
        setDictionnary(dataObj);
    }

    return (
        usePrompt(dataChanged),
        
        <>

            <div className={`fixed bg-[#080404] top-0 py-[.7rem] px-5 w-full left-0 z-[999] m-0 text-center test ${dataChanged ? "transition ease-in-out delay-250 opacity-1 duration-500" : "opacity-0 delay-250 duration-500"}`}>
                <div className="flex justify-between text-[white]">
                    <div className="cursor-pointer" onClick={() => navigate('/')}>
                        <svg fill="currentColor" width="38" height="38" viewBox="0 0 174 174" xmlns="http://www.w3.org/2000/svg">
                            <path fill="white" fillRule="evenodd" clipRule="evenodd" d="M0 9.23318C0 4.13387 4.13275 0 9.23075 0H164.769C169.867 0 174 4.13387 174 9.23318V41.7256C168.389 42.1805 163.181 43.5167 158.377 45.7341C152.334 48.4078 147.394 52.1858 143.559 57.0681C140.421 51.9533 136.12 48.1172 130.658 45.5598C125.312 42.8861 119.152 41.5493 112.178 41.5493C105.786 41.5493 99.9745 42.6537 94.7445 44.8623C89.5144 47.0711 85.1559 50.2678 81.6691 54.4526V42.9386H60.9231V135.708H82.7151V88.4543C82.7151 79.3872 84.8653 72.4706 89.1657 67.7045C93.5822 62.9385 99.5097 60.5555 106.948 60.5555C113.689 60.5555 118.861 62.6479 122.464 66.8327C126.067 70.9013 127.868 77.0041 127.868 85.1414V135.708H149.661V88.4543C149.661 79.5034 151.869 72.6449 156.285 67.8789C160.702 62.9966 166.571 60.5555 173.893 60.5555L174 60.5557V164.767C174 169.866 169.867 174 164.769 174H9.23075C4.13275 174 0 169.866 0 164.767V9.23318ZM112.321 69.9598C106.222 69.9598 101.199 72.2675 97.4993 76.8928L97.4895 76.9051L97.4798 76.9175C93.89 81.527 92.2757 87.9726 92.2757 95.8953V135.709H97.1218V95.8953C97.1218 88.5482 98.631 83.3425 101.29 79.9206C104.013 76.523 107.607 74.8191 112.321 74.8191V69.9598Z"></path>
                        </svg>
                    </div>

                    <div className="mt-2 font-bold text-md">
                        Modifications non enregistrées
                    </div>

                    <div className="flex flex-row">
                        <div onClick={() => handleResetData()} className='border-[1px] border-gray-200 hover:bg-[#eb2f06] hover:border-[#eb2f06] rounded  hover:opacity-[1] cursor-pointer flex flex-row px-4 py-2 mr-2'>
                            <i className="fa fa-times text-gray-200 text-[27px] relative top-[.33rem]" />
                            <span className="text-gray-200 ml-1 mt-[.1rem] font-bold">Annuler</span>
                        </div>
                        <div onClick={() => handleAddDictionnary()} className='bg-indigo-700 hover:bg-indigo-500 rounded  hover:opacity-[1] cursor-pointer flex flex-row px-4 py-2'>
                            <i className="fa fa-save text-[white] text-[25px]" />
                            <span className="text-[white] ml-2 mt-[.1rem] font-bold">Enregistrer</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-margin">

                <div className="flex row">
                    <button onClick={() => navigate('/settings/languages')} className="text-white cursor-pointer border-[1.2px] border-[#babfc3] bg-transparent transition duration-150 ease-in-out w-10 h-10 rounded flex items-center justify-center ">
                        <svg viewBox="0 0 20 20" height={25} width={25} fill='#5c5f62' focusable="false" aria-hidden="true"><path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path></svg>
                    </button>

                    <h1 className="text-xl font-semibold mt-1 ml-4">Ajouter des traductions</h1>
                </div>


                <div className="flex items-center justify-between w-full mt-10">
                    <div className="w-full lg:w-3/3">
                        <div className="py-0">
                            <div className="mx-auto bg-white shadow rounded">

                                <div className="w-full overflow-x-scroll xl:overflow-x-hidden">
                                    <table className="min-w-full bg-white">
                                        <thead>
                                            <tr className="w-full h-16 border-gray-300 border-b py-8">
                                                <th className="pl-8 text-gray-600 font-normal pr-6 text-left text-sm tracking-normal leading-4">
                                                    <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                                                </th>
                                                <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Clé</th>
                                                <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Valeur</th>
                                                <th className="text-gray-600 pr-6 text-left text-sm tracking-normal leading-4 font-semibold">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="h-20 border-gray-300 border-b">
                                                <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">

                                                </td>
                                                <td>
                                                    <input type="text" name="slug" value={translation?.key || ''} onChange={(e) => setTranslation({ ...translation, key: e?.target?.value })} className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="Shopping_Cart" />
                                                </td>
                                                <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                    <input type="text" value={translation?.value || ''} onChange={(e) => setTranslation({ ...translation, value: e?.target?.value })} name="slug" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2" placeholder="Panier" />
                                                </td>
                                                <td>
                                                    <button onClick={() => handleAddTranslation(translation?.key, translation?.value)} className="mt-2 text-white cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                                                        Ajouter
                                                    </button>
                                                </td>
                                            </tr>
                                            {dictionnary && Object.keys(dictionnary).map((key, index) => (
                                                <tr key={index} className="h-20 border-gray-300 border-b">
                                                    <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                        <input type="checkbox" className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            name="slug"
                                                            value={dictionnary[key]?.key}
                                                            readOnly
                                                            className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                                        />
                                                    </td>
                                                    <td className="pl-8 pr-6 text-left whitespace-no-wrap text-sm text-gray-800 tracking-normal leading-4">
                                                        <input
                                                            type="text"
                                                            value={dictionnary[key]?.value}
                                                            readOnly
                                                            className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2"
                                                        />
                                                    </td>
                                                    <td>
                                                        <button onClick={() => handleDeleteTranslation(index)} className="mt-2 text-white cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-red-500 transition duration-150 ease-in-out hover:bg-red-600 p-4 h-8 rounded flex items-center justify-center">
                                                            Supprimer
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddTranslation;

