import React, { useState } from "react";
import Select from 'react-select';

const Devices = () => {
    const [select, setSelect] = useState(null);
    const [multiSelect, setMultiSelect] = useState(null);

    const optionsDefault = [
        { value: 'eur', label: 'EUR' },
        { value: 'usd', label: 'USD' },
        { value: 'dkk', label: 'DKK' },
    ];

    const multiOptions = [
        { value: 'eur', label: 'EUR' },
        { value: 'usd', label: 'USD' },
        { value: 'dkk', label: 'DKK' },
        { value: 'cad', label: 'CAD' },
        { value: 'aed', label: 'AED' },
        { value: 'afn', label: 'AFN' },
    ];

    const handleChangeSelect = (item) => {
        setSelect(item);
    }

    const handleChangeSelectMulti = (item) => {
        setMultiSelect(item);
    }

    return (
        <div>

            {/* BREADCRUMBS */}
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                    <a href="/" className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-900">
                        <svg width="15px" height="15px" viewBox="0 0 50 50" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.8889 0H8.33333C6.04167 0 3.95833 0.936111 2.44722 2.44722C0.936111 3.95833 0 6.04167 0 8.33333V13.8889C0 16.1806 0.936111 18.2639 2.44722 19.775C3.95833 21.2861 6.04167 22.2222 8.33333 22.2222H13.8889C16.1806 22.2222 18.2639 21.2861 19.775 19.775C21.2861 18.2639 22.2222 16.1806 22.2222 13.8889V8.33333C22.2222 6.04167 21.2861 3.95833 19.775 2.44722C18.2639 0.936111 16.1806 0 13.8889 0Z" />
                            <path d="M41.6667 0H36.1112C33.8195 0 31.7362 0.936111 30.2251 2.44722C28.7139 3.95833 27.7778 6.04167 27.7778 8.33333V13.8889C27.7778 16.1806 28.7139 18.2639 30.2251 19.775C31.7362 21.2861 33.8195 22.2222 36.1112 22.2222H41.6667C43.9584 22.2222 46.0417 21.2861 47.5528 19.775C49.0639 18.2639 50.0001 16.1806 50.0001 13.8889V8.33333C50.0001 6.04167 49.0639 3.95833 47.5528 2.44722C46.0417 0.936111 43.9584 0 41.6667 0Z" />
                            <path d="M13.8889 27.7778H8.33333C6.04167 27.7778 3.95833 28.7139 2.44722 30.225C0.936111 31.7361 0 33.8194 0 36.1111V41.6667C0 43.9583 0.936111 46.0417 2.44722 47.5528C3.95833 49.0639 6.04167 50 8.33333 50H13.8889C16.1806 50 18.2639 49.0639 19.775 47.5528C21.2861 46.0417 22.2222 43.9583 22.2222 41.6667V36.1111C22.2222 33.8194 21.2861 31.7361 19.775 30.225C18.2639 28.7139 16.1806 27.7778 13.8889 27.7778Z" />
                            <path d="M41.6667 27.7778H36.1112C33.8195 27.7778 31.7362 28.7139 30.2251 30.225C28.7139 31.7361 27.7778 33.8194 27.7778 36.1111V41.6667C27.7778 43.9583 28.7139 46.0417 30.2251 47.5528C31.7362 49.0639 33.8195 50 36.1112 50H41.6667C43.9584 50 46.0417 49.0639 47.5528 47.5528C49.0639 46.0417 50.0001 43.9583 50.0001 41.6667V36.1111C50.0001 33.8194 49.0639 31.7361 47.5528 30.225C46.0417 28.7139 43.9584 27.7778 41.6667 27.7778Z" />
                            <clipPath id="clip0_308_250">
                                <rect width="15px" height="15px" />
                            </clipPath>
                        </svg>
                        <span className="ml-2">Tableau de bord</span>
                    </a>
                </li>
                <li>
                    <div className="flex items-center">
                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                        <a href="/settings" className="ml-1 text-sm font-medium text-gray-500 hover:text-gray-900 md:ml-2 ">Paramètres</a>
                    </div>
                </li>
                <li aria-current="page">
                    <div className="flex items-center">
                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                        <span className="ml-1 text-sm font-medium text-gray-900 md:ml-2z">Devices</span>
                    </div>
                </li>
            </ol>
            {/* BREADCRUMBS */}

            <div className="w-full border-[.5px] border-gray-200 mt-8" />



            <div className="grid grid-cols-3 gap-4 mt-8">
                <div className="col-span-2">
                    <div className="flex justify-between w-full">
                        <div className="w-full rounded bg-white shadow p-6">
                            <h1 className="font-semibold  text-xl">Devices</h1>
                            <p className="mt-1 text-[#6d7175] mb-10 text-[.9rem] opacity-[.45] font-semibold">Gérez les devises dans lesquelles vous opérerez</p>

                            {/* TITLE INPUT */}
                            <div className="relative w-full">
                                <label className="text-gray-700">
                                    Device de la boutique par défaut
                                </label>

                                <div className="mt-2 mb-4">
                                    <Select
                                        value={select}
                                        onChange={(item) => handleChangeSelect(item)}
                                        options={optionsDefault}
                                        placeholder={"Choisir une device"}
                                    />
                                </div>

                                <label className="text-gray-700">
                                    Device des boutiques
                                </label>

                                <div className="mt-2">
                                    <Select
                                        isMulti={true}
                                        value={multiSelect}
                                        onChange={(item) => handleChangeSelectMulti(item)}
                                        options={multiOptions}
                                        placeholder={"Choisir une ou plusieurs devices"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* FIXED SAVE BTN */}
            <div className='fixed bottom-[1rem] right-[1rem] p-2 bg-[#4338ca] rounded opacity-[.80] hover:opacity-[1] cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" height={32} width={32}><path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" /></svg>
            </div>
        </div>
    );
};

export default Devices;
