import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import Moment from 'moment';
import { getBestSeller, getCountAnalytics, getRecentOrder } from "../actions/analytics";
import 'moment/locale/fr';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { addDays, } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import status from '../utils/status.json';
import { useDispatch } from "react-redux";
import fr from 'date-fns/locale/fr';
import { IMG_LINK } from "../config/key";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const chartSplineRef = useRef(null);
  const chartBarRef = useRef(null);
  const [gettingStarted, setGettingStarted] = useState(false);
  const [dropdownActions, setDropdownActions] = useState(false);
  const [countAnalytics, setCountAnalytics] = useState({ "salesNumber": 0, "totalGains": 0, "orderAverage": 0, "clientNumber": 0 });
  const [recentOrder, setRecentOrder] = useState([]);
  const [bestSeller, setBestSeller] = useState([]);
  const [loadingSpinnerOrder, setLoadingSpinnerOrder] = useState(false);
  const [loadingSpinnerBestSeller, setLoadingSpinnerBestSeller] = useState(false);

  const [showDatePicker, setShowDatePicker] = useState(false);

  const [date, setDate] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  useEffect(() => {
    const resizeChart = () => {
      const chartSpline = chartSplineRef.current?.chart;
      const chartBar = chartBarRef.current?.chart;
      if (chartSpline || chartBar) {
        chartSpline.reflow();
        chartBar.reflow();
      }
    };

    window.addEventListener("load", resizeChart);

    return () => {
      window.removeEventListener("load", resizeChart);
    };
  }, []);

  const options1 = {
    chart: {
      type: 'spline'
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    series: [
      {
        data: [1, 2, 1, 4, 3, 6],
        showInLegend: false
      }
    ],
  };

  const options2 = {
    chart: {
      type: 'bar',
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    series: [
      {
        data: [7, 2, 3, 1, 4, 6],
        showInLegend: false
      }
    ]
  };

  useEffect(() => {

    const dataCountAnalytics = {
      startDate: Number(Moment(date[0]?.startDate).format("x")),
      endDate: Number(Moment(date[0]?.endDate).format('x'))
    }

    dispatch(getCountAnalytics(dataCountAnalytics)).then((res) => {
      setCountAnalytics(res);
    });

    setLoadingSpinnerOrder(true);

    getRecentOrder().then((res) => {
      setLoadingSpinnerOrder(false);
      setRecentOrder(res);
    });

    const dataBestSeller = {
      number: 7,
      startDate: Number(Moment(date[0]?.startDate).format("x")),
      endDate: Number(Moment(date[0]?.endDate).format('x'))
    }

    setLoadingSpinnerBestSeller(true);

    getBestSeller(dataBestSeller).then((res) => {
      setLoadingSpinnerBestSeller(false);
      setBestSeller(res);
    });
  }, [date]);

  const handleRefreshAnalytics = () => {

    const dataCountAnalytics = {
      startDate: Number(Moment(date[0]?.startDate).format("x")),
      endDate: Number(Moment(date[0]?.endDate).format('x'))
    }

    dispatch(getCountAnalytics(dataCountAnalytics)).then((res) => {
      setCountAnalytics(res);
    });

    getRecentOrder().then((res) => {
      setRecentOrder(res)
    });

    const dataBestSeller = {
      number: 7,
      startDate: Number(Moment(date[0]?.startDate).format("x")),
      endDate: Number(Moment(date[0]?.endDate).format('x'))
    }

    getBestSeller(dataBestSeller).then((res) => {
      setBestSeller(res);
    });
  }

  const handleShowDatePicker = () => {
    setShowDatePicker(true);

    if (showDatePicker) {
      setShowDatePicker(false);
    }
  }

  return (
    <div className="container-margin">
      <div onClick={() => setDropdownActions(false)} className={`${dropdownActions !== false && "mydropdown"}`}></div>

      {gettingStarted &&
        <div className="p-10">
          <div className="grid grid-cols-2 gap-2">
            <div>
              <h1 className="text-[40px] text-[#283341] font-semibold">Mise en route</h1>
              <p className="text-[17px] text-[#41546c]">Bienvenue ! Nous sommes là pour vous aider à faire fonctionner les choses.</p>

              <ul className="mt-10 ml-4">
                <div className="flex flex-row">
                  <div className="w-8 h-8 bg-indigo-500 rounded-full flex justify-center items-center">
                    <img className="w-4 H-4" src='/check.svg' />
                  </div>
                  <div onClick={() => navigate('/settings/delivery')} className="flex flex-row cursor-pointer">
                    <li className="text-[17px] mt-1 ml-2 text-[#41546c] font-semibold">Configurer votre expédition</li>
                    <img className="w-5 ml-2 mt-1" src='/arrow.svg' />
                  </div>
                </div>

                <div className="w-3 h-3 bg-indigo-500 rounded-full flex justify-center items-center mt-2 mb-2 ml-[.6rem]"></div>

                <div className="flex flex-row">
                  <div className="w-8 h-8 bg-[#d6e4f7] rounded-full flex justify-center items-center"></div>
                  <div onClick={() => navigate('/settings/domains')} className="flex flex-row cursor-pointer">
                    <li className="text-[17px] mt-1 ml-2 text-[#41546c] font-semibold">Configurer un domaine personnalisé</li>
                    <img className="w-5 ml-2 mt-1" src='/arrow.svg' />
                  </div>
                </div>

                <div className="w-3 h-3 bg-[#d6e4f7] rounded-full flex justify-center items-center mt-2 mb-2 ml-[.6rem]"></div>

                <div className="flex flex-row">
                  <div className="w-8 h-8 bg-[#d6e4f7] rounded-full flex justify-center items-center"></div>
                  <div onClick={() => navigate('/products')} className="flex flex-row cursor-pointer">
                    <li className="text-[17px] mt-1 ml-2 text-[#41546c] font-semibold">Ajouter vos produits</li>
                    <img className="w-5 ml-2 mt-1" src='/arrow.svg' />
                  </div>
                </div>

                <div className="w-3 h-3 bg-[#d6e4f7] rounded-full flex justify-center items-center mt-2 mb-2 ml-[.6rem]"></div>

                <div className="flex flex-row">
                  <div className="w-8 h-8 bg-[#d6e4f7] rounded-full flex justify-center items-center"></div>
                  <div onClick={() => navigate('/settings/payments')} className="flex flex-row cursor-pointer">
                    <li className="text-[17px] mt-1 ml-2 text-[#41546c] font-semibold">Configurer une passerelle de paiement</li>
                    <img className="w-5 ml-2 mt-1" src='/arrow.svg' />
                  </div>
                </div>

                <div className="w-3 h-3 bg-[#d6e4f7] rounded-full flex justify-center items-center mt-2 mb-2 ml-[.6rem]"></div>

                <div className="flex flex-row mb-4">
                  <div className="w-8 h-8 bg-[#d6e4f7] rounded-full flex justify-center items-center"></div>
                  <div className="flex flex-row cursor-pointer">
                    <li className="text-[17px] mt-1 ml-2 text-[#41546c] font-semibold">Configurer votre taxe</li>
                    <img className="w-5 ml-2 mt-1" src='/arrow.svg' />
                  </div>
                </div>
              </ul>
            </div>
            <div className="flex justify-end" style={{ width: '95%' }}>
              <img width={'80%'} src='/preview.svg' />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-28 mt-24">
            <div className="justify-start bg-indigo-500 shadow-xl px-8 py-4 rounded-lg">
              <div className="flex flex-row mb-6">
                <img src='./computer_1.svg' width={'35px'} />
                <h3 className="text-[white] text-[15px] uppercase ml-2 mt-[.45rem]">Modélisation de produit</h3>
              </div>
              <h1 className="text-[white] text-[25px] font-semibold mb-3">Étendez vos données produit</h1>
              <p className="text-[white]">Ajoutez n'importe quel type de données aux produits avec des attributs. Ajoutez des attributs de produit à partir du tableau de bord.</p>

              <div className="flex justify-center mt-10">
                <img className="w-[100%]" src="./attributes.png" />
              </div>

            </div>
            <div className="bg-[white] shadow-xl rounded-lg relative overflow-hidden">
              <div className="px-8 py-4">
                <div className="flex flex-row mb-6">
                  <img src='./computer_2.svg' width={'35px'} />
                  <h3 className="text-[15px] uppercase ml-2 mt-[.45rem]">Pour les développeurs</h3>
                </div>

                <h1 className="text-[25px] font-semibold mb-3">Commencer à construire</h1>
                <p>Démarrez avec nos API flexibles, légères mais puissantes. Créez des vitrines personnalisées, des présentoirs de produits, des paniers et des caisses.</p>
              </div>
              <div className="bg-indigo-500 px-8 py-4 h-[100%] mt-6">
                <ul>
                  <div>
                    <li className="text-[white] font-semibold text-[20px]">Installer le SDK Commerce.js</li>
                    <div className="bg-transparent border-2 border-[white] text-[white] rounded-sm mt-3 p-2">npm install @chec/commerce.js</div>
                  </div>
                  <div className="mt-5">
                    <li className="text-[white] font-semibold text-[20px]">Installer l'interface de ligne de commande</li>
                    <div className="bg-transparent border-2 border-[white] text-[white] rounded-sm mt-3 p-2">npm install -g @chec/cli</div>
                  </div>

                  <div className="mt-5">
                    <h1 className="text-[white] font-semibold text-[20px]">Clés API</h1>
                    <li className="text-[white] font-semibold text-[12px] mt-4">CLÉ PUBLIQUE DU BAC À SABLE</li>
                    <div className="bg-transparent border-2 border-[white] text-[white] rounded-sm mt-2 p-2">pk_test_4805749e064869adc400f6781e62861a453db5eac1b40</div>

                    <li className="text-[white] font-semibold text-[12px] mt-4">CLÉ SECRÈTE DU BAC À SABLE</li>
                    <div className="bg-transparent border-2 border-[white] text-[white] rounded-sm mt-2 p-2">sk_test_48057c24aacec91d81cf7a9a8d7252b51676ce38856a2</div>
                  </div>
                </ul>
              </div>
            </div>
          </div>

          {/* SEPARATOR */}
          <div className="w-full border-[.5px] border-gray-200 mt-24 mb-24" />

          <div className="flex justify-center">
            <div className="bg-[white] shadow-xl p-8 w-1/2 rounded-lg">
              <h1 className="font-semibold text-[25px] text-[#283341] mb-4">Parler à un expert</h1>
              <p className="text-[#41546c]">Nos experts en commerce peuvent s'assurer que notre marketplace convienne parfaitement à votre entreprise et vous aider à concevoir une solution.</p>

              <button className="text-white mt-8 w-full h-12 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-indigo-500 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                Écrivez-nous
              </button>
            </div>
          </div>
        </div>
      }

      {!gettingStarted &&
        <div>

          <div className="flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
            <div className="flex flex-row">
              <h1 className="text-xl font-semibold mt-[.1rem] mr-3 whitespace-nowrap">Tableau de bord</h1>
              <button onClick={() => handleRefreshAnalytics()} className="text-white flex items-center justify-center cursor-pointer bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 p-4 h-8 rounded flex items-center justify-center">
                <i className="fa fa-refresh"></i>
                <span className="ml-2 text-[14px]">Rafraichir</span>
              </button>
            </div>
            <div className="flex justify-start mt-4 lg:mt-0 lg:justify-self-end md:justify-self-end xl:justify-self-end">
              <button onClick={() => handleShowDatePicker()} className="text-gray-500  flex text-[15px] items-center justify-center cursor-pointer bg-white border-[1px] border-gray-200 transition duration-150 ease-in-out pl-3 pr-2 py-5 h-8 rounded flex items-center justify-center">
                <div className="mr-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="currentColor">
                    <path fill='text-gray-400' d="M8 0.5C8.82843 0.5 9.5 1.17157 9.5 2V3H14.5V2C14.5 1.17157 15.1716 0.5 16 0.5C16.8284 0.5 17.5 1.17157 17.5 2V3H19C20.6569 3 22 4.34315 22 6V8H2V6C2 4.34315 3.34315 3 5 3H6.5V2C6.5 1.17157 7.17157 0.5 8 0.5Z" />
                    <path fill='text-gray-400' d="M2 20V10H22V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20Z" />
                  </svg>
                </div>

                <span className="font-semibold ml-1 mr-1">{Moment(date[0]?.startDate).format('LL')}</span>
                {' - '}
                <span className="font-semibold ml-1 mr-1">{Moment(date[0]?.endDate).format('LL')}</span>

                <svg className={`w-6 h-6 mt-[.1rem] ${showDatePicker && "arrow-sub-menu"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>

              </button>
            </div>
          </div>

          {showDatePicker &&
            <div className="mt-6">
              <DateRangePicker
                onChange={item => setDate([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                rangeColors={["#5045e5"]}
                color="#5045e5"
                ranges={date}
                direction="horizontal"
                locale={fr}
              />
            </div>
          }

          <div className="intro-y report-box mt-12 sm:mt-4 mb-8">
            <div className="box py-0 xl:py-5 grid grid-cols-12  gap-0 divide-y xl:divide-y-0 divide-x divide-dashed divide-slate-200 dark:divide-white/5">
              <div className="report-box__item py-5 xl:py-0 px-5 col-span-12 sm:col-span-6 xl:col-span-3">
                <div className="report-box__content">
                  <div className="flex">
                    <div className="report-box__item__icon text-primary bg-primary/20 border border-primary/20 flex items-center justify-center rounded-full bg-[black] border-[black]">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide">
                        <path d="M21.21 15.89A10 10 0 118 2.83"></path>
                        <path d="M22 12A10 10 0 0012 2v10z"></path>
                      </svg>
                    </div>
                    <div className="ml-auto">
                      <div className="report-box__item__indicator text-[#3da69d]">

                        <h1 className={`${Math.sign(countAnalytics?.totalGainsDiff) === -1 ? "text-[#cb5a5b]" : "text-[#27ae60]"}`}>{countAnalytics?.totalGainsDiff?.toFixed(2) === undefined ? 0 + " %" : countAnalytics?.totalGainsDiff?.toFixed(2) + " %"}</h1>

                        {Math.sign(countAnalytics?.totalGainsDiff) === -1
                          ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#cb5a5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide w-4 h-4 ml-0.5">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <polyline points="19 12 12 19 5 12"></polyline>
                          </svg>
                          : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#27ae60" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4 ml-0.5 rotate-180 lucide">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <polyline points="19 12 12 19 5 12"></polyline>
                          </svg>
                        }

                      </div>
                    </div>
                  </div>
                  <div className="text-2xl font-medium leading-7 mt-6">{countAnalytics?.totalGains?.toFixed(2)} €</div>
                  <div className="text-slate-500 mt-1">Chiffre d'affaires</div>
                </div>
              </div>
              <div className="report-box__item py-5 xl:py-0 px-5 sm:!border-t-0 col-span-12 sm:col-span-6 xl:col-span-3">
                <div className="report-box__content">
                  <div className="flex">
                    <div className="report-box__item__icon text-pending bg-pending/20 border border-pending/20 flex items-center justify-center rounded-full bg-[black] border-[black]">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide">
                        <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                        <line x1="1" y1="10" x2="23" y2="10"></line>
                      </svg>
                    </div>
                    <div className="ml-auto">
                      <div className="report-box__item__indicator">
                        <h1 className={`${Math.sign(countAnalytics?.orderAverageDiff) === -1 ? "text-[#cb5a5b]" : "text-[#27ae60]"}`}>{countAnalytics?.orderAverageDiff?.toFixed(2) + " %"}</h1>

                        {Math.sign(countAnalytics?.orderAverageDiff) === -1
                          ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#cb5a5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide w-4 h-4 ml-0.5">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <polyline points="19 12 12 19 5 12"></polyline>
                          </svg>
                          : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#27ae60" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4 ml-0.5 rotate-180 lucide">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <polyline points="19 12 12 19 5 12"></polyline>
                          </svg>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="text-2xl font-medium leading-7 mt-6">{countAnalytics?.orderAverage == null ? 0 : countAnalytics?.orderAverage?.toFixed(2)} €</div>
                  <div className="text-slate-500 mt-1">Panier moyen</div>
                </div>
              </div>
              <div className="report-box__item py-5 xl:py-0 px-5 col-span-12 sm:col-span-6 xl:col-span-3">
                <div className="report-box__content ">
                  <div className="flex">
                    <div className="report-box__item__icon text-warning bg-warning/20 border border-warning/20 flex items-center justify-center rounded-full bg-[black] border-[black]">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide">
                        <path d="M6 2L3 6v14a2 2 0 002 2h14a2 2 0 002-2V6l-3-4z"></path>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <path d="M16 10a4 4 0 01-8 0"></path>
                      </svg>
                    </div>
                    <div className="ml-auto">
                      <div className="report-box__item__indicator">

                        <h1 className={`${Math.sign(countAnalytics?.salesNumberDiff) === -1 ? "text-[#cb5a5b]" : "text-[#27ae60]"}`}>{countAnalytics?.salesNumberDiff?.toFixed(2) === undefined ? 0 + " %" : countAnalytics?.salesNumberDiff?.toFixed(2) + " %"}</h1>

                        {Math.sign(countAnalytics?.salesNumberDiff) === -1
                          ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#cb5a5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide w-4 h-4 ml-0.5">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <polyline points="19 12 12 19 5 12"></polyline>
                          </svg>
                          : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#27ae60" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4 ml-0.5 rotate-180 lucide">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <polyline points="19 12 12 19 5 12"></polyline>
                          </svg>
                        }

                      </div>
                    </div>
                  </div>
                  <div className="text-2xl font-medium leading-7 mt-6">{countAnalytics?.salesNumber}</div>
                  <div className="text-slate-500 mt-1">Nombre de ventes</div>
                </div>
              </div>
              <div className="report-box__item py-5 xl:py-0 px-5 col-span-12 sm:col-span-6 xl:col-span-3">
                <div className="report-box__content">
                  <div className="flex">
                    <div className="report-box__item__icon text-success bg-success/20 border border-success/20 flex items-center justify-center rounded-full bg-[black] border-[black]">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide">
                        <line x1="22" y1="12" x2="2" y2="12"></line>
                        <path d="M5.45 5.11L2 12v6a2 2 0 002 2h16a2 2 0 002-2v-6l-3.45-6.89A2 2 0 0016.76 4H7.24a2 2 0 00-1.79 1.11z"></path>
                        <line x1="6" y1="16" x2="6.01" y2="16"></line>
                        <line x1="10" y1="16" x2="10.01" y2="16"></line>
                      </svg>
                    </div>
                    <div className="ml-auto">
                      <div className={`report-box__item__indicator ${countAnalytics?.clientNumberDiff !== 0 ? "text-[#27ae60]" : "text-[#cb5a5b]"}`}>


                        <h1 className={`${Math.sign(countAnalytics?.clientNumberDiff) === -1 ? "text-[#cb5a5b]" : "text-[#27ae60]"}`}>{countAnalytics?.clientNumberDiff?.toFixed(2) === undefined ? 0 + " %" : countAnalytics?.clientNumberDiff?.toFixed(2) + " %"}</h1>

                        {Math.sign(countAnalytics?.clientNumberDiff) === -1
                          ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#cb5a5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide w-4 h-4 ml-0.5">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <polyline points="19 12 12 19 5 12"></polyline>
                          </svg>
                          : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#27ae60" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4 ml-0.5 rotate-180 lucide">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <polyline points="19 12 12 19 5 12"></polyline>
                          </svg>
                        }




                      </div>
                    </div>

                  </div>
                  <div className="text-2xl font-medium leading-7 mt-6">{countAnalytics?.clientNumber}</div>
                  <div className="text-slate-500 mt-1">Nombre de clients</div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="flex flex-col">
            <div className="flex grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
              <div className="bg-white rounded p-4">
                <h1 className="text-base font-semibold mb-8 lg:mb-1 text-18px lg:text-16px">Chart #1</h1>
                <div className="chart-container">
                  <HighchartsReact ref={chartSplineRef}
                    highcharts={Highcharts} options={options1} />
                </div>
              </div>
              <div className="bg-white rounded p-4">
                <h1 className="text-base font-semibold mb-8 lg:mb-1 text-18px lg:text-16px">Chart #2</h1>
                <div className="chart-container">
                  <HighchartsReact ref={chartBarRef}
                    highcharts={Highcharts} options={options2} />
                </div>
              </div>
            </div>
          </div> */}

          <div className="grid xl:grid-cols-2 grid-cols-1 gap-4 mt-3">
            <div className="bg-[white] rounded p-4">
              <div className="flex row justify-between">
                <h1 className="text-base font-semibold mb-8 lg:mb-4 text-[18px] lg:text-[16px]">Commandes récentes</h1>
              </div>

              {loadingSpinnerOrder ? (
                <div className="flex justify-center items-center content-center">
                  <svg className="w-11 h-11 animate-spin mb-14" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#000000" />
                    <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z" fill="#000000" />
                  </svg>
                </div>
              ) : (
                <>
                  {recentOrder.length === 0 ? (
                    <div className="flex flex-col w-full">
                      <div className="flex justify-center ml-5 mb-4">
                        <img src="../../no_data.png" width={100} />
                      </div>
                      <div className="flex justify-center">
                        <span className="text-gray-500">Aucune commande récente n'a été trouvée.</span>
                      </div>
                    </div>
                  ) : (
                    <div className="overflow-x-auto">
                      <table className="min-w-full bg-white">
                        <thead>
                          <tr className="border-gray-300 border-b">
                            <th className="text-left py-2 sm:py-4 px-2 sm:px-6 text-sm font-semibold text-gray-600">Date</th>
                            <th className="text-left py-2 sm:py-4 px-2 sm:px-6 text-sm font-semibold text-gray-600">Client</th>
                            <th className="text-left py-2 sm:py-4 px-2 sm:px-6 text-sm font-semibold text-gray-600">Total</th>
                            <th className="text-left py-2 sm:py-4 px-2 sm:px-6 text-sm font-semibold text-gray-600">Statut</th>
                            <th className="text-left py-2 sm:py-4 px-2 sm:px-6 text-sm font-semibold text-gray-600">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(recentOrder).slice(0, 5).map((key) => (
                            <tr key={key} className="border-gray-300 border-b hover:bg-gray-100 text-[14px]">
                              <td className="py-2 sm:py-4 px-2 sm:px-6 whitespace-no-wrap">
                                <div className="flex justify-between">
                                  <span className="responsive-thead hidden font-semibold">Date</span>
                                  <span>{Moment(recentOrder[key]?.createdAt).format("L")} à {Moment(recentOrder[key]?.createdAt).format("LT")}</span>
                                </div>
                              </td>
                              <td
                                className="py-2 sm:py-4 px-2 sm:px-6 whitespace-no-wrap cursor-pointer"
                                onClick={() => navigate("/edit/user/" + recentOrder[key]?.userId)}
                              >
                                <div className="flex justify-between">
                                  <span className="responsive-thead hidden font-semibold">Client</span>
                                  <span>
                                    {recentOrder[key]?.user[0]?.name} {recentOrder[key]?.user[0]?.lastname}
                                  </span>
                                </div>
                              </td>
                              <td className="py-2 sm:py-4 px-2 sm:px-6 whitespace-no-wrap">
                                <div className="flex justify-between">
                                  <span className="responsive-thead hidden font-semibold">Total</span>
                                  <span className="whitespace-nowrap">{recentOrder[key]?.totalPrice?.toFixed(2)} Є</span>
                                </div>
                              </td>
                              <td className="py-2 sm:py-4 px-2 sm:px-6 whitespace-no-wrap">
                                <div className="flex justify-between md:justify-start h-full">
                                  <div className="flex items-center">
                                    <span className="responsive-thead hidden font-semibold">Statut</span>
                                  </div>
                                  <div
                                    className={`inline-block py-2 px-4 rounded text-sm text-white whitespace-nowrap`}
                                    style={{ backgroundColor: status[recentOrder[key]?.status[recentOrder[key]?.status?.length - 1]?.status]?.color }}
                                  >
                                    <i className={`${status[recentOrder[key]?.status[recentOrder[key]?.status?.length - 1]?.status]?.icon}`}></i>

                                    <span className="ml-1">{status[recentOrder[key]?.status[recentOrder[key]?.status?.length - 1]?.status]?.text}</span>

                                  </div>
                                </div>
                              </td>
                              <td className="py-2 sm:py-4 px-2 sm:px-6 cursor-pointer">
                                <div className="flex justify-between md:justify-center h-full">
                                  <div className="flex items-center">
                                    <span className="responsive-thead hidden font-semibold">Actions</span>
                                  </div>
                                  <span onClick={() => navigate("/order/" + recentOrder[key]?._id)} className="bg-gray-200 p-2 rounded text-sm">
                                    <i className="fa fa-search text-gray-700" />
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="bg-[white] rounded p-4">
              <div className="flex row justify-between">
                <h1 className="text-base font-semibold mb-8 lg:mb-4 text-[18px] lg:text-[16px]">Meilleures ventes</h1>
              </div>

              {loadingSpinnerBestSeller && (
                <div className="flex justify-center items-center content-center">
                  <svg
                    className="w-11 h-11 animate-spin mb-14"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50px"
                    height="50px"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.2"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      fill="#000000"
                    />
                    <path
                      d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
                      fill="#000000"
                    />
                  </svg>
                </div>
              )}

              {!loadingSpinnerBestSeller && (
                <>
                  {bestSeller.length === 0 ? (
                    <div className="flex flex-col w-full">
                      <div className="flex justify-center ml-5 mb-4">
                        <img src="../../no_data.png" width={100} />
                      </div>
                      <div className="flex justify-center">
                        <span className="text-gray-500">Aucune meilleure vente n'a été trouvée sur cette période.</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {!loadingSpinnerBestSeller && (
                        <div className="overflow-x-auto">
                          <table className="min-w-full bg-white">
                            <thead>
                              <tr className="border-gray-300 border-b">
                                <th className="text-left py-0 sm:py-4 px-2 sm:px-4 text-sm font-semibold text-gray-600">
                                  Produit
                                </th>

                                <th className="text-left py-2 sm:py-4 px-2 sm:px-6 text-sm font-semibold text-gray-600"></th>
                                <th className="text-left py-2 sm:py-4 px-2 sm:px-6 text-sm font-semibold text-gray-600">
                                  Prix
                                </th>
                                <th className="text-left py-2 sm:px-6 text-sm font-semibold text-gray-600">
                                  Déclinaison(s)
                                </th>
                                <th className="text-left py-2 sm:py-4 px-2 sm:px-6 text-sm font-semibold text-gray-600">
                                  Ventes
                                </th>

                                <th className="text-left py-2 sm:py-4 px-2 sm:px-6 text-sm font-semibold text-gray-600">
                                  Stock
                                </th>
                                {/* <th className="text-left py-2 sm:py-4 px-2 sm:px-6 text-sm font-semibold text-gray-600">
                                  Action
                                </th> */}
                              </tr>
                            </thead>

                            <tbody>
                              {Object.keys(bestSeller)
                                .slice(0, 5)
                                .map((key) => (
                                  <tr
                                    key={key}
                                    className="cursor-pointer lg:h-14 border-gray-300 border-b hover:bg-gray-100 text-[14px]"
                                    onClick={() =>
                                      navigate(
                                        "/edit/product/" + bestSeller[key]?.info?.productId
                                      )
                                    }

                                  >
                                    {bestSeller[key] && (
                                      <>
                                        <td className="py-2 sm:py-4 px-2 sm:px-6">
                                          <div className="">
                                            <div className="flex justify-between h-full">
                                              <div className="flex items-center">
                                                <span className="responsive-thead hidden font-semibold">Image produit</span>
                                              </div>

                                              <div className="h-9 w-9">

                                                {bestSeller[key]?.info?.images?.thumbnailId || bestSeller[key]?.info?.images?.image1Id ? (
                                                  <img
                                                    src={
                                                      IMG_LINK +
                                                      (bestSeller[key]?.info?.images?.thumbnailId || bestSeller[key]?.info?.images?.image1Id)
                                                    }
                                                    width={50}
                                                    alt="produit"
                                                  />
                                                ) : (
                                                  <img src="../../placeholder.jpeg" width={50} />
                                                )}

                                              </div>
                                            </div>
                                          </div>
                                        </td>

                                        <td className="pr-2 sm:pr-6">
                                          <div className="flex justify-between h-full">
                                            <div className="flex items-center">
                                              <span className="responsive-thead hidden font-semibold">Produit</span>
                                            </div>
                                            <span>{bestSeller[key]?.info?.Product?.name.slice(0, 25) + "..."}</span>
                                          </div>
                                        </td>

                                        <td className="whitespace-nowrap pr-8">
                                          <div className="flex justify-between">
                                            <span className="responsive-thead hidden font-semibold">Prix</span>
                                            <span className="ml-6">{bestSeller[key]?.info?.price?.toFixed(2)} €</span>
                                          </div>
                                        </td>

                                        <td className="pr-2 sm:pr-6 whitespace-no-wrap">
                                          <div className="flex justify-between h-full md:justify-start ml-6">
                                            <div className="flex items-center">
                                              <span className="responsive-thead hidden font-semibold">Déclinaison</span>
                                            </div>
                                            {typeof bestSeller[key]?.info?.properties === 'undefined' ? (
                                              <div className="">
                                                <span className="italic text-gray-400 text-[14px]">
                                                  Produit simple
                                                </span>
                                              </div>
                                            ) : (
                                              <div className="">
                                                {Object.keys(bestSeller[key]?.info?.properties || {}).map(
                                                  (it, idx) => (
                                                    <div
                                                      className="bg-gray-200 text-[14px] py-2 px-3 rounded text-[14px] whitespace-nowrap"
                                                      key={idx}
                                                    >
                                                      <span className="capitalize">{it}</span>:{" "}
                                                      {bestSeller[key]?.info?.properties[it]}
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </td>


                                        <td className="pr-2 sm:pr-6 whitespace-no-wrap">
                                          <div className="flex justify-between">
                                            <span className="responsive-thead hidden font-semibold">Ventes</span>
                                            <span className="ml-6">
                                              {bestSeller[key].selledQuantity}
                                            </span>
                                          </div>
                                        </td>

                                        <td className="pr-2 sm:pr-6 whitespace-no-wrap">
                                          <div className="flex justify-between">
                                            <span className="responsive-thead hidden font-semibold">Quantité</span>
                                            <span className="ml-6">
                                              {bestSeller[key]?.info?.quantity}
                                            </span>
                                          </div>
                                        </td>
                                        {/* <td className="pr-2 sm:pr-8 cursor-pointer">
                                          <div className="flex justify-between h-full">
                                            <div className="flex items-center">
                                              <span className="responsive-thead hidden font-semibold">Actions</span>
                                            </div>
                                            <span
                                              onClick={() =>
                                                navigate(
                                                  "/edit/product/" + bestSeller[key]?.info?.productId
                                                )
                                              }
                                              className="bg-gray-200 p-2 rounded"
                                            >
                                              <i className="fa fa-search text-gray-700" />
                                            </span>
                                          </div>
                                        </td> */}
                                      </>
                                    )}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>

          </div>

        </div>
      }

    </div>
  );
};

export default Dashboard;
