import { apiClient } from './ApiClient'

function getAllShipper() {
    return apiClient().get('/admin/region/shipper')
}

function editShipper(data) {
    return apiClient().patch('/admin/region/shipper', data)
}


function deleteShipper(id) {
    return apiClient().delete('/admin/region/shipper/' + id)
}

function addShipper(data) {
    return apiClient().post('/admin/region/shipper', data)
}

function getShipperById(id) {
    return apiClient().get('/admin/region/shipper/' + id )
}

export default {
    getAllShipper,
    editShipper,
    deleteShipper,
    addShipper,
    getShipperById
}
