import { apiClient } from './ApiClient'

function getAllCategories() {
    return apiClient().get('/categories/')
}

function getAllCategoriesFlat() {
    return apiClient().get('/categories/flat')
}

function getCategoryById(id) {
    return apiClient().get('/categories/' + id)
}

function addCategory(data) {
    return apiClient().post('/admin/categories/', data)
}

function editCategoryById(data) {
    return apiClient().patch('/admin/categories/', data)
}

function deleteCategoryById(id, type) {
    return apiClient().delete('/admin/categories/' + id + '/' + type)
}

export default {
    getAllCategories,
    getCategoryById,
    addCategory,
    editCategoryById,
    deleteCategoryById,
    getAllCategoriesFlat
}
