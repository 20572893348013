import { apiStripe } from './ApiStripe'
import { apiClient } from './ApiClient'

function getAllStores() {
    return apiStripe().get('/store/')
}

function addStore(data) {
    return apiStripe().post('/store', data)
}




function getStore() {
    return apiClient().get('/admin/store')
}

function editStore(data) {
    return apiClient().patch('/admin/store', data)
}

export default {
    getAllStores,
    getStore,
    addStore,
    editStore
}
