import { GET_ALL_DISCOUNTS } from "./types";
import apiAuth from "../api/discounts";

// GET ALL DISCOUNTS
export const getAllDiscounts = () =>
    async (dispatch) => {
        return apiAuth
            .getAllDiscounts()
            .then(async (result) => {
                if (result) {
                    dispatch({ type: GET_ALL_DISCOUNTS, payload: result.data })
                    return result?.data
                }
            })
    };

// CREATE DISCOUNT
export const createDiscount = async (data) => {
    return apiAuth
        .createDiscount(data)
        .then(async (result) => {
            if (result) {
                return result
            }
        })
};

// GET DISCOUNT BY ID
export const getDiscountById = async (id) => {
    return apiAuth
        .getDiscountById(id)
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};

// DELETE DISCOUNT BY ID
export const deleteDiscountById = (id) =>
    async (dispatch) => {
        return apiAuth
            .deleteDiscountById(id)
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_DISCOUNTS, payload: result.data })
                    return result?.data
                }
            })
    };

// EDIT DISCOUNT
export const editDiscount = async (data) => {
    return apiAuth
        .editDiscount(data)
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })
};
