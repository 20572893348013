import { GET_ALL_CATEGORIES } from "./types";
import apiAuth from "../api/categories";

// GET ALL CATEGORIES
export const getAllCategories = () =>
    async (dispatch) => {
        return apiAuth
            .getAllCategories()
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_CATEGORIES, payload: result.data })
                    return result?.data
                }
            })

    };

// GET ALL CATEGORIES
export const getAllCategoriesFlat = () => {
    return apiAuth
        .getAllCategoriesFlat()
        .then(async (result) => {
            if (result) {
                return result?.data
            }
        })

};

// GET CATEGORY BY ID
export const getCategoryById = (id) =>
    async (dispatch) => {
        return apiAuth
            .getCategoryById(id)
            .then(async (result) => {
                if (result) {
                    return result.data
                }
            })
    };

// EDIT CATEGORY BY ID
export const editCategoryById = (id) =>
    async () => {
        return apiAuth
            .editCategoryById(id)
            .then(async (result) => {
                if (result) {
                    return result.data
                }
            })
    };

// ADD CATEGORY
export const addCategory = (data) =>
    async () => {
        return apiAuth
            .addCategory(data)
            .then(async (result) => {
                if (result) {
                    return result.data
                }
            })
    };

// DELETE CATEGORY BY ID
export const deleteCategoryById = (id, type) =>
    async (dispatch) => {
        return apiAuth
            .deleteCategoryById(id, type)
            .then(async (result) => {
                if (result) {
                    await dispatch({ type: GET_ALL_CATEGORIES, payload: result.data })
                    return result?.data
                }
            })
    };