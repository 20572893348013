import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { getAllCategories } from "../actions/categories";
import { getAllCollections } from "../actions/collections";
import { getAllBrands } from "../actions/brands";
import { getAllItems } from "../actions/items";
import { createDiscount, editDiscount, getDiscountById } from "../actions/discounts";
import DatePicker from "react-datepicker";
import Moment from 'moment';
import fr from "date-fns/locale/fr-CH";
import { NotificationManager } from 'react-notifications';
import "../datepicker.css";
import { usePrompt } from '../utils/prompt';
import Select from 'react-select';

const AddDiscount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  let selectInputProducts = useRef();
  let selectInputCollections = useRef();
  let selectInputBrands = useRef();


  let discountId = location?.pathname.split('/')[3];

  const [allCategories, setAllCategories] = useState([]);
  const [allCollections, setAllCollections] = useState([])
  const [allProducts, setAllProducts] = useState([]);
  const [allBrands, setAllBrands] = useState([]);

  const [discount, setDiscount] = useState([]);
  const [arrCollapseCat, setArrCollapseCat] = useState([]);
  const [errorInput, setErrorInput] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [dataObj, setDataObj] = useState({});

  useEffect(() => {
    if (location?.pathname.split('/')[1] === 'add') {
      setDiscount({ ...discount, type: "flat" });
      setDataObj({ ...discount, type: "flat" });
    }

    dispatch(getAllCategories())
      .then((res) => {
        setAllCategories(res);
      });

    dispatch(getAllCollections())
      .then((res) => {
        setAllCollections(res);
      });


    dispatch(getAllBrands())
      .then((res) => {
        setAllBrands(res);
      });

    dispatch(getAllItems())
      .then((res) => {
        setAllProducts(res);
      })

    if (discountId) {
      getDiscountById(discountId).then((res) => {

        setDiscount(res);
        setDataObj(res);


        dispatch(getAllItems()).then((item) => {
          res?.products.map((discountProduct) => {

            let result = item.find((it) => it?._id === discountProduct);

            setAllProducts((state) => [...state, {
              label: result?.name,
              value: result?.name,
              key: result?._id
            }])

          })
        })

      })
    }
  }, []);

  useEffect(() => {
    compareObjects()

  }, [discount]);

  const compareObjects = () => {

    const discountKeys = Object.keys(discount);

    const isDifferent = discountKeys.some((key) => {
      const discountValue = discount[key];
      const dataObjValue = dataObj[key];

      if (typeof discountValue !== "object" && typeof dataObjValue !== "object") {
        return discountValue !== dataObjValue;
      }

      return JSON.stringify(discountValue) !== JSON.stringify(dataObjValue);
    });

    setDataChanged(isDifferent);
  };

  const handleGenerateDiscountCode = () => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let charactersLength = characters.length;

    for (let i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    setDiscount({ ...discount, discountCode: result });
  }

  const handleCollaspeCategory = (id) => {
    setArrCollapseCat(current => [...current, id]);

    if (arrCollapseCat.indexOf(id) !== -1) {
      setArrCollapseCat(current =>
        current.filter(element => {
          return element !== id;
        }),
      );
    }
  }

  const handleChooseCategories = (id) => {

    let updatedCategories;

    if (discount?.categories?.includes(id)) {
      updatedCategories = discount?.categories?.filter(categoryId => categoryId !== id);
    } else {
      if (discount?.categories)
        updatedCategories = [...discount?.categories, id];
      else
        updatedCategories = [id];
    }

    // vérifier si l'array a une longueur de 0
    const newCategories = updatedCategories.length === 0 ? null : updatedCategories;

    // supprimer complètement l'objet "categories" si l'array est vide
    const newDiscount = newCategories ? { ...discount, categories: newCategories } : Object.assign({}, discount);
    if (newCategories === null) {
      delete newDiscount.categories;
    }

    setDiscount(newDiscount);
  }

  const renderCategories = item => Object.keys(item).map((it, index) => {
    return (
      <div key={index}>
        <ul>
          <div className="flex row">
            <svg onClick={() => handleCollaspeCategory(item[it]._id)} className={`w-6 h-6 cursor-pointer`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
            </svg>

            <input type="checkbox" name={item[it]._id} onChange={() => handleChooseCategories(item[it]?._id)} checked={discount?.categories?.includes(item[it]._id) ? true : false} />
            <label className="ml-1">{item[it].name}</label>
          </div>

          <div className="ml-4">
            {renderCategories(Object.assign({}, item[it]?.subCat))}
          </div>
        </ul>
      </div>
    )
  })

  const handleCreateDiscount = () => {
    const data = {
      amount: discount?.amount,
      categories: discount?.categories,
      discountCode: discount?.discountCode,
      global: discount?.global,
      enabled: true,
      startDate: Number(Moment(discount?.startDate).format("x")),
      endDate: Number(Moment(discount?.endDate).format("x")),
      products: discount?.products,
      brands: discount?.brands,
      collections: discount?.collections,
      type: discount?.type
    }

    createDiscount(data).then((res) => {
      if (res.status === 200) {
        setErrorInput([])
        NotificationManager.success("Votre réduction a été créer avec succès !", 'Succès');
        setDataChanged(false);
      }
    }).catch((err) => {
      let tempArr = [];
      Object.keys(err?.response?.data?.error).map((key) => {
        NotificationManager.error(err?.response?.data?.error[key], 'Erreur');
        tempArr.push(key)
      })

      setErrorInput(tempArr);

    })
  }

  const handleEditDiscount = () => {
    const data = {
      amount: discount?.amount,
      categories: discount?.categories,
      discountCode: discount?.discountCode,
      global: discount?.global,
      enabled: true,
      startDate: Number(Moment(discount?.startDate).format("x")),
      endDate: Number(Moment(discount?.endDate).format("x")),
      products: discount?.products,
      collections: discount?.collections,
      brands: discount?.brands,
      type: discount?.type,
      id: discountId
    }

    editDiscount(data).then(() => {
      setDataChanged(false);
      NotificationManager.success("Votre réduction a été modifier avec succès !", 'Succès');
    }).catch((err) => {
      NotificationManager.error(err?.response?.data?.error, 'Erreur')
    })

  }

  const toInputUppercase = (e) => {
    e.target.value = (e.target.value).toUpperCase();
  };

  const handleResetData = () => {
    setDiscount(dataObj);

    if (!discountId) {
      selectInputProducts.current.clearValue();
      selectInputCollections.current.clearValue();
      selectInputBrands.current.clearValue();

      setDiscount({ ...dataObj, type: 'flat' });
    }

    setErrorInput([]);
  }

  const handleChooseProducts = (item) => {
    const selectedValues = item.map(option => option.value);

    const updatedDiscount = { ...discount };
    if (selectedValues.length === 0) {
      delete updatedDiscount.products;
    } else {
      updatedDiscount.products = selectedValues;
    }

    setDiscount(updatedDiscount);
  }

  const handleChooseCollections = (item) => {
    const selectedValues = item.map(option => option.value);

    const updatedDiscount = { ...discount };
    if (selectedValues.length === 0) {
      delete updatedDiscount.collections;
    } else {
      updatedDiscount.collections = selectedValues;
    }

    setDiscount(updatedDiscount);
  }

  const handleChooseBrands = (item) => {
    const selectedValues = item.map(option => option.value);

    const updatedDiscount = { ...discount };
    if (selectedValues.length === 0) {
      delete updatedDiscount.brands;
    } else {
      updatedDiscount.brands = selectedValues;
    }

    setDiscount(updatedDiscount);
  }

  return (
    usePrompt(dataChanged),
    <>
      <div className={`fixed bg-[#080404] top-0 py-[.7rem] px-5 w-full left-0 z-[999] m-0 text-center test ${dataChanged ? "transition ease-in-out delay-250 opacity-1 duration-500" : "opacity-0 delay-250 duration-500 z-[0]"}`}>
        <div className="flex justify-between text-[white]">
          <div className="cursor-pointer" onClick={() => navigate('/')}>
            <svg fill="currentColor" width="38" height="38" viewBox="0 0 174 174" xmlns="http://www.w3.org/2000/svg">
              <path fill="white" fillRule="evenodd" clipRule="evenodd" d="M0 9.23318C0 4.13387 4.13275 0 9.23075 0H164.769C169.867 0 174 4.13387 174 9.23318V41.7256C168.389 42.1805 163.181 43.5167 158.377 45.7341C152.334 48.4078 147.394 52.1858 143.559 57.0681C140.421 51.9533 136.12 48.1172 130.658 45.5598C125.312 42.8861 119.152 41.5493 112.178 41.5493C105.786 41.5493 99.9745 42.6537 94.7445 44.8623C89.5144 47.0711 85.1559 50.2678 81.6691 54.4526V42.9386H60.9231V135.708H82.7151V88.4543C82.7151 79.3872 84.8653 72.4706 89.1657 67.7045C93.5822 62.9385 99.5097 60.5555 106.948 60.5555C113.689 60.5555 118.861 62.6479 122.464 66.8327C126.067 70.9013 127.868 77.0041 127.868 85.1414V135.708H149.661V88.4543C149.661 79.5034 151.869 72.6449 156.285 67.8789C160.702 62.9966 166.571 60.5555 173.893 60.5555L174 60.5557V164.767C174 169.866 169.867 174 164.769 174H9.23075C4.13275 174 0 169.866 0 164.767V9.23318ZM112.321 69.9598C106.222 69.9598 101.199 72.2675 97.4993 76.8928L97.4895 76.9051L97.4798 76.9175C93.89 81.527 92.2757 87.9726 92.2757 95.8953V135.709H97.1218V95.8953C97.1218 88.5482 98.631 83.3425 101.29 79.9206C104.013 76.523 107.607 74.8191 112.321 74.8191V69.9598Z"></path>
            </svg>
          </div>

          <div className="mt-2 font-bold text-md">
            Modifications non enregistrées
          </div>

          <div className="flex flex-row">
            <div onClick={() => handleResetData()} className='border-[1px] border-gray-200 hover:bg-[#eb2f06] hover:border-[#eb2f06] rounded  hover:opacity-[1] cursor-pointer flex flex-row px-4 py-2 mr-2'>
              <i className="fa fa-times text-gray-200 text-[27px] relative top-[.33rem]" />
              <span className="text-gray-200 ml-1 mt-[.1rem] font-bold">Annuler</span>
            </div>
            <div onClick={() => discountId ? handleEditDiscount() : handleCreateDiscount()} className='bg-indigo-700 hover:bg-indigo-500 rounded  hover:opacity-[1] cursor-pointer flex flex-row px-4 py-2'>
              <i className="fa fa-save text-[white] text-[25px]" />
              <span className="text-[white] ml-2 mt-[.1rem] font-bold">Enregistrer</span>
            </div>
          </div>
        </div>
      </div>
      <div className="container-margin">
        <div className="flex row">
          <button onClick={() => navigate('/discounts')} className="text-white cursor-pointer border-[1.2px] border-[#babfc3] bg-transparent transition duration-150 ease-in-out w-10 h-10 rounded flex items-center justify-center ">
            <svg viewBox="0 0 20 20" height={25} width={25} fill='#5c5f62' focusable="false" aria-hidden="true"><path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path></svg>
          </button>


          {discountId
            ? <h1 className="text-xl font-semibold mt-1 ml-4">Éditer une réduction</h1>
            : <h1 className="text-xl font-semibold mt-1 ml-4">Ajouter une réduction</h1>
          }

        </div>

        <div className="grid mt-8">
          <div className="col">
            <div className="flex justify-between w-full">
              <div className="w-full rounded bg-white shadow p-6">

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="relative w-full mr-1">
                    <label className="text-gray-700">
                      Code de réduction <span className="text-[red]">*</span>
                    </label>
                    <input onInput={toInputUppercase} type="text" value={discount?.discountCode || ''} onChange={(e) => { setDiscount({ ...discount, discountCode: e.target.value }) }} placeholder="EN3WUZCLX8" className={`rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2 ${!discount?.discountCode && errorInput.includes("discountCode") && 'border-[1px] border-[red]'}`} />
                    <div className="flex flex-row text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-2">
                      <i className="fa fa-info-circle mt-1" aria-hidden="true"></i>
                      <p className="ml-[.25rem]">Renseignez ou générez un code de réduction.</p>
                    </div>

                    <div className="absolute top-[30px] right-0 mr-2">
                      <button onClick={handleGenerateDiscountCode} className="bg-gray-200 transition duration-150 ease-in-out focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray hover:bg-gray-300 rounded text-white-700 px-3 py-1 flex items-center text-sm mt-2">Générer</button>
                    </div>
                  </div>

                  <div className="relative w-full ml-1">
                    <label className="text-gray-700">
                      Montant <span className="text-[red]">*</span>
                    </label>
                    <input type="number" pattern="[0-9]*"
                      value={discount?.amount || ''} onChange={(e) => { setDiscount({ ...discount, amount: Number(e.target.value) }) }} className={`rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base mt-2 ${(discount?.amount || typeof discount?.amount == 'number') ? '' : errorInput.includes("amount") && 'border-[1px] border-[red]'}`} placeholder="14" />

                    <div className="absolute top-[30px] right-0 mr-2">
                      <div className="flex flex-row">
                        <button onClick={() => setDiscount({ ...discount, type: "flat" })} className={`${discount?.type === 'flat' ? "bg-indigo-600 text-[white]" : "bg-gray-200"} transition duration-150 ease-in-out focus:outline-none border border-transparent px-3 py-1 flex items-center text-sm mt-2`}>€</button>
                        <button onClick={() => setDiscount({ ...discount, type: "percentage" })} className={`${discount?.type === 'percentage' ? "bg-indigo-600 text-[white]" : "bg-gray-200"} transition duration-150 ease-in-out focus:outline-none border border-transparent text-white-700 px-3 py-1 flex items-center text-sm mt-2`}>%</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* DISCOUNT CODE */}

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-6">
                  <div>
                    <label className="text-gray-700">
                      Date de début
                    </label>

                    <div className="mt-2">
                      <DatePicker
                        selected={discount?.startDate || ''}
                        showTimeSelect={true}
                        locale={fr}
                        owTimeSelecthow
                        onChange={(date) => { setDiscount({ ...discount, startDate: date }) }}
                        className="flex w-full border-transparent focus:border-transparent focus:ring-0 py-0"
                        placeholderText="JJ/MM/AAAA HH:MM"
                        dateFormat="dd/MM/yyyy HH:mm"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-700">
                      Date de fin
                    </label>

                    <div className="mt-2">
                      <DatePicker
                        selected={discount?.endDate || ''}
                        showTimeSelect={true}
                        locale={fr}
                        onChange={(date) => { setDiscount({ ...discount, endDate: date }) }}
                        className="flex w-full border-transparent focus:border-transparent focus:ring-0 py-0"
                        placeholderText="JJ/MM/AAAA HH:MM"
                        dateFormat="dd/MM/yyyy HH:mm"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  <label className="text-gray-700 mt-6">
                    Réduction global ?
                  </label>
                  <input type="checkbox" onChange={() => { setDiscount({ ...discount, global: !discount.global }) }} checked={discount?.global} className="ml-4 cursor-pointer relative w-5 h-5 border rounded border-gray-400 bg-white outline-none" />

                  <div className="mt-2">

                    <div className="flex flex-row text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-2">
                      <i className="fa fa-info-circle mt-1" aria-hidden="true"></i>
                      <p className="ml-[.25rem]">Si selectionné, cette réduction s'appliquera au panier .</p>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">

                  <div className="mt-6">
                    <label className="text-gray-700">
                      Limitez-vous à des produits ?
                    </label>

                    <div className="mt-2">

                      <Select
                        styles={{
                          input: (provided) => ({ ...provided, padding: 4 }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused && "rgb(243 244 246)",
                            color: state.isFocused && "black"
                          }),
                          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                          menu: (provided) => ({ ...provided, zIndex: 9999 })
                        }}
                        ref={selectInputProducts}
                        isMulti
                        value={discount?.products?.map((id) => Object.keys(allProducts).find((it) => allProducts[it]._id === id)).map((key) => ({ label: allProducts[key]?.name, value: allProducts[key]?._id, key: allProducts[key]?._id })).filter((item) => item.label)}
                        options={Object.keys(allProducts).map((key) => {
                          if (allProducts[key]?.name) {
                            return { label: allProducts[key].name, value: allProducts[key]._id, key: allProducts[key]._id }
                          } else {
                            return null;
                          }
                        }).filter((item) => item)}
                        placeholder={"Choisissez vos produits..."}
                        onChange={(item) => handleChooseProducts(item)}
                      />

                      <div className="flex flex-row text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-2">
                        <i className="fa fa-info-circle mt-1" aria-hidden="true"></i>
                        <p className="ml-[.25rem]">Si laissé vide, cette réduction fonctionnera pour tous les produits.</p>
                      </div>
                    </div>

                  </div>


                  <div className="mt-6">
                    <label className="text-gray-700 mt-6">
                      Limitez-vous à des collections ?
                    </label>

                    <div className="mt-2">

                      <Select
                        styles={{
                          input: (provided) => ({ ...provided, padding: 4 }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused && "rgb(243 244 246)",
                            color: state.isFocused && "black"
                          }),
                          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                          menu: (provided) => ({ ...provided, zIndex: 9999 })
                        }}
                        value={discount?.collections?.map((id) => Object.keys(allCollections).find((it) => allCollections[it]._id === id)).map((key) => ({ label: allCollections[key]?.name, value: allCollections[key]?._id, key: allCollections[key]?._id })).filter((item) => item.label)}
                        options={Object.keys(allCollections).map((key) => {
                          if (allCollections[key]?.name) {
                            return { label: allCollections[key].name, value: allCollections[key]._id, key: allCollections[key]._id }
                          } else {
                            return null;
                          }
                        }).filter((item) => item)}
                        ref={selectInputCollections}
                        isMulti
                        placeholder={"Choisissez vos collections..."}
                        onChange={(item) => handleChooseCollections(item)}
                      />

                      <div className="flex flex-row text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-2">
                        <i className="fa fa-info-circle mt-1" aria-hidden="true"></i>
                        <p className="ml-[.25rem]">Si laissé vide, cette réduction fonctionnera pour toutes les collections.</p>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="mt-6">
                  <label className="text-gray-700">
                    Limitez-vous à des fournisseurs ?
                  </label>

                  <div className="mt-2">

                    <Select
                      styles={{
                        input: (provided) => ({ ...provided, padding: 4 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused && "rgb(243 244 246)",
                          color: state.isFocused && "black"
                        }),
                        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                        menu: (provided) => ({ ...provided, zIndex: 9999 })
                      }}
                      value={discount?.brands?.map((id) => Object.keys(allBrands).find((it) => allBrands[it]._id === id)).map((key) => ({ label: allBrands[key]?.name, value: allBrands[key]?._id, key: allBrands[key]?._id })).filter((item) => item.label)}
                      options={Object.keys(allBrands).map((key) => {
                        if (allBrands[key]?.name) {
                          return { label: allBrands[key].name, value: allBrands[key]._id, key: allBrands[key]._id }
                        } else {
                          return null;
                        }
                      }).filter((item) => item)}
                      ref={selectInputBrands}
                      isMulti
                      placeholder={"Choisissez vos fournisseurs..."}
                      onChange={(item) => handleChooseBrands(item)}
                    />

                    <div className="flex flex-row text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-2">
                      <i className="fa fa-info-circle mt-1" aria-hidden="true"></i>
                      <p className="ml-[.25rem]">Si laissé vide, cette réduction fonctionnera pour tous les fournisseurs.</p>
                    </div>
                  </div>

                </div>


                <div className="flex justify-between w-full mt-6">
                  <div className="w-full rounded">
                    <h1 className="text-gray-700 mb-2">Limitez-vous à des catégories ou sous-catégories ?</h1>

                    <div className="w-full rounded border hover:border-indigo-300 border-2 border-gray-300 p-3 md:p-7">

                      {renderCategories(allCategories)}

                    </div>
                    <div className="flex flex-row text-[#6d7175] text-[.9rem] opacity-[.45] font-semibold mt-2">
                      <i className="fa fa-info-circle mt-1" aria-hidden="true"></i>
                      <p className="ml-[.25rem]">Si laissé vide, cette réduction fonctionnera pour toutes les catégories.</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDiscount;

