import React from "react";
import { Link } from "react-router-dom";

const PageNonTrouvee = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-indigo-600 text-white">
      {/* <img className="w-[250px] mb-28" src='../../milango-logo-white.svg' /> */}
      <h1 className="text-6xl font-bold mb-4">Oops ! Page non trouvée</h1>
      <p className="text-lg mb-8">La page que vous recherchez n'existe pas.</p>
      <Link to="/" className="bg-white text-indigo-600 px-4 py-2 rounded-full font-semibold text-lg hover:bg-indigo-100">
        Retourner à l'accueil
      </Link>
    </div>
  );
};

export default PageNonTrouvee;
